<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light v-on:click="$router.push('/books')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ bookStore.selectBookshelf.category || 'Loading...' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row grid-list-md v-if="!bookStore.isUpdatePageData">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Book</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddBook">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(book, index) in bookStore.selectBookshelf.books"
                    :key="book._id"
                    v-on:click="onSelectBook(book._id)"
                    :class="index === selectedBookIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ book.name }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        Number of pages
                        {{ book.pages.length }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn
                        class="btn-center"
                        @click="
                          onSelectBook(book._id);
                          onClickHomeData();
                        "
                        flat
                        icon
                      >
                        <v-icon>home</v-icon></v-btn
                      >
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedBookIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Book Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onClickUpdateBook">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img :src="book.cover_uri">
                  <v-container>
                    <v-layout>
                      <v-flex>
                        <v-btn
                          icon
                          color="white"
                          v-on:click="
                            isEditImage = true;
                            isUpdateBook = true;
                          "
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="book.isGuestPlayable"
                        :value="book.isGuestPlayable"
                        v-on:change="
                          e => onChangeBookData('isGuestPlayable', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Name"
                        :value="book.name"
                        v-on:change="e => onChangeBookData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Description"
                        :value="book.description"
                        v-on:change="e => onChangeBookData('description', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Tag"
                        :value="book.tag"
                        v-on:change="e => onChangeBookData('tag', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constants.COLORCODE"
                        label="Color"
                        :value="book.color"
                        v-on:change="e => onChangeBookData('color', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        :value="book.bgm_uri"
                        v-on:change="
                          e => {
                            bgMusicEdit = true;
                            onChangeSoundURI(e);
                          }
                        "
                      ></v-text-field>
                      <v-btn
                        v-on:click="(isEditSound = true), (bgMusicEdit = true)"
                        >Background Music</v-btn
                      >
                      <aplayer
                        v-if="book.bgm_uri"
                        mutex
                        :music="{
                          src: book.bgm_uri,
                        }"
                      ></aplayer>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Read Count"
                        :value="book.read_count"
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-switch
                        :value="book.is_locked"
                        label="Lock Status"
                        v-on:change="e => onChangeBookData('is_locked', e)"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteBook(book._id)"
                  >
                    <v-icon>delete</v-icon>Delete this book
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs1 mr-2 v-if="pages.length > 0 && selectedBookIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Page</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddPage">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(pages, index) in pages"
                    :key="pages._id"
                    v-on:click="onSelectPage(pages._id)"
                    :class="index === selectedPageIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ index + 1 }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="Object.keys(page).length > 1 && selectedPageIndex != -1"
              xs8
              mr-2
            >
              <v-card dark>
                <v-card-actions>
                  <v-btn icon fab color="green" v-on:click="onClickSavePage">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    fab
                    color="red"
                    v-on:click="onClickDeletePage(page._id)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <img
                          :src="page.img_uri"
                          :alt="page.text"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <span class="headline" dark>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="isEditImage = true"
                                >
                                  <v-icon>edit</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="onClickFullscreen(page.img_uri)"
                                >
                                  <v-icon>fullscreen</v-icon>
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-text-field
                              label="Text"
                              :value="page.text"
                              v-on:change="onChangeText"
                            />
                          </v-flex>

                          <v-flex>
                            <v-text-field
                              label="Sound URI"
                              :value="page.sound_uri"
                              v-on:change="e => onChangeSoundURI(e)"
                            ></v-text-field>
                            <v-btn v-on:click="isEditSound = true"
                              >Select Sound</v-btn
                            >
                            <aplayer
                              v-if="page.sound_uri"
                              mutex
                              :music="{
                                src: page.sound_uri,
                              }"
                            ></aplayer>
                            <v-btn v-on:click="isStickers = true"
                              >Stickers</v-btn
                            >
                            <ModalStickers
                              v-if="isStickers"
                              :isOpenSticker="isStickers"
                              :dataArray="page.stickers"
                              @saveModalEditSticker="saveModalEditSticker"
                              @closeModalSticker="closeModalSticker"
                            >
                            </ModalStickers>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
      :save="saveEditImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedBookIndex != -1
          ? bookStore.selectBookshelf.books[selectedBookIndex].learned_words
          : null
      "
      :incidentalWords="
        selectedBookIndex != -1
          ? bookStore.selectBookshelf.books[selectedBookIndex].incidental_words
          : null
      "
      :rewards="
        selectedBookIndex != -1
          ? bookStore.selectBookshelf.books[selectedBookIndex].reward
          : null
      "
      :sentences="
        selectedBookIndex != -1
          ? bookStore.selectBookshelf.books[selectedBookIndex].sentence
          : null
      "
      :phonics="
        selectedBookIndex != -1
          ? bookStore.selectBookshelf.books[selectedBookIndex].phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddPage"
      :isOpen="isAddPage"
      :close="toggleAddPage"
      :save="onClickAddPage"
      title="Add Page"
    >
      <v-container>
        <v-layout grid-list-xs>
          <v-flex>
            <v-text-field
              label="Text"
              :value="bookStore.addPageData.text"
              v-on:change="e => onChangeCreatePage('text', e)"
            ></v-text-field>
            <img
              :src="bookStore.addPageData.img_uri"
              width="400"
              height="300"
            /><br />
            <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
            <v-text-field
              label="Sound URI"
              :value="bookStore.addPageData.sound_uri"
              v-on:change="e => onChangeCreatePage('sound_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
            <aplayer
              v-if="selectSoundUri"
              mutex
              :music="{
                src: selectSoundUri,
              }"
            ></aplayer>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddBook"
      :isOpen="isAddBook"
      :close="toggleAddBook"
      :save="onClickAddBook"
      title="Add Book"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex justify-center>
            <img
              :src="bookStore.addBookData.cover_uri"
              width="250"
              height="250"
            /><br />
            <v-btn v-on:click="isEditImage = true">Select Cover Image</v-btn>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Name"
              v-on:change="e => onChangeCreateBook(e, 'name')"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Description"
              v-on:change="e => onChangeCreateBook(e, 'description')"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Tag"
              v-on:change="e => onChangeCreateBook(e, 'tag')"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.COLORCODE"
              label="Color"
              v-on:change="e => onChangeCreateBook(e, 'color')"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-container>
              <v-layout row>
                <v-flex xs2 mr-5>
                  <v-text-field
                    label="Minimum Age"
                    type="number"
                    outline
                    v-model="minMax[0]"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-range-slider
                    :max="15"
                    :min="1"
                    :step="1"
                    v-model="minMax"
                  ></v-range-slider>
                </v-flex>
                <v-flex xs2 ml-5>
                  <v-text-field
                    label="Maximum Age"
                    type="number"
                    outline
                    v-model="minMax[1]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
    <HomeDataAssign
      :isOpen="isOpenHomeData"
      :gameData="book"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.Book"
      @close="isOpenHomeData = false"
    ></HomeDataAssign>
  </Container>
</template>

<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 300px;
  width: 400px;
  border-radius: 10px;
}
</style>

<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import Aplayer from 'vue-aplayer';
import swal from 'sweetalert2';
import constants from '../../constants';
import ModalStickers from '../../components/ModalStickers.vue';
import HomeDataAssign from '../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../HomePage/constant';

export default {
  name: 'BookShelf',
  components: {
    Container,
    Aplayer,
    ResourceSelect,
    Modal,
    PointData,
    ModalStickers,
    HomeDataAssign,
  },
  computed: {
    ...mapState({
      bookStore: state => state.books,
      selectedBookIndexStore: state => state.books.selectedBookIndex,
    }),
  },
  data: function () {
    return {
      minMax: [1, 5],
      pages: [],
      page: {},
      book: {},
      selectedBookIndex: -1,
      selectedPageIndex: -1,
      isEditImage: false,
      isEditSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddPage: false,
      isAddBook: false,
      isUpdateBook: false,
      isUpdateSound: false,
      constants,
      selectBookId: '',
      isOpenPointData: false,
      isStickers: false,
      bgMusicEdit: false,
      isOpenHomeData: false,
      constantHomePage,
    };
  },
  methods: {
    ...mapActions('books', [
      'fetchBookShelf',
      'fetchSingleBook',
      'fetchSingleBookByCategory',
      'updatePageData',
      'updatePage',
      'clearUpdateData',
      'deletePage',
      'deleteBook',
      'createPageData',
      'addPage',
      'updateBookData',
      'updateBook',
      'updateCreateBookData',
      'addBook',
      'clearCreatePage',
      'clearCreateBook',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateBookData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateBookData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateBookData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateBookData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateBookData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateBook();
      this.isOpenPointData = false;
    },
    onSelectBook(id) {
      const books = this.bookStore.selectBookshelf.books;
      const index = books.findIndex(book => book._id === id);
      this.selectedBookIndex = index;
      this.selectedPageIndex = -1;
      this.pages = [];
      this.page = {};
      this.pages = [...books[index].pages];
      let formatBook = {};
      Object.keys(books[index]).forEach(key => {
        if (key === 'color') {
          const colorcode = books[index][key];
          const colorIndex = constants.COLORCODE.findIndex(
            code => code.value === colorcode,
          );
          if (colorIndex !== -1) {
            formatBook[key] = constants.COLORCODE[colorIndex];
          } else {
            formatBook[key] = {
              text: books[index]['color'],
              value: books[index]['color'],
            };
          }
        } else {
          formatBook[key] = books[index][key];
        }
      });
      this.book = { ...formatBook };
      this.createPageData({ key: 'book_id', value: id });
      this.updateBookData({ key: '_id', value: id });
      this.selectBookId = id;
    },
    onSelectPage(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this page yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.isDirty = false;
              const pages = this.pages;
              const index = pages.findIndex(page => page._id === id);
              this.selectedPageIndex = index;
              this.page = pages[index];
              this.selectImageUri = '';
              this.selectSoundUri = '';
              this.clearUpdateData();
              this.updatePageData({ key: '_id', value: id });
            }
          });
      } else {
        const pages = this.pages;
        const index = pages.findIndex(page => page._id === id);
        this.selectedPageIndex = index;
        this.page = pages[index];
        this.selectImageUri = '';
        this.selectSoundUri = '';
        this.clearUpdateData();
        this.updatePageData({ key: '_id', value: id });
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddPage) {
        this.createPageData({ key: 'img_uri', value: uri });
      } else if (this.isUpdateBook) {
        this.isUpdateBook = false;
        this.updateBookData({ key: 'cover_uri', value: uri });
      } else if (this.isAddBook) {
        this.updateCreateBookData({ key: 'cover_uri', value: uri });
      } else {
        this.isDirty = true;
        this.page.img_uri = uri;
        this.updatePageData({ key: 'img_uri', value: uri });
        this.selectImageUri = '';
      }
      this.isEditImage = !this.isEditImage;
      // this.closeEditImage();
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddPage) {
        this.createPageData({ key: 'sound_uri', value: uri });
      } else if (this.bgMusicEdit) {
        this.bgMusicEdit = false;
        this.updateBookData({ key: 'bgm_uri', value: uri });
        this.book.bgm_uri = uri;
      } else {
        this.isDirty = true;
        this.updatePageData({ key: 'sound_uri', value: uri });
        this.page.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onChangeText(e) {
      this.isDirty = true;
      this.updatePageData({ key: 'text', value: e });
    },
    onChangeSoundURI(e) {
      if (this.bgMusicEdit) {
        this.updateBookData({ key: 'bgm_uri', value: e });
        this.bgMusicEdit = false;
      } else {
        this.isDirty = true;
        this.updatePageData({ key: 'sound_uri', value: e });
      }
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    toggleAddPage() {
      this.isAddPage = !this.isAddPage;
    },
    onClickSavePage() {
      swal
        .fire({
          titleText: 'Are you sure to update this page.',
          type: 'question',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            const updatePageData = { ...this.bookStore.updatePageData };
            this.updatePage(updatePageData);
            this.isDirty = false;
            // this.selectedPageIndex = -1;
            // this.page = {};
            // this.pages = [];
            // this.selectedBookIndex = -1;
          }
        });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onClickDeletePage(id) {
      swal
        .fire({
          titleText: 'Confirm Delete Page',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deletePage(id);
            this.isDirty = false;
            this.selectedPageIndex = -1;
            this.page = {};
            // this.pages = [];
            // this.selectedBookIndex = -1;
          }
        });
    },
    onClickDeleteBook(id) {
      swal
        .fire({
          titleText: 'Confirm Delete Book',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteBook(id);
            this.isDirty = false;
            this.selectedPageIndex = -1;
            this.page = {};
            this.pages = [];
            this.selectedBookIndex = -1;
          }
        });
    },
    onClickAddPage() {
      const { book_id, ...data } = { ...this.bookStore.addPageData };
      this.addPage({ id: book_id, data });
      this.isAddPage = false;
      this.selectedPageIndex = -1;
      this.page = {};
      this.clearCreatePage();
      // this.pages = [];
      // this.selectedBookIndex = -1;
    },
    onChangeCreatePage(key, value) {
      this.createPageData({ key, value });
    },
    toggleAddBook() {
      this.updateCreateBookData({
        key: 'bookshelf_id',
        value: this.$route.query.id,
      });
      this.isAddBook = !this.isAddBook;
    },
    onClickAddBook() {
      swal
        .fire({
          titleText: 'Are you sure to add book',
          type: 'info',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.updateCreateBookData({
              key: 'min_age',
              value: this.minMax[0],
            });
            this.updateCreateBookData({
              key: 'max_age',
              value: this.minMax[1],
            });
            const createBookData = { ...this.bookStore.addBookData };
            this.addBook(createBookData);
            this.selectBookId = '';
            this.selectedBookIndex = -1;
            this.selectedPageIndex = -1;
            this.selectImageUri = '';
            this.selectSoundUri = '';
            this.isAddBook = false;
            this.clearCreateBook();
          }
        });
    },
    onChangeBookData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateBookData({ key, value });
    },
    onClickUpdateBook() {
      swal
        .fire({
          titleText: 'Confirm Update Book',
          showCancelButton: true,
          type: 'question',
        })
        .then(result => {
          if (result.value) {
            const updateBookData = { ...this.bookStore.updateBookData };
            this.updateBook(updateBookData);
            // this.selectedBookIndex = -1;
            this.selectedPageIndex = -1;
            this.page = {};
            // this.pages = [];
          }
        });
    },
    onChangeCreateBook(value, key) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateCreateBookData({ key, value });
    },
    closeModalSticker(data) {
      this.page.stickers = [...data];
      this.isStickers = false;
    },
    saveModalEditSticker(data) {
      let stickers = Object.values(data);
      this.updatePageData({
        key: 'stickers',
        value: [...stickers],
      });
      this.page.stickers = [...data];
      this.isStickers = false;
      this.isDirty = true;
    },
    onClickHomeData() {
      this.isOpenHomeData = true;
    },
  },
  watch: {
    selectedBookIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectBook(this.$route.query.sub_id);
      }
    },
    'bookStore.selectBookshelf.books'(books) {
      const indexBook = this.selectedBookIndex;
      const indexPage = this.selectedPageIndex;
      if (indexBook != -1) {
        let formatBook = {};
        Object.keys(books[indexBook]).forEach(key => {
          if (key === 'color') {
            const colorcode = books[indexBook][key];
            const colorIndex = constants.COLORCODE.findIndex(
              code => code.value === colorcode,
            );
            if (colorIndex !== -1) {
              formatBook[key] = constants.COLORCODE[colorIndex];
            } else {
              formatBook[key] = {
                text: books[indexBook]['color'],
                value: books[indexBook]['color'],
              };
            }
          } else {
            formatBook[key] = books[indexBook][key];
          }
        });
        this.book = { ...formatBook };
        this.pages = [...books[indexBook].pages];
      }
      if (indexPage != -1) {
        this.page = { ...this.pages[indexPage] };
      }
    },
  },
  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleBookByCategory({
        category_id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (Object.keys(this.bookStore.selectBookshelf).length < 1) {
      this.fetchSingleBook(this.$route.query.id);
    }
  },
};
</script>

<style scoped>
.active {
  background-color: #242424;
}
</style>
