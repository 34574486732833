<template>
  <div>
    <Chat
      v-if="isOepn"
      :participants="participants"
      :myself="myself"
      :messages="messages"
      :chat-title="chatTitle"
      :placeholder="placeholder"
      :colors="colors"
      :border-style="borderStyle"
      :hide-close-button="hideCloseButton"
      :close-button-icon-size="closeButtonIconSize"
      :submit-icon-size="submitIconSize"
      :submit-image-icon-size="submitImageIconSize"
      :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
      :async-mode="asyncMode"
      :scroll-bottom="scrollBottom"
      :display-header="displayHeader"
      :send-images="sendImage"
      :profile-picture-config="profilePictureConfig"
      :timestamp-config="timestampConfig"
      :link-options="linkOptions"
      :accept-image-types="'.png, .jpeg'"
      @onImageClicked="onImageClicked"
      @onImageSelected="onImageSelected"
      @onMessageSubmit="onMessageSubmit"
      @onType="onType"
      @onClose="onClose"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Chat } from 'vue-quick-chat';
import 'vue-quick-chat/dist/vue-quick-chat.css';
export default {
  name: 'CustomerServiceChat',
  components: { Chat },
  methods: {
    ...mapActions('customer_service_chat', ['sendMessageToCustomer']),
    onType() {
      // console.log('onType');
    },
    onMessageSubmit(value) {
      const payload = {
        customer_id: this.customerId,
        message: value.content,
      };
      this.sendMessageToCustomer(payload);
    },
    onImageClicked() {},
    onImageSelected() {},
    onClose() {
      this.$emit('closeChatbox');
    },
  },
  props: {
    isOepn: {
      type: Boolean,
      default: false,
      required: true,
    },
    customerId: {
      type: String,
      default: () => '',
    },
    chatTitle: {
      type: String,
      default: () => '',
    },
    participants: {
      type: Array,
      default: () => [],
    },
    myself: {
      type: Object,
      default: () => {},
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadMoreMessages: null,
      isClose: false,
      sendImage: false,
      placeholder: 'Enter a message',
      colors: {
        header: {
          bg: '#000000de',
          text: '#fff',
        },
        message: {
          myself: {
            bg: '#000000de',
            text: '#fff',
          },
          others: {
            bg: '#5834c1',
            text: '#fff',
          },
          messagesDisplay: {
            bg: '#f7f3f3',
          },
        },
        submitIcon: '#b91010',
        submitImageIcon: '#b91010',
      },
      borderStyle: {
        topLeft: '3px',
        topRight: '3px',
        bottomLeft: '3px',
        bottomRight: '3px',
      },
      hideCloseButton: false,
      submitIconSize: 25,
      submitImageIconSize: 25,
      closeButtonIconSize: '20px',
      asyncMode: false,
      toLoad: [
        {
          content: 'Hey, John Doe! How are you today?',
          myself: false,
          participantId: 2,
          timestamp: {
            year: 2011,
            month: 3,
            day: 5,
            hour: 10,
            minute: 10,
            second: 3,
            millisecond: 123,
          },
          uploaded: true,
          viewed: true,
          type: 'text',
        },
        {
          content: "Hey, Adam! I'm feeling really fine this evening.",
          myself: true,
          participantId: 3,
          timestamp: {
            year: 2010,
            month: 0,
            day: 5,
            hour: 19,
            minute: 10,
            second: 3,
            millisecond: 123,
          },
          uploaded: true,
          viewed: true,
          type: 'text',
        },
      ],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      displayHeader: false,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: '30px',
          height: '30px',
          borderRadius: '50%',
        },
      },
      timestampConfig: {
        format: 'DD HH:mm',
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: 'myLinkClass',
          events: {
            click: function(e) {
              // alert('Link clicked!');
            },
            mouseover: function(e) {
              // alert('Link hovered!');
            },
          },
          format: function(value, type) {
            if (type === 'url' && value.length > 500) {
              value = value.slice(0, 500) + '…';
            }
            return value;
          },
        },
        others: {
          className: 'othersLinkClass',
          events: {
            click: function(e) {
              // alert('Link clicked!');
            },
            mouseover: function(e) {
              // alert('Link hovered!');
            },
          },
          format: function(value, type) {
            if (type === 'url' && value.length > 500) {
              value = value.slice(0, 500) + '…';
            }
            return value;
          },
        },
      },
    };
  },
  mounted() {},
};
</script>

<style scoped>
body {
  overflow: hidden;
}

.quick-chat-container >>> .header-container .header-title {
  padding-top: 25px;
}

.quick-chat-container >>> .container-message-display {
  overflow: auto;
  height: 696px;
  flex: auto;
}
</style>
