<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid && !data.start_time"
      :title="'Live Class Information'"
    >
      <v-card>
        <v-toolbar dark tabs>
          <v-toolbar-title>Live Class Data</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-form
            ref="formData"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="_id"
                  :value="data._id"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="teacher_id"
                  :value="data.teacher_id"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="child name"
                  :value="data.child_name"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="create_time"
                  :value="moment(data.create_time).format('MMMM D YYYY h:mm a')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="start_time"
                  :value="
                    data.start_time
                      ? moment(data.start_time).format('MMMM D YYYY h:mm a')
                      : 'invalid'
                  "
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="end_time"
                  :value="
                    data.end_time
                      ? moment(data.end_time).format('MMMM D YYYY h:mm a')
                      : 'invalid'
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs8>
                <v-select
                  :disabled="data.start_time !== null"
                  :items="liveClassTemplatesList"
                  item-text="title"
                  item-value="_id"
                  v-model="data.template_id"
                  label="template_id"
                  :rules="[rules.required]"
                  @change="e => onchangeLiveClass('template_id', e)"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
      <v-card v-if="data.live_class_offer_data">
        <v-toolbar dark tabs>
          <v-toolbar-title>Offer Cards</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-form
            ref="formOffer"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="offer_id"
                  :value="data.offer_id"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="child_id"
                  :value="data.live_class_offer_data.child_id"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-flex>
                  <v-text-field
                    disabled
                    label="create_time"
                    :value="
                      moment(data.live_class_offer_data.create_time).format(
                        'MMMM D YYYY h:mm a',
                      )
                    "
                  ></v-text-field>
                </v-flex>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="duration"
                  :value="data.live_class_offer_data.duration"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <v-text-field
                  disabled
                  label="status"
                  :value="
                    formatDisplaystatus(data.live_class_offer_data.status)
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <!-- <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    /> -->
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from '../constants';
import globalConstants from '../../../../constants';
import moment from 'moment';

export default {
  components: { Modal },
  name: 'LiveclassOfferCardsModal',
  data() {
    return {
      moment,
      globalConstants,
      constants,
      valid: true,
      rules,
      data: {},
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      liveClassOfferList: state => state.liveclass.liveClassOfferList,
      liveClassTemplatesList: state => state.liveclass.liveClassTemplatesList,
      selectedOfferCard: state => state.liveclass.selectedOfferCard,
      updateLiveClassData: state => state.liveclass.updateLiveClassData,
    }),
  },
  methods: {
    ...mapActions('liveclass', [
      'fetchOfferByOwnerUserId',
      'fetchLiveClassTempateList',
      'updateOfferCard',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formData.validate()) {
        this.updateOfferCard();
        this.close();
      }
    },
    close() {
      this.clearStateByKey('selectedOfferCard');
      this.clearStateByKey('updateLiveClassData');
      this.$emit('close');
    },
    onchangeLiveClass(key, value) {
      this.setStateByKey({
        statekey: 'updateLiveClassData',
        key,
        value,
      });
    },
    formatDisplaystatus(value) {
      if (value >= 0) {
        switch (value) {
          case constants.STATUS.WAITING:
            return 'WAITING';
          case constants.STATUS.CANCLE:
            return 'CANCLE';
          case constants.STATUS.PREPARING:
            return 'PREPARING';
          case constants.STATUS.READY:
            return 'READY';
          default:
            return null;
        }
      } else {
        return null;
      }
    },
  },
  watch: {
    async isOpen(val) {
      if (val) {
        await this.fetchLiveClassTempateList(true);
        this.data = JSON.parse(JSON.stringify({ ...this.selectedOfferCard }));
      }
    },
  },
};
</script>

<style></style>
