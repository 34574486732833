<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Live Class (Live Streaming)</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-list class="pt-0">
            <v-list-tile
              v-for="item in items"
              :key="item.title"
              @click="toRouter(item)"
            >
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import Container from '../../components/Container.vue';

export default {
  name: 'live_class',
  components: {
    Container,
  },
  data() {
    return {
      items: [
        {
          title: 'OfferCards',
          icon: 'view_list',
          path: '/liveclass/offercards',
        },
        // {
        //   title: 'TeachClass',
        //   icon: 'cast_for_education',
        //   path: '/teach_class',
        // },
        {
          title: 'ClassTemplates',
          icon: 'class',
          path: '/liveclass/class_template',
        },
      ],
    };
  },
  methods: {
    toRouter({ path }) {
      this.$router.push({ path: path });
    },
  },
};
</script>
