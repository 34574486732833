export default {
  NODE_TYPE: {
    QUESTION: 0,
    ANSWER: 1,
    ANIMATION: 2,
    IMAGE: 3,
    VIDEO: 4,
    QUESTION_POPUP: 5,
    IMAGE_POPUP: 6,
    INTERACTION: 7,
    ITEM: 8,
    CARD: 9,
    SOUND: 10,
  },
  NODE_TYPE_LIST: [
    {
      text: 'Question Node',
      value: 0,
    },
    {
      text: 'Answer Node',
      value: 1,
    },
    {
      text: 'Animation Node',
      value: 2,
    },
    {
      text: 'Image Node',
      value: 3,
    },
    {
      text: 'Video Node',
      value: 4,
    },
    {
      text: 'Question Popup Node',
      value: 5,
    },
    {
      text: 'Image Popup Node',
      value: 6,
    },
    {
      text: 'Interaction Node',
      value: 7,
    },
    {
      text: 'Item Node',
      value: 8,
    },
    {
      text: 'Card Node',
      value: 9,
    },
    {
      text: 'Sound Node',
      value: 10,
    },
  ],
  TYPE_FOR_SKIP_LEGACY_PROCESS_IS_FALSE: [
    {
      text: 'Question Node',
      value: 0,
    },
    {
      text: 'Answer Node',
      value: 1,
    },
    {
      text: 'Animation Node',
      value: 2,
    },
    {
      text: 'Image Node',
      value: 3,
    },
  ],
  TYPE_FOR_SKIP_LEGACY_PROCESS_IS_TRUE: [
    {
      text: 'Answer Node',
      value: 1,
    },
    {
      text: 'Animation Node',
      value: 2,
    },
    {
      text: 'Video Node',
      value: 4,
    },
    {
      text: 'Question Popup Node',
      value: 5,
    },
    {
      text: 'Image Popup Node',
      value: 6,
    },
    {
      text: 'Interaction Node',
      value: 7,
    },
    {
      text: 'Item Node',
      value: 8,
    },
    {
      text: 'Card Node',
      value: 9,
    },
    {
      text: 'Sound Node',
      value: 10,
    },
  ],
  TYPE: [
    {
      text: 'Question Node',
      value: 0,
    },
    {
      text: 'Answer Node',
      value: 1,
    },
    {
      text: 'Animation Node',
      value: 2,
    },
    {
      text: 'Image Node',
      value: 3,
    },
    {
      text: 'Video Node',
      value: 4,
    },
    {
      text: 'Question Popup Node',
      value: 5,
    },
    {
      text: 'Image Popup Node',
      value: 6,
    },
    {
      text: 'Interaction Node',
      value: 7,
    },
    {
      text: 'Item Node',
      value: 8,
    },
    {
      text: 'Card Node',
      value: 9,
    },
    {
      text: 'Sound Node',
      value: 10,
    },
  ],
  MAIN_CHARACTER: [
    {
      text: 'Nana',
      value: 0,
    },
    {
      text: 'Noodle Monster',
      value: 1,
    },
    {
      text: 'Maxi',
      value: 2,
    },
  ],
  CHARACTER_SIZE: [
    {
      text: 'Small',
      value: 0,
    },
    {
      text: 'Medium',
      value: 1,
    },
    {
      text: 'Large',
      value: 2,
    },
  ],
  CHARACTER_POSITION: [
    {
      text: 'Center',
      value: 0,
    },
    {
      text: 'Left',
      value: 1,
    },
    {
      text: 'Right',
      value: 2,
    },
  ],
  START_ANIMATION: [
    {
      text: 'None',
      value: 0,
    },
    {
      text: 'Idle',
      value: 1,
    },
    {
      text: 'Talk',
      value: 2,
    },
    {
      text: 'Listen',
      value: 3,
    },
    {
      text: 'Celebate',
      value: 4,
    },
    {
      text: 'Greeting',
      value: 5,
    },
    {
      text: 'Incorrect',
      value: 6,
    },
    {
      text: 'Thinking',
      value: 15,
    },
    {
      text: 'Cry',
      value: 16,
    },
    {
      text: 'Instrument',
      value: 17,
    },
  ],
  START_ANIMATION_DEV_ROLE: [
    {
      text: 'None',
      value: 0,
    },
    {
      text: 'Idle',
      value: 1,
    },
    {
      text: 'Talk',
      value: 2,
    },
    {
      text: 'Listen',
      value: 3,
    },
    {
      text: 'Celebate',
      value: 4,
    },
    {
      text: 'Greeting',
      value: 5,
    },
    {
      text: 'Incorrect',
      value: 6,
    },
    {
      text: 'Angry',
      value: 7,
    },
    {
      text: 'Funny1',
      value: 8,
    },
    {
      text: 'Funny2',
      value: 9,
    },
    {
      text: 'Happy',
      value: 10,
    },
    {
      text: 'Jump',
      value: 11,
    },
    {
      text: 'Lookaround',
      value: 12,
    },
    {
      text: 'Sad',
      value: 13,
    },
    {
      text: 'Scare',
      value: 14,
    },
    {
      text: 'Thinking',
      value: 15,
    },
    {
      text: 'Cry',
      value: 16,
    },
    {
      text: 'Instrument',
      value: 17,
    },
  ],
  END_ANIMATION_DEV_ROLE: [
    {
      text: 'None',
      value: 0,
    },
    {
      text: 'Idle',
      value: 1,
    },
    {
      text: 'Talk',
      value: 2,
    },
    {
      text: 'Listen',
      value: 3,
    },
    {
      text: 'Celebate',
      value: 4,
    },
    {
      text: 'Greeting',
      value: 5,
    },
    {
      text: 'Incorrect',
      value: 6,
    },
    {
      text: 'Angry',
      value: 7,
    },
    {
      text: 'Funny1',
      value: 8,
    },
    {
      text: 'Funny2',
      value: 9,
    },
    {
      text: 'Happy',
      value: 10,
    },
    {
      text: 'Jump',
      value: 11,
    },
    {
      text: 'Lookaround',
      value: 12,
    },
    {
      text: 'Sad',
      value: 13,
    },
    {
      text: 'Scare',
      value: 14,
    },
    {
      text: 'Thinking',
      value: 15,
    },
    {
      text: 'Cry',
      value: 16,
    },
    {
      text: 'Instrument',
      value: 17,
    },
  ],
  TAP_ANIMATION: [
    {
      text: 'Laugh',
      value: 100,
    },
    {
      text: 'Reaction',
      value: 101,
    },
  ],
  END_ANIMATION: [
    {
      text: 'None',
      value: 0,
    },
    {
      text: 'Idle',
      value: 1,
    },
    {
      text: 'Talk',
      value: 2,
    },
    {
      text: 'Listen',
      value: 3,
    },
    {
      text: 'Celebate',
      value: 4,
    },
    {
      text: 'Greeting',
      value: 5,
    },
    {
      text: 'Incorrect',
      value: 6,
    },
    {
      text: 'Thinking',
      value: 15,
    },
    {
      text: 'Cry',
      value: 16,
    },
    {
      text: 'Instrument',
      value: 17,
    },
  ],
  VIDEO_POSITION: [
    {
      text: 'Center',
      value: 1,
    },
    {
      text: 'Left',
      value: 2,
    },
    {
      text: 'Right',
      value: 3,
    },
  ],
  AFTER_VIDEO_ACTION: [
    {
      text: 'Show character with background',
      value: 1,
    },
    {
      text: 'Show only character',
      value: 2,
    },
    {
      text: 'Show nothing',
      value: 3,
    },
  ],
  IMAGE_LAYOUT_POSITION: [
    {
      text: 'Middle',
      value: 1,
    },
    {
      text: 'Left',
      value: 2,
    },
    {
      text: 'Right',
      value: 3,
    },
  ],
  ITEM_ANIMATION: [
    {
      text: 'None',
      value: 0,
    },
    {
      text: 'Idle',
      value: 1,
    },
    {
      text: 'Talk',
      value: 2,
    },
    {
      text: 'Listen',
      value: 3,
    },
    {
      text: 'Celebate',
      value: 4,
    },
    {
      text: 'Greeting',
      value: 5,
    },
    {
      text: 'Incorrect',
      value: 6,
    },
    {
      text: 'Angry',
      value: 7,
    },
    {
      text: 'Funny1',
      value: 8,
    },
    {
      text: 'Funny2',
      value: 9,
    },
    {
      text: 'Happy',
      value: 10,
    },
    {
      text: 'Jump',
      value: 11,
    },
    {
      text: 'Lookaround',
      value: 12,
    },
    {
      text: 'Sad',
      value: 13,
    },
    {
      text: 'Scare',
      value: 14,
    },
    {
      text: 'Open Mouth',
      value: 201,
    },
    {
      text: 'Chew',
      value: 202,
    },
  ],
};
