<template>
  <div>
    <v-content>
      <v-flex>
        <img :src="maze.cell_image_uri" class="content_img" />
        <v-text-field
          label="Cell Image URI"
          v-model="maze.cell_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectCell')"
          ><v-icon>image</v-icon>Select Cell Image URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.wall_left_image_uri" class="content_img" />
        <v-text-field
          label="Wall Left Image URI"
          v-model="maze.wall_left_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectWallLeft')"
          ><v-icon>image</v-icon>Select Wall Left Image URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.wall_right_image_uri" class="content_img" />
        <v-text-field
          label="Wall Right Image URI"
          v-model="maze.wall_right_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectWallRight')"
          ><v-icon>image</v-icon>Select Wall Right Image URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.wall_up_image_uri" class="content_img" />
        <v-text-field
          label="Wall Up Image URI"
          v-model="maze.wall_up_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectWallUp')"
          ><v-icon>image</v-icon>Select Wall Up URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.wall_down_image_uri" class="content_img" />
        <v-text-field
          label="Wall Down Image URI"
          v-model="maze.wall_down_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectWallDown')"
          ><v-icon>image</v-icon>Select Wall Down Image URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.arrow_image_uri" class="content_img" />
        <v-text-field
          label="Arrow Image URI"
          v-model="maze.arrow_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectArrow')"
          ><v-icon>image</v-icon>Select Arrow Image URI</v-btn
        >
      </v-flex>
      <v-flex>
        <img :src="maze.joint_image_uri" class="content_img" />
        <v-text-field
          label="Joint Image URI"
          v-model="maze.joint_image_uri"
        ></v-text-field>
        <v-btn class="btn-center" @click="onclickImage('isSelectJoint')"
          ><v-icon>image</v-icon>Select Joint Image URI</v-btn
        >
      </v-flex>
    </v-content>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
  </div>
</template>

<script>
import ResourceSelect from '../../../components/ResourceSelect.vue';
export default {
  components: {
    ResourceSelect,
  },
  data() {
    return {
      isSelectImage: false,
      selectedImageUri: '',
      isSelectCell: false,
      isSelectWallLeft: false,
      isSelectWallRight: false,
      isSelectWallUp: false,
      isSelectWallDown: false,
      isSelectArrow: false,
      isSelectJoint: false,
    };
  },
  props: {
    maze: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    onclickImage(key) {
      this.isSelectImage = true;
      this[key] = true;
      // this.isSelectCell = true;
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      switch (true) {
        case this.isSelectCell:
          this.maze.cell_image_uri = uri;
          this.isSelectCell = false;
          break;
        case this.isSelectWallLeft:
          this.maze.wall_left_image_uri = uri;
          this.isSelectWallLeft = false;
          break;
        case this.isSelectWallRight:
          this.maze.wall_right_image_uri = uri;
          this.isSelectWallRight = false;
          break;
        case this.isSelectWallUp:
          this.maze.wall_up_image_uri = uri;
          this.isSelectWallUp = false;
          break;
        case this.isSelectWallDown:
          this.maze.wall_down_image_uri = uri;
          this.isSelectWallDown = false;
          break;
        case this.isSelectArrow:
          this.maze.arrow_image_uri = uri;
          this.isSelectArrow = false;
          break;
        case this.isSelectJoint:
          this.maze.joint_image_uri = uri;
          this.isSelectJoint = false;
          break;
        default:
          break;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
  },
};
</script>

<style scoped>
.content_img {
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
.btn-center {
  margin: 0 auto;
  display: flex;
}
</style>
