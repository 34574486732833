<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark>
          <v-toolbar-title>Users Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-slot:extension>
            <v-tabs dark centered slider-color="white">
              <v-tab
                dark
                v-for="(type, index) in typeList"
                :key="index"
                @click="onChangeTab(type)"
                >{{ type }}</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-text-field
                  v-if="this.selectedType !== 'user deletion time'"
                  outline
                  v-model="search"
                  label="Press enter for search"
                  append-icon="search"
                  @keyup.enter="callSearch(search)"
                ></v-text-field>
                <v-btn v-else color="black" @click="deleteUserDeletionTime()">
                  Delete user by deletion time
                </v-btn>
              </v-flex>
              <!--v-flex xs3>
                <v-layout justify-space-between>
                  <v-menu
                    v-model="start_date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          start && start
                            ? moment(start).format('YYYY MMMM D')
                            : moment(start).format('YYYY MMMM D')
                        "
                        label="Subscription Start Date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-on:input="handleChangeStartDate"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="end_date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          end && end
                            ? moment(end).format('YYYY MMMM D')
                            : moment(end).format('YYYY MMMM D')
                        "
                        label="Subscription End Date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-on:input="handleChangeEndDate"
                    ></v-date-picker>
                  </v-menu>
                </v-layout>
              </v-flex-->
            </v-layout>
          </v-container>
          <v-data-table
            v-if="!usersStore.isFetchUserList"
            :headers="headers"
            :items="usersStore.showUserList"
            :server-items-length="pagerStore.totalItems"
            :pagination.sync="pagination"
            hide-actions
          >
            <template v-slot:items="props">
              <td>{{ props.item._id }}</td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.firstname }}</td>
              <td>{{ props.item.lastname }}</td>
              <td>{{ props.item.email }}</td>
              <td>
                <v-icon v-if="props.item.is_validate">checked</v-icon>
                <v-icon v-else>close</v-icon>
              </td>
              <td>{{ props.item.tier == 0 ? 'Free' : 'Paid' }}</td>
              <td>
                {{
                  props.item.role_id >= 99
                    ? 'Developer'
                    : props.item.role_id === 98
                    ? 'Media'
                    : props.item.role_id === 97
                    ? 'Marketing'
                    : props.item.role_id === 96
                    ? 'Content Creator'
                    : props.item.role_id === 51
                    ? 'Customer Service'
                    : props.item.role_id === 50
                    ? 'Translator'
                    : props.item.role_id === 2
                    ? 'Customer'
                    : 'None'
                }}
              </td>
              <td>
                {{
                  props.item.last_login !== invalid_date
                    ? props.item.last_login
                    : ''
                }}
              </td>
              <td>
                {{
                  props.item.subscription_start_date !== invalid_date
                    ? props.item.subscription_start_date
                    : ''
                }}
              </td>
              <td>
                {{
                  props.item.subscription_end_date !== invalid_date
                    ? props.item.subscription_end_date
                    : ''
                }}
              </td>
              <td>
                {{
                  props.item.deletion_time !== invalid_date
                    ? props.item.deletion_time
                    : ''
                }}
              </td>
              <td>
                <v-layout align-content-space-between>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="handleShowEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="userLevelStore === 99"
                    icon
                    color="red"
                    v-on:click="onDeleteUser(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>

          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
          <div style="text-align: center">
            <v-pagination
              circle
              v-if="pagerStore.pages && pagerStore.pages.length"
              v-model="pagerStore.currentPage"
              :length="pagerStore.totalPages"
              :total-visible="7"
              @input="next"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      :isOpen="isEdit"
      :close="onCloseEdit"
      title="Edit User infomation"
      :save="onSaveEdit"
    >
      <v-card>
        <v-container>
          <v-layout justify-center column>
            <v-flex>
              <v-text-field
                label="ID"
                :value="usersStore.selectedUser._id"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Username"
                :value="usersStore.selectedUser.username"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-layout justify-space-between>
                <v-text-field
                  v-if="
                    userLevelStore !== 99 &&
                    usersStore.selectedUser.role_id === 2
                  "
                  label="Password"
                  placeholder="Change Password"
                  type="password"
                  v-on:change="e => handleChangeForm(e, 'password')"
                ></v-text-field>

                <v-text-field
                  v-else-if="userLevelStore === 99"
                  label="Password"
                  placeholder="Change Password"
                  type="password"
                  v-on:change="e => handleChangeForm(e, 'password')"
                ></v-text-field>
                <v-spacer
                  v-if="
                    usersStore.updateUserData &&
                    usersStore.updateUserData.password
                  "
                ></v-spacer>
                <v-text-field
                  v-if="
                    usersStore.updateUserData &&
                    usersStore.updateUserData.password
                  "
                  v-on:change="e => handleChangeForm(e, 'password2')"
                  type="password"
                  placeholder="Retype Password"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-space-between>
                <v-text-field
                  label="Email"
                  :value="usersStore.selectedUser.email"
                  disabled
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-switch
                  v-if="userLevelStore === 99"
                  color="black"
                  :value="usersStore.selectedUser.is_validate"
                  label="Email Verified status"
                  v-on:change="e => handleChangeForm(e, 'is_validate')"
                ></v-switch>
                <v-switch
                  v-if="userLevelStore !== 99"
                  disabled
                  color="black"
                  :value="usersStore.selectedUser.is_validate"
                  label="Email Verified status"
                  v-on:change="e => handleChangeForm(e, 'is_validate')"
                ></v-switch>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-space-between>
                <v-flex xs7>
                  <v-switch
                    v-if="userLevelStore === 99"
                    color="black"
                    :value="usersStore.selectedUser.tier"
                    label="Subscription Status"
                    readonly
                  ></v-switch>
                  <v-switch
                    v-if="userLevelStore !== 99"
                    disabled
                    color="black"
                    :value="usersStore.selectedUser.tier"
                    label="Subscription Status"
                    readonly
                  ></v-switch>
                </v-flex>
                <v-flex xs5 v-if="usersStore.selectedUser.tier == false">
                  <v-layout justify-space-between>
                    <v-btn
                      dark
                      color="black"
                      @click="showAddFreeDay = !showAddFreeDay"
                      >Add Free Days</v-btn
                    >
                    <v-text-field
                      v-if="showAddFreeDay"
                      label="days"
                      :value="free_days"
                      v-on:change="e => (free_days = parseInt(e))"
                    ></v-text-field>
                    <v-btn
                      v-if="showAddFreeDay"
                      dark
                      icon
                      color="green"
                      @click="onAddFreeDays"
                    >
                      <v-icon>done</v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs5 v-else>
                  <v-text-field
                    label="Subscription End"
                    :value="usersStore.selectedUser.subscription_end"
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- Customer Service : start -->
            <div v-if="userLevelStore === 51">
              <!-- Allow customer service to edit only MODE -->
              <v-flex>
                <v-text-field
                  disabled
                  label="First name"
                  :value="usersStore.selectedUser.firstname"
                  v-on:change="e => handleChangeForm(e, 'firstname')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="Last name"
                  :value="usersStore.selectedUser.lastname"
                  v-on:change="e => handleChangeForm(e, 'lastname')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  disabled
                  label="Gender"
                  :items="genders"
                  :value="usersStore.selectedUser.gender"
                  v-on:change="e => handleChangeForm(e, 'gender')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  disabled
                  label="Role"
                  :items="roles"
                  :value="usersStore.selectedUser.role_id"
                  v-on:change="e => handleChangeForm(e, 'role_id')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  label="Mode"
                  :items="modes"
                  :value="usersStore.selectedUser.mode"
                  v-on:change="e => handleChangeForm(e, 'mode')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="
                        usersStore.updateUserData &&
                        usersStore.updateUserData.date_of_birth
                          ? moment(
                              usersStore.updateUserData.date_of_birth,
                            ).format('MMMM D YYYY')
                          : moment(
                              usersStore.selectedUser.date_of_birth,
                            ).format('MMMM D YYYY')
                      "
                      label="Date of Birth"
                      disabled
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-on:input="handleChangeDate"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex></v-flex>
              <v-flex>
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title>Social Connect</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list>
                      <v-list-tile v-if="usersStore.selectedUser.facebook_id">
                        <v-list-tile-avatar>
                          <img
                            :src="`http://graph.facebook.com/${usersStore.selectedUser.facebook_id}/picture`"
                          />
                        </v-list-tile-avatar>
                        <v-list-tile-title>Facebook</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex mt-3>
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title>Children</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list v-if="usersStore.selectedUser.children">
                      <v-list-tile
                        v-for="(child, index) in usersStore.selectedUser
                          .children"
                        :key="child._id"
                        @click="onSelectChildren(index)"
                      >
                        <v-list-tile-avatar>
                          <img :src="child.avatar" />
                        </v-list-tile-avatar>
                        <v-list-tile-title>
                          {{ child.firstname }}
                          {{ child.lastname }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </div>
            <!-- Customer Service : end -->
            <div v-else-if="userLevelStore === 99">
              <v-flex>
                <v-text-field
                  label="First name"
                  :value="usersStore.selectedUser.firstname"
                  v-on:change="e => handleChangeForm(e, 'firstname')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Last name"
                  :value="usersStore.selectedUser.lastname"
                  v-on:change="e => handleChangeForm(e, 'lastname')"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  label="Gender"
                  :items="genders"
                  :value="usersStore.selectedUser.gender"
                  v-on:change="e => handleChangeForm(e, 'gender')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  v-if="userLevelStore === 99"
                  label="Role"
                  :items="roles"
                  :value="usersStore.selectedUser.role_id"
                  v-on:change="e => handleChangeForm(e, 'role_id')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  v-if="userLevelStore !== 99"
                  label="Role"
                  :items="roles"
                  :value="usersStore.selectedUser.role_id"
                  disabled
                  v-on:change="e => handleChangeForm(e, 'role_id')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  v-if="userLevelStore === 99"
                  label="Mode"
                  :items="modes"
                  :value="usersStore.selectedUser.mode"
                  v-on:change="e => handleChangeForm(e, 'mode')"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="
                        usersStore.updateUserData &&
                        usersStore.updateUserData.date_of_birth
                          ? moment(
                              usersStore.updateUserData.date_of_birth,
                            ).format('MMMM D YYYY')
                          : moment(
                              usersStore.selectedUser.date_of_birth,
                            ).format('MMMM D YYYY')
                      "
                      label="Date of Birth"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-on:input="handleChangeDate"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex></v-flex>
              <v-flex>
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title>Social Connect</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list>
                      <v-list-tile v-if="usersStore.selectedUser.facebook_id">
                        <v-list-tile-avatar>
                          <img
                            :src="`http://graph.facebook.com/${usersStore.selectedUser.facebook_id}/picture`"
                          />]
                        </v-list-tile-avatar>
                        <v-list-tile-title>Facebook</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex mt-3>
                <v-card>
                  <v-toolbar dark>
                    <v-toolbar-title>Children</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-list v-if="usersStore.selectedUser.children">
                      <v-list-tile
                        v-for="(child, index) in usersStore.selectedUser
                          .children"
                        :key="child._id"
                        @click="onSelectChildren(index)"
                      >
                        <v-list-tile-avatar>
                          <img :src="child.avatar" />
                        </v-list-tile-avatar>
                        <v-list-tile-title>
                          {{ child.firstname }}
                          {{ child.lastname }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </v-card>
      <Modal
        v-if="isEditChildren"
        :isOpen="isEditChildren"
        :close="onCloseEditChildren"
        title="Edit Children infomation"
        :save="onSaveChildrenData"
      >
        <v-layout column>
          <v-flex>
            <v-text-field
              label="First Name"
              @change="e => updateChildrenData({ key: 'firstname', value: e })"
              :value="usersStore.selectedUser.children[childrenIndex].firstname"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Last Name"
              @change="e => updateChildrenData({ key: 'lastname', value: e })"
              :value="usersStore.selectedUser.children[childrenIndex].lastname"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn dark color="red" @click="onDeleteChildren">Delete</v-btn>
          </v-flex>
        </v-layout>
      </Modal>
    </Modal>
  </Container>
</template>

<script>
import Container from '../components/Container';
import Modal from '../components/Modal';
import Moment from 'moment';
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Users',
  components: {
    Container,
    Modal,
  },
  data() {
    return {
      invalid_date: 'Invalid date',
      selectedType: 'username',
      typeList: [
        'username',
        'email',
        `parent's first name`,
        `child's first name`,
        `parent's ID`,
        'user deletion time',
      ],
      searchValidator: false,
      searchRules: [v => !!v || 'Word is required'],
      search: '',
      moment: Moment,
      isEdit: false,
      date_picker: false,
      start_date_picker: false,
      end_date_picker: false,
      start: new Date(),
      end: new Date(),
      showAddFreeDay: false,
      free_days: 1,
      headers: [
        { text: 'ID', value: '_id', sortable: true },
        { text: 'Username', align: 'left', sortable: true, value: 'username' },
        { text: 'Firstname', value: 'firstname', sortable: true },
        { text: 'Lastname', value: 'lastname', sortable: true },
        { text: 'E-mail', value: 'email', sortable: true },
        { text: 'Is Verified', value: 'is_validate', sortable: true },
        { text: 'Tier', value: 'tier', sortable: true },
        { text: 'Role', value: 'role_id', sortable: true },
        { text: 'Last Login', value: 'last_login', sortable: true },
        {
          text: 'Start Subscribe',
          value: 'subscription_start_date',
          sortable: true,
        },
        {
          text: 'End Subscribe',
          value: 'subscription_end_date',
          sortable: true,
        },
        {
          text: 'Deletion Time',
          value: 'deletion_time',
          sortable: true,
        },
        { text: 'Action', value: 'action', sortable: false, align: 'center' },
      ],
      genders: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
      roles: [
        { text: 'Developer', value: 99 },
        { text: 'Media', value: 98 },
        { text: 'Marketing', value: 97 },
        { text: 'Content Creator', value: 96 },
        { text: 'Customer Service', value: 51 },
        { text: 'Translator', value: 50 },
        { text: 'Customer', value: 2 },
      ],
      modes: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
      ],
      isEditChildren: false,
      childrenIndex: -1,
      pagination: {
        rowsPerPage: 100,
      },
      searchByUsername: false,
      searchByEmail: false,
      searchByFirstname: false,
    };
  },
  methods: {
    ...mapActions('userInfo', ['logoutUser']),
    ...mapActions('users', [
      'fetchUsers',
      'selectedUser',
      'updateUserData',
      'clearSelectData',
      'updateUser',
      'deleteUser',
      'updateChildren',
      'updateChildrenData',
      'deleteChildren',
      'addFreeDays',
      'fetchUsersByChildName',
      'deletionTimeUser',
    ]),
    callSearch(search) {
      if (search) {
        switch (this.selectedType) {
          case `username`:
            this.fetchUsers({
              key: this.selectedType,
              value: search,
              page: 1,
            });
            break;
          case `email`:
            this.fetchUsers({
              key: this.selectedType,
              value: search,
              page: 1,
            });
            break;
          case `parent's first name`:
            this.fetchUsers({
              key: 'firstname',
              value: search,
              page: 1,
            });
            break;
          case `child's first name`:
            this.fetchUsersByChildName({
              key: 'firstname',
              value: search,
              page: 1,
            });
            break;
          case `parent's ID`:
            this.fetchUsers({
              key: 'id',
              value: search,
              page: 1,
            });
            break;
          case `user deletion time`:
            this.fetchUsers({
              key: 'deletion_time',
              value: search,
              page: 1,
            });
            break;
          default:
            break;
        }
      }
    },
    onChangeTab(title) {
      this.selectedType = title;
      if (this.selectedType === 'user deletion time') this.callSearch(true);
    },
    next(page) {
      this.fetchUsers({
        key: this.selectedType,
        value: this.search,
        page: page,
      });
    },
    onCloseEdit() {
      this.isEdit = false;
      this.clearSelectData();
    },
    onCloseEditChildren() {
      this.isEditChildren = false;
      this.childrenIndex = -1;
    },
    handleShowEdit(id) {
      this.clearSelectData();
      this.isEdit = true;
      this.selectedUser(id);
      this.updateUserData({ key: 'id', value: id });
    },
    handleChangeDate(e) {
      this.date_picker = false;
      this.updateUserData({ key: 'date_of_birth', value: new Date(e) });
    },
    handleChangeStartDate(e) {
      this.start_date_picker = false;
      this.start = new Date(e);
      if (this.start && this.end) {
        this.usersStore.showUserList = this.usersStore.userList.filter(user => {
          if (
            new Date(user.subscribe_date).getTime() > this.start.getTime() &&
            new Date(user.subscribe_date).getTime() < this.end.getTime()
          ) {
            return user;
          }
        });
      }
    },
    handleChangeEndDate(e) {
      this.end_date_picker = false;
      this.end = new Date(e);
      if (this.start && this.end) {
        this.usersStore.showUserList = this.usersStore.userList.filter(user => {
          if (
            new Date(user.subscribe_date).getTime() > this.start.getTime() &&
            new Date(user.subscribe_date).getTime() < this.end.getTime()
          ) {
            return user;
          }
        });
      }
    },
    handleChangeForm(e, key) {
      this.updateUserData({ key, value: e });
    },
    onSelectChildren(index) {
      this.isEditChildren = true;
      this.childrenIndex = index;
      this.updateChildrenData({
        key: 'children_id',
        value: this.usersStore.selectedUser.children[this.childrenIndex]._id,
      });
    },
    onSaveChildrenData() {
      this.isEditChildren = false;
      this.childrenIndex = -1;
      this.isEdit = false;
      this.updateChildren();
    },
    onDeleteChildren() {
      this.deleteChildren(this.childrenIndex);
      this.isEditChildren = false;
      this.childrenIndex = -1;
      this.isEdit = false;
    },
    onSaveEdit() {
      const data = { ...this.usersStore.updateUserData };
      if (data.password) {
        if (data.password === data.password2) {
          delete data.password2;
          this.updateUser(data);
          this.isEdit = false;
          this.clearSelectData();
        } else {
          swal.fire('Password mismatch', '', 'warning');
        }
      } else {
        if (Object.keys(data).length > 1) {
          this.updateUser(data);
        }
        this.isEdit = false;
        this.clearSelectData();
      }
    },
    onDeleteUser(id) {
      this.deleteUser(id);
    },
    onAddFreeDays() {
      this.showAddFreeDay = false;
      this.addFreeDays({
        parent_id: this.usersStore.selectedUser._id,
        free_days: this.free_days,
      });
      this.isEdit = false;
    },
    deleteUserDeletionTime() {
      this.deletionTimeUser();
    },
  },
  mounted() {
    const date = new Date();
    this.start = new Date(date.setDate(date.getDate() - 30));
  },
  computed: {
    ...mapState({
      usersStore: state => state.users,
      userInfoStore: state => state.userInfo,
      userLevelStore: state => state.userInfo.userData.role_id,
      pagerStore: state => state.users.pager,
    }),
  },
};
</script>
