<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Mini Games</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-list class="pt-0">
            <v-list-tile
              v-for="item in items"
              :key="item.title"
              @click="toRouter(item)"
            >
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card-text>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import Container from '../../components/Container.vue';

export default {
  name: 'mini_games',
  components: {
    Container,
  },
  data() {
    return {
      items: [
        {
          title: 'Snap Game',
          icon: 'view_list',
          path: '/snap',
        },
        {
          title: 'Running Game',
          icon: 'directions_run',
          path: '/running_game',
        },
        {
          title: 'Driving Game',
          icon: 'time_to_leave',
          path: '/driving_game',
        },
        {
          title: 'Sequence Game',
          icon: 'format_list_numbered',
          path: '/sequence_game',
        },
        {
          title: 'Drag and Drop 2',
          icon: 'swipe_vertical',
          path: '/drag_and_drop_2',
        },
        {
          title: 'Labyrinth',
          icon: 'extension',
          path: '/labyrinth',
        },
        {
          title: 'Shooting Arrow',
          icon: 'adjust',
          path: '/shooting_arrow',
        },
        {
          title: 'Cooking Game',
          icon: 'outdoor_grill',
          path: '/cooking_game',
        },
      ],
    };
  },
  methods: {
    toRouter({ path }) {
      this.$router.push({ path: path });
    },
  },
};
</script>
