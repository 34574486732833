import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const state = {
  codeList: [],
  isLoadCode: false,
  createCode: {
    // type: '',
    // amount: 1,
    voucher_type: 'FREEDAY',
    usage_limit_quota: 1,
    free_day: 30,
  },
  totalSize: 0,
  page: 1,
  limit: 20,
  codeExist: true,
};
const getters = {};
const actions = {
  fetchCodes(
    { commit, dispatch },
    filter,
    // { page = state.page, limit = state.limit, campain },
  ) {
    let query = 'isWeb=true';
    for (const [key, value] of Object.entries(filter)) {
      query += `&${key}=${value}`;
    }
    commit('storeIsloadCode');
    http
      // .GET(`/api/code?page=${page}&limit=${limit}`, {
      //   isAuthorization: true,
      // })
      .GET(`/api/voucher/code/view?${query}`, {
        isAuthorization: true,
      })
      .then(({ data: { data, totalsize } }) => {
        const formatData = data.map(code => ({
          ...code,
          created_at: moment(code.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(code.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        commit('storeCode', formatData);
        commit('storeTotalSize', totalsize);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch codes at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        commit('storeIsloadCode');
      });
  },
  checkCodeExist({ dispatch }, { voucher_code }) {
    http
      // .POST('/api/code', generateCode, {
      //   isAuthorization: true,
      // })
      .GET(`/api/voucher/code/view?voucher_code=${voucher_code}`, {
        isAuthorization: true,
      })
      .then(({ data, status }) => {
        data.length > 0 || status === 200
          ? dispatch(
              'updateCheckCodeExist',
              'This code exists, Please try again.',
            )
          : dispatch('updateCheckCodeExist', true);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Query Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  generateCode({ dispatch }, generateCode) {
    const url =
      'voucher_amount' in generateCode
        ? '/api/voucher/code/addMany'
        : '/api/voucher/code/add';
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Generate Code',
      },
      {
        root: true,
      },
    );
    http
      // .POST('/api/code', generateCode, {
      //   isAuthorization: true,
      // })
      .POST(url, generateCode, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('clearCreateCode');
        dispatch('fetchCodes', { page: state.page, limit: state.limit });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Generate Code complete',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteCode({ dispatch }, { id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Code',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            // .DELETE(`/api/code?_id=${id}`, {
            //   isAuthorization: true,
            // })
            .DELETE(`/api/voucher/code/delete?id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchCodes', { page: 1, limit: state.limit });
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Cannot delete please try again later !',
                  type: 'error',
                },
                { root: true },
              );
              throw new Error(err);
            });
        }
      });
  },
  editCreateCode({ commit }, { key, value }) {
    commit('storeCreateCode', {
      key,
      value,
    });
  },
  updatePage({ commit }, page) {
    commit('storeUpdatePage', page);
  },
  storeTotalSize({ commit }, size) {
    commit('storeTotalSize', size);
  },
  updateCheckCodeExist({ commit }, size) {
    commit('storeCheckCodeExist', size);
  },
  clearCreateCode({ commit }) {
    commit('clearCreateCodeStore');
  },
};
const mutations = {
  storeCode(state, code) {
    state.codeList = code.map(res => ({
      ...res,
      expired_date: moment
        .unix(new Date(res.expired_date) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadCode(state) {
    state.isLoadCode = !state.isLoadCode;
  },
  storeTotalSize(state, size) {
    state.totalSize = size;
  },
  storeCreateCode(state, { key, value }) {
    state.createCode = {
      ...state.createCode,
      [key]: value,
    };
  },
  storeUpdatePage(state, page) {
    state.page = page;
  },
  storeCheckCodeExist(state, data) {
    state.codeExist = data;
  },
  clearCreateCodeStore(state) {
    state.createCode = {
      voucher_type: 'FREEDAY',
      usage_limit_quota: 1,
      free_day: 30,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
