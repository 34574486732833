export default {
  FilterMode: [
    { name: 'OnStart', value: 0 },
    { name: 'OnTrigger', value: 1 },
  ],
  FilterType: [
    { name: 'Basic', value: 0 },
    { name: 'SnapFace', value: 1 },
    { name: 'FaceNPoint', value: 2 },
    { name: 'FaceCap', value: 3 },
    { name: 'Sunglasses', value: 4 },
    { name: 'Particle', value: 5 },
    { name: 'CoverFace', value: 6 },
    { name: 'Scale', value: 7 },
  ],
  TriggerType: [
    { name: 'None', value: 0 },
    { name: 'OpenMouth', value: 1 },
    { name: 'BlinkLeftEye', value: 2 },
    { name: 'BlinkRightEye', value: 3 },
    { name: 'TiltHeadLeft', value: 4 },
    { name: 'TiltHeadRight', value: 5 },
  ],
  FacePosition: [
    { name: 'None', value: -1 },
    { name: 'LeftEar', value: 0 },
    { name: 'CenterLeftEye', value: 1 },
    { name: 'CenterRightEye', value: 2 },
    { name: 'RightEar', value: 3 },
    { name: 'RightCheek', value: 4 },
    { name: 'Nose', value: 5 },
    { name: 'LeftCheek', value: 6 },
    { name: 'CenterMouth', value: 7 },
    { name: 'Forehead ', value: 8 },
  ],
  GameType: [
    { name: 'Filter', value: 0 },
    { name: 'Mic', value: 1 },
    { name: 'Tts', value: 2 },
    { name: 'MicWords', value: 3 },
  ],
  EventType: [
    { name: 'Start', value: 0 },
    { name: 'Play', value: 1 },
    { name: 'End', value: 2 },
  ],
  ShapeType: [
    { name: 'Cone', value: 0 },
    { name: 'Circle', value: 1 },
    { name: 'Rain', value: 2 },
  ],
  FaceNPointType: [
    {
      name: 'FaceToPoint',
      value: 0,
    },
    {
      name: 'PointToFace',
      value: 1,
    },
    {
      name: 'PointToPoint',
      value: 2,
    },
    {
      name: 'FaceToFace',
      value: 3,
    },
  ],
  Ttsmode: [
    {
      name: 'AllCorrect',
      value: 0,
    },
    {
      name: 'SelectOne',
      value: 1,
    },
  ],
  PositionModePaticle: [
    {
      name: 'Face',
      value: 0,
    },
    {
      name: 'Point',
      value: 1,
    },
  ],
  rule: {
    required: value => {
      if (value === 0) return true;
      return !!value || 'Required.';
    },
    validtag: value => {
      if (value instanceof Array && value.length == 0) return 'Required';
      return !!value || 'Required.';
    },
    validURI: value => {
      function validURL(str) {
        var pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        ); // fragment locator
        return !!pattern.test(str);
      }
      if (!value) return true;
      else if (validURL(value)) return true;
      else if (
        /[ ]/.test(value) ||
        value === '' ||
        /[()]/.test(value) ||
        /[’]/.test(value)
      )
        return true;
      else return 'URI must be valid';
    },
    numberRule: value => {
      if (!isNaN(parseFloat(value)) && value >= -1) return true;
      return 'The number must be greater than equal -1';
    },
    numberRowColumn: value => {
      if (!isNaN(parseFloat(value)) && value >= 1) return true;
      return 'The number must be greater than equal 1';
    },
    numberFloat: value => {
      if (!isNaN(parseFloat(value)) && value >= 0 && value <= 1) return true;
      return 'The number are between 0-1.';
    },
    isDecimal: value => {
      if (!isNaN(parseFloat(value)) && value.toString().includes('.'))
        return 'The number is demical.';
      return true;
    },
  },
};
