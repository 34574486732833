<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Promotion Code Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="isCreate = true">
            <v-icon>add</v-icon>Generate New Code
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-text-field
                  outline
                  label="Filter by Campaign"
                  append-icon="search"
                  @input="e => onchangePage('search', e)"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center>
              <v-flex xs11>
                <v-pagination
                  v-model="codeStore.page"
                  :length="totalPages"
                  :total-visible="10"
                  @input="onchangePage('page')"
                ></v-pagination>
              </v-flex>
              <v-flex xs1>
                <v-select
                  v-model="codeStore.limit"
                  :items="[20, 100, 200, 500]"
                  label="Rows per page"
                  @input="onchangePage('limit')"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <div v-if="!codeStore.isLoadCode">
            <v-data-table
              :items="codeStore.codeList"
              :headers="headers"
              :search="search"
              hide-actions
            >
              <template v-slot:items="props">
                <td>{{ props.index + 1 }}</td>
                <td>{{ props.item.voucher_code }}</td>
                <td>
                  <v-btn icon v-clipboard:copy="props.item.voucher_code">
                    <v-icon>link</v-icon>
                  </v-btn>
                </td>
                <td>{{ props.item.issuer_username }}</td>
                <td>{{ props.item.campain }}</td>
                <td>{{ props.item.end_date }}</td>
                <td>{{ props.item.voucher_type }}</td>
                <td>{{ props.item.usage_limit_quota }}</td>
                <!-- <td>
                <v-select
                  :items="constants.CODE_TYPE"
                  :value="props.item.type"
                  readonly
                  append-icon=""
                >
                </v-select>
              </td> -->
                <td>{{ props.item.free_day }}</td>
                <td>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">remove</v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </div>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
          <v-container>
            <v-layout grid-list-xs text-xs-center>
              <v-flex xs11>
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                  :total-visible="10"
                  @input="onchangePage('page')"
                ></v-pagination>
              </v-flex>
              <v-flex xs1>
                <v-select
                  v-model="limit"
                  :items="[20, 100, 200, 500]"
                  label="Rows per page"
                  @input="onchangePage('limit')"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickSaveCode"
      :close="closeCreate"
      title="Generate New Code"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-checkbox
                  label="Create multiple code."
                  v-model="isCreateMutipleCode"
                  @change="codeStore.createCode.voucher_code = null"
                ></v-checkbox>
                <v-text-field
                  v-if="isCreateMutipleCode"
                  type="number"
                  label="Number of codes to generate"
                  :rules="[rules.required, rules.positiveInteger]"
                  v-on:change="e => onChangeCreateContent('voucher_amount', e)"
                ></v-text-field>
                <v-text-field
                  v-if="!isCreateMutipleCode"
                  label="Voucher Code (If empty value, it will be generated automatically.)"
                  v-model="codeStore.createCode.voucher_code"
                  v-on:change="e => onChangeCreateContent('voucher_code', e)"
                  @input="e => checkCodeExist({ voucher_code: e })"
                  :rules="[rules.string, codeExist]"
                ></v-text-field>
                <v-text-field
                  label="Campain"
                  v-model="codeStore.createCode.campain"
                  v-on:change="e => onChangeCreateContent('campain', e)"
                  :rules="[rules.required, rules.string]"
                ></v-text-field>
                <v-text-field
                  label="Promotion"
                  v-model="codeStore.createCode.promotion"
                  v-on:change="e => onChangeCreateContent('promotion', e)"
                  :rules="[rules.string]"
                ></v-text-field>
                <v-text-field
                  label="Period"
                  v-model="codeStore.createCode.period"
                  v-on:change="e => onChangeCreateContent('period', e)"
                  :rules="[rules.string]"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="Usage limit quota"
                  v-model.number="codeStore.createCode.usage_limit_quota"
                  :rules="[rules.required, rules.positiveInteger]"
                  v-on:change="
                    e => onChangeCreateContent('usage_limit_quota', parseInt(e))
                  "
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="Free Day"
                  v-model.number="codeStore.createCode.free_day"
                  :rules="[rules.required, rules.positiveInteger]"
                  v-on:change="
                    e => onChangeCreateContent('free_day', parseInt(e))
                  "
                ></v-text-field>
                <v-checkbox
                  label="Voucher code has no expiration date."
                  v-model="isVoucherDateExpired"
                  v-on:change="
                    e => onChangeCreateContent('isVoucherDateExpired', e)
                  "
                ></v-checkbox>
                <div v-if="!isVoucherDateExpired">
                  <v-text-field
                    type="datetime-local"
                    label="Start date voucher"
                    v-model.number="codeStore.createCode.start_date"
                    v-on:change="e => onChangeCreateContent('start_date', e)"
                  ></v-text-field>
                  <v-text-field
                    type="datetime-local"
                    label="End date voucher"
                    v-model.number="codeStore.createCode.end_date"
                    v-on:change="e => onChangeCreateContent('end_date', e)"
                  ></v-text-field>
                </div>
              </v-flex>
              <!-- <v-flex>
              <v-select
                :items="constants.CODE_TYPE"
                label="Voucher Type"
                :value="codeStore.createCode.voucher_type"
                v-model="codeStore.createCode.voucher_type"
                v-on:change="e => onChangeCreateContent('voucher_type', e)"
              >
              </v-select>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Amount"
                v-model="codeStore.createCode.amount"
                v-on:change="e => onChangeCreateContent('amount', e)"
              ></v-text-field>
            </v-flex>
            <v-flex
              v-if="codeStore.createCode.type == 'subscription.free.trial'"
            >
              <v-text-field
                label="Days"
                v-model="codeStore.createCode.days"
                v-on:change="e => onChangeCreateContent('days', parseInt(e))"
              ></v-text-field>
            </v-flex> -->
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Container from '../components/Container';
import Modal from '../components/Modal';
import constants from '../constants';
import rules from '../utils/ruleValidate';

export default {
  name: 'Code',
  components: {
    Container,
    Modal,
  },
  data: function () {
    return {
      rules,
      constants,
      valid: true,
      search: '',
      headers: [
        { text: 'Index', value: 'index', sortable: false },
        { text: 'Code', value: 'voucher_code', sortable: false },
        { text: '', value: '', sortable: false },
        { text: 'Username', value: 'issuer_username', sortable: false },
        { text: 'Campain', value: 'campain', sortable: false },
        { text: 'Expired Date', value: 'updated_at', sortable: false },
        { text: 'Type', value: 'voucher_type', sortable: false },
        {
          text: 'Usage limitq uota',
          value: 'usage_limit_quota',
          sortable: false,
        },
        { text: 'Day', value: 'free_day', sortable: false },
        // { text: 'Created Date', value: 'created_at', sortable: true },
        // { text: 'Updated Date', value: 'updated_at', sortable: true },
        { text: '', value: '', sortable: false },
      ],
      isCreate: false,
      isVoucherDateExpired: false,
      isCreateMutipleCode: false,
      // page: 1,
      // limit: 20,
    };
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('code', [
      'fetchCodes',
      'deleteCode',
      'generateCode',
      'editCreateCode',
      'clearCreateCode',
      'updatePage',
      'checkCodeExist',
    ]),
    onClickDelete(id) {
      this.deleteCode({ id });
    },
    clearIsState() {
      this.isCreate = false;
      this.isVoucherDateExpired = false;
      this.isCreateMutipleCode = false;
    },
    closeCreate() {
      this.clearIsState();
      this.clearCreateCode();
    },
    onClickSaveCode() {
      if (this.$refs.form.validate()) {
        const createCodeData = { ...this.codeStore.createCode };
        this.generateCode(createCodeData);
        this.clearIsState();
      }
    },
    onChangeCreateContent(key, value) {
      if (key === 'isVoucherDateExpired') {
        const date = { start_date: null, end_date: null };
        for (const [key, value] of Object.entries(date)) {
          this.editCreateCode({ key, value });
        }
      } else this.editCreateCode({ key, value });
    },
    onchangePage(key, value) {
      if (key === 'limit') this.updatePage(1);
      if (key === 'search') {
        this.updatePage(1);
        this.fetchCodes({ campain: value, page: this.page, limit: this.limit });
      } else this.fetchCodes({ page: this.page, limit: this.limit });
    },
  },
  mounted: function () {
    this.fetchCodes({ page: this.page, limit: this.limit });
  },

  computed: {
    ...mapState({
      codeStore: state => state.code,
      userInfoStore: state => state.userInfo,
      page: state => state.code.page,
      limit: state => state.code.limit,
      codeExist: state => state.code.codeExist,
    }),
    totalPages() {
      if (this.limit == null || this.codeStore.totalSize == null) return 0;
      return Math.ceil(this.codeStore.totalSize / this.limit);
    },
  },
};
</script>
