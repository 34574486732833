/* eslint-disable prettier/prettier */
import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  dragAndDrop2List: [],
  selectedDragAndDrop2: {},
  createDragAndDrop2: {},
  updateDragAndDrop2: {},
  isLoadDragAndDrop2: true,
  defaultCreate: {
    category_id: '',
    title: '',
    music_intro_uri: '',
    tts_message: '',
    voice_name: 'enUSAnaNeural',
    bgm_uri: '',
    bgm_volume: '1',
    bg_uri: '',
    anwing_uri: '',
    wall_uri: '',
    frame_uri: '',
    table_uri: '',
    basket_uri: '',
    order_image: {
      image_uri: '',
      object_rect: {
        startX: '',
        startY: '',
        endX: '',
        endY: '',
      },
    },
    items_info: [
      {
        word: 'default word',
        image_box_uri: '',
        image_obj_uri: '',
        sound_uri: '',
        tts_message: '',
      },
    ],
    rounds: [
      {
        orders_info: [
          {
            word: 'word',
            amount: 0,
          }
        ]
      }
    ],
    decorates_image: [
      {
        image_uri: '',
        object_rect: {
          startX: '',
          startY: '',
          endX: '',
          endY: '',
        },
      }
    ],
  },
};
const getters = {
  defaultCreate(state) {
    return state.defaultCreate;
  },
};
const actions = {
  fetchDragAndDrop2Category({ commit, dispatch }, { page = 1, limit = 0 }) {
    const query = {
      params: { page, limit },
    };
    return http
      .GET(`/api/drag_and_drop_2/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'dragAndDrop2List',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch drag and drop 2 in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadDragAndDrop2',
          key: null,
          value: false,
        });
      });
  },
  fetchDragAndDrop2List(
    { commit, dispatch },
    { id, game_list_id, category_id },
  ) {
    const url = id ? `/api/drag_and_drop_2` : `/api/drag_and_drop_2/category`;
    const query = {
      params: game_list_id
        ? { id: game_list_id }
        : category_id
        ? { category_id }
        : { id, isWeb: true },
    };
    return http
      .GET(url, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => {
          const drag_and_drop_2 = e.drag_and_drop_2_games.map(data => {
            return {
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            };
          });
          e.drag_and_drop_2_games = drag_and_drop_2;
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'dragAndDrop2List',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch drag and drop 2 in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadDragAndDrop2',
          key: null,
          value: false,
        });
      });
  },
  createDragAndDrop2({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createDragAndDrop2;
    http
      .POST(`/api/drag_and_drop_2`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.dragAndDrop2List._id) {
          dispatch('fetchDragAndDrop2List', {
            game_list_id: state.dragAndDrop2List._id,
          });
        } else {
          router.go({
            path: '/drag_and_drop_2',
          });
          dispatch('fetchDragAndDrop2Category', {
            page: 1,
            limit: 0,
          });
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create drag and drop 2 successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create drag and drop 2 in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteDragAndDrop2({ commit, dispatch }, id) {
    http
      .DELETE(`/api/drag_and_drop_2?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateDragAndDrop2');
        dispatch('fetchDragAndDrop2List', {
          game_list_id: state.dragAndDrop2List._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete drag and drop 2 successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete runnung game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateDragAndDrop2({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateDragAndDrop2;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/drag_and_drop_2`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchDragAndDrop2List', {
          game_list_id: state.dragAndDrop2List._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update drag and drop 2 successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message || 'Cannot update drag and drop 2 in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
