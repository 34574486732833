import moment from 'moment';
import http from '../../utils/httpClient';
import swal from 'sweetalert2';

const state = {
  dictionaryList: [],
  isLoadDictionary: false,
  selectedWord: {},
  createWord: {
    word: '',
    en: '',
    th: '',
    ch: '',
    alphabet: '',
  },
  isValidWord: true,
};
const getters = {};
const actions = {
  validateDuplicateWord({ commit }, word) {
    http
      .GET(`/api/library/word/validateWord?word=${word}`, {
        isAuthorization: true,
      })
      .then(response => {
        commit('storeDuplicateWord', response.data.valid);
      })
      .catch(error => {
        if (error.response.status === 409) {
          commit('storeDuplicateWord', error.response.data.valid);
        }
      });
  },
  fetchDictionary({ commit, dispatch }, { page = 1, limit = 0 }) {
    commit('storeIsloadDictionary');
    http
      .GET(`/api/library/word?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(dictionary => ({
          ...dictionary,
          created_at: moment(dictionary.created_at).format(
            'MMMM D YYYY h:mm a',
          ),
          updated_at: moment(dictionary.updated_at).format(
            'MMMM D YYYY h:mm a',
          ),
        }));
        commit('storeDictionary', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch dictionary at this time. Please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      })
      .finally(() => {
        commit('storeIsloadDictionary');
      });
  },
  addNewWord({ dispatch }, wordData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Adding new word',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/dictionary', wordData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch('fetchDictionary', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Add new word successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  updateWord({ dispatch }, updateData) {
    delete updateData.created_at;
    delete updateData.updated_at;
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Updating word',
      },
      {
        root: true,
      },
    );
    http
      .PUT('/api/dictionary', updateData, {
        isAuthorization: true,
      })
      .then(data => {
        dispatch('fetchDictionary', {});
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update word successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: err.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  deleteWordById({ dispatch }, { id, word }) {
    swal
      .fire({
        titleText: 'Warning Confirm Delete Word',
        text:
          'This action will remove ' + word + ' in all groups of the library.',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/dictionary?_id=${id}`, {
              isAuthorization: true,
            })
            .then(({ data }) => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete word successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchDictionary', {});
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Error: Cannot delete this word. Please try again.',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  editUpdateWord({ commit }, { key, value }) {
    commit('storeUpdateWord', {
      key,
      value,
    });
  },
  editCreateWord({ commit }, { key, value }) {
    commit('storeCreateWord', {
      key,
      value,
    });
  },
  selectedWord({ commit }, id) {
    commit('storeSeleteWord', id);
  },
  clearSelectData({ commit }) {
    commit('clearSelectWord');
  },
  clearisValidWord({ commit }) {
    commit('clearisValidWord');
  },
};
const mutations = {
  storeDuplicateWord(state, word) {
    state.isValidWord = word;
  },
  storeDictionary(state, dictionary) {
    state.dictionaryList = dictionary.map(res => ({
      ...res,
      created_at: moment
        .unix(new Date(res.created_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
      updated_at: moment
        .unix(new Date(res.updated_at) * 0.001)
        .format('MMMM D YYYY h:mm a'),
    }));
  },
  storeIsloadDictionary(state) {
    state.isLoadDictionary = !state.isLoadDictionary;
  },
  storeUpdateWord(state, { key, value }) {
    state.selectedWord = {
      ...state.selectedWord,
      [key]: value,
    };
  },
  storeCreateWord(state, { key, value }) {
    state.createWord = {
      ...state.createWord,
      [key]: value,
    };
  },
  storeSeleteWord(state, id) {
    const Index = state.dictionaryList.findIndex(word => word._id === id);
    state.selectedWord = { ...state.dictionaryList[Index] };
  },
  clearSelectWord(state) {
    state.selectedWord = {};
  },
  clearisValidWord(state) {
    state.isValidWord = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
