<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="isCreate ? 'Create CookingGame' : 'Update CookingGame'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <div v-if="isCreate">
              <v-layout grid-list-xs column text-xs-center v-if="isCreate">
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  required
                  label="Title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
              </v-layout>
            </div>

            <div v-else>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Title"
                    :value="data.title"
                    v-model="data.title"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('title', e)"
                  ></v-text-field>

                  <v-flex>
                    <img
                      v-if="data.bg_uri"
                      :src="data.bg_uri"
                      :alt="data.bg_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Background URI (URL or Select file)"
                      v-model.trim="data.bg_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('bg_uri', e)"
                    ></v-text-field>
                    <v-btn
                      dark
                      @click="setResource('image', 'isBackgroundImage')"
                    >
                      <v-icon>images</v-icon>Select Background URI
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <img
                      v-if="data.table_uri"
                      :src="data.table_uri"
                      :alt="data.table_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Table URI (URL or Select file)"
                      v-model.trim="data.table_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('table_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isTableImage')">
                      <v-icon>images</v-icon>Select Table URI
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <img
                      v-if="data.microwave_uri"
                      :src="data.microwave_uri"
                      :alt="data.microwave_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Microwave URI (URL or Select file)"
                      v-model.trim="data.microwave_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('microwave_uri', e)"
                    ></v-text-field>
                    <v-btn
                      dark
                      @click="setResource('image', 'isMicrowaveImage')"
                    >
                      <v-icon>images</v-icon>Select Microwave URI
                    </v-btn>
                  </v-flex>

                  <v-layout grid-list-xs column text-xs-center>
                    <v-flex>
                      <v-text-field
                        label="Background Music URI (URL or Select file)"
                        v-model.trim="data.bgm_uri"
                        :rules="[rules.validURI]"
                        @change="e => onchangeUpdate('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn dark @click="setResource('sound', 'isBgmSound')">
                        <v-icon>audiotrack</v-icon>Select Background Music
                      </v-btn>
                    </v-flex>
                    <v-text-field
                      label="Volume Music (0-1)"
                      v-model.trim="data.bgm_volume"
                      :rules="[rules.required, rules.numberFloat]"
                      @change="e => onchangeUpdate('bgm_volume', e)"
                    ></v-text-field>
                  </v-layout>

                  <v-text-field
                    label="TTS Message"
                    v-model.trim="data.tts_message"
                    @change="e => onchangeUpdate('tts_message', e)"
                  ></v-text-field>
                  <v-select
                    :items="globalConstants.VOICE_NAME"
                    label="Voice Name"
                    :value="data.voice_name"
                    v-model="data.voice_name"
                    @change="e => onchangeUpdate('voice_name', e)"
                  ></v-select>

                  <v-text-field
                    label="Intro Music URI (URL or Select file)"
                    v-model.trim="data.music_intro_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('music_intro_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isIntroSound')">
                    <v-icon>audiotrack</v-icon>Select Intro Music
                  </v-btn>
                </v-flex>
              </v-layout>

              <ItemInfo
                v-if="isCreate === false"
                @validate="valid = $event"
                :isCreate="isCreate"
              >
              </ItemInfo>

              <RecipeInfo
                v-if="isCreate === false"
                @validate="valid = $event"
                :isCreate="isCreate"
              >
              </RecipeInfo>

              <DecorateImage @validate="valid = $event" :isCreate="isCreate">
              </DecorateImage>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import ItemInfo from './ItemInfo.vue';
import RecipeInfo from './RecipeInfo.vue';
import DecorateImage from './DecorateImage.vue';
import globalConstants from '../../../../constants';
import { keyExists } from '../../../../utils/lib/keyExists.js';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Modal, ResourceSelect, ItemInfo, RecipeInfo, DecorateImage },
  data() {
    return {
      keyExists,
      globalConstants,
      constants,
      valid: true,
      rules,
      data: {},
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isImage: false,
        },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      cookingGameStore: state => state.cooking_game,
      selectedCookingGame: state => state.cooking_game.selectedCookingGame,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('cooking_game', [
      'createCookingGame',
      'updateCookingGame',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createCookingGame() : this.updateCookingGame();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createCookingGame')
        : this.clearStateByKey('updateCookingGame');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isBackgroundImage) {
            this.data.bg_uri = uri;
            this.onchangeUpdate('bg_uri', uri);
            this.resource[resourceType].isBackgroundImage = false;
          } else if (this.resource[resourceType].isTableImage) {
            this.data.table_uri = uri;
            this.onchangeUpdate('table_uri', uri);
            this.resource[resourceType].isTableImage = false;
          } else if (this.resource[resourceType].isMicrowaveImage) {
            this.data.microwave_uri = uri;
            this.onchangeUpdate('microwave_uri', uri);
            this.resource[resourceType].isMicrowaveImage = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isBgmSound) {
            this.data.bgm_uri = uri;
            this.onchangeUpdate('bgm_uri', uri);
            this.resource[resourceType].isBgmSound = false;
          } else if (this.resource[resourceType].isIntroSound) {
            this.data.music_intro_uri = uri;
            this.onchangeUpdate('music_intro_uri', uri);
            this.resource[resourceType].isIntroSound = false;
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createCookingGame' : 'updateCookingGame',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    set_category_id() {
      if (
        this.isCreate &&
        'category_id' in this.cookingGameStore.cookingGameList
      ) {
        this.data.category_id = this.cookingGameStore.cookingGameList.category_id;
        this.onchangeUpdate(
          'category_id',
          this.cookingGameStore.cookingGameList.category_id,
        );
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) {
          this.fetchCategories();
          this.set_category_id();
          this.data = JSON.parse(
            JSON.stringify({ ...this.cookingGameStore.defaultCreate }),
          );
        } else {
          this.data = JSON.parse(
            JSON.stringify({ ...this.selectedCookingGame }),
          );
        }
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
