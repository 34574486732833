<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light @click="$router.push('/swipe')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (swipeStore.selectSwipe && swipeStore.selectSwipe.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="swipeStore.selectSwipe">
          <v-layout row grid-list-md v-if="!swipeStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Deck</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="toggleAddDeck">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(deck, index) in swipeStore.selectSwipe.decks"
                    :key="deck._id"
                    @click="onSelectDeck(deck._id)"
                    :class="index === selectedDeckIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ deck.name }}</v-list-tile-title>
                      <v-list-tile-sub-title v-if="deck.cards.length > 1">
                        {{ deck.cards.length }} cards
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else>
                        {{ deck.cards.length }} card
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedDeckIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Deck Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateDeck">
                    <v-icon>save</v-icon>Save Deck
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data</v-btn
                      >
                      <v-checkbox
                        label="Available for Free User"
                        v-model="deck.isGuestPlayable"
                        :value="deck.isGuestPlayable"
                        @change="e => onChangeDeckData('isGuestPlayable', e)"
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Deck Name"
                        :value="deck.name"
                        @change="e => onChangeDeckData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="TTS Message"
                        :value="deck.tts_message"
                        v-on:change="e => onChangeDeckData('tts_message', e)"
                      ></v-text-field>
                      <v-select
                        :items="constants.VOICE_NAME"
                        label="Voice Name"
                        :value="deck.voice_name"
                        v-model="deck.voice_name"
                        v-on:change="e => onChangeDeckData('voice_name', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        v-if="deck.introduction_voice_uri"
                        class="white--text"
                        color="subtitle_sound"
                        @click="openSubtitleSound()"
                        >Subtitle Sound
                      </v-btn>
                      <v-text-field
                        label="Introduction Voice (URL or Select file)"
                        :value="deck.introduction_voice_uri"
                        v-model="deck.introduction_voice_uri"
                        @change="
                          e => onChangeDeckData('introduction_voice_uri', e)
                        "
                      ></v-text-field>
                      <v-btn
                        @click="
                          isEditSound = true;
                          isEditIntroVoice = true;
                        "
                        ><v-icon>audiotrack</v-icon>Select Introduction
                        Voice</v-btn
                      >
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music (URL or Select file)"
                        :value="deck.bgm_uri"
                        v-model="deck.bgm_uri"
                        @change="e => onChangeDeckData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        @click="
                          isEditSound = true;
                          isEditBGM = true;
                        "
                      >
                        <v-icon>audiotrack</v-icon>
                        Select Background Music
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-img
                        v-if="deck.background_image_uri"
                        :src="deck.background_image_uri"
                        class="content_img"
                      ></v-img>
                      <v-text-field
                        label="Background Image"
                        :value="deck.background_image_uri"
                        v-model="deck.background_image_uri"
                        @change="
                          e => onChangeDeckData('background_image_uri', e)
                        "
                      >
                      </v-text-field>
                      <v-btn
                        @click="
                          isEditImage = true;
                          isEditBackgroundImage = true;
                          keyResource = 'background_image_uri';
                        "
                        >Select Background Image</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-btn color="red" dark @click="onClickDeleteDeck(deck._id)">
                    <v-icon>delete</v-icon>Delete this deck
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs2 mr-2 v-if="cards.length > 0 && selectedDeckIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Card</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    @click="toggleAddCard"
                    v-if="cards.length < 8"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(card, index) in swipeStore.selectSwipe.decks[
                      selectedDeckIndex
                    ].cards"
                    :key="card._id"
                    @click="onSelectCard(card._id)"
                    :class="index === selectedCardIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ index + 1 }}. {{ card.text }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="Object.keys(card).length > 1 && selectedDeckIndex != -1"
              xs4
              mr-2
            >
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Card Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickSaveCard">
                    <v-icon>save</v-icon>Save card
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-text-field
                              label="Text"
                              :value="card.text"
                              @change="e => onChangeCardData('text', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Article"
                              :value="card.article"
                              @change="e => onChangeCardData('article', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Word TTS Message"
                              :value="card.word_tts_message"
                              v-on:change="
                                e => onChangeCardData('word_tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Word Voice Name"
                              :value="card.word_voice_name"
                              v-model="card.word_voice_name"
                              v-on:change="
                                e => onChangeCardData('word_voice_name', e)
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-btn color="black" @click="editWrongQuestion()">
                              <v-icon>image</v-icon> Edit Wrong Question Image
                            </v-btn>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Correct Question Correct Sound (URL or Select file)"
                              :value="card.correct_sound_uri"
                              @change="
                                e => onChangeCardData('correct_sound_uri', e)
                              "
                            />
                          </v-flex>
                          <v-btn
                            color="black"
                            @click="
                              IsEditCorrectQuestionCorrectSound = true;
                              isEditCard = true;
                              isEditSound = true;
                            "
                          >
                            <v-icon>audiotrack</v-icon> Select Correct Question
                            Correct Sound
                          </v-btn>
                          <v-flex>
                            <v-text-field
                              label="Correct TTS Message"
                              :value="card.correct_tts_message"
                              v-on:change="
                                e => onChangeCardData('correct_tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Correct Voice Name"
                              :value="card.correct_voice_name"
                              v-model="card.correct_voice_name"
                              v-on:change="
                                e => onChangeCardData('correct_voice_name', e)
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Wrong Question Correct Sound (URL or Select file)"
                              :value="card.wrong_sound_uri"
                              @change="
                                e => onChangeCardData('wrong_sound_uri', e)
                              "
                            />
                          </v-flex>
                          <v-btn
                            color="black"
                            @click="
                              isEditSound = true;
                              isEditCard = true;
                              isEditWrongSoundUri = true;
                            "
                            ><v-icon>audiotrack</v-icon>Select Wrong
                            Sound</v-btn
                          >
                          <v-flex>
                            <v-text-field
                              label="Wrong TTS Message"
                              :value="card.wrong_tts_message"
                              v-on:change="
                                e => onChangeCardData('wrong_tts_message', e)
                              "
                            ></v-text-field>
                            <v-select
                              :items="constants.VOICE_NAME"
                              label="Wrong Voice Name"
                              :value="card.wrong_voice_name"
                              v-model="card.wrong_voice_name"
                              v-on:change="
                                e => onChangeCardData('wrong_voice_name', e)
                              "
                            ></v-select>
                          </v-flex>
                          <v-flex>
                            <v-select
                              :items="constants.CARD_TYPE"
                              label="Card Type"
                              :value="card.type"
                              v-model="card.type"
                              @change="e => onChangeCardData('type', e)"
                            ></v-select>
                          </v-flex>
                          <v-flex
                            v-if="
                              card.type &&
                              (card.type == 'sound' ||
                                card.type == 'image_sound')
                            "
                          >
                            <v-text-field
                              label="Question Sound (URL or Select file)"
                              :value="card.sound_uri"
                              @change="e => onChangeCardData('sound_uri', e)"
                            />
                            <v-btn color="black" @click="isEditSound = true">
                              <v-icon>audiotrack</v-icon> Edit Sound
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        xs6
                        v-if="
                          card.type &&
                          (card.type == 'image' ||
                            card.type == 'image_sound' ||
                            card.type == 'image_tts')
                        "
                      >
                        <img
                          :src="card.img_uri"
                          :alt="card.card"
                          class="content_img"
                        />

                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Image (URL or Select file)"
                            :value="card.img_uri"
                            @change="e => onChangeCardData('img_uri', e)"
                          />
                          <span class="headline" dark>
                            <v-btn
                              color="black"
                              @click="
                                isEditImage = true;
                                keyResource = 'img_uri';
                              "
                            >
                              <v-icon>image</v-icon>Edit Image
                            </v-btn>
                          </span>
                        </v-flex>
                        <v-flex xs12 align-end flexbox>
                          <img
                            v-if="card.front_img_uri"
                            :src="card.front_img_uri"
                            :alt="card.front_img_uri"
                            class="content_img"
                          />
                          <v-text-field
                            label="Front Card Image (URL or Select file)"
                            :value="card.front_img_uri"
                            v-on:change="
                              e => onChangeCardData('front_img_uri', e)
                            "
                          />
                          <span class="headline" dark>
                            <v-btn
                              color="black"
                              v-on:click="
                                isEditImage = true;
                                keyResource = 'front_img_uri';
                              "
                            >
                              <v-icon>image</v-icon>Edit Front Card Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-flex>
                      <v-flex xs12 align-end flexbox>
                        <img
                          v-if="card.back_img_uri"
                          :src="card.back_img_uri"
                          :alt="card.back_img_uri"
                          class="content_img"
                        />
                        <v-text-field
                          label="Back Card Image (URL or Select file)"
                          :value="card.back_img_uri"
                          v-on:change="e => onChangeCardData('back_img_uri', e)"
                        />
                        <span class="headline" dark>
                          <v-btn
                            color="black"
                            v-on:click="
                              isEditImage = true;
                              keyResource = 'back_img_uri';
                            "
                          >
                            <v-icon>image</v-icon>Edit Back Card Image
                          </v-btn>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-btn
                    v-if="cards.length > 1"
                    color="red"
                    dark
                    @click="onClickDeleteCard(card._id)"
                  >
                    <v-icon>delete</v-icon>Delete this card
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedDeckIndex !== -1
          ? swipeStore.selectSwipe.decks[selectedDeckIndex].learned_words
          : null
      "
      :incidentalWords="
        selectedDeckIndex !== -1
          ? swipeStore.selectSwipe.decks[selectedDeckIndex].incidental_words
          : null
      "
      :rewards="
        selectedDeckIndex !== -1
          ? swipeStore.selectSwipe.decks[selectedDeckIndex].reward
          : null
      "
      :sentences="
        selectedDeckIndex !== -1
          ? swipeStore.selectSwipe.decks[selectedDeckIndex].sentence
          : null
      "
      :phonics="
        selectedDeckIndex !== -1
          ? swipeStore.selectSwipe.decks[selectedDeckIndex].phonic
          : null
      "
    >
    </PointData>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <Modal
      :v-if="wrongQuestionManager.isAddWrongQuestion"
      :isOpen="wrongQuestionManager.isAddWrongQuestion"
      :close="onCloseModalAddWrongImage"
      :save="onSaveAddWrongImage"
      title="Add Wrong Question Manager"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <WrongQuestionManager
            :parenObject="deck"
            :action="wrongQuestionManager.action"
            @addedStatus="addedStatus"
            @wrongQuestionAddImageArray="wrongQuestionAddImageArray"
          ></WrongQuestionManager>
        </v-layout>
      </v-container>
    </Modal>
    <Modal
      :v-if="wrongQuestionManager.isEditWrongQuestion"
      :isOpen="wrongQuestionManager.isEditWrongQuestion"
      :close="onCloseModalEditWrongImage"
      :save="onSaveEditWrongImage"
      title="Edit Wrong Question Manager"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <WrongQuestionManager
            :parenObject="card"
            :action="wrongQuestionManager.action"
            @edittedStatus="edittedStatus"
            @wrongQuestionEditImageArray="wrongQuestionEditImageArray"
          ></WrongQuestionManager>
        </v-layout>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddDeck"
      :isOpen="isAddDeck"
      :isLastStep="isLastStep"
      :close="toggleAddDeck"
      :save="onClickAddDeck"
      title="Add Deck"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <v-flex>
            <v-text-field
              label="Deck Name"
              :value="deck.name"
              v-model="deck.name"
              @change="e => onChangeCreateDeck('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="TTS Message"
              :value="deck.tts_message"
              v-on:change="e => onChangeCreateDeck('tts_message', e)"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="deck.voice_name"
              v-model="deck.voice_name"
              v-on:change="e => onChangeCreateDeck('voice_name', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="deck.bgm_uri"
              v-model="deck.bgm_uri"
              @change="e => onChangeCreateDeck('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              @click="
                isEditSound = true;
                isEditBGM = true;
              "
              >Select Sound</v-btn
            >
          </v-flex>
        </v-layout>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title> Card {{ currentStep + 1 }} </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-text-field
                label="Text"
                :value="card.text"
                v-model="card.text"
                @change="e => onChangeCreateCard('text', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Article"
                :value="card.article"
                v-model="card.article"
                @change="e => onChangeCreateCard('article', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-select
                :items="constants.CARD_TYPE"
                label="Card Type"
                :value="card.type"
                v-model="card.type"
                @change="e => onChangeCreateCard('type', e)"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="
                card.type &&
                (card.type == 'sound' || card.type == 'image_sound')
              "
            >
              <v-text-field
                label="Question Sound"
                :value="card.sound_uri"
                v-model="card.sound_uri"
                @change="e => onChangeCreateCard('sound_uri', e)"
                disabled
              >
              </v-text-field>
              <v-btn @click="isEditSound = true">Select Sound</v-btn>
            </v-flex>
            <v-container
              justify-center
              v-if="
                card.type &&
                (card.type == 'image' ||
                  card.type == 'image_sound' ||
                  card.type == 'image_tts')
              "
            >
              <v-layout>
                <v-flex>
                  <v-img :src="card.img_uri" width="400" height="300">
                    <v-btn
                      @click="
                        isEditImage = true;
                        keyResource = 'img_uri';
                      "
                      >Select Image</v-btn
                    >
                  </v-img>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            @click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep + 1 < cards.length"
            color="green"
            dark
            @click="onClickNextStep"
            >Next</v-btn
          >
          <v-btn
            v-if="currentStep + 1 == cards.length && currentStep + 1 < maxCard"
            color="green"
            dark
            @click="onClickNextStep"
            >Add More Card</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddCard"
      :isOpen="isAddCard"
      :close="toggleAddCard"
      :save="onClickAddCard"
      title="Add Card"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Text"
              :value="card.text"
              v-model="card.text"
              @change="e => onChangeCreateSingleCard('text', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Article"
              :value="card.article"
              v-model="card.article"
              @change="e => onChangeCreateSingleCard('article', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn
              :class="wrongQuestionManager.isAdded ? 'added' : 'normal'"
              @click="addWrongQuestion()"
              >{{ wrongQuestionManager.buttonName }}</v-btn
            >
            <v-flex>
              <v-text-field
                label="Wrong Question Correct Sound (URL or Select file)"
                :value="card.wrong_sound_uri"
                @change="e => onChangeCreateSingleCard('wrong_sound_uri', e)"
              />
            </v-flex>
            <v-btn
              color="black"
              @click="
                isEditSound = true;
                isAddCard = true;
                isEditWrongSoundUri = true;
              "
              ><v-icon>audiotrack</v-icon>Select Wrong Sound</v-btn
            >
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Card Type"
              :value="card.type"
              v-model="card.type"
              @change="e => onChangeCreateSingleCard('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              card.type && (card.type == 'sound' || card.type == 'image_sound')
            "
          >
            <v-text-field
              label="Question Sound"
              :value="card.sound_uri"
              v-model="card.sound_uri"
              @change="e => onChangeCreateSingleCard('sound_uri', e)"
            >
            </v-text-field>
            <v-btn @click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
          <v-container
            justify-center
            v-if="
              card.type &&
              (card.type == 'image' ||
                card.type == 'image_sound' ||
                card.type == 'image_tts')
            "
          >
            <v-layout>
              <v-flex>
                <v-img :src="card.img_uri" width="300" height="300"></v-img>
                <v-text-field
                  label="Image"
                  :value="card.img_uri"
                  v-model="card.img_uri"
                  @change="e => onChangeCreateSingleCard('img_uri', e)"
                >
                </v-text-field>
                <v-btn
                  @click="
                    isEditImage = true;
                    keyResource = 'img_uri';
                  "
                  >Select Image</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
</style>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import WrongQuestionManager from './components/WrongQuestionManager.vue';

export default {
  name: 'Swipe',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
    WrongQuestionManager,
  },
  computed: {
    ...mapState({
      swipeStore: state => state.swipe,
      selectedDeckIndexStore: state => state.swipe.selectedDeckIndex,
    }),
  },
  data() {
    return {
      IsEditCorrectQuestionCorrectSound: false,
      isEditCard: false,
      isEditWrongSoundUri: false,
      wrongQuestionManager: {
        isEditWrongQuestion: false,
        isAddWrongQuestion: false,
        action: '',
        isAdded: false,
        isEditted: false,
        buttonName: 'Add Wrong Question',
      },
      isOpenPointData: false,
      currentStep: -1,
      isLastStep: false,
      maxCard: 8,
      cards: [],
      card: {},
      deck: {},
      selectedDeckIndex: -1,
      selectedCardIndex: -1,
      isEditImage: false,
      isEditBackgroundImage: false,
      selectImageUri: '',
      isEditSound: false,
      isEditBGM: false,
      isEditIntroVoice: false,
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddCard: false,
      isAddDeck: false,
      isUpdateDeck: false,
      constants,
      selectDeckId: '',
      keyResource: '',
    };
  },
  methods: {
    ...mapActions('swipe', [
      'fetchSwipe',
      'fetchSingleSwipe',
      'updateCardData',
      'updateCard',
      'clearUpdateData',
      'deleteDeck',
      'deleteCard',
      'updateCreateDeckData',
      'updateCreateCardData',
      'updateCreateSingleCardData',
      'updateDeckData',
      'updateDeck',
      'addDeck',
      'addCardByDeckId',
      'selectCategory',
      'selectSwipe',
    ]),
    editWrongQuestion() {
      this.wrongQuestionManager.isEditWrongQuestion = true;
      this.wrongQuestionManager.action = 'edit';
    },
    wrongQuestionEditImageArray(imageArray) {
      this.onChangeCardData('wrong_image_uri', imageArray);
    },
    wrongQuestionAddImageArray(imageArray) {
      this.onChangeCreateSingleCard('wrong_image_uri', imageArray);
    },
    edittedStatus(value) {
      if (value) {
        this.wrongQuestionManager.isEditted = true;
        this.wrongQuestionManager.buttonName = '✅ Editted Wrong Question';
      } else {
        this.wrongQuestionManager.isEditted = false;
      }
    },
    addedStatus(value) {
      if (value) {
        this.wrongQuestionManager.isAdded = true;
        this.wrongQuestionManager.buttonName = '✅ Added Wrong Question';
      } else {
        this.wrongQuestionManager.isAdded = false;
      }
    },
    addWrongQuestion() {
      this.wrongQuestionManager.isAddWrongQuestion = true;
      this.wrongQuestionManager.action = 'add';
    },
    onCloseModalEditWrongImage() {
      this.wrongQuestionManager.isEditWrongQuestion = false;
    },
    onCloseModalAddWrongImage() {
      this.wrongQuestionManager.isAddWrongQuestion = false;
    },
    onSaveAddWrongImage() {
      this.wrongQuestionManager.isAddWrongQuestion = false;
    },
    onSaveEditWrongImage() {
      const currentImageArray = this.card.wrong_image_uri;
      this.onChangeCardData('wrong_image_uri', currentImageArray);
      this.wrongQuestionManager.isEditWrongQuestion = false;
    },
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.deck.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.updateDeckData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateDeckData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateDeckData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateDeckData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateDeckData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateDeck();
      this.isOpenPointData = false;
    },
    onClickNextStep() {
      this.currentStep++;
      if (this.currentStep == 1) {
        this.isLastStep = true;
      }
      this.onActiveCreateCard();
    },
    onClickBackStep() {
      this.currentStep--;
      this.onActiveCreateCard();
    },
    async onSelectDeck(id) {
      this.clearUpdateData();
      const decks = this.swipeStore.selectSwipe.decks;
      const index = decks.findIndex(deck => deck._id === id);
      this.selectedDeckIndex = index;
      this.selectedCardIndex = -1;
      this.cards = [];
      this.card = {};
      this.cards = [...decks[index].cards];
      let formatDeck = {};
      Object.keys(decks[index]).forEach(key => {
        if (key === 'deck_type') {
          const deck_type = decks[index][key];
          const deckTypeIndex = constants.QUESTION_TYPE.findIndex(
            deck_type => deck_type.value === deck_type,
          );
          if (deckTypeIndex !== -1) {
            formatDeck[key] = constants.QUESTION_TYPE[deckTypeIndex];
          } else {
            formatDeck[key] = {
              text: decks[index]['deck_type'],
              value: decks[index]['deck_type'],
            };
          }
        } else if (key === 'card_type') {
          const card_type = decks[index][key];
          const cardTypeIndex = constants.ANSWER_TYPE.findIndex(
            card_type => card_type.value === card_type,
          );
          if (cardTypeIndex !== -1) {
            formatDeck[key] = constants.ANSWER_TYPE[cardTypeIndex];
          } else {
            formatDeck[key] = {
              text: decks[index]['card_type'],
              value: decks[index]['card_type'],
            };
          }
        } else {
          formatDeck[key] = decks[index][key];
        }
      });
      this.deck = {
        ...formatDeck,
      };
      this.updateDeckData({
        key: '_id',
        value: id,
      });
      this.selectDeckId = id;
    },
    onSelectCard(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this card yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateData();
              this.isDirty = false;
              const cards = [
                ...this.swipeStore.selectSwipe.decks[this.selectedDeckIndex]
                  .cards,
              ];
              const index = cards.findIndex(card => card._id === id);
              this.selectedCardIndex = index;
              this.card = cards[index];
              this.selectImageUri = '';
              this.updateCardData({
                key: '_id',
                value: id,
              });
            }
          });
      } else {
        this.clearUpdateData();
        const cards = [
          ...this.swipeStore.selectSwipe.decks[this.selectedDeckIndex].cards,
        ];
        const index = cards.findIndex(card => card._id === id);
        this.selectedCardIndex = index;
        this.card = cards[index];
        this.selectImageUri = '';
        this.updateCardData({
          key: '_id',
          value: id,
        });
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeCardData(key, value) {
      this.isDirty = true;
      this.card[key] = value;
      this.updateCardData({
        key,
        value,
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.keyResource) {
        if (this.isAddDeck) {
          this.updateCreateCardData({
            key: this.keyResource,
            value: uri,
            index: this.currentStep,
          });
          this.card[this.keyResource] = uri;
        } else if (this.isAddCard) {
          this.updateCreateSingleCardData({
            key: this.keyResource,
            value: uri,
          });
          this.card[this.keyResource] = uri;
        } else if (this.isEditBackgroundImage) {
          this.isDirty = true;
          this.updateDeckData({
            key: this.keyResource,
            value: uri,
          });
          this.deck[this.keyResource] = uri;
        } else {
          this.isDirty = true;
          this.updateCardData({
            key: this.keyResource,
            value: uri,
          });
          this.card[this.keyResource] = uri;
        }
      }
      this.keyResource = '';
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.IsEditCorrectQuestionCorrectSound && this.isEditCard) {
        this.onChangeCardData('correct_sound_uri', uri);
        this.IsEditCorrectQuestionCorrectSound = false;
      } else if (this.isAddCard && this.isEditWrongSoundUri) {
        this.updateCreateSingleCardData({
          key: 'wrong_sound_uri',
          value: uri,
        });
        this.card.wrong_sound_uri = uri;
        this.isEditWrongSoundUri = false;
      } else if (this.isEditCard && this.isEditWrongSoundUri) {
        this.updateCardData({
          key: 'wrong_sound_uri',
          value: uri,
        });
        this.card.wrong_sound_uri = uri;
        this.isEditCard = false;
        this.isEditWrongSoundUri = false;
      } else if (this.isAddDeck && this.isEditBGM) {
        this.updateCreateDeckData({
          key: 'bgm_uri',
          value: uri,
        });
        this.deck.bgm_uri = uri;
      } else if (this.isAddCard) {
        this.updateCreateCardData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.card.sound_uri = uri;
      } else if (!this.isAddDeck && !this.isAddCard && this.isEditBGM) {
        this.isDirty = true;
        this.updateDeckData({
          key: 'bgm_uri',
          value: uri,
        });
        this.deck.bgm_uri = uri;
      } else if (!this.isAddDeck && !this.isAddCard && this.isEditIntroVoice) {
        this.isDirty = true;
        this.updateDeckData({
          key: 'introduction_voice_uri',
          value: uri,
        });
        this.deck.introduction_voice_uri = uri;
      } else {
        this.isDirty = true;
        this.updateCardData({
          key: 'sound_uri',
          value: uri,
        });
        this.card.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    onClickSaveCard() {
      const updateCardData = {
        ...this.swipeStore.updateCardData,
      };
      this.updateCard(updateCardData);
      this.isDirty = false;
      Object.keys(updateCardData).forEach(key => {
        this.card[key] = updateCardData[key];
      });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateCard() {
      const cards = [...this.swipeStore.addCardData];
      if (this.currentStep == cards.length && this.currentStep < this.maxCard) {
        const new_card = { ...this.swipeStore.cardData };
        this.card = { ...new_card };
        this.cards = [...cards, new_card];
        this.updateCreateCardData({
          key: 'text',
          value: '',
          index: this.currentStep,
        });
      } else {
        this.card = { ...cards[this.currentStep] };
        this.cards = [...cards];
      }
    },
    onChangeCreateCard(key, value) {
      this.updateCreateCardData({
        key,
        value,
        index: this.currentStep,
      });
    },
    onChangeCreateSingleCard(key, value) {
      this.updateCreateSingleCardData({
        key,
        value,
      });
    },
    toggleAddDeck() {
      this.selectedDeckIndex = -1;
      this.selectedCardIndex = -1;
      // init deck data
      this.updateCreateDeckData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.deck = { ...this.swipeStore.addDeckData };
      // init card data
      this.cards = [...this.swipeStore.addCardData];
      this.isAddDeck = !this.isAddDeck;
      if (!this.isAddDeck && this.swipeStore.selectedCategoryId) {
        this.$router.push('/swipes');
      }
    },
    onClickAddDeck() {
      const id = this.$route.query.id;
      const createDeckData = {
        ...this.swipeStore.addDeckData,
      };
      const createCardData = [...this.swipeStore.addCardData];
      this.addDeck({
        category_id: id,
        deck: createDeckData,
        cards: createCardData,
      });
      this.isAddDeck = false;
    },
    onClickDeleteDeck(id) {
      this.deleteDeck(id);
      this.isDirty = false;
      this.selectedDeckIndex = -1;
      this.card = {};
      this.cards = [];
      this.selectedCardIndex = -1;
    },
    toggleAddCard(id) {
      this.selectedDeckIndex = -1;
      this.selectedCardIndex = -1;
      this.card = { ...this.swipeStore.cardData };
      this.isAddCard = !this.isAddCard;
      this.resetWrongQuestionStatus();
    },
    resetWrongQuestionStatus() {
      this.wrongQuestionManager.buttonName = 'Add Wrong Question';
      this.wrongQuestionManager.isAdded = false;
    },
    onClickAddCard() {
      const id = this.$route.query.id;
      const createCardData = {
        ...this.swipeStore.addSingleCardData,
      };
      this.addCardByDeckId({
        category_id: id,
        deck_id: this.selectDeckId,
        card: createCardData,
      });
      this.isAddCard = false;
    },
    onClickDeleteCard(id) {
      this.deleteCard(id);
      this.isDirty = false;
      this.selectedCardIndex = -1;
      this.card = {};
    },
    onChangeCardType(e) {
      if (this.isAddDeck) {
        this.createDeckData({
          key: 'card_type',
          value: e,
        });
        this.deck.card_type = e;
      } else if (this.isUpdateDeck) {
        this.updateDeckData({
          key: 'card_type',
          value: e,
        });
        this.deck.card_type = e;
      }
    },
    onChangeDeckData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateDeckData({
        key,
        value,
      });
    },
    onClickUpdateDeck() {
      const updateDeckData = {
        ...this.swipeStore.updateDeckData,
      };
      this.updateDeck(updateDeckData);
      this.selectedDeckIndex = -1;
      this.selectedCardIndex = -1;
      this.card = {};
      this.cards = [];
    },
    onChangeCreateDeck(key, value) {
      this.updateCreateDeckData({
        key,
        value,
      });
    },
  },
  watch: {
    selectedDeckIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectDeck(this.$route.query.sub_id);
      }
    },
  },
  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleSwipe({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.swipeStore.selectedCategoryId) {
      this.toggleAddDeck();
    } else if (Object.keys(this.swipeStore.selectSwipe).length < 1) {
      this.fetchSingleSwipe({ id: this.$route.query.id });
    }
  },
};
</script>
<style scoped>
.added {
  color: lawngreen;
}
</style>
