<template>
  <div>
    <v-btn icon color="green" v-on:click="addData()">
      <v-icon>add</v-icon>
    </v-btn>
    Recipe Info
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="currentStep > n"
            :step="n"
            editable
          >
            <!-- item {{ n }} -->
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <v-form ref="formRound">
            <v-card v-if="currentStep > 0">
              <v-toolbar dark>
                <v-toolbar-title>Recipe Info {{ currentStep }}</v-toolbar-title>
                <v-btn
                  v-if="steps > 1"
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="onClickRemoveByIndex(currentStep - 1)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-container v-if="data[currentStep - 1]">
                <v-layout column class="elevation-5" style="padding: 20px">
                  <b style="align-self: center;">Recipe Info</b>
                  <v-flex xs12>
                    <v-combobox
                      v-model="data[currentStep - 1].ingredient_items"
                      label="Add some ingredient items"
                      multiple
                      small-chips
                      :hint="`Maximum of ${max.ingredient_items} items`"
                      @change="e => onChangeRound('ingredient_items', e)"
                    ></v-combobox>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Word"
                      :value="data[currentStep - 1].product_infos.word"
                      v-model="data[currentStep - 1].product_infos.word"
                      :rules="[rules.required]"
                      @change="e => onChangeRound('product_infos.word', e)"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data[currentStep - 1].product_infos.image_uri"
                      :src="data[currentStep - 1].product_infos.image_uri"
                      :alt="data[currentStep - 1].product_infos.image_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Image URI (URL or Select file)"
                      :value="data[currentStep - 1].product_infos.image_uri"
                      v-model="data[currentStep - 1].product_infos.image_uri"
                      :rules="[rules.validURI]"
                      @change="e => onChangeRound('product_infos.image_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isImageUri')"
                      ><v-icon>image</v-icon>Select Image</v-btn
                    >
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="TTS Message"
                      v-model.trim="
                        data[currentStep - 1].product_infos.tts_message
                      "
                      @change="
                        e => onChangeRound('product_infos.tts_message', e)
                      "
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Sound URI (URL or Select file)"
                      :value="data[currentStep - 1].product_infos.sound_uri"
                      v-model="data[currentStep - 1].product_infos.sound_uri"
                      :rules="[rules.validURI]"
                      @change="e => onChangeRound('product_infos.sound_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('sound', 'isSoundUri')"
                      ><v-icon>audiotrack</v-icon>Select Sound</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="currentStep > 1"
                color="red"
                dark
                @click="onClickBackStep()"
                >Previous</v-btn
              >
              <v-btn
                v-if="currentStep < steps"
                color="primary"
                dark
                @click="onClickNextStep()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import rules from '../../../../utils/ruleValidate';
import constants from '../../../../constants';
import { keyExists } from '../../../../utils/lib/keyExists.js';

export default {
  name: 'RecipeInfo',
  components: { ResourceSelect },
  computed: {
    ...mapState({
      cookingGameStore: state => state.cooking_game,
      recipe_infos: state =>
        state.cooking_game.selectedCookingGame.recipe_infos,
      updatedCookingGame: state => state.cooking_game.updateCookingGame,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
    currentRecipeInfoStep() {
      return this.currentStep;
    },
    currentIngredientItem() {
      return (
        this.data[this.currentStep - 1] &&
        this.data[this.currentStep - 1].ingredient_items
      );
    },
  },
  data() {
    return {
      keyExists,
      max: { ingredient_items: 3 },
      constants,
      rules,
      currentStep: 1,
      steps: 1,
      data: [],
      valid: true,
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
        sound: {
          isEdit: false,
          selectUri: '',
        },
      },
      resourceType: '',
    };
  },
  props: {
    testProp: {
      type: String,
      default: '',
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('cooking_game', ['setStateByKey']),
    limitData() {
      return 5;
    },
    addData() {
      const defaultData = JSON.parse(
        JSON.stringify(this.cookingGameStore.defaultCreate.recipe_infos[0]),
      );
      if (this.data.length < this.limitData()) {
        this.data.push(defaultData);
        this.onChangeRound();
        this.currentStep = this.data.length;
        this.steps = this.data.length;
      }
    },
    onClickRemoveByIndex(index) {
      swal
        .fire({
          titleText: `Are your sure to remove item ${index + 1}?`,
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            this.onChangeRound();
            this.steps = this.data.length;
            if (this.currentStep === 1) {
              this.currentStep = 1;
            } else this.currentStep -= 1;
          }
        });
    },
    onClickNextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onChangeRound() {
      this.$emit('validate', this.valid);
      if (this.$refs.formRound[0].validate()) {
        this.isCreate
          ? this.setStateByKey({
              statekey: 'createCookingGame',
              key: 'recipe_infos',
              value: this.data,
            })
          : this.setStateByKey({
              statekey: 'updateCookingGame',
              key: 'recipe_infos',
              value: this.data,
            });
      }
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      const index = this.currentStep - 1;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isImageUri) {
            this.data[index].product_infos.image_uri = uri;
            this.resource[resourceType].isImageUri = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isSoundUri) {
            this.data[index].product_infos.sound_uri = uri;
            this.resource[resourceType].isSoundUri = false;
          }
          break;
        default:
          break;
      }
      this.onChangeRound();
      this.closeEditResource();
    },
    closeEditResource() {
      this.resource[this.resourceType].isEdit = false;
      this.resource[this.resourceType].selectUri = '';
      this.resourceType = '';
    },
  },
  watch: {
    currentIngredientItem(val) {
      if (val.length > this.max.ingredient_items) {
        this.$nextTick(() => this.currentIngredientItem.pop());
      }
    },
  },
  mounted() {
    if (this.isCreate) {
      this.data = JSON.parse(
        JSON.stringify(this.cookingGameStore.defaultCreate.recipe_infos),
      );
    } else {
      this.data = JSON.parse(JSON.stringify(this.recipe_infos));
      this.steps = this.data.length;
    }
  },
};
</script>
