<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="
        isCreate ? 'Create Live Class Template' : 'Update Live Class Template'
      "
    >
      <v-card>
        <v-container>
          <v-form
            ref="formData"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs text-xs-center>
              <v-flex>
                <div v-if="!isCreate">
                  <v-text-field
                    disabled
                    label="_id"
                    :value="data._id"
                  ></v-text-field>
                  <v-text-field
                    disabled
                    label="activity_id"
                    :value="data.activity_id"
                  ></v-text-field>
                </div>
                <v-text-field
                  label="title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeTemplate('title', e)"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout grid-list-xs text-xs-center v-if="!isCreate">
              <v-flex>
                <v-text-field
                  disabled
                  label="unit_index"
                  :value="data.unit_index"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="lesson_index"
                  :value="data.lesson_index"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  disabled
                  label="level_index"
                  :value="data.level_index"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <!-- <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    /> -->
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import moment from 'moment';

export default {
  components: { Modal },
  name: 'LiveclassOfferCardsModal',
  data() {
    return {
      moment,
      valid: true,
      rules,
      data: {},
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      liveClassTemplatesList: state => state.liveclass.liveClassTemplatesList,
      selectedTemplate: state => state.liveclass.selectedTemplate,
      updateTemplateData: state => state.liveclass.updateTemplateData,
      defaultCreateTeamplate: state => state.liveclass.defaultCreateTeamplate,
    }),
  },
  methods: {
    ...mapActions('liveclass', [
      'createTemplate',
      'updateTemplate',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formData.validate()) {
        this.isCreate ? this.createTemplate() : this.updateTemplate();
        this.close();
      }
    },
    close() {
      if (this.isCreate) {
        this.clearStateByKey('defaultCreateTeamplate');
      } else {
        this.clearStateByKey('selectedTemplate');
        this.clearStateByKey('updateTemplateData');
      }
      this.$emit('close');
    },
    onchangeTemplate(key, value) {
      this.isCreate
        ? this.setStateByKey({
            statekey: 'createTemaplateData',
            key,
            value,
          })
        : this.setStateByKey({
            statekey: 'updateTemplateData',
            key,
            value,
          });
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.data = this.isCreate
          ? JSON.parse(JSON.stringify({ ...this.defaultCreateTeamplate }))
          : JSON.parse(JSON.stringify({ ...this.selectedTemplate }));
      }
    },
  },
};
</script>

<style></style>
