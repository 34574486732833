<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :save="save"
    :close="close"
    :isLastStep="valid"
    title="Assign To Live Class Template"
  >
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- <v-layout grid-list-xs column> -->
          <v-data-table
            :items="liveClassTemplatesList"
            :headers="headers"
            :pagination.sync="pagination"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <tr
                class="row-pointer"
                @click="
                  setData(props.item._id, paginationNumber + (props.index + 1))
                "
                :class="
                  paginationNumber + (props.index + 1) === selectedTemplateIndex
                    ? 'active'
                    : 'inactive'
                "
              >
                <td>
                  {{ paginationNumber + (props.index + 1) }}
                </td>
                <td>
                  {{ props.item.title }}
                </td>
                <td>
                  <v-icon v-if="props.item.activity_id" color="white"
                    >check_circle</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- </v-layout> -->
        </v-form>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: { Modal },
  data() {
    return {
      valid: true,
      updateData: {},
      headers: [
        { text: 'No.', value: 'no.', sortable: false },
        { text: 'Title', value: 'title', sortable: false },
        {
          text: 'activity_id',
          value: 'activity_id',
          sortable: false,
        },
      ],
      selectedTemplateIndex: -1,
      pagination: {},
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    activity_id: {
      type: String,
      default: '',
      require: true,
    },
  },
  computed: {
    ...mapState({
      liveClassTemplatesList: state => state.liveclass.liveClassTemplatesList,
    }),
    paginationNumber() {
      return (this.pagination.page - 1) * this.pagination.rowsPerPage;
    },
  },
  methods: {
    ...mapActions('liveclass', ['fetchLiveClassTempateList', 'updateTemplate']),
    setData(template_id, selectedIndex) {
      this.selectedTemplateIndex = selectedIndex;
      this.updateData._id = template_id;
    },
    save() {
      if (this.selectedTemplateIndex > -1) {
        this.updateTemplate(this.updateData);
        this.close();
      }
    },
    close() {
      this.selectedTemplateIndex = -1;
      this.updateData = {};
      this.$emit('close');
    },
  },
  watch: {
    async isOpen(val) {
      if (val) {
        this.updateData = {
          activity_id: this.activity_id,
        };
        await this.fetchLiveClassTempateList();
      }
    },
  },
};
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>
