var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',[_c('img',{staticClass:"content_img",attrs:{"src":_vm.finish.start_image_uri}}),_c('v-text-field',{attrs:{"label":"Start Image URI"},model:{value:(_vm.finish.start_image_uri),callback:function ($$v) {_vm.$set(_vm.finish, "start_image_uri", $$v)},expression:"finish.start_image_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":_vm.onclickStartImage}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Start Image URI")],1),_c('SpriteSheet',{attrs:{"title":'is start sprite sheet',"isToggle":_vm.finish.is_start_sprite_sheet,"fieldname":'Edit Start Sheet Data',"object":_vm.finish.start_sprite_sheet_data},on:{"onchangeToggleSprite":function($event){return _vm.$emit(
          'onchangeToggleSprite',
          'is_start_sprite_sheet',
          'start_sprite_sheet_data',
          $event
        )},"saveSpriteSheetData":function($event){return _vm.$emit('saveSpriteSheetData', 'start_sprite_sheet_data', $event)}}}),_c('img',{staticClass:"content_img",attrs:{"src":_vm.finish.finish_image_uri}}),_c('v-text-field',{attrs:{"label":"Finish Image URI"},model:{value:(_vm.finish.finish_image_uri),callback:function ($$v) {_vm.$set(_vm.finish, "finish_image_uri", $$v)},expression:"finish.finish_image_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":_vm.onclickFinishImage}},[_c('v-icon',[_vm._v("audiotrack")]),_vm._v("Select Finish Image URI")],1),_c('SpriteSheet',{attrs:{"title":'is finish sprite sheet',"isToggle":_vm.finish.is_finish_sprite_sheet,"fieldname":'Edit Finish Sheet Data',"object":_vm.finish.finish_sprite_sheet_data},on:{"onchangeToggleSprite":function($event){return _vm.$emit(
          'onchangeToggleSprite',
          'is_finish_sprite_sheet',
          'finish_sprite_sheet_data',
          $event
        )},"saveSpriteSheetData":function($event){return _vm.$emit('saveSpriteSheetData', 'finish_sprite_sheet_data', $event)}}}),_c('v-text-field',{attrs:{"label":"Finish Sound URI"},model:{value:(_vm.finish.finish_sound_uri),callback:function ($$v) {_vm.$set(_vm.finish, "finish_sound_uri", $$v)},expression:"finish.finish_sound_uri"}}),_c('v-btn',{staticClass:"btn-center",on:{"click":_vm.onclickFinishSound}},[_c('v-icon',[_vm._v("audiotrack")]),_vm._v("Select Finish Sound URI")],1)],1),(_vm.isSelectImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isSelectImage,"close":_vm.closeSelectImage,"save":_vm.saveSelectImage,"value":_vm.selectedImageUri,"onSelect":_vm.onSelectImageResource}}):_vm._e(),(_vm.isSelectSound)?_c('ResourceSelect',{attrs:{"resourceType":"sound","isShow":_vm.isSelectSound,"close":_vm.closeSelectSound,"save":_vm.saveSelectSound,"value":_vm.selectedSoundUri,"onSelect":_vm.onSelectSoundResource}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }