<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="isCreate ? 'Create Labyrinth' : 'Update Labyrinth'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <div v-if="isCreate">
              <v-layout grid-list-xs column text-xs-center v-if="isCreate">
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  required
                  label="Title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
              </v-layout>
            </div>

            <div v-else>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Title"
                    :value="data.title"
                    v-model="data.title"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('title', e)"
                  ></v-text-field>
                  <v-text-field
                    label="Intro Music URI (URL or Select file)"
                    v-model.trim="data.music_intro_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('music_intro_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isIntroSound')">
                    <v-icon>audiotrack</v-icon>Select Intro Music
                  </v-btn>
                  <v-text-field
                    label="Collect SFX URI (URL or Select file)"
                    v-model.trim="data.collect_sfx"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('collect_sfx', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isCollectSfx')">
                    <v-icon>audiotrack</v-icon>Select Collect SFX
                  </v-btn>
                  <v-text-field
                    label="TTS Message"
                    v-model.trim="data.tts_message"
                    @change="e => onchangeUpdate('tts_message', e)"
                  ></v-text-field>
                  <v-select
                    :items="globalConstants.VOICE_NAME"
                    label="Voice Name"
                    :value="data.voice_name"
                    v-model="data.voice_name"
                    @change="e => onchangeUpdate('voice_name', e)"
                  ></v-select>
                  <v-flex>
                    <img
                      v-if="data.background_uri"
                      :src="data.background_uri"
                      :alt="data.background_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Background URI (URL or Select file)"
                      v-model.trim="data.background_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('background_uri', e)"
                    ></v-text-field>
                    <v-btn
                      dark
                      @click="setResource('image', 'isBackgroundImage')"
                    >
                      <v-icon>images</v-icon>Select Background URI
                    </v-btn>
                    <v-text-field
                      label="Size Area"
                      type="number"
                      v-model.number="data.size_area"
                      @change="e => onchangeUpdate('size_area', e)"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.ground_uri"
                      :src="data.ground_uri"
                      :alt="data.ground_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Ground URI (URL or Select file)"
                      v-model.trim="data.ground_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('ground_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isGroundImage')">
                      <v-icon>images</v-icon>Select Ground URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.wall_uri"
                      :src="data.wall_uri"
                      :alt="data.wall_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Wall URI (URL or Select file)"
                      v-model.trim="data.wall_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('wall_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isWallImage')">
                      <v-icon>images</v-icon>Select Wall URI
                    </v-btn>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Background Music URI (URL or Select file)"
                    v-model.trim="data.bgm_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('bgm_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isBgmSound')">
                    <v-icon>audiotrack</v-icon>Select Background Music
                  </v-btn>
                  <v-text-field
                    label="Volume Music (0-1)"
                    v-model.trim="data.bgm_volume"
                    type="number"
                    :rules="[rules.required, rules.numberFloat]"
                    @change="e => onchangeUpdate('bgm_volume', e)"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout column class="elevation-5" style="padding: 20px">
                <b style="align-self: center;">Character Info</b>
                <v-flex>
                  <img
                    v-if="data.character_info.head_data.head_uri"
                    :src="data.character_info.head_data.head_uri"
                    :alt="data.character_info.head_data.head_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Head URI (URL or Select file)"
                    v-model.trim="data.character_info.head_data.head_uri"
                    :rules="[rules.validURI]"
                    @change="
                      e =>
                        onchangeUpdate('character_info.head_data.head_uri', e)
                    "
                  ></v-text-field>
                  <v-btn dark @click="setResource('image', 'isHeadUri')">
                    <v-icon>images</v-icon>Select Head URI
                  </v-btn>
                  <v-flex>
                    <SpriteSheet
                      :title="'Use Sprite Sheet'"
                      :isToggle="data.character_info.head_data.is_sprite_sheet"
                      :fieldname="'Edit Move Sheet Data'"
                      :object="
                        data.character_info.head_data
                          .labyrinth_sprite_sheet_data
                      "
                      :color="'dark'"
                      @onchangeToggleSprite="onchangeToggleSprite($event)"
                      @saveSpriteSheetData="saveSpriteSheetData($event)"
                    />
                  </v-flex>
                </v-flex>
                <v-flex>
                  <img
                    v-if="data.character_info.body_uri"
                    :src="data.character_info.body_uri"
                    :alt="data.character_info.body_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Body URI (URL or Select file)"
                    v-model.trim="data.character_info.body_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('character_info.body_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('image', 'isBodyUri')">
                    <v-icon>images</v-icon>Select Body URI
                  </v-btn>
                </v-flex>
                <v-flex>
                  <img
                    v-if="data.character_info.neck_uri"
                    :src="data.character_info.neck_uri"
                    :alt="data.character_info.neck_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Neck URI (URL or Select file)"
                    v-model.trim="data.character_info.neck_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('character_info.neck_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('image', 'isNeckUri')">
                    <v-icon>images</v-icon>Select Neck URI
                  </v-btn>
                </v-flex>
              </v-layout>

              <GameRound
                v-if="isCreate === false"
                @validate="valid = $event"
                :isCreate="isCreate"
              >
              </GameRound>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import GameRound from './GameRound.vue';
import globalConstants from '../../../../constants';
import SpriteSheet from '../../../../components/SpriteSheetNew.vue';
import { keyExists } from '../../../../utils/lib/keyExists.js';

export default {
  components: { Modal, ResourceSelect, GameRound, SpriteSheet },
  data() {
    return {
      globalConstants,
      keyExists,
      constants,
      valid: true,
      rules,
      data: {},
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isImage: false,
        },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      labyrinthStore: state => state.labyrinth,
      selectedLabyrinth: state => state.labyrinth.selectedLabyrinth,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('labyrinth', [
      'createLabyrinth',
      'updateLabyrinth',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createLabyrinth() : this.updateLabyrinth();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createLabyrinth')
        : this.clearStateByKey('updateLabyrinth');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isGroundImage) {
            this.data.ground_uri = uri;
            this.onchangeUpdate('ground_uri', uri);
            this.resource[resourceType].isGroundImage = false;
          } else if (this.resource[resourceType].isWallImage) {
            this.data.wall_uri = uri;
            this.onchangeUpdate('wall_uri', uri);
            this.resource[resourceType].isWallImage = false;
          } else if (this.resource[resourceType].isBackgroundImage) {
            this.data.background_uri = uri;
            this.onchangeUpdate('background_uri', uri);
            this.resource[resourceType].isBackgroundImage = false;
          } else if (this.resource[resourceType].isHeadUri) {
            this.data.character_info.head_data.head_uri = uri;
            this.onchangeUpdate('character_info.head_data.head_uri', uri);
            this.resource[resourceType].isHeadUri = false;
          } else if (this.resource[resourceType].isBodyUri) {
            this.data.character_info.body_uri = uri;
            this.onchangeUpdate('character_info.body_uri', uri);
            this.resource[resourceType].isBodyUri = false;
          } else if (this.resource[resourceType].isNeckUri) {
            this.data.character_info.neck_uri = uri;
            this.onchangeUpdate('character_info.neck_uri', uri);
            this.resource[resourceType].isNeckUri = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isBgmSound) {
            this.data.bgm_uri = uri;
            this.onchangeUpdate('bgm_uri', uri);
          } else if (this.resource[resourceType].isCollectSfx) {
            this.data.collect_sfx = uri;
            this.onchangeUpdate('collect_sfx', uri);
          } else {
            this.data.music_intro_uri = uri;
            this.onchangeUpdate('music_intro_uri', uri);
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createLabyrinth' : 'updateLabyrinth',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    checkSpriteSheetData(object) {
      const default_labyrinth_sprite_sheet_data = {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      };
      let altered_object = Object.assign({}, object);
      const expected_default = {
        is_sprite_sheet: false,
        labyrinth_sprite_sheet: JSON.parse(
          JSON.stringify(default_labyrinth_sprite_sheet_data),
        ),
      };
      if (!this.keyExists(object, 'is_sprite_sheet')) {
        altered_object.character_info.head_data.is_sprite_sheet =
          expected_default.is_sprite_sheet;
        this.onchangeUpdate(
          'character_info.head_data.is_sprite_sheet',
          expected_default.is_sprite_sheet,
        );
      }
      if (!this.keyExists(object, 'labyrinth_sprite_sheet_data')) {
        this.onchangeUpdate(
          'character_info.head_data.labyrinth_sprite_sheet_data',
          expected_default.labyrinth_sprite_sheet,
        );
        altered_object.character_info.head_data.labyrinth_sprite_sheet_data =
          expected_default.labyrinth_sprite_sheet;
      }
      return altered_object;
    },
    onchangeToggleSprite({ value }) {
      this.data.character_info.head_data.is_sprite_sheet = value;
      this.onchangeUpdate('character_info.head_data.is_sprite_sheet', value);
    },
    saveSpriteSheetData({ value }) {
      this.data.character_info.head_data.labyrinth_sprite_sheet_data = value;
      this.onchangeUpdate(
        'character_info.head_data.labyrinth_sprite_sheet_data',
        value,
      );
    },
    setSpriteSheetData() {
      this.data = { ...this.checkSpriteSheetData(this.data) };
    },
    set_category_id() {
      if (this.isCreate && 'category_id' in this.labyrinthStore.labyrinthList) {
        this.data.category_id = this.labyrinthStore.labyrinthList.category_id;
        this.onchangeUpdate(
          'category_id',
          this.labyrinthStore.labyrinthList.category_id,
        );
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) this.fetchCategories();
        this.data = this.isCreate
          ? JSON.parse(JSON.stringify({ ...this.labyrinthStore.defaultCreate }))
          : JSON.parse(JSON.stringify({ ...this.selectedLabyrinth }));
        this.set_category_id();
        this.setSpriteSheetData();
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
