<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="isCreate ? 'Create Sequence Game' : 'Update Sequence Game'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-autocomplete
                  v-if="isCreate"
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  label="title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
                <v-text-field
                  label="Intro Music URI (URL or Select file)"
                  v-model.trim="data.music_intro_uri"
                  :rules="[rules.validURI]"
                  @change="e => onchangeUpdate('music_intro_uri', e)"
                ></v-text-field>
                <v-btn dark @click="setResource('sound', 'isIntroSound')">
                  <v-icon>audiotrack</v-icon>Select Intro Music
                </v-btn>
                <v-text-field
                  label="TTS Message"
                  v-model.trim="data.tts_message"
                  @change="e => onchangeUpdate('tts_message', e)"
                ></v-text-field>
                <v-select
                  :items="globalConstants.VOICE_NAME"
                  label="Voice Name"
                  :value="data.voice_name"
                  v-model="data.voice_name"
                  @change="e => onchangeUpdate('voice_name', e)"
                ></v-select>
              </v-flex>
            </v-layout>
            <ItemInfo @validate="valid = $event" :isCreate="isCreate">
            </ItemInfo>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-layout row wrap>
                  <v-select
                    label="Difficulty"
                    :items="constants.difficultyDropdown"
                    item-text="name"
                    item-value="value"
                    v-model="data.difficulty"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('difficulty', e)"
                  ></v-select>
                  <v-select
                    label="Theme"
                    :items="constants.themeDropdown"
                    item-text="name"
                    item-value="value"
                    v-model="data.theme"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('theme', e)"
                  ></v-select>
                  <v-select
                    label="Control"
                    :items="constants.controlDropdown"
                    item-text="name"
                    item-value="value"
                    v-model="data.control"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('control', e)"
                  ></v-select>
                  <v-flex
                    ><v-text-field
                      type="number"
                      label="correct_count"
                      :value="data.correct_count"
                      v-model.number="data.correct_count"
                      :rules="[rules.required, rules.isNumber]"
                      @change="
                        e => onchangeUpdate('correct_count', parseInt(e))
                      "
                    ></v-text-field
                  ></v-flex>
                </v-layout>
                <v-text-field
                  label="Background Music URI (URL or Select file)"
                  v-model.trim="data.bgm_uri"
                  :rules="[rules.validURI]"
                  @change="e => onchangeUpdate('bgm_uri', e)"
                ></v-text-field>
                <v-btn dark @click="setResource('sound', 'isBgmSound')">
                  <v-icon>audiotrack</v-icon>Select Background Music
                </v-btn>
                <v-text-field
                  label="Volume Music (0-1)"
                  v-model.trim="data.bgm_volume"
                  :rules="[rules.required, rules.numberFloat]"
                  @change="e => onchangeUpdate('bgm_volume', e)"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import ItemInfo from './ItemInfo.vue';
import globalConstants from '../../../../constants';

export default {
  components: { Modal, ResourceSelect, ItemInfo },
  data() {
    return {
      globalConstants,
      constants,
      valid: true,
      rules,
      data: {},
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      sequenceGameStore: state => state.sequence_game,
      selectedSequenceGame: state => state.sequence_game.selectedSequenceGame,
      sequenceGameList() {
        const [data] = this.sequenceGameStore.sequenceGameList;
        return data;
      },
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('sequence_game', [
      'createSequenceGame',
      'updateSequenceGame',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createSequenceGame() : this.updateSequenceGame();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createSequenceGame')
        : this.clearStateByKey('updateSequenceGame');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          break;
        case 'sound':
          if (this.resource[resourceType].isBgmSound) {
            this.data.bgm_uri = uri;
            this.onchangeUpdate('bgm_uri', uri);
          } else {
            this.data.music_intro_uri = uri;
            this.onchangeUpdate('music_intro_uri', uri);
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createSequenceGame' : 'updateSequenceGame',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    set_category_id() {
      if (this.isCreate && 'category_id' in this.sequenceGameList) {
        this.data.category_id = this.sequenceGameList.category_id;
        this.onchangeUpdate('category_id', this.sequenceGameList.category_id);
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) this.fetchCategories();
        this.data = this.isCreate
          ? JSON.parse(
              JSON.stringify({ ...this.sequenceGameStore.defaultCreate }),
            )
          : JSON.parse(JSON.stringify({ ...this.selectedSequenceGame }));
        this.set_category_id();
      }
    },
  },
};
</script>

<style></style>
