<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/cooking_game')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ cookingGameList.category }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="showModalCreate()">
            <v-icon>add</v-icon>New Cooking Game
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-if="!isLoadCookingGame"
            :items="cookingGameList.cooking_games"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>{{ props.index + 1 }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.title }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-chip color="primary" text-color="white">
                    {{ props.item.display_cooking_game_list }}
                  </v-chip>
                </v-layout>
              </td>
              <td>
                <v-layout justify-center> {{ props.item.updated_at }}</v-layout>
              </td>
              <td>
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    @click="onClickAddHomePage(props.item._id)"
                  >
                    <v-icon>home</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="isCreate"
      @close="isShowModal = false"
    ></ModalCreateUpdate>
    <HomeDataAssign
      :isOpen="isAddHomepage"
      :gameData="selectedCookingGame"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.CookingGame"
      @close="isAddHomepage = false"
    ></HomeDataAssign>
  </Container>
</template>

<!-- eslint-disable prettier/prettier -->
<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import { ModalCreateUpdate } from './components';
import rules from '../../../utils/ruleValidate';
import swal from 'sweetalert2';
import constants from './components/constants';
import HomeDataAssign from '../../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../../HomePage/constant';

export default {
  name: 'CookingGame',
  components: {
    Container,
    ModalCreateUpdate,
    HomeDataAssign,
  },
  data() {
    return {
      constants,
      constantHomePage,
      search: '',
      headers: [
        {
          text: 'No.',
          value: 'no.',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Title',
          value: 'title',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Item Info - Word(s)',
          value: 'round',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
          align: 'center',
          sortable: false,
        },
      ],
      isCreate: false,
      isShowModal: false,
      rules,
      isAddHomepage: false,
    };
  },
  beforeUpdate() {
    this.formatDisplayCookingGame();
  },
  async mounted() {
    const { id, game_list_id, category_id, sub_id } = this.$route.query;
    if (id) {
      await this.fetchCookingGameList({ id });
      this.onClickEdit(id);
    } else if (game_list_id) {
      await this.fetchCookingGameList({
        game_list_id,
      });
    } else if (category_id && sub_id) {
      await this.fetchCookingGameList({
        category_id,
      });
      this.onClickEdit(sub_id);
    }
  },
  computed: {
    ...mapState({
      cookingGameGameStore: state => state.cooking_game,
      cookingGameList: state => state.cooking_game.cookingGameList,
      selectedCookingGame: state =>
        state.cooking_game.selectedCookingGame,
      isLoadCookingGame: state => state.cooking_game.isLoadCookingGame,
      defaultCreate: state => state.cooking_game.defaultCreate,
    }),
  },
  methods: {
    ...mapActions('cooking_game', [
      'fetchCookingGameList',
      'deleteCookingGame',
      'setStateByKey',
    ]),
    formatDisplayCookingGame() {
      this.cookingGameList.cooking_games.forEach((element, index) => {
        let itemInfos =
          this.cookingGameList.cooking_games[index].item_infos;
        let list = [];
        itemInfos.forEach(element => {
          list.push(element.word);
        });
        this.cookingGameList.cooking_games[
          index
        ].display_cooking_game_list = list.toString();
      });
    },
    setSelectedCookingGame(id) {
      const [selectedData] = this.cookingGameList.cooking_games.filter(
        ({ _id }) => _id == id,
      );
      if (selectedData) {
        this.setStateByKey({
          statekey: 'selectedCookingGame',
          value: selectedData,
        });
      }
    },
    onClickAddHomePage(id) {
      this.setSelectedCookingGame(id);
      this.isAddHomepage = true;
    },
    onClickEdit(id) {
      this.setSelectedCookingGame(id);
      this.setStateByKey({
        statekey: 'updateCookingGame',
        key: 'id',
        value: id,
      });
      this.isShowModal = true;
      this.isCreate = false;
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure to delete?',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.deleteCookingGame(id);
          }
        });
    },
    showModalCreate() {
      this.isShowModal = true;
      this.isCreate = true;
      this.setStateByKey({
        statekey: 'createCookingGame',
        value: this.defaultCreate,
      });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
