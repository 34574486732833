import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  liveClassOfferList: [],
  liveClassTemplatesList: [],
  selectedOfferCard: {},
  selectedTemplate: {},
  updateLiveClassData: {},
  createTemaplateData: {},
  updateTemplateData: {},
  isLoadOfferCards: true,
  isLoadTemplate: true,
  defaultCreateTeamplate: {
    activity_id: '',
    title: '',
    // level_index: 0,
    // unit_index: 0,
    // lesson_index: 0,
  },
};
const getters = {};
const actions = {
  fetchOfferByOwnerUserId({ commit, dispatch }) {
    return http
      .GET(`/api/live_streaming/liveclass_offer/byteacher`, {
        // option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'isLoadOfferCards',
          value: true,
        });
        dispatch('setStateByKey', {
          statekey: 'liveClassOfferList',
          value: data,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch liveclass offer in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadOfferCards',
          value: false,
        });
      });
  },
  fetchLiveClassTempateList({ commit, dispatch }, isWeb) {
    const query = isWeb ? { params: { isWeb: true } } : {};
    return http
      .GET(`/api/live_streaming/liveclass_template`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('setStateByKey', {
          statekey: 'isLoadTemplate',
          value: true,
        });
        dispatch('setStateByKey', {
          statekey: 'liveClassTemplatesList',
          value: data,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch liveclass template in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(data => {
        dispatch('setStateByKey', {
          statekey: 'isLoadTemplate',
          value: false,
        });
      });
  },
  updateOfferCard({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateLiveClassData;
    http
      .PUT(`/api/live_streaming/liveclass`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchOfferByOwnerUserId');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update live offer successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot update live offer in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  createTemplate({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createTemaplateData;
    http
      .POST(`/api/live_streaming/liveclass_template`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchLiveClassTempateList');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create template successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        let message = err.response.data.message
          ? err.response.data.message
          : 'Cannot create template in this time. please try again.';
        dispatch(
          'appConfig/toggleAlert',
          {
            message,
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateTemplate({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateTemplateData;
    http
      .PUT(`/api/live_streaming/liveclass_template`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchLiveClassTempateList');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update template successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        let message = err.response.data.message
          ? err.response.data.message
          : 'Cannot update template in this time. please try again.';
        dispatch(
          'appConfig/toggleAlert',
          {
            message,
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteTemplate({ commit, dispatch }, id) {
    http
      .DELETE(`/api/live_streaming/liveclass_template?_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchLiveClassTempateList');
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'delete template successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        let message = err.response.data.message
          ? err.response.data.message
          : 'Cannot delete template in this time. please try again.';
        dispatch(
          'appConfig/toggleAlert',
          {
            message,
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    console.log(
      '%c 🌦️: set_state_by_key ->  statekey, key, value ',
      'font-size:16px;background-color:#ed0369;color:white;',
      statekey,
      key,
      value,
    );
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
