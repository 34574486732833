<template>
  <div>
    <v-btn icon color="green" v-on:click="addData()">
      <v-icon>add</v-icon>
    </v-btn>
    Decorate Image
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="currentStep > n"
            :step="n"
            editable
          >
            <!-- item {{ n }} -->
          </v-stepper-step>

          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
          <v-form>
            <v-card v-if="currentStep > 0">
              <v-toolbar dark>
                <v-toolbar-title
                  >Decorate Image {{ currentStep }}</v-toolbar-title
                >
                <v-btn
                  v-if="steps > 1"
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="onClickRemoveByIndex(currentStep - 1)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-container v-if="data[currentStep - 1]">
                <v-layout>
                  <v-flex>
                    <v-form ref="formItem" v-model="valid" lazy-validation>
                      <v-flex>
                        <img
                          v-if="data[currentStep - 1].image_uri"
                          :src="data[currentStep - 1].image_uri"
                          :alt="data[currentStep - 1].image_uri"
                          class="content_img"
                        />
                        <v-text-field
                          label="Image Box URI (URL or Select file)"
                          :value="data[currentStep - 1].image_uri"
                          v-model="data[currentStep - 1].image_uri"
                          :rules="[rules.validURI]"
                          @change="onChangeDecorateImage()"
                        ></v-text-field>
                        <v-btn dark @click="setResource('image', 'isImageUri')"
                          ><v-icon>image</v-icon>Select Image</v-btn
                        >
                      </v-flex>
                      <v-layout
                        column
                        class="elevation-2"
                        style="padding: 20px"
                      >
                        <label>
                          <u>⌗ Rec.Event</u>
                        </label>
                        <!-- <td class="elevation-2" 
                        style="padding: 10px;
                        margin-top: 5px;
                        margin-bottom: 5px;"
                        >
                          <label>Event:</label>
                          <v-layout align-content-space-between>
                            <v-flex>
                              <v-text-field
                                label="Start X"
                                v-model="
                                  data[currentStep - 1].object_rect.startX
                                "
                                :value="
                                  data[currentStep - 1].object_rect.startX
                                "
                                @change="onChangeDecorateImage()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                label="End X"
                                v-model="data[currentStep - 1].object_rect.endX"
                                :value="data[currentStep - 1].object_rect.endX"
                                @change="onChangeDecorateImage()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout align-content-space-between>
                            <v-flex>
                              <v-text-field
                                label="Start Y"
                                v-model="
                                  data[currentStep - 1].object_rect.startY
                                "
                                :value="
                                  data[currentStep - 1].object_rect.startY
                                "
                                @change="onChangeDecorateImage()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                label="End Y"
                                v-model="data[currentStep - 1].object_rect.endY"
                                :value="data[currentStep - 1].object_rect.endY"
                                @change="onChangeDecorateImage()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </td> -->
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="currentStep > 1"
                color="red"
                dark
                @click="onClickBackStep()"
                >Previous</v-btn
              >
              <v-btn
                v-if="currentStep < steps"
                color="primary"
                dark
                @click="onClickNextStep()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import rules from '../../../../utils/ruleValidate';
import constants from '../../../../constants';

export default {
  name: 'itemInfo',
  components: { ResourceSelect },
  computed: {
    ...mapState({
      dragAndDrop2Store: state => state.drag_and_drop_2,
      decorates_image: state =>
        state.drag_and_drop_2.selectedDragAndDrop2.decorates_image,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  data() {
    return {
      constants,
      rules,
      currentStep: 1,
      steps: 1,
      data: [],
      valid: true,
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
        sound: {
          isEdit: false,
          selectUri: '',
        },
      },
      resourceType: '',
    };
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('drag_and_drop_2', ['setStateByKey']),
    limitData() {
      return 10;
    },
    addData() {
      const defaultData = JSON.parse(
        JSON.stringify(this.dragAndDrop2Store.defaultCreate.decorates_image[0]),
      );
      if (this.data.length < this.limitData()) {
        this.data.push(defaultData);
        this.currentStep = this.data.length;
        this.steps = this.data.length;
      }
    },
    onClickRemoveByIndex(index) {
      swal
        .fire({
          titleText: `Are your sure to remove item ${index + 1}?`,
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            this.onChangeDecorateImage();
            this.steps = this.data.length;
            if (this.currentStep === 1) {
              this.currentStep = 1;
            } else this.currentStep -= 1;
          }
        });
    },
    onClickNextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onChangeDecorateImage() {
      this.$emit('validate', this.valid);
      if (this.$refs.formItem[0].validate()) {
        this.isCreate
          ? this.setStateByKey({
              statekey: 'createDragAndDrop2',
              key: 'decorates_image',
              value: this.data,
            })
          : this.setStateByKey({
              statekey: 'updateDragAndDrop2',
              key: 'decorates_image',
              value: this.data,
            });
      }
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      const index = this.currentStep - 1;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isImageUri) {
            this.data[index].image_uri = uri;
            this.resource[resourceType].isImageUri = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isSoundUri) {
            this.data[index].sound_uri = uri;
            this.resource[resourceType].isSoundUri = false;
          }
          break;
        default:
          break;
      }
      this.onChangeDecorateImage();
      this.closeEditResource();
    },
    closeEditResource() {
      this.resource[this.resourceType].isEdit = false;
      this.resource[this.resourceType].selectUri = '';
      this.resourceType = '';
    },
  },
  mounted() {
    if (this.isCreate) {
      this.data = JSON.parse(
        JSON.stringify(this.dragAndDrop2Store.defaultCreate.decorates_image),
      );
    } else {
      this.data = JSON.parse(JSON.stringify(this.decorates_image));
      this.steps = this.data.length;
    }
  },
};
</script>
