var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('v-flex',{attrs:{"justify-start":""}},[(!_vm.questStore.isLoadQuest)?_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" Quest Manager ")])],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","grid-list-md":""}},[_c('v-flex',{attrs:{"xs3":"","mr-2":""}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Quest List")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":_vm.onClickAddQuest}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-list',_vm._l((_vm.questStore.quest),function(quest,index){return _c('v-list-tile',{key:quest._id,class:index === _vm.selectedQuestIndex ? 'active' : 'inactive',on:{"click":function($event){return _vm.onClickSelectQuest(index, quest._id)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(quest.name))]),_c('v-list-tile-sub-title',[_vm._v(" "+_vm._s(quest.display_quest_date)+" ")])],1)],1)}),1)],1),(_vm.selectedQuestIndex != -1)?_c('v-flex',{attrs:{"mr-2":"","xs2":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Activities")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){_vm.onClickAddActivity();
                    _vm.selectedActivityIndex = -1;}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-list',[_c('draggable',{on:{"end":_vm.order}},_vm._l((_vm.questStore.quest[
                      _vm.selectedQuestIndex
                    ].activities),function(activity,index){return _c('v-list-tile',{key:activity._id,class:index === _vm.selectedActivityIndex ? 'active' : 'inactive',on:{"click":function($event){return _vm.onClickSelectActivity(index, activity._id)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(index + 1)+" "+_vm._s(activity.title))]),_c('v-list-tile-sub-title',[_vm._v("Game type : "+_vm._s(activity.game_data[0].game_type))])],1)],1)}),1)],1)],1)],1):_vm._e(),(_vm.selectedQuestIndex != -1 && _vm.selectedActivityIndex == -1)?_c('v-flex',{attrs:{"xs3":"","mr-2":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Quest Detail")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":_vm.onSaveEditQuest}},[_c('v-icon',[_vm._v("save")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onClickDeleteQuest(_vm.quest._id)}}},[_c('v-icon',[_vm._v("delete")])],1)],1),_c('v-card',[_c('v-container',[_c('v-layout',{attrs:{"grid-list-xs":"","column":"","text-xs-center":""}},[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Quest","value":_vm.quest.name},on:{"change":function (v) { return _vm.onChangeEditQuest('name', v); }},model:{value:(_vm.quest.name),callback:function ($$v) {_vm.$set(_vm.quest, "name", $$v)},expression:"quest.name"}}),_c('v-flex',[(_vm.quest.image_uri)?_c('img',{staticClass:"content_img",attrs:{"src":_vm.quest.image_uri}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Image URI","value":_vm.quest.image_uri},on:{"change":function (v) { return _vm.onChangeEditQuest('image_uri', v); }},model:{value:(_vm.quest.image_uri),callback:function ($$v) {_vm.$set(_vm.quest, "image_uri", $$v)},expression:"quest.image_uri"}}),_c('v-btn',{on:{"click":function($event){_vm.isEditImage = true;
                            _vm.isEditQuest = true;}}},[_c('v-icon',[_vm._v("image")]),_vm._v("Select Image ")],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Quest Date","prepend-icon":"event","readonly":"","show-current":true},model:{value:(_vm.quest.edit_quest_date),callback:function ($$v) {_vm.$set(_vm.quest, "edit_quest_date", $$v)},expression:"quest.edit_quest_date"}},on))]}}],null,false,1126723498),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"show-current":true,"min":_vm.nowDate},on:{"input":function($event){_vm.menu = false},"change":function (v) { return _vm.onChangeEditQuest('quest_date', +new Date(v)); }},model:{value:(_vm.quest.edit_quest_date),callback:function ($$v) {_vm.$set(_vm.quest, "edit_quest_date", $$v)},expression:"quest.edit_quest_date"}})],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.selectedActivityIndex != -1)?_c('v-flex',{attrs:{"mr-2":""}},[_c('v-card',{attrs:{"dark":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Activity Configuration")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.onSaveEditActivity}},[_c('v-icon',[_vm._v("save")]),_vm._v("Save ")],1)],1),_c('v-card-text',[_c('v-container',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('v-layout',{attrs:{"align-content-space-between":""}},[_c('v-text-field',{attrs:{"label":"Search Activity Title"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){return _vm.fetchLearningPathActivity(_vm.keyword)}}},[_c('v-icon',[_vm._v("search")])],1)],1)],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.questStore.lpActivities,"label":"Load Leaning Path Activity","value":_vm.select_activity},on:{"change":function (e) { return _vm.onLoadActivity(e); }},model:{value:(_vm.select_activity),callback:function ($$v) {_vm.select_activity=$$v},expression:"select_activity"}})],1),_c('v-flex',[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.clearLocalActivity();
                          _vm.isDisable = false;}}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" Clear Data ")],1)],1),_c('br'),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Title","value":_vm.activity.title,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeEditActivity('title', e); }}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Icon","value":_vm.activity.icon,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeEditActivity('icon', e); }}}),_c('v-btn',{attrs:{"dark":"","color":"black","disabled":_vm.isDisable},on:{"click":function($event){_vm.isEditImage = true;
                          _vm.isEditActivity = true;}}},[_c('v-icon',[_vm._v("edit")]),_vm._v("EDIT ICON ")],1),_c('v-img',{staticClass:"content_img",attrs:{"src":_vm.questStore.selectedActivity.icon || _vm.activity.icon}})],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.transition,"label":"Transition","value":_vm.activity.transition,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeEditActivity('transition', e); }},model:{value:(_vm.activity.transition),callback:function ($$v) {_vm.$set(_vm.activity, "transition", $$v)},expression:"activity.transition"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Skip Activity","value":_vm.activity.skip_activity,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeEditActivity('skip_activity', e); }},model:{value:(_vm.activity.skip_activity),callback:function ($$v) {_vm.$set(_vm.activity, "skip_activity", $$v)},expression:"activity.skip_activity"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Skip (Under Construction)","value":_vm.activity.skip,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeEditActivity('skip', e); }},model:{value:(_vm.activity.skip),callback:function ($$v) {_vm.$set(_vm.activity, "skip", $$v)},expression:"activity.skip"}})],1),_vm._l((_vm.activity.game_data),function(data,index){return _c('v-flex',{key:index},[_c('container',[_c('v-layout',{attrs:{"column":""}},[_c('v-card-actions',[_c('label',[_c('b',[_vm._v("🎯 GAME "+_vm._s(index + 1)+":")])]),_c('v-spacer'),(
                                _vm.activity.game_data.length > 1 && index > 0
                              )?_c('v-btn',{attrs:{"icon":"","color":"blue","dark":"","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onMoveUp(index)}}},[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1):_vm._e(),(
                                _vm.activity.game_data.length > 1 &&
                                index < _vm.activity.game_data.length - 1
                              )?_c('v-btn',{attrs:{"icon":"","color":"blue","dark":"","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onMoveDown(index)}}},[_c('v-icon',[_vm._v("keyboard_arrow_down")])],1):_vm._e(),(_vm.activity.game_data.length > 1)?_c('v-btn',{attrs:{"icon":"","color":"red","dark":"","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onDeleteGame(index)}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.constants.GAME_TYPE,"label":"Game Type","value":data.game_type,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeGameData('game_type', e, index); }},model:{value:(data.game_type),callback:function ($$v) {_vm.$set(data, "game_type", $$v)},expression:"data.game_type"}})],1),_c('v-flex',[_c('v-select',{attrs:{"items":_vm.questStore.gameType[data.game_type],"label":"Category","value":data.game_id,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeGameData('game_id', e, index); }},model:{value:(data.game_id),callback:function ($$v) {_vm.$set(data, "game_id", $$v)},expression:"data.game_id"}})],1),(
                              _vm.requiredSubIdList.indexOf(data.game_type) > -1
                            )?_c('v-flex',[_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Sub data")])]),_vm._l((data.sub_id_list),function(id,idx){return _c('td',{key:id},[_c('v-layout',{attrs:{"align-content-space-between":""}},[_c('v-select',{attrs:{"items":_vm.subTitleList[index],"value":data.sub_id_list[idx],"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeSubTitleData(index, idx, e); }}}),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(id),expression:"id",arg:"copy"}],attrs:{"icon":"","dark":"","color":"black","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("filter_none")])],1)],1)],1)}),(
                                    _vm.selectedGameDataIndex == index &&
                                    _vm.newSubId == true
                                  )?_c('td',[_c('v-layout',{attrs:{"align-content-space-between":""}},[_c('v-select',{attrs:{"items":_vm.subTitleList,"value":_vm.subTitleId,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeSubTitleData(
                                            index,
                                            data.sub_id_list.length,
                                            e
                                          ); }}}),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.subTitleId),expression:"subTitleId",arg:"copy"}],attrs:{"icon":"","dark":"","color":"black","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("filter_none")])],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(
                                      data.sub_id_list.length > 1 || _vm.newSubId
                                    )?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.onRemoveSubTitle(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("remove")])],1),(!_vm.newSubId)?_c('v-btn',{attrs:{"icon":"","color":"green","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onAddSubTitle(index)}}},[_c('v-icon',[_vm._v("add")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1)],1):_vm._e(),(
                              _vm.requiredVideoData.indexOf(data.game_type) > -1
                            )?_c('v-flex',[(data.game_type === 'MNC')?_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Video data")])]),_vm._l((data.video_data),function(vdo,idx){return _c('v-flex',{key:idx,staticClass:"elevation-2",staticStyle:{"padding":"10px","margin-top":"5px","margin-bottom":"5px"}},[_c('label',[_vm._v("Video "+_vm._s(idx + 1)+":")]),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Is Loop?","value":vdo.isLoop,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'isLoop',
                                            e
                                          ); }},model:{value:(vdo.isLoop),callback:function ($$v) {_vm.$set(vdo, "isLoop", $$v)},expression:"vdo.isLoop"}})],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"label":"Video Link","items":_vm.minichatVideoURIsState,"chips":"","hide-details":"","hide-no-data":"","hide-selected":"","disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'video_uri',
                                            e
                                          ); }},model:{value:(vdo.video_uri),callback:function ($$v) {_vm.$set(vdo, "video_uri", $$v)},expression:"vdo.video_uri"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Next Index","value":vdo.nextIndex,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'nextIndex',
                                            parseInt(e)
                                          ); }}})],1)],1)}),_c('v-card-actions',[_c('v-spacer'),(
                                      data.video_data &&
                                      data.video_data.length > 1
                                    )?_c('v-btn',{attrs:{"icon":"","color":"red","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onRemoveVideoData(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("remove")])],1),_c('v-btn',{attrs:{"icon":"","color":"green","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onAddVideoData(index)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1):_c('container',[_c('v-layout',{staticClass:"elevation-2",staticStyle:{"padding":"20px"},attrs:{"column":""}},[_c('label',[_c('u',[_vm._v("⌗ Video data")])]),_vm._l((data.video_data),function(vdo,idx){return _c('v-flex',{key:idx,staticClass:"elevation-2",staticStyle:{"padding":"10px","margin-top":"5px","margin-bottom":"5px"}},[_c('label',[_vm._v("Video "+_vm._s(idx + 1)+":")]),_c('v-flex',[_c('v-checkbox',{attrs:{"label":"Is Loop?","value":vdo.isLoop,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'isLoop',
                                            e
                                          ); }},model:{value:(vdo.isLoop),callback:function ($$v) {_vm.$set(vdo, "isLoop", $$v)},expression:"vdo.isLoop"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Video Link","value":vdo.video_uri,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'video_uri',
                                            e
                                          ); }}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Next Index","value":vdo.nextIndex,"disabled":_vm.isDisable},on:{"change":function (e) { return _vm.onChangeVideoData(
                                            index,
                                            idx,
                                            'nextIndex',
                                            parseInt(e)
                                          ); }}})],1)],1)}),_c('v-card-actions',[_c('v-spacer'),(
                                      data.video_data &&
                                      data.video_data.length > 1
                                    )?_c('v-btn',{attrs:{"icon":"","color":"red","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onRemoveVideoData(index)}}},[_c('v-icon',[_vm._v("remove")])],1):_c('v-btn',{attrs:{"icon":"","color":"grey","disabled":_vm.isDisable}},[_c('v-icon',[_vm._v("remove")])],1),_c('v-btn',{attrs:{"icon":"","color":"green","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onAddVideoData(index)}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1)],1):_vm._e()],1)],1)],1)}),(
                        _vm.requiredSubIdList.indexOf(
                          _vm.activity.game_data[0].game_type
                        ) > -1
                      )?_c('v-btn',{attrs:{"color":"green","disabled":_vm.isDisable},on:{"click":_vm.onAddMoreGame}},[_c('v-icon',[_vm._v("add")]),_vm._v("Add More Game ")],1):_vm._e()],2),_c('v-card-actions',[(
                        _vm.questStore.quest[_vm.selectedQuestIndex].activities
                          .length > 1
                      )?_c('v-btn',{attrs:{"color":"red","disabled":_vm.isDisable},on:{"click":function($event){return _vm.onClickDeleteActivity(_vm.activity._id)}}},[_c('v-icon',[_vm._v("delete")]),_vm._v("Delete this activity ")],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1)],1):_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v("Loading...")])],1),_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1),(_vm.isEditImage)?_c('ResourceSelect',{attrs:{"resourceType":"image","isShow":_vm.isEditImage,"close":_vm.closeEditImage,"save":_vm.saveImage,"value":_vm.selectImageUri,"onSelect":_vm.onSelectImage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }