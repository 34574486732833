<template>
  <v-flex mr-2 xs3>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Set Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickUpdateSet()"
          :disabled="
            !dragComboStore.selectedDragCombo.sets[
              dragComboStore.selectedSetIndex
            ]._id
          "
          color="green"
        >
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-xs text-xs-center>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout column>
              <v-flex>
                <v-text-field
                  label="_id"
                  :value="
                    dragComboStore.selectedDragCombo.sets[
                      dragComboStore.selectedSetIndex
                    ]._id
                  "
                  disabled
                ></v-text-field>
                <v-text-field
                  label="Name"
                  :value="
                    dragComboStore.selectedDragCombo.sets[
                      dragComboStore.selectedSetIndex
                    ].name
                  "
                  @change="e => onchangeSet('name', e)"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-btn
                  v-if="getNodeLength < 10"
                  color="green"
                  small
                  @click="createNodes()"
                >
                  <v-icon>add</v-icon>Create Node
                </v-btn>
                <v-data-table
                  :headers="[{ text: 'Sets', value: 'set', sortable: false }]"
                  :items="
                    dragComboStore.selectedDragCombo.sets[
                      dragComboStore.selectedSetIndex
                    ].nodes
                  "
                  class="elevation-1 row-pointer"
                  hide-actions
                >
                  <template v-slot:items="props">
                    <tr
                      :class="
                        props.index === dragComboStore.selectedNodeIndex
                          ? 'active'
                          : 'inactive'
                      "
                      @click="seleteNodes(props.item._id, props.index)"
                    >
                      <td class="text-xs-left">
                        {{ props.item.name }}
                      </td>
                      <td>
                        <v-btn
                          color="error"
                          @click="deleteNodes(props.item._id)"
                          small
                          fab
                          left
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <!-- <br /> -->
              <!-- <v-flex>
                <v-btn
                  color="red"
                  dark
                  @click="
                    onClickDeleteSet(
                      dragComboStore.selectedDragCombo.sets[
                        dragComboStore.selectedSetIndex
                      ]._id,
                    )
                  "
                >
                  <v-icon>delete</v-icon>Delete this set
                </v-btn>
              </v-flex> -->
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <!-- <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    /> -->
    <ModalCreate
      :isOpen="isCreate"
      :title="title"
      @close="closeCreate()"
    ></ModalCreate>
  </v-flex>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
// import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import ModalCreate from './ModalCreate.vue';

export default {
  components: {
    // ResourceSelect,
    ModalCreate,
  },
  data() {
    return {
      valid: true,
      rules,
      isCreate: false,
      title: '',
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    getNodeLength() {
      if (this.dragComboStore.selectedDragCombo.sets) {
        const index = this.dragComboStore.selectedSetIndex;
        return this.dragComboStore.selectedDragCombo.sets[index].nodes.length;
      } else return -1;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'updateSetDragCombo',
      'setStateByKey',
      'deleteSetDragCombo',
      'deleteNodeDragCombo',
      'clearStateByKey',
    ]),
    onClickUpdateSet() {
      if (this.$refs.form.validate()) {
        // swal
        //   .fire({
        //     titleText: 'Are you sure.',
        //     type: 'warning',
        //     showCancelButton: true,
        //   })
        //   .then(result => {
        //     if (result.value) {
        this.updateSetDragCombo();
        // this.clearDirty('game');
        //   }
        // });
      }
    },
    onchangeSet(key, value) {
      // this.setDirty('game');
      this.setStateByKey({
        statekey: 'updateSet',
        key: key,
        value: value,
      });
    },
    onClickDeleteSet(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteSetDragCombo(id);
          }
        });
    },
    seleteNodes(id, index) {
      this.clearStateByKey('updateNode');
      this.clearStateByKey('selectedChoiceIndex');
      this.setStateByKey({
        statekey: 'selectedNodeIndex',
        value: index,
      });

      this.setStateByKey({
        statekey: 'updateNode',
        key: 'id',
        value: id,
      });
    },
    deleteNodes(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteNodeDragCombo(id);
          }
        });
    },
    createNodes() {
      this.isCreate = true;
      this.title = 'Create Nodes';
    },
    closeCreate() {
      this.isCreate = false;
      this.title = '';
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
.row-pointer:hover {
  cursor: pointer;
}
</style>
