<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/liveclass')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Live Class Template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="showModalEdit()">
            <v-icon>add</v-icon>Add Template
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-if="!isLoadTemplate"
            :items="liveClassTemplatesList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>{{ props.index + 1 }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item._id }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.title }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.updated_at }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-btn
                    color="primary"
                    v-on:click="showModalEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                    Edit
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="isCreate"
      @close="closeModal()"
    ></ModalCreateUpdate>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import { ModalCreateUpdate } from './components';
import rules from '../../../utils/ruleValidate';
import swal from 'sweetalert2';
import moment from 'moment';

export default {
  name: 'LiveclassOfferCardsList',
  components: {
    Container,
    ModalCreateUpdate,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'No.',
          value: 'no.',
          align: 'center',
          sortable: false,
        },
        {
          text: 'template_id',
          value: 'template_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'title',
          value: 'title',
          align: 'center',
          sortable: false,
        },
        {
          text: 'updated_at',
          value: 'updated_at',
          align: 'center',
          sortable: true,
        },
      ],
      isCreate: false,
      isShowModal: false,
      rules,
      moment,
    };
  },
  async mounted() {
    await this.fetchLiveClassTempateList();
  },
  computed: {
    ...mapState({
      liveClassTemplatesList: state => state.liveclass.liveClassTemplatesList,
      selectedTemplate: state => state.liveclass.selectedTemplate,
      isLoadTemplate: state => state.liveclass.isLoadTemplate,
      updateLiveClassData: state => state.liveclass.updateLiveClassData,
    }),
  },
  methods: {
    ...mapActions('liveclass', [
      'fetchLiveClassTempateList',
      'updateOfferCard',
      'deleteTemplate',
      'setStateByKey',
    ]),
    onClickReady(id) {
      const updateData = this.updateLiveClassData;
      if (Object.keys(updateData).length > 0) {
        if (updateData._id === id) this.updateOfferCard();
      }
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure delete template.',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.deleteTemplate(id);
          }
        });
    },
    showModalEdit(template_id) {
      if (template_id) {
        //open modal edit template
        const [selectedData] = this.liveClassTemplatesList.filter(
          ({ _id }) => _id == template_id,
        );
        if (Object.keys(selectedData).length > 0) {
          this.isShowModal = true;
          this.isCreate = false;
          this.setStateByKey({
            statekey: 'selectedTemplate',
            value: selectedData,
          });
          this.setStateByKey({
            statekey: 'updateTemplateData',
            key: '_id',
            value: template_id,
          });
        }
      } else {
        //open modal create template
        this.isCreate = true;
        this.isShowModal = true;
      }
    },
    closeModal() {
      this.isShowModal = false;
      this.isCreate = false;
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
