<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Category Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" light v-on:click="onClickAddCategory">
            <v-icon>add</v-icon>Add Category
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="categoryStore.category">
          <v-layout row grid-list-md>
            <v-flex xs3 mr-2>
              <v-card>
                <v-list>
                  <v-list-tile
                    v-for="(category, index) in categoryStore.category"
                    :key="category._id"
                    v-on:click="onSelectCategory(category._id)"
                    :class="
                      index === selectedCategoryIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-avatar>
                      <v-icon>apps</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{
                        category.category
                      }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedCategoryIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Category Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="onSaveEdit">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(category._id)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-img :src="category.category_img">
                  <v-container>
                    <v-layout>
                      <v-flex>
                        <v-btn
                          icon
                          color="white"
                          v-on:click="
                            isEditImage = true;
                            isUpdateCategory = true;
                          "
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                        label="Category"
                        :value="category.category"
                        v-on:change="e => onChangeContent('category', e)"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <!-- Create Category -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="closeCreate"
      title="Create Category"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex justify-center>
              <v-img
                :src="category.category_img"
                min-height="250"
                max-height="250"
              >
                <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
              </v-img>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Category"
                :value="category.category"
                v-model="category.category"
                v-on:change="e => onChangeCreateContent('category', e)"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../components/Container';
import ResourceSelect from '../components/ResourceSelect';
import Modal from '../components/Modal';

export default {
  name: 'Category',
  components: {
    Container,
    ResourceSelect,
    Modal,
  },
  computed: {
    ...mapState({
      categoryStore: state => state.category,
    }),
  },
  data: function () {
    return {
      category: {},
      isCreate: false,
      isEditImage: false,
      selectedCategoryIndex: -1,
      selectImageUri: '',
    };
  },
  methods: {
    ...mapActions('category', [
      'fetchCategory',
      'deleteCategory',
      'selectCategory',
      'editCategory',
      'clearSelected',
      'editCreateCategory',
      'updateCategory',
      'clearCreate',
      'createCategory',
    ]),
    onSelectCategory(id) {
      const categoryList = this.categoryStore.category;
      const index = categoryList.findIndex(category => category._id === id);
      this.selectedCategoryIndex = index;
      this.category = { ...categoryList[index] };
      this.editCategory({
        key: '_id',
        value: id,
      });
    },
    onClickDelete(id) {
      this.deleteCategory({ id });
      this.selectedCategoryIndex = -1;
    },
    closeEdit() {
      this.clearSelected();
    },
    closeCreate() {
      this.isCreate = false;
      this.clearCreate();
    },
    onChangeContent(key, value) {
      this.editCategory({ key, value });
    },
    onChangeCreateContent(key, value) {
      this.editCreateCategory({ key, value });
    },
    onSaveEdit() {
      this.selectedCategoryIndex = -1;
      const updateCategory = this.categoryStore.updateCategory;
      this.updateCategory({ ...updateCategory });
    },
    onSaveCreate() {
      const createCategoryData = { ...this.categoryStore.createCategory };
      this.createCategory(createCategoryData);
      this.isCreate = false;
    },
    onClickAddCategory() {
      this.selectedCategoryIndex = -1;
      this.isCreate = true;
      this.selectImageUri = '';
      this.clearCreate();
      this.category = { ...this.categoryStore.createCategory };
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isCreate) {
        this.editCreateCategory({
          key: 'category_img',
          value: uri,
        });
      } else {
        this.editCategory({
          key: 'category_img',
          value: uri,
        });
      }
      this.category.category_img = uri;
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
  },
  mounted: function () {
    this.fetchCategory({ page: 1, limit: 0 });
  },
};
</script>
<style>
.image_thumbnail {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin-top: 15px;
}
.image_edit {
  height: 200px;
}
</style>
