<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark tabs>
          <v-toolbar-title>Short Story Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="isCreate = true">
            <v-icon>add</v-icon>Create Short Story
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex xs3>
                <v-flex grow pa-1>
                  <v-radio-group v-model="seachType" row>
                    <v-radio
                      label="Name"
                      value="name"
                      @change="onChangeRadioName()"
                    ></v-radio>
                    <v-radio label="Tag" value="tag"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex grow pa-1>
                  <v-text-field
                    v-if="seachType === 'name'"
                    solo
                    placeholder="Search By Name"
                    append-icon="search"
                    hide-details
                    v-model="search"
                  >
                  </v-text-field>
                  <v-combobox
                    v-else
                    v-model="selectTag"
                    label="Search By Tag"
                    hint="Maximum of 10 tags"
                    multiple
                    persistent-hint
                    small-chips
                    v-on:keyup.enter="searchByTag"
                  ></v-combobox>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
          <v-data-table
            class="row-pointer"
            :items="
              seachType === 'tag'
                ? shortStoryStore.shortStoryListByTags
                : shortStoryStore.shortStoryList
            "
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <!-- <tr> -->
              <td>
                {{ props.item.name }}
              </td>
              <td>
                <v-chip
                  v-if="formatDisplayTag(props.item.tags)"
                  color="primary"
                  text-color="white"
                  >{{ formatDisplayTag(props.item.tags) }}</v-chip
                >
                <v-chip
                  v-if="!formatDisplayTag(props.item.tags)"
                  color="grey"
                  text-color="white"
                  >tag not found</v-chip
                >
              </td>
              <td>
                {{ props.item.updated_at }}
              </td>
              <td class="tools">
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                </v-layout>
              </td>
              <td class="tools">
                <v-layout justify-end>
                  <v-btn
                    color="error"
                    @click="deleteGame(props.item._id)"
                    small
                    fab
                    right
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
              <!-- </tr> -->
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickCreate"
      :close="closeCreate"
      :isLastStep="valid"
      title="Create Short Story"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  label="Image URI"
                  :value="shortStoryStore.createGame.image_uri"
                  v-model="shortStoryStore.createGame.image_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage()"
                  ><v-icon>image</v-icon>Select Image URI</v-btn
                >
                <v-text-field
                  label="Icon URI"
                  :value="shortStoryStore.createGame.icon_uri"
                  v-model="shortStoryStore.createGame.icon_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage('icon')">
                  <v-icon>image</v-icon>Select Icon URI</v-btn
                >
                <v-text-field
                  label="Name*"
                  v-model="shortStoryStore.createGame.name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-combobox
                  v-model="shortStoryStore.createGame.tags"
                  label="Add By Tags*"
                  hint="Maximum of 10 tags"
                  multiple
                  persistent-hint
                  small-chips
                  :rules="[rules.validtag]"
                ></v-combobox>
                <v-select
                  :items="GameType"
                  item-text="name"
                  item-value="value"
                  label="Type*"
                  v-model="shortStoryStore.createGame.type"
                  :rules="[rules.required]"
                ></v-select>
                <v-text-field
                  :value="shortStoryStore.selectedShortStory.music_uri"
                  label="Music URI"
                  v-model="shortStoryStore.createGame.music_uri"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound()"
                  ><v-icon>audiotrack</v-icon>Select Music URI</v-btn
                >
                <v-text-field
                  type="number"
                  v-model.number="shortStoryStore.createGame.duration_ms"
                  label="Duration ms"
                  :rules="[rules.numberRule]"
                ></v-text-field>
                <v-checkbox
                  :value="shortStoryStore.createGame.available"
                  v-model="shortStoryStore.createGame.available"
                  label="Available"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </Container>
</template>

<script>
import Container from '../../components/Container';
import { mapActions, mapState } from 'vuex';
import Modal from '../../components/Modal';
import constant from './constant';
import ResourceSelect from '../../components/ResourceSelect.vue';
import swal from 'sweetalert2';

export default {
  components: {
    Container,
    Modal,
    ResourceSelect,
  },
  data() {
    return {
      search: '',
      seachType: 'name',
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Tags', value: '', sortable: false },
        { text: 'Updated_at', value: 'updated_at', sortable: false },
      ],
      selectTag: null,
      isCreate: false,
      rules: constant.rule,
      valid: true,
      GameType: constant.GameType,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectMusicBtn: false,
      isEditImage: false,
      selectImageUri: '',
      isSelectImageBtn: false,
      isSelectImageIcon: false,
    };
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'fetchShortStoryList',
      'fetchShortStoryListByTags',
      'clearShortStoryListByTags',
      'createGameShortStory',
      'clearCreateGame',
      'clearSelectedGame',
      'clearSelectedFilterIndex',
      'clearSelectedEventIndex',
      'clearUpdateGame',
      'clearUpdateEvent',
      'clearUpdateFilter',
      'clearDirty',
      'deleteGameShortStory',
      'clearSelectedCheckboxEvent',
    ]),
    deleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            const routePath = this.$route.path;
            this.seachType === 'name'
              ? this.deleteGameShortStory({ id: id, url: routePath })
              : this.deleteGameShortStory({
                  id: id,
                  tags: this.selectTag,
                  url: routePath,
                });
          }
        });
    },
    onclickEditImage(type) {
      this.isEditImage = true;
      type ? (this.isSelectImageIcon = true) : (this.isSelectImageBtn = true);
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImageBtn:
          this.shortStoryStore.createGame.image_uri = uri;
          this.isSelectImageBtn = false;
          break;
        case this.isSelectImageIcon:
          this.shortStoryStore.createGame.icon_uri = uri;
          this.isSelectImageIcon = false;
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound() {
      this.isSelectSound = true;
      this.isSelectMusicBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectMusicBtn:
          this.shortStoryStore.createGame.music_uri = uri;
          // this.updateGameStore({ key: 'music_uri', value: uri });
          this.isSelectMusicBtn = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onClickEdit(id) {
      this.$router.push(`/short_story/detail?id=${id}`);
    },
    defaultTypeData(type) {
      let data = {};
      switch (type) {
        case 1:
          //mic
          data = { ...data, ...this.shortStoryStore.Mic };
          break;
        case 3:
          //micword
          data = { ...data, ...this.shortStoryStore.Micwords };
          break;
        default:
          break;
      }
      return data;
    },
    onClickCreate() {
      if (this.$refs.form.validate()) {
        let create = { ...this.shortStoryStore.createGame };
        create.available =
          create.available === null || create.available === false
            ? false
            : true;
        create = { ...create, ...this.defaultTypeData(create.type) };
        this.createGameShortStory(create);
        this.clearCreateGame();
        this.isCreate = false;
      }
    },
    closeCreate() {
      this.clearCreateGame();
      this.isCreate = false;
    },
    onChangeRadioName() {
      this.clearShortStoryListByTags();
      this.fetchShortStoryList();
      this.search = '';
      this.selectTag = null;
    },
    searchByTag() {
      this.fetchShortStoryListByTags(this.selectTag);
    },
    searchWordOrTag(type) {
      if (type === 'name') {
        this.headers[0].value = type;
        this.headers[1].value = '';
      } else {
        this.headers[0].value = '';
        this.headers[1].value = type;
      }
    },
    formatDisplayTag(value) {
      if (value) {
        return value.toString().split(',').join(' | ');
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.fetchShortStoryList();
    this.clearSelectedGame();
    this.clearSelectedFilterIndex();
    this.clearSelectedEventIndex();
    this.clearUpdateGame();
    this.clearUpdateEvent();
    this.clearUpdateFilter();
    this.clearDirty();
    this.clearSelectedCheckboxEvent();
  },
  watch: {
    headers(val) {
      this.headers = val;
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer:hover {
  cursor: pointer;
}
.tools {
  width: 10px;
}
</style>
