<template>
  <v-layout row grid-list-md>
    <v-flex xs12 mr-2 v-if="shortStoryStore.selectedFilterIndex != -1">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Filter Detail</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            v-on:click="onClickUpdateFilter()"
            :disabled="shortStoryStore.isDirty.filter == false"
          >
            <v-icon>save</v-icon>Save
          </v-btn>
        </v-toolbar>
      </v-card>
      <v-card-text>
        <v-container grid-list-xs text-xs-center>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout column>
              <v-flex>
                <v-text-field
                  :value="filterData.name"
                  v-model="filterData.name"
                  label="Name"
                  :rules="[rules.required]"
                  @change="e => onchangeFilterDetail('name', e)"
                ></v-text-field>
                <TypeFilter :filterData="filterData"></TypeFilter>
                <br />
                <!-- <div class="line"></div>
                <div style="display:inline-block;"><h2>Basic Data</h2></div>
                <div class="line"></div> -->
                <hr />
                <h2>Basic Data</h2>
                <hr />
                <br />
                <v-text-field
                  label="Display Duration ms (-1 Always)"
                  type="number"
                  :value="filterData.duration_ms"
                  v-model="filterData.duration_ms"
                  @change="
                    e => onchangeFilterDetail('duration_ms', parseInt(e))
                  "
                  :rules="[rules.numberRule]"
                ></v-text-field>
                <v-select
                  :items="filterIndexList()"
                  item-text="name"
                  item-value="index"
                  :value="filterData.next_index"
                  v-model="filterData.next_index"
                  label="Next Index"
                  v-on:change="
                    e => onchangeFilterDetail('next_index', parseInt(e))
                  "
                ></v-select>
                <v-text-field
                  :value="filterData.sound_uri"
                  v-model="filterData.sound_uri"
                  label="Sound URI"
                  v-on:change="e => onchangeFilterDetail('sound_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickSound()"
                  ><v-icon>audiotrack</v-icon>Select Sound Uri</v-btn
                >
                <v-select
                  :items="FilterMode"
                  item-text="name"
                  item-value="value"
                  :value="filterData.mode"
                  v-model="filterData.mode"
                  label="Mode"
                  v-on:change="e => onchangeFilterDetail('mode', parseInt(e))"
                ></v-select>
                <!-- <v-text-field
                  label="Next Index"
                  :value="filterData.next_index"
                  v-model.number="filterData.next_index"
                  @change="e => onchangeFilterDetail('next_index', parseInt(e))"
                  :rules="[rules.numberRule]"
                ></v-text-field> -->
                <img
                  class="image_thumbnail"
                  :src="filterData.image.image_uri"
                />
                <v-text-field
                  label="Image URI"
                  :value="filterData.image.image_uri"
                  v-model="filterData.image.image_uri"
                  @change="e => onchangeFilterImage('image_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage()"
                  ><v-icon>image</v-icon>Select Image URI</v-btn
                >
                <v-checkbox
                  :input-value="filterData.is_stretch"
                  label="is stretch"
                  @change="e => onchangeFilterDetail('is_stretch', e)"
                ></v-checkbox>
                <div v-if="filterData.type !== 5">
                  <!-- 5 = Particle -->
                  <v-layout row>
                    <v-text-field
                      label="Columns"
                      type="number"
                      :value="filterData.image.columns"
                      v-model.number="filterData.image.columns"
                      @change="e => onchangeFilterImage('columns', parseInt(e))"
                      :rules="[rules.required, rules.numberRowColumn]"
                    ></v-text-field>
                    <v-text-field
                      label="Rows"
                      type="number"
                      :value="filterData.image.rows"
                      v-model.number="filterData.image.rows"
                      @change="e => onchangeFilterImage('rows', parseInt(e))"
                      :rules="[rules.required, rules.numberRowColumn]"
                    ></v-text-field>
                  </v-layout>
                  <v-layout row>
                    <v-text-field
                      label="Loop (-1 Infinite)"
                      type="number"
                      :value="filterData.image.loop"
                      v-model.number="filterData.image.loop"
                      @change="e => onchangeFilterImage('loop', parseInt(e))"
                      :rules="[rules.required, rules.numberRule]"
                    ></v-text-field>
                    <v-text-field
                      label="Frame_ms"
                      type="number"
                      :value="filterData.image.frame_ms"
                      v-model.number="filterData.image.frame_ms"
                      @change="
                        e => onchangeFilterImage('frame_ms', parseInt(e))
                      "
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-layout>
                  <v-text-field
                    label="Face_ratio"
                    :value="filterData.image.face_ratio"
                    v-model="filterData.image.face_ratio"
                    @change="e => onchangeFilterImage('face_ratio', e)"
                    :rules="[rules.numberRule]"
                  ></v-text-field>
                </div>
                <!-- <v-select
                  :items="TriggerType"
                  item-text="name"
                  item-value="value"
                  :value="filterData.image.type"
                  v-model="filterData.image.type"
                  label="Type"
                  @change="e => onchangeFilterImage('type', e)"
                ></v-select> -->
                <v-select
                  v-if="filterData.action_type != 2"
                  :items="FacePosition"
                  item-text="name"
                  item-value="value"
                  :value="filterData.image.face_point_index"
                  v-model.number="filterData.image.face_point_index"
                  label="Face Point Index"
                  @change="
                    e => onchangeFilterImage('face_point_index', parseInt(e))
                  "
                  :rules="[rules.required]"
                ></v-select>
                <v-layout row>
                  <v-text-field
                    type="number"
                    label="Pivot Point X"
                    :value="filterData.image.pivot.x"
                    v-model="filterData.image.pivot.x"
                    @change="e => onchangeFilterImage('pivot', e)"
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="Pivot Point Y"
                    :value="filterData.image.pivot.y"
                    v-model="filterData.image.pivot.y"
                    @change="e => onchangeFilterImage('pivot', e)"
                  ></v-text-field>
                </v-layout>
                <ModalTriggerImage
                  :dataGame="filterData.trigger_images"
                  @changeTriggerData="changeTriggerData"
                ></ModalTriggerImage>
                <v-data-table
                  class="elevation-1"
                  :headers="[
                    { text: 'Trigger Name', sortable: false, align: 'center' },
                    { text: 'Type', sortable: false, align: 'center' },
                    {
                      text: 'Face Point Index',
                      sortable: false,
                      align: 'center',
                    },
                  ]"
                  :items="filterData.trigger_images"
                  hide-actions
                >
                  <template v-slot:items="props">
                    <td>
                      {{ props.item.name }}
                    </td>
                    <td>
                      {{ TriggerType[props.item.type].name }}
                    </td>
                    <td>
                      {{ FacePosition[props.item.face_point_index + 1].name }}
                    </td>
                  </template>
                </v-data-table>
                <br />
                <v-btn
                  color="red"
                  dark
                  @click="onClickDeleteFilter(filterData._id)"
                >
                  <v-icon>delete</v-icon>Delete this filter
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </v-layout>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import constant from '../constant';
import ModalTriggerImage from './ModalTriggerImage.vue';
import TypeFilter from './TypeFilter.vue';

export default {
  components: {
    ResourceSelect,
    ModalTriggerImage,
    TypeFilter,
  },
  data() {
    return {
      FilterType: constant.FilterType,
      TriggerType: constant.TriggerType,
      FacePosition: constant.FacePosition,
      FilterMode: constant.FilterMode,
      isEditImage: false,
      isSelectImage: false,
      selectImageUri: '',
      isImage: false,
      updateFilterImage: {},
      valid: true,
      rules: constant.rule,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectSoundBtn: false,
    };
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    ...mapActions('short_story', [
      'updateFilterShortStory',
      'updateFilterStore',
      'clearUpdateFilter',
      'setDirty',
      'clearDirty',
      'deleteFilterShortStory',
      'clearSelectedFilterIndex',
    ]),
    onclickSound() {
      this.isSelectSound = true;
      this.isSelectSoundBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectSoundBtn:
          this.filterData.sound_uri = uri;
          this.updateFilterStore({ key: 'sound_uri', value: uri });
          this.setDirty('filter');
          this.isSelectSoundBtn = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    changeTriggerData(data) {
      this.filterData.trigger_images = [...data];
    },
    onclickEditImage() {
      this.isEditImage = true;
      this.isImage = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isImage:
          this.filterData.image.image_uri = uri;
          this.updateFilterImage = {
            ...this.updateFilterImage,
            image_uri: uri,
          };
          this.updateFilterStore({
            key: 'image',
            value: this.updateFilterImage,
          });
          this.isImage = false;
          this.setDirty('filter');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onchangeFilterDetail(key, value) {
      this.updateFilterStore({ key, value });
      this.setDirty('filter');
    },
    onchangeFilterImage(key, value) {
      if (key === 'pivot') value = this.filterData.image[key];
      this.updateFilterImage = { ...this.updateFilterImage, [key]: value };
      this.updateFilterStore({ key: 'image', value: this.updateFilterImage });
      this.setDirty('filter');
    },
    onClickUpdateFilter() {
      if (this.$refs.form.validate()) {
        swal
          .fire({
            titleText: 'Are you sure.',
            type: 'warning',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              const updateData = { ...this.shortStoryStore.updateGameFilter };
              this.updateFilterShortStory(updateData);
              const filterId = this.shortStoryStore.updateGameFilter.id;
              this.clearUpdateFilter();
              this.updateFilterStore({ key: 'id', value: filterId });
              this.updateFilterImage = {};
              this.clearDirty('filter');
            }
          });
      }
    },
    onClickDeleteFilter(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteFilterShortStory(id);
            this.clearSelectedFilterIndex();
            this.clearDirty('filter');
          }
        });
    },
    filterIndexList() {
      const selectedEventIndex = this.shortStoryStore.selectedEventIndex;
      const event =
        this.shortStoryStore.selectedShortStory.events[selectedEventIndex];
      if ('filters' in event) {
        let filters = [
          ...this.shortStoryStore.selectedShortStory.events[selectedEventIndex]
            .filters,
        ];
        if (filters.length) {
          filters = filters.map((e, index) => {
            // e = { ...e, index: index };
            e.index = index;
            return { name: e.name, index: e.index };
          });
          filters.unshift({ name: null, index: -1 });
          return filters;
        } else return [{ name: null, index: -1 }];
      } else return [{ name: null, index: -1 }];
    },
  },
  // watch: {
  // selectedEventIndex(val) {
  //   if (val) {
  //     this.selectedEvent = [
  //       ...this.shortStoryStore.selectedShortStory.events,
  //     ];
  //     this.selectedEvent = { ...this.selectedEvent[this.selectedEventIndex] };
  //   }
  // },
  // },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
/* .line {
  width: 36%;
  height: 0;
  border: 1px solid #c4c4c4;
  margin: 3px;
  display: inline-block;
} */
hr {
  /* width: 70%; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  border-top: 1px solid #c4c4c4;
  margin-block: revert;
}
</style>
