<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light v-on:click="$router.push('/media')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            Media :
            {{
              (mediaStore.selectMedia && mediaStore.selectMedia.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="mediaStore.selectMedia">
          <v-layout row grid-list-md v-if="!mediaStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Video</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddVideo">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(video, index) in mediaStore.selectMedia.videos"
                    :key="video._id"
                    v-on:click="onSelectVideo(video._id)"
                    :class="
                      index === selectedVideoIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ video.title }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn
                        class="btn-center"
                        @click="
                          onSelectVideo(video._id);
                          onClickHomeData();
                        "
                        flat
                        icon
                      >
                        <v-icon>home</v-icon></v-btn
                      >
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs9 mr-10 v-if="selectedVideoIndex != -1">
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Video Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateVideo">
                    <v-icon>save</v-icon>Save Video
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <v-btn color="pointdata" @click="openPointData"
                        >Point Data
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="video.isGuestPlayable"
                        :value="video.isGuestPlayable"
                        v-on:change="
                          e => onChangeVideoData('isGuestPlayable', e)
                        "
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Title"
                        :value="video.title"
                        v-on:change="e => onChangeVideoData('title', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-btn v-on:click="isEditVideoResource = true"
                        >Select Video</v-btn
                      ><br />
                      <video id="edit-video" width="600" height="350" controls>
                        <source :src="video.video_uri" />
                      </video>
                      <v-text-field
                        label="Video"
                        :value="video.video_uri"
                        v-on:change="e => onChangeVideoData('video_uri', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-btn v-on:click="isEditImage = true">Select Image</v-btn
                      ><br />
                      <img :src="video.img_uri" class="content_img" />
                      <v-text-field
                        label="Cover Image"
                        :value="video.img_uri"
                        v-on:change="e => onChangeVideoData('img_uri', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Lyrics"
                        :value="video.lyrics"
                        v-on:change="e => onChangeVideoData('lyrics', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Description"
                        :value="video.description"
                        v-on:change="e => onChangeVideoData('description', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        :value="video.bgm_uri"
                        v-model="video.bgm_uri"
                        v-on:change="e => onChangeVideoData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn v-on:click="isEditSound = true"
                        >Select Sound</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout column class="elevation-2" style="padding: 20px">
                    <label><u>⌗ Rec.Event</u></label>
                    <td
                      v-for="(event, index) in rectEvent"
                      :key="index"
                      class="elevation-2"
                      style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <label>Event {{ index + 1 }}:</label>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start X"
                            :value="event.startX"
                            v-on:change="
                              e => onChangeEventData(index, 'startX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End X"
                            :value="event.endX"
                            v-on:change="
                              e => onChangeEventData(index, 'endX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start Y"
                            :value="event.startY"
                            v-on:change="
                              e => onChangeEventData(index, 'startY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End Y"
                            :value="event.endY"
                            v-on:change="
                              e => onChangeEventData(index, 'endY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-flex>
                        <v-text-field
                          label="Next Index"
                          :value="event.nextIndex"
                          v-on:change="
                            e =>
                              onChangeEventData(index, 'nextIndex', parseInt(e))
                          "
                        ></v-text-field>
                      </v-flex>
                    </td>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="rectEvent && rectEvent.length > 0"
                        icon
                        color="red"
                        @click="onRemoveEventData"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn v-else icon color="grey">
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn icon color="green" @click="onAddEventData">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteVideo(video._id)"
                  >
                    <v-icon>delete</v-icon>Delete this video
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditVideoResource"
      resourceType="video"
      :isShow="isEditVideoResource"
      :close="closeEditVideoResource"
      :save="saveEditVideoResource"
      :value="selectVideoUri"
      :onSelect="onSelectVideoResource"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedVideoIndex != -1
          ? mediaStore.selectMedia.videos[selectedVideoIndex].learned_words
          : null
      "
      :incidentalWords="
        selectedVideoIndex != -1
          ? mediaStore.selectMedia.videos[selectedVideoIndex].incidental_words
          : null
      "
      :rewards="
        selectedVideoIndex != -1
          ? mediaStore.selectMedia.videos[selectedVideoIndex].reward
          : null
      "
      :sentences="
        selectedVideoIndex != -1
          ? mediaStore.selectMedia.videos[selectedVideoIndex].sentence
          : null
      "
      :phonics="
        selectedVideoIndex != -1
          ? mediaStore.selectMedia.videos[selectedVideoIndex].phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddVideo"
      :isOpen="isAddVideo"
      :close="toggleAddVideo"
      :save="onClickAddVideo"
      title="Add Video"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Title"
              :value="video.title"
              v-model="video.title"
              v-on:change="e => onChangeCreateVideo('title', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn v-on:click="isEditVideoResource = true">Select Video</v-btn
            ><br />
            <video id="new-video" width="600" height="350" controls>
              <source :src="video.video_uri" />
            </video>
            <v-text-field
              label="Video"
              :value="video.video_uri"
              v-on:change="e => onChangeCreateVideo('video_uri', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn v-on:click="isEditImage = true">Select Image</v-btn><br />
            <img :src="video.img_uri" class="content_img" />
            <v-text-field
              label="Cover Image"
              :value="video.img_uri"
              v-on:change="e => onChangeCreateVideo('img_uri', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Lyrics"
              :value="video.lyrics"
              v-on:change="e => onChangeCreateVideo('lyrics', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Description"
              :value="video.description"
              v-on:change="e => onChangeCreateVideo('description', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="video.bgm_uri"
              v-model="video.bgm_uri"
              v-on:change="e => onChangeCreateVideo('bgm_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>

    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <HomeDataAssign
      :isOpen="isOpenHomeData"
      :gameData="video"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.Video"
      @close="isOpenHomeData = false"
    ></HomeDataAssign>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import PointData from '../../components/PointData';
import HomeDataAssign from '../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../HomePage/constant';

export default {
  name: 'Media',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
    HomeDataAssign,
  },
  computed: {
    ...mapState({
      mediaStore: state => state.media,
      pointdataMediaStore: state => state.media,
      selectedVideoIndexStore: state => state.media.selectedVideoIndex,
    }),
  },
  data: function () {
    return {
      isOpenPointData: false,
      videos: [],
      video: {},
      rectEvent: [],
      selectedVideoIndex: -1,
      isEditImageIndex: -1,
      isEditImage: false,
      isEditSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      isEditVideoResource: false,
      selectVideoUri: '',
      text: '',
      isDirty: false,
      isAddVideo: false,
      isUpdateVideo: false,
      selectVideoId: '',
      learned_words: [{ word: '', listening: 0, speaking: 0 }],
      incidental_words: [{ word: '', listening: 0, speaking: 0 }],
      reward: [{ name: 'jewel', amount: 0 }],
      isOpenHomeData: false,
      constantHomePage,
    };
  },
  methods: {
    ...mapActions('media', [
      'fetchMedia',
      'fetchSingleMedia',
      'clearUpdateData',
      'deleteVideo',
      'updateCreateVideoData',
      'updateVideoData',
      'updateVideo',
      'addVideo',
      'selectCategory',
      'selectMedia',
    ]),
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateVideoData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateVideoData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateVideoData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateVideoData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateVideoData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateVideo();
      this.isOpenPointData = false;
    },
    onSelectVideo(id) {
      this.clearUpdateData();
      const videos = this.mediaStore.selectMedia.videos;
      const index = videos.findIndex(video => video._id === id);
      this.selectedVideoIndex = index;
      this.video = {
        ...videos[index],
      };
      this.rectEvent = this.video.rectEvent;
      this.updateVideoData({
        key: '_id',
        value: id,
      });
      this.selectVideoId = id;
      var video = document.getElementById('edit-video');
      if (video) {
        video.load();
      }
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onSelectVideoResource(uri) {
      this.selectVideoUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddVideo) {
        this.updateCreateVideoData({
          key: 'img_uri',
          value: uri,
        });
      } else {
        this.isDirty = true;
        this.updateVideoData({
          key: 'img_uri',
          value: uri,
        });
      }
      this.video.img_uri = uri;
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;

      if (this.isAddVideo) {
        this.updateCreateVideoData({
          key: 'bgm_uri',
          value: uri,
        });
      } else {
        this.isDirty = true;
        this.updateVideoData({
          key: 'bgm_uri',
          value: uri,
        });
      }
      this.video.bgm_uri = uri;
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    saveEditVideoResource() {
      const uri = this.selectVideoUri;
      let video = null;
      if (this.isAddVideo) {
        this.updateCreateVideoData({
          key: 'video_uri',
          value: uri,
        });
        video = document.getElementById('new-video');
      } else {
        this.isDirty = true;
        this.updateVideoData({
          key: 'video_uri',
          value: uri,
        });
        video = document.getElementById('edit-video');
      }
      this.video.video_uri = uri;
      this.selectVideoUri = '';
      this.isEditVideoResource = !this.isEditVideoResource;
      if (video) {
        video.load();
      }
    },
    closeEditVideoResource() {
      this.selectVideoUri = '';
      this.isEditVideoResource = !this.isEditVideoResource;
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    toggleAddVideo() {
      this.updateCreateVideoData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.video = { ...this.mediaStore.addVideoData };
      this.isAddVideo = !this.isAddVideo;
      if (!this.isAddVideo && this.mediaStore.selectedCategoryId) {
        this.$router.push('/medias');
      }
    },
    onClickAddVideo() {
      const id = this.$route.query.id;
      const createVideoData = {
        ...this.mediaStore.addVideoData,
      };
      this.addVideo({
        category_id: id,
        video: createVideoData,
      });
      this.isAddVideo = false;
    },
    onClickDeleteVideo(id) {
      this.deleteVideo(id);
      this.isDirty = false;
      this.selectedVideoIndex = -1;
    },
    onChangeVideoData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.updateVideoData({
        key,
        value,
      });
      if (key == 'video_uri') {
        var video = document.getElementById('edit-video');
        if (video) {
          video.load();
        }
      }
    },
    onClickUpdateVideo() {
      const updateVideoData = {
        ...this.mediaStore.updateVideoData,
      };
      this.updateVideo(updateVideoData);
      Object.keys(updateVideoData).forEach(key => {
        this.video[key] = updateVideoData[key];
      });
    },
    onChangeCreateVideo(key, value) {
      this.updateCreateVideoData({
        key,
        value,
      });
      this.video[key] = value;
    },
    onChangeEventData(index, key, value) {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent[index][key] = value;
      this.rectEvent = rectEvent;
      this.updateVideoData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onAddEventData() {
      const rectEvent = this.rectEvent ? [...this.rectEvent] : [];
      this.rectEvent = [...rectEvent, { ...this.mediaStore.eventData }];
      this.updateVideoData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onRemoveEventData() {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent.pop();
      this.rectEvent = rectEvent;
      this.updateVideoData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onClickHomeData() {
      this.isOpenHomeData = true;
    },
  },
  watch: {
    selectedVideoIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectVideo(this.$route.query.sub_id);
      }
    },
  },

  mounted() {
    if (this.$route.query.category_id) {
      this.fetchSingleMedia({
        id: this.$route.query.category_id,
        sub_id: this.$route.query.sub_id,
      });
    } else if (this.mediaStore.selectedCategoryId) {
      this.toggleAddVideo();
    } else if (Object.keys(this.mediaStore.selectMedia).length < 1) {
      this.fetchSingleMedia({ id: this.$route.query.id });
    }
  },
};
</script>
