<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="isCreate ? 'Create Drag And Drop 2' : 'Update Drag And Drop 2'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <div v-if="isCreate">
              <v-layout grid-list-xs column text-xs-center v-if="isCreate">
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  required
                  label="Title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
              </v-layout>
            </div>

            <div v-else>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Title"
                    :value="data.title"
                    v-model="data.title"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('title', e)"
                  ></v-text-field>
                  <v-text-field
                    label="Intro Music URI (URL or Select file)"
                    v-model.trim="data.music_intro_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('music_intro_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isIntroSound')">
                    <v-icon>audiotrack</v-icon>Select Intro Music
                  </v-btn>
                  <v-text-field
                    label="TTS Message"
                    v-model.trim="data.tts_message"
                    @change="e => onchangeUpdate('tts_message', e)"
                  ></v-text-field>
                  <v-select
                    :items="globalConstants.VOICE_NAME"
                    label="Voice Name"
                    :value="data.voice_name"
                    v-model="data.voice_name"
                    @change="e => onchangeUpdate('voice_name', e)"
                  ></v-select>
                  <v-flex>
                    <img
                      v-if="data.bg_uri"
                      :src="data.bg_uri"
                      :alt="data.bg_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Background URI (URL or Select file)"
                      v-model.trim="data.bg_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('bg_uri', e)"
                    ></v-text-field>
                    <v-btn
                      dark
                      @click="setResource('image', 'isBackgroundUri')"
                    >
                      <v-icon>images</v-icon>Select Background URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.anwing_uri"
                      :src="data.anwing_uri"
                      :alt="data.anwing_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Anwing URI (URL or Select file)"
                      v-model.trim="data.anwing_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('anwing_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isAnwingImage')">
                      <v-icon>images</v-icon>Select Anwing URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.wall_uri"
                      :src="data.wall_uri"
                      :alt="data.wall_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Wall URI (URL or Select file)"
                      v-model.trim="data.wall_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('wall_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isWallImage')">
                      <v-icon>images</v-icon>Select Wall URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.frame_uri"
                      :src="data.frame_uri"
                      :alt="data.frame_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Frame URI (URL or Select file)"
                      v-model.trim="data.frame_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('frame_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isFrameImage')">
                      <v-icon>images</v-icon>Select Frame URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.table_uri"
                      :src="data.table_uri"
                      :alt="data.table_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Table URI (URL or Select file)"
                      v-model.trim="data.table_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('table_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isTableImage')">
                      <v-icon>images</v-icon>Select Table URI
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <img
                      v-if="data.basket_uri"
                      :src="data.basket_uri"
                      :alt="data.basket_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Basket URI (URL or Select file)"
                      v-model.trim="data.basket_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('basket_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isBasketImage')">
                      <v-icon>images</v-icon>Select Basket URI
                    </v-btn>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Background Music URI (URL or Select file)"
                    v-model.trim="data.bgm_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('bgm_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isBgmSound')">
                    <v-icon>audiotrack</v-icon>Select Background Music
                  </v-btn>
                  <v-text-field
                    label="Volume Music (0-1)"
                    v-model.trim="data.bgm_volume"
                    :rules="[rules.required, rules.numberFloat]"
                    @change="e => onchangeUpdate('bgm_volume', e)"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout column class="elevation-2" style="padding: 20px">
                <label>
                  <u>Order Image</u>
                </label>
                <v-flex>
                  <img
                    v-if="data.order_image.image_uri"
                    :src="data.order_image.image_uri"
                    :alt="data.order_image.image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Image URI (URL or Select file)"
                    v-model.trim="data.order_image.image_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('order_image.image_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('image', 'isOrderImageUri')">
                    <v-icon>images</v-icon>Select Image URI
                  </v-btn>
                </v-flex>
                <td
                  class="elevation-2"
                  style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                >
                  <label>Object Rect:</label>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Start X"
                        :value="data.order_image.object_rect.startX"
                        v-on:change="
                          e =>
                            onchangeUpdate('order_image.object_rect.startX', e)
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="End X"
                        :value="data.order_image.object_rect.endX"
                        v-on:change="
                          e => onchangeUpdate('order_image.object_rect.endX', e)
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout align-content-space-between>
                    <v-flex>
                      <v-text-field
                        label="Start Y"
                        :value="data.order_image.object_rect.startY"
                        v-on:change="
                          e =>
                            onchangeUpdate('order_image.object_rect.startY', e)
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="End Y"
                        :value="data.order_image.object_rect.endY"
                        v-on:change="
                          e => onchangeUpdate('order_image.object_rect.endY', e)
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </td>
              </v-layout>

              <ItemInfo @validate="valid = $event" :isCreate="isCreate">
              </ItemInfo>

              <Round
                v-if="isCreate === false"
                @validate="valid = $event"
                :isCreate="isCreate"
              >
              </Round>

              <DecorateImage @validate="valid = $event" :isCreate="isCreate">
              </DecorateImage>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import ItemInfo from './ItemInfo.vue';
import Round from './Round.vue';
import DecorateImage from './DecorateImage.vue';
import globalConstants from '../../../../constants';

export default {
  components: { Modal, ResourceSelect, ItemInfo, Round, DecorateImage },
  data() {
    return {
      globalConstants,
      constants,
      valid: true,
      rules,
      data: {},
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isImage: false,
        },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      dragAndDrop2Store: state => state.drag_and_drop_2,
      selectedDragAndDrop2: state => state.drag_and_drop_2.selectedDragAndDrop2,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('drag_and_drop_2', [
      'createDragAndDrop2',
      'updateDragAndDrop2',
      'setStateByKey',
      'clearStateByKey',
    ]),
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createDragAndDrop2() : this.updateDragAndDrop2();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createDragAndDrop2')
        : this.clearStateByKey('updateDragAndDrop2');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isAnwingImage) {
            this.data.anwing_uri = uri;
            this.onchangeUpdate('anwing_uri', uri);
            this.resource[resourceType].isAnwingImage = false;
          } else if (this.resource[resourceType].isWallImage) {
            this.data.wall_uri = uri;
            this.onchangeUpdate('wall_uri', uri);
            this.resource[resourceType].isWallImage = false;
          } else if (this.resource[resourceType].isFrameImage) {
            this.data.frame_uri = uri;
            this.onchangeUpdate('frame_uri', uri);
            this.resource[resourceType].isFrameImage = false;
          } else if (this.resource[resourceType].isTableImage) {
            this.data.table_uri = uri;
            this.onchangeUpdate('table_uri', uri);
            this.resource[resourceType].isTableImage = false;
          } else if (this.resource[resourceType].isBasketImage) {
            this.data.basket_uri = uri;
            this.onchangeUpdate('basket_uri', uri);
            this.resource[resourceType].isBasketImage = false;
          } else if (this.resource[resourceType].isOrderImageUri) {
            this.data.order_image.image_uri = uri;
            this.onchangeUpdate('order_image.image_uri', uri);
            this.resource[resourceType].isOrderImageUri = false;
          } else {
            this.data.bg_uri = uri;
            this.onchangeUpdate('bg_uri', uri);
            this.resource[resourceType].isBackgroundUri = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isBgmSound) {
            this.data.bgm_uri = uri;
            this.onchangeUpdate('bgm_uri', uri);
          } else {
            this.data.music_intro_uri = uri;
            this.onchangeUpdate('music_intro_uri', uri);
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createDragAndDrop2' : 'updateDragAndDrop2',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    set_category_id() {
      if (
        this.isCreate &&
        'category_id' in this.dragAndDrop2Store.dragAndDrop2List
      ) {
        this.data.category_id = this.dragAndDrop2Store.dragAndDrop2List.category_id;
        this.onchangeUpdate(
          'category_id',
          this.dragAndDrop2Store.dragAndDrop2List.category_id,
        );
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) this.fetchCategories();
        this.data = this.isCreate
          ? JSON.parse(
              JSON.stringify({ ...this.dragAndDrop2Store.defaultCreate }),
            )
          : JSON.parse(JSON.stringify({ ...this.selectedDragAndDrop2 }));
        this.set_category_id();
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
