<template>
  <v-flex mr-2 xs3>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Game Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="onClickUpdateGameDetail()"
          color="green"
          :disabled="shortStoryStore.isDirty.game == false"
        >
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-xs text-xs-center>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout column>
              <v-flex>
                <img
                  class="image_thumbnail"
                  :src="shortStoryStore.selectedShortStory.image_uri"
                />
                <v-text-field
                  label="Image URI"
                  :value="shortStoryStore.selectedShortStory.image_uri"
                  v-model="shortStoryStore.selectedShortStory.image_uri"
                  @change="e => onchangeGameDetail('image_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage()"
                  ><v-icon>image</v-icon>Select Image URI</v-btn
                >
                <img
                  class="image_thumbnail"
                  :src="shortStoryStore.selectedShortStory.icon_uri"
                />
                <v-text-field
                  label="Icon URI"
                  :value="shortStoryStore.selectedShortStory.icon_uri"
                  v-model="shortStoryStore.selectedShortStory.icon_uri"
                  @change="e => onchangeGameDetail('icon_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage('icon')"
                  ><v-icon>image</v-icon>Select Icon URI</v-btn
                >
                <v-text-field
                  label="Name"
                  :value="shortStoryStore.selectedShortStory.name"
                  @change="e => onchangeGameDetail('name', e)"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-combobox
                  :value="shortStoryStore.selectedShortStory.tags"
                  label="Tags"
                  hint="Maximum of 10 tags"
                  multiple
                  persistent-hint
                  small-chips
                  @change="e => onchangeGameDetail('tags', e)"
                  :rules="[rules.validtag]"
                ></v-combobox
                ><br />
                <v-text-field
                  type="number"
                  :value="shortStoryStore.selectedShortStory.duration_ms"
                  label="Duration ms"
                  v-on:change="
                    e => onchangeGameDetail('duration_ms', parseInt(e))
                  "
                  :rules="[rules.numberRule]"
                ></v-text-field>
                <v-text-field
                  :value="shortStoryStore.selectedShortStory.music_uri"
                  label="Music URI"
                  v-on:change="e => onchangeGameDetail('music_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound('music')"
                  ><v-icon>audiotrack</v-icon>Select Music URI</v-btn
                >
                <TypeGameData></TypeGameData>
                <v-checkbox
                  :value="shortStoryStore.selectedShortStory.available"
                  label="Available"
                  @change="e => onchangeGameDetail('available', e)"
                ></v-checkbox>
                <v-text-field
                  label="_id"
                  :value="shortStoryStore.selectedShortStory._id"
                  disabled
                ></v-text-field>
                <v-btn
                  color="red"
                  dark
                  @click="
                    onClickDeleteGame(shortStoryStore.selectedShortStory._id)
                  "
                >
                  <v-icon>delete</v-icon>Delete this game
                </v-btn>
                <p>
                  Last Updated:
                  {{ shortStoryStore.selectedShortStory.updated_at }}
                </p>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </v-flex>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import constant from '../constant';
import TypeGameData from '../components/TypeGameData.vue';

export default {
  components: {
    ResourceSelect,
    TypeGameData,
  },
  data() {
    return {
      isEditImage: false,
      selectImageUri: '',
      isSelectImageBtn: false,
      isSelectIconBtn: false,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectMusicBtn: false,
      // GameType: constant.GameType,
      valid: true,
      rules: constant.rule,
      onchange: false,
    };
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  // props: {
  //   dataGame: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  methods: {
    ...mapActions('short_story', [
      'clearSelectedGame',
      'updateGameShortStory',
      'clearUpdateGame',
      'updateGameStore',
      'setDirty',
      'clearDirty',
      'deleteGameShortStory',
    ]),
    onclickEditImage(type) {
      this.isEditImage = true;
      if (type === 'icon') this.isSelectIconBtn = true;
      else this.isSelectImageBtn = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectImageBtn:
          this.shortStoryStore.selectedShortStory.image_uri = uri;
          this.updateGameStore({ key: 'image_uri', value: uri });
          this.isSelectImageBtn = false;
          this.setDirty('game');
          break;
        case this.isSelectIconBtn:
          this.shortStoryStore.selectedShortStory.icon_uri = uri;
          this.updateGameStore({ key: 'icon_uri', value: uri });
          this.isSelectIconBtn = false;
          this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      if (type === 'music') this.isSelectMusicBtn = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectMusicBtn:
          this.shortStoryStore.selectedShortStory.music_uri = uri;
          this.updateGameStore({ key: 'music_uri', value: uri });
          this.isSelectMusicBtn = false;
          this.setDirty('game');
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onClickUpdateGameDetail() {
      if (this.$refs.form.validate()) {
        swal
          .fire({
            titleText: 'Are you sure.',
            type: 'warning',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              const updateData = { ...this.shortStoryStore.updateGameDetail };
              this.updateGameShortStory(updateData);
              this.clearUpdateGame();
              this.updateGameStore({ key: 'id', value: this.$route.query.id });
              this.clearDirty('game');
            }
          });
      }
    },
    onchangeGameDetail(key, value) {
      this.setDirty('game');
      this.setDirty('game');
      if (key === 'available') value = value === null ? false : true;
      this.updateGameStore({ key, value });
    },
    onClickDeleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteGameShortStory({ id: id });
          }
        });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
</style>
