<template>
  <Container>
    <v-flex>
      <v-card v-if="!lpStore.isLoadLearnPath">
        <v-toolbar dark>
          <v-btn icon fab color="white" small @click="onClickBack">
            <v-icon color="black">arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            lpStore.selectedLearningpath.name
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row grid-list-md>
            <v-flex mr-2 xs3>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Unit Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn @click="onClickUpdateUnit" color="green">
                    <v-icon>save</v-icon>Save
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex>
                        <v-text-field
                          disabled
                          readonly
                          label="Unit ID"
                          :value="lpStore.selectedLearningpath._id"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Name"
                          :value="lpStore.selectedLearningpath.name"
                          @change="value => onChangeUnit('name', value)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-layout>
                          <v-flex mt-3>
                            <v-btn
                              dark
                              color="black"
                              v-on:click="
                                isEditImage = true;
                                isEditUnit = true;
                              "
                            >
                              <v-icon>edit</v-icon>EDIT IMAGE
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-img
                          :src="
                            lpStore.updateUnit.img_uri ||
                              lpStore.selectedLearningpath.img_uri
                          "
                          v-lazy="
                            lpStore.updateUnit.img_uri ||
                              lpStore.selectedLearningpath.img_uri
                          "
                          class="content_img"
                        ></v-img>
                      </v-flex>
                      <v-flex>
                        <v-combobox
                          v-model="lpStore.selectedLearningpath.tags"
                          hide-selected
                          hint="Maximum of 10 tags"
                          label="Add tags"
                          multiple
                          small-chips
                          clearable
                          solo
                          @change="e => onChangeUnit('tags', e)"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              :selected="data.selected"
                              close
                              @input="removeTag(data.item)"
                            >
                              <strong>{{ data.item }}</strong>
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="lpStore.selectedLearningpath.isGuestPlayable"
                          :value="lpStore.selectedLearningpath.isGuestPlayable"
                          v-on:change="e => onChangeUnit('isGuestPlayable', e)"
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Publish"
                          v-model="lpStore.selectedLearningpath.isPublish"
                          :value="lpStore.selectedLearningpath.isPublish"
                          v-on:change="e => onChangeUnit('isPublish', e)"
                        ></v-checkbox>
                      </v-flex>
                      <v-card-actions>
                        <v-btn color="red" dark @click="onClickDeleteUnit">
                          <v-icon>delete</v-icon>Delete this unit
                        </v-btn>
                      </v-card-actions>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex mr-2 xs2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Lesson</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" @click="createLesson">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <draggable @end="orderLessonList">
                    <v-list-tile
                      v-for="(lesson, index) in lpStore.selectedLearningpath
                        .lessons"
                      :key="lesson._id"
                      @click="onSelectLesson(index, lesson._id)"
                      :class="
                        index === selectedLessonIndex ? 'active' : 'inactive'
                      "
                    >
                      <v-list-tile-content>
                        <v-list-tile-title>{{ lesson.name }}</v-list-tile-title>
                        <v-list-tile-sub-title
                          v-if="lesson.activities.length > 1"
                          >{{
                            lesson.activities.length
                          }}
                          activities</v-list-tile-sub-title
                        >
                        <v-list-tile-sub-title v-else
                          >{{
                            lesson.activities.length
                          }}
                          activity</v-list-tile-sub-title
                        >
                      </v-list-tile-content>
                    </v-list-tile>
                  </draggable>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex mr-2 v-if="selectedLessonIndex != -1" xs2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Activities</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    @click="
                      onClickCreateActivity();
                      selectedActivityIndex = -1;
                    "
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <draggable @end="orderActivityList">
                    <v-list-tile
                      v-for="(activity, index) in lpStore.selectedLearningpath
                        .lessons[selectedLessonIndex].activities"
                      :key="activity._id"
                      @click="onSelectActivity(index, activity._id)"
                      :class="
                        index === selectedActivityIndex ? 'active' : 'inactive'
                      "
                    >
                      <v-list-tile-content>
                        <v-list-tile-title
                          >{{ index + 1 }}
                          {{ activity.title }}</v-list-tile-title
                        >
                        <v-list-tile-sub-title
                          >Game type :
                          {{
                            activity.game_data &&
                              activity.game_data[0] &&
                              activity.game_data[0].game_type
                          }}</v-list-tile-sub-title
                        >
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn
                          class="btn-center"
                          @click="
                            onSelectActivity(index, activity._id);
                            homeData(1);
                          "
                          flat
                          icon
                        >
                          <v-icon>home</v-icon></v-btn
                        >
                      </v-list-tile-action>
                      <v-list-tile-action>
                        <v-btn
                          class="btn-center"
                          @click="
                            onSelectActivity(index, activity._id);
                            templateAssignData();
                          "
                          flat
                          icon
                        >
                          <v-icon>live_tv</v-icon></v-btn
                        >
                      </v-list-tile-action>
                    </v-list-tile>
                  </draggable>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              mr-2
              v-if="selectedLessonIndex != -1 && selectedActivityIndex === -1"
            >
              <v-card dark>
                <v-toolbar>
                  <v-toolbar-title>Lesson Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn @click="onClickUpdateLesson" color="green">
                    <v-icon>save</v-icon>Save
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex>
                        <v-text-field
                          disabled
                          readonly
                          label="Lesson ID"
                          :value="lesson._id"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Name"
                          :value="lesson.name"
                          @change="
                            e => updateLessonData({ key: 'name', value: e })
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="lesson.isGuestPlayable"
                          :value="lesson.isGuestPlayable"
                          v-on:change="
                            e =>
                              updateLessonData({
                                key: 'isGuestPlayable',
                                value: e,
                              })
                          "
                        >
                        </v-checkbox>
                        <!-- Skip Lesson -->
                        <v-checkbox
                          label="Skip Lesson"
                          v-model="lesson.skip_lesson"
                          :value="lesson.skip_lesson"
                          v-on:change="
                            e =>
                              updateLessonData({
                                key: 'skip_lesson',
                                value: e,
                              })
                          "
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <img :src="lesson.img_uri" class="content_img" />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <span class="headline" dark>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="
                                    isEditImage = true;
                                    isEditLesson = true;
                                  "
                                >
                                  <v-icon>edit</v-icon>
                                </v-btn>
                                <v-btn
                                  fab
                                  icon
                                  small
                                  color="black"
                                  v-on:click="onClickFullscreen(page.img_uri)"
                                >
                                  <v-icon>fullscreen</v-icon>
                                </v-btn>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-btn
                        color="red"
                        @click="onClickDeleteLesson"
                        v-if="lpStore.selectedLearningpath.lessons.length > 1"
                      >
                        <v-icon>delete</v-icon>Delete this lesson
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex mr-2 v-if="selectedActivityIndex != -1">
              <v-card dark>
                <v-toolbar>
                  <v-toolbar-title>Activity Configuration</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="onUpdateActivity"
                    color="green"
                    :disabled="isLock"
                  >
                    <v-icon>save</v-icon>Save
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container>
                    <v-layout column>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="activity.isGuestPlayable"
                          :value="activity.isGuestPlayable"
                          v-on:change="
                            e => onChangeActivityData('isGuestPlayable', e)
                          "
                          :disabled="isLock"
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          disabled
                          readonly
                          label="Activity ID"
                          :value="activity._id"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Title"
                          :value="activity.title"
                          v-on:change="e => onChangeActivityData('title', e)"
                          :disabled="isLock"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Icon"
                          :value="lpStore.updateActivity.icon || activity.icon"
                          v-on:change="e => onChangeActivityData('icon', e)"
                          :disabled="isLock"
                        />
                        <v-btn
                          dark
                          color="black"
                          v-on:click="
                            isEditImage = true;
                            isEditActivity = true;
                          "
                          :disabled="isLock"
                        >
                          <v-icon>edit</v-icon>EDIT ICON
                        </v-btn>
                        <v-img
                          :src="lpStore.updateActivity.icon || activity.icon"
                          class="content_img"
                        ></v-img>
                      </v-flex>
                      <v-flex>
                        <v-select
                          :items="transition"
                          label="Transition"
                          :value="activity.transition"
                          v-model="activity.transition"
                          v-on:change="
                            e => onChangeActivityData('transition', e)
                          "
                          :disabled="isLock"
                        ></v-select>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Skip Activity"
                          v-model="activity.skip_activity"
                          :value="activity.skip_activity"
                          v-on:change="
                            e => onChangeActivityData('skip_activity', e)
                          "
                          :disabled="isLock"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-checkbox
                          label="Skip (Under Construction)"
                          v-model="activity.skip"
                          :value="activity.skip"
                          v-on:change="e => onChangeActivityData('skip', e)"
                          :disabled="isLock"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-btn
                          class="btn-center"
                          @click="homeData(0)"
                          :disabled="isLock"
                        >
                          <v-icon>home</v-icon>Assign Game to Home Page</v-btn
                        >
                      </v-flex>
                      <v-flex
                        v-for="(data, index) in activity.game_data"
                        :key="index"
                      >
                        <container>
                          <v-layout column>
                            <v-card-actions>
                              <label>
                                <b>🎯 GAME {{ index + 1 }}:</b>
                              </label>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="
                                  activity.game_data.length > 1 && index > 0
                                "
                                icon
                                color="blue"
                                dark
                                @click="onMoveUp(index)"
                                :disabled="isLock"
                              >
                                <v-icon>keyboard_arrow_up</v-icon>
                              </v-btn>
                              <v-btn
                                v-if="
                                  activity.game_data.length > 1 &&
                                    index < activity.game_data.length - 1
                                "
                                icon
                                color="blue"
                                dark
                                @click="onMoveDown(index)"
                                :disabled="isLock"
                              >
                                <v-icon>keyboard_arrow_down</v-icon>
                              </v-btn>
                              <v-btn
                                v-if="activity.game_data.length > 1"
                                icon
                                color="red"
                                dark
                                @click="onDeleteGame(index)"
                                :disabled="isLock"
                              >
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </v-card-actions>
                            <v-flex>
                              <v-select
                                :items="constants.GAME_TYPE"
                                label="Game Type"
                                :value="data.game_type"
                                v-model="data.game_type"
                                v-on:change="
                                  e => onChangeGameData('game_type', e, index)
                                "
                                :disabled="isLock"
                              ></v-select>
                            </v-flex>
                            <v-flex>
                              <v-text-field
                                label="Icon URI"
                                :value="data.icon_uri"
                                v-on:change="
                                  url =>
                                    onChangeGameData('icon_uri', url, index)
                                "
                                :disabled="isLock"
                              />
                              <v-btn
                                dark
                                color="black"
                                v-on:click="
                                  setActivityGameDataIconUriState(index)
                                "
                                :disabled="isLock"
                              >
                                <v-icon>edit</v-icon>EDIT ICON URI
                              </v-btn>
                              <v-img
                                :src="data.icon_uri"
                                class="content_icon_uri"
                              ></v-img>
                            </v-flex>
                            <v-flex>
                              <v-select
                                :items="gameTypeStore[data.game_type]"
                                label="Category"
                                :value="data.game_id"
                                v-model="data.game_id"
                                v-on:change="
                                  e => onChangeGameData('game_id', e, index)
                                "
                                :disabled="isLock"
                              ></v-select>
                            </v-flex>
                            <v-flex
                              v-if="
                                hasEndingEffect.indexOf(data.game_type) > -1
                              "
                            >
                              <v-checkbox
                                label="Has Ending Effect"
                                v-model="data.ending_effect"
                                :value="data.ending_effect"
                                v-on:change="
                                  e =>
                                    onChangeGameData('ending_effect', e, index)
                                "
                                :disabled="isLock"
                              ></v-checkbox>
                            </v-flex>
                            <v-flex
                              v-if="
                                requiredSubIdList.indexOf(data.game_type) > -1
                              "
                            >
                              <container>
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Sub data</u>
                                  </label>
                                  <td
                                    v-for="(id, idx) in data.sub_id_list"
                                    :key="id"
                                  >
                                    <v-layout align-content-space-between>
                                      <v-select
                                        :items="subTitleList[index]"
                                        :value="data.sub_id_list[idx]"
                                        v-on:change="
                                          e =>
                                            onChangeSubTitleData(index, idx, e)
                                        "
                                        :disabled="isLock"
                                      ></v-select>
                                      <v-btn
                                        icon
                                        dark
                                        color="black"
                                        v-clipboard:copy="id"
                                      >
                                        <v-icon>filter_none</v-icon>
                                      </v-btn>
                                      <v-btn
                                        v-if="
                                          data.game_type && data.game_id && id
                                        "
                                        icon
                                        dark
                                        color="black"
                                        v-on:click="
                                          onClickOpenGameWindow(
                                            data.game_type,
                                            data.game_id,
                                            id,
                                          )
                                        "
                                      >
                                        <v-icon>open_in_new</v-icon>
                                      </v-btn>
                                      <v-btn v-else icon dark color="grey">
                                        <v-icon>open_in_new</v-icon>
                                      </v-btn>
                                    </v-layout>
                                  </td>
                                  <td
                                    v-if="
                                      selectedGameDataIndex == index &&
                                        newSubId == true
                                    "
                                  >
                                    <v-layout align-content-space-between>
                                      <v-select
                                        :items="subTitleList[index]"
                                        :value="subTitleId"
                                        v-on:change="
                                          e =>
                                            onChangeSubTitleData(
                                              index,
                                              data.sub_id_list.length,
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-select>
                                      <v-btn
                                        icon
                                        dark
                                        color="black"
                                        v-clipboard:copy="subTitleId"
                                      >
                                        <v-icon>filter_none</v-icon>
                                      </v-btn>
                                    </v-layout>
                                  </td>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.sub_id_list.length > 1 || newSubId
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveSubTitle(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="!newSubId"
                                      icon
                                      color="green"
                                      @click="onAddSubTitle(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isLock"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                            </v-flex>
                            <v-flex
                              v-if="
                                requiredVideoData.indexOf(data.game_type) > -1
                              "
                            >
                              <container v-if="data.game_type === 'MNC'">
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Video data</u>
                                  </label>
                                  <v-flex
                                    v-for="(vdo, idx) in data.video_data"
                                    :key="idx"
                                    class="elevation-2"
                                    style="
                                      padding: 10px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    "
                                  >
                                    <label>Video {{ idx + 1 }}:</label>
                                    <v-flex>
                                      <v-checkbox
                                        label="Is Loop?"
                                        v-model="vdo.isLoop"
                                        :value="vdo.isLoop"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'isLoop',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-checkbox>
                                      <!-- <v-checkbox
                                        label="Show Subtitle Video"
                                        v-model="vdo.show_subtitle_video"
                                        :value="vdo.show_subtitle_video"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'show_subtitle_video',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-checkbox> -->
                                      <v-btn
                                        v-if="vdo.video_uri"
                                        dark
                                        color="black"
                                        @click="
                                          onClickOpenCreateSubtitleVideoLocalization(
                                            vdo,
                                          )
                                        "
                                        :disabled="isLock"
                                      >
                                        SRT Subtitle Video<v-icon
                                          >open_in_new</v-icon
                                        >
                                      </v-btn>
                                    </v-flex>
                                    <v-flex>
                                      <v-autocomplete
                                        label="Video Link"
                                        v-model="vdo.video_uri"
                                        :items="minichatVideoURIsState"
                                        chips
                                        hide-details
                                        hide-no-data
                                        hide-selected
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'video_uri',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-autocomplete>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Next Index"
                                        :value="vdo.nextIndex"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'nextIndex',
                                              parseInt(e),
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-flex>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.video_data &&
                                          data.video_data.length > 1
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveVideoData(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      icon
                                      color="green"
                                      @click="onAddVideoData(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                              <container v-else>
                                <v-layout
                                  column
                                  class="elevation-2"
                                  style="padding: 20px"
                                >
                                  <label>
                                    <u>⌗ Video data</u>
                                  </label>
                                  <v-flex
                                    v-for="(vdo, idx) in data.video_data"
                                    :key="idx"
                                    class="elevation-2"
                                    style="
                                      padding: 10px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    "
                                  >
                                    <label>Video {{ idx + 1 }}:</label>
                                    <v-flex>
                                      <v-checkbox
                                        label="Is Loop?"
                                        v-model="vdo.isLoop"
                                        :value="vdo.isLoop"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'isLoop',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-checkbox>
                                      <!-- <v-checkbox
                                        label="Show Subtitle Video"
                                        v-model="vdo.show_subtitle_video"
                                        :value="vdo.show_subtitle_video"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'show_subtitle_video',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-checkbox> -->

                                      <v-btn
                                        v-if="vdo.video_uri"
                                        dark
                                        color="black"
                                        @click="
                                          onClickOpenCreateSubtitleVideoLocalization(
                                            vdo,
                                          )
                                        "
                                        :disabled="isLock"
                                      >
                                        SRT Subtitle Video<v-icon
                                          >open_in_new</v-icon
                                        >
                                      </v-btn>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Video Link"
                                        :value="vdo.video_uri"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'video_uri',
                                              e,
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex>
                                      <v-text-field
                                        label="Next Index"
                                        :value="vdo.nextIndex"
                                        v-on:change="
                                          e =>
                                            onChangeVideoData(
                                              index,
                                              idx,
                                              'nextIndex',
                                              parseInt(e),
                                            )
                                        "
                                        :disabled="isLock"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-flex>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="
                                        data.video_data &&
                                          data.video_data.length > 1
                                      "
                                      icon
                                      color="red"
                                      @click="onRemoveVideoData(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      icon
                                      color="grey"
                                      :disabled="isLock"
                                    >
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                    <v-btn
                                      icon
                                      color="green"
                                      @click="onAddVideoData(index)"
                                      :disabled="isLock"
                                    >
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-layout>
                              </container>
                            </v-flex>
                          </v-layout>
                        </container>
                      </v-flex>

                      <v-btn
                        color="green"
                        @click="onAddMoreGame"
                        v-if="
                          requiredSubIdList.indexOf(
                            activity.game_data &&
                              activity.game_data[0] &&
                              activity.game_data[0].game_type,
                          ) > -1
                        "
                        :disabled="isLock"
                      >
                        <v-icon>add</v-icon>Add More Game
                      </v-btn>
                      <v-flex>
                        <h2>Custom Configuration</h2>
                        <v-jsoneditor
                          class="editor"
                          :plus="false"
                          height="400px"
                          v-model="activity.config"
                          :options="editorOption"
                          :value="activity.config || {}"
                        />
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-btn
                        color="red"
                        @click="deleteActivity(lesson._id)"
                        v-if="
                          lpStore.selectedLearningpath.lessons[
                            selectedLessonIndex
                          ].activities.length > 1
                        "
                        :disabled="isLock"
                      >
                        <v-icon>delete</v-icon>Delete this activity
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-toolbar dark>
          <v-toolbar-title>Loading...</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card>
    </v-flex>
    <Modal
      title="Create Activity"
      v-if="isAddActivity"
      :isOpen="isAddActivity"
      :close="onCloseCreateActivity"
      :save="onClickSaveCreateActivity"
    >
      <v-container>
        <v-layout text-xs-center grid-list-md column>
          <v-flex>
            <v-text-field
              label="Title"
              :value="title"
              v-on:change="e => onChangeCreateActivityData('title', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              label="Game Type"
              :items="constants.GAME_TYPE"
              :value="selectGameType"
              v-model="selectGameType"
              v-on:change="e => onChangeCreateActivityGameData('game_type', e)"
            ></v-select>
          </v-flex>
          <v-flex v-if="selectGameType">
            <v-select
              label="Game"
              :items="gameTypeStore[selectGameType]"
              :value="selectGameId"
              v-model="selectGameId"
              v-on:change="e => onChangeCreateActivityGameData('game_id', e)"
            ></v-select>
          </v-flex>
          <v-flex>
            <h2>Custom Configuration</h2>
          </v-flex>
          <v-flex>
            <v-jsoneditor
              class="editor"
              :plus="false"
              height="400px"
              :options="editorOption"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
      :save="onSaveResource"
    />
    <HomeDataAssign
      :isOpen="isOpenHomeData"
      :gameData="this.activity"
      :type="typeHomeData"
      @close="isOpenHomeData = false"
    ></HomeDataAssign>
    <TemplateDataAssign
      :isOpen="isOpenLiveClassTemplate"
      :activity_id="this.activity._id"
      @close="isOpenLiveClassTemplate = false"
    >
    </TemplateDataAssign>
  </Container>
</template>

<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 100px;
  max-width: 100%;
  border-radius: 10px;
}
.content_icon_uri {
  max-height: 30%;
  max-width: 30%;
}

.editor {
  background-color: white;
  color: black;
}
</style>
<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import draggable from 'vuedraggable';
import Container from '../../components/Container';
import Modal from '../../components/Modal';
import ResourceSelect from '../../components/ResourceSelect';
import constants from '../../constants';
import HomeDataAssign from '../HomePage/components/HomeDataAssign.vue';
import TemplateDataAssign from '../LiveClass/ClassTemplates/components/TemplateDataAssign.vue';
import rules from '../../utils/ruleValidate';

export default {
  name: 'Learningpath',
  components: {
    Container,
    ResourceSelect,
    Modal,
    draggable,
    HomeDataAssign,
    TemplateDataAssign,
  },
  data() {
    return {
      rules,
      minichatVideoURIsStateWatcher: null,
      gamedataIndex: -1,
      minichatVideoURIs: [],
      selectedLessonIndex: -1,
      selectedActivityIndex: -1,
      selectedGameIndex: -1,
      selectedActivityGameDataIconUriIndex: -1,
      selectLessonId: '',
      selectActivityId: '',
      lessons: [],
      lesson: {},
      activities: [],
      activity: {},
      game_data: [],
      subTitleList: [],
      selectedGameDataIndex: -1,
      newSubId: false,
      subTitleId: '',
      isEditImage: false,
      isEditUnit: false,
      isEditLesson: false,
      isEditActivity: false,
      isEditActivityGameDataIconUri: false,
      selectImageUri: '',
      isDirty: false,
      constants,
      title: '',
      selectGameType: '',
      selectGameId: '',
      editorOption: {
        onChangeText: e => this.onChangeConfig(e),
        mode: 'code',
      },
      isAddActivity: false,
      requiredSubIdList: [
        'WWA',
        'ESL',
        'MC',
        'CN',
        'DD',
        'DD2',
        'TTS',
        'MEDIA',
        'MM',
        'SW',
        'TR',
        'TB',
        'MPU',
        // 'CHR',
        'LB',
        'MNC',
        'LM',
        'FG',
        'TS',
        'SP',
        'WAM',
        // 'MG',
        'DC',
        'SNAP',
        'RUNNINGGAME',
        'DRIVINGGAME',
        'SEQUENCEGAME',
        'QUIZ',
        'LABYRINTH',
        'SHOOTING_ARROW',
      ],
      requiredVideoData: ['DD', 'TTS', 'MEDIA', 'MPU', 'TR', 'TB', 'MNC', 'LM'],
      hasEndingEffect: ['DD', 'TTS'],
      transition: [
        // New transition
        'Random',
        'Bath',
        'Camping',
        'Garden',
        'Learn',
        'Picnic',
        'Seaworld',
        'Sunflower',
        // Old transition
        'LearningTime',
        'GameTime',
        'TalkTime',
        'StoryTime',
        'ReviewTime',
        'SongTime',
        'PhonicsTime',
      ],
      isOpenHomeData: false,
      typeHomeData: 0,
      isLock: false,
      isOpenLiveClassTemplate: false,
    };
  },
  computed: {
    ...mapState({
      lpStore: state => state.learningpath,
      gameTypeStore: state => state.learningpath.gameType,
      updateLessonStore: state => state.updateLesson,
      minichatVideoURIsState: state => state.learningpath.minichatVideoURIs,
      userData: state => state.userInfo.userData, //get roleuser
    }),
    tags() {
      return this.lpStore.selectedLearningpath.tags;
    },
  },
  methods: {
    ...mapActions('learningpath', [
      'fetchSingleLP',
      'updateUnitData',
      'updateUnit',
      'updateActivityData',
      'updateActivity',
      'deleteActivity',
      'createActivityData',
      'createActivity',
      'updateLessonData',
      'updateLesson',
      'createLesson',
      'deleteLesson',
      'orderActivity',
      'orderLesson',
      'deleteUnit',
      'clearUpdateActivityData',
      'clearUpdateLessonData',
      'clearUpdateUnitData',
      'fetchAllGameType',
      'fetchMinichatVideoURIs',
      'clearMinichatVideoURIs',
    ]),
    isLockAdmin(isLock) {
      const role_id = this.userData.role_id;
      this.isLock = role_id !== 100 ? isLock : false;
    },
    orderActivityList(e) {
      this.selectedActivityIndex = -1;
      this.orderActivity({
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
        lessonId: this.lpStore.selectedLearningpath.lessons[
          this.selectedLessonIndex
        ]._id,
      });
    },
    orderLessonList(e) {
      this.selectedLessonIndex = -1;
      this.orderLesson({
        currentIndex: e.oldIndex,
        newIndex: e.newIndex,
        learningPathId: this.lpStore.selectedLearningpath._id,
      });
    },
    onClickBack() {
      if (this.isDirty) {
        swal
          .fire({
            titleText: 'Unsaved modify. Are you sure you want to leave?',
            showCancelButton: true,
            type: 'info',
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateUnitData();
              this.clearUpdateLessonData();
              this.clearUpdateActivityData();
              this.$router.push('/learningpaths');
            }
          });
      } else {
        this.$router.push('/learningpaths');
      }
    },
    setActivityGameDataIconUriState(index) {
      this.isEditImage = true;
      this.isEditActivityGameDataIconUri = true;
      this.selectedActivityGameDataIconUriIndex = index;
    },
    updateActivityGameDataIcon(uri) {
      let game_data = this.activity.game_data;
      game_data[this.selectedActivityGameDataIconUriIndex].icon_uri = uri;
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
      this.isEditActivityGameDataIconUri = false; // toggle resource modal
    },
    onSaveResource() {
      const uri = this.selectImageUri;
      if (this.isEditLesson) {
        this.updateLessonData({
          key: 'img_uri',
          value: uri,
        });
        this.lesson.img_uri = uri;
        this.isEditLesson = false;
      } else if (this.isEditUnit) {
        this.updateUnitData({
          key: 'img_uri',
          value: uri,
        });
        this.isEditUnit = false;
      } else if (this.isEditActivity) {
        this.updateActivityData({
          key: 'icon',
          value: uri,
        });
        this.isEditActivity = false;
      } else if (this.isEditActivityGameDataIconUri) {
        this.updateActivityGameDataIcon(uri);
      }
      this.selectImageUri = '';
      this.isDirty = true;
      this.isEditImage = false;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = false;
    },
    onSelectImage(value) {
      this.selectImageUri = value;
    },
    onChangeUnit(key, value) {
      if ((key == 'isPublish' || key == 'isGuestPlayable') && !value) {
        value = false;
      }
      this.isDirty = true;
      this.updateUnitData({
        key,
        value,
      });
    },
    onClickUpdateUnit() {
      this.isDirty = false;
      this.selectedLessonIndex = -1;
      this.selectedActivityIndex = -1;
      this.selectGameType = '';
      this.selectGameId = '';
      this.updateUnit();
    },
    async onChangeSubTitleData(index, idx, value) {
      const isMinichat = this.activity.game_data[index].game_type === 'MNC';
      if (isMinichat) {
        await this.fetchMinichatVideoURIs(value);
        this.gamedataIndex = index;
      }
      this.isDirty = true;
      if (this.selectedGameDataIndex == index && this.newSubId) {
        this.newSubId = false;
        this.selectedGameDataIndex = -1;
      }
      const game_data = [...this.activity.game_data];
      game_data[index].sub_id_list[idx] = value;
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    async onAutoGenerateMinichatVideoURIs(videoURI, videoIndex, gamedataIndex) {
      const game_data = [...this.activity.game_data];
      if (videoIndex > 0) {
        this.onAddVideoData(gamedataIndex);
        game_data[gamedataIndex].video_data[videoIndex].video_uri = videoURI;
        game_data[gamedataIndex].video_data[videoIndex].isLoop = false;
        game_data[gamedataIndex].video_data[videoIndex].nextIndex = -1;
      } else {
        game_data[gamedataIndex].video_data[videoIndex].video_uri = videoURI;
        game_data[gamedataIndex].video_data[videoIndex].isLoop = false;
        game_data[gamedataIndex].video_data[videoIndex].nextIndex = -1;
      }
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddSubTitle(index) {
      this.selectedGameDataIndex = index;
      this.newSubId = true;
      this.subTitleId = '';
    },
    onRemoveSubTitle(index) {
      if (this.newSubId) {
        this.newSubId = false;
        this.selectedGameDataIndex = -1;
      } else {
        this.isDirty = true;
        const game_data = [...this.activity.game_data];
        game_data[index].sub_id_list.pop();
        this.updateActivityData({
          key: 'game_data',
          value: game_data,
        });
      }
    },
    onChangeVideoData(index, idx, key, value) {
      this.isDirty = true;
      if (this.selectedGameDataIndex == index) {
        this.selectedGameDataIndex = -1;
      }
      const game_data = [...this.activity.game_data];
      if ((key === 'isLoop' || key === 'show_subtitle_video') && !value) {
        value = false;
      }
      game_data[index].video_data[idx][key] = value;
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddVideoData(index) {
      this.selectedGameDataIndex = index;
      const game_data = [...this.activity.game_data];
      game_data[index].video_data = game_data[index].video_data
        ? [...game_data[index].video_data, { ...this.lpStore.videoData }]
        : [{ ...this.lpStore.videoData }];
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    onRemoveVideoData(index) {
      this.isDirty = true;
      const game_data = [...this.activity.game_data];
      game_data[index].video_data.pop();
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    onAddMoreGame() {
      const new_game_data = { ...this.lpStore.gameData };
      const game_data = [...this.activity.game_data, new_game_data];
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onDeleteGame(index) {
      swal
        .fire({
          titleText: 'Delete Game ?',
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(async result => {
          if (result.value) {
            const game_data = this.activity.game_data;
            game_data.splice(index, 1);
            this.updateActivityData({
              key: 'game_data',
              value: game_data,
            });
            this.activity.game_data = game_data;
          }
        });
    },
    onMoveUp(index) {
      const game_data = this.activity.game_data;
      game_data.splice(index - 1, 0, game_data.splice(index, 1)[0]);
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onMoveDown(index) {
      const game_data = this.activity.game_data;
      game_data.splice(index + 1, 0, game_data.splice(index, 1)[0]);
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
      this.activity.game_data = game_data;
    },
    onChangeActivityData(key, value) {
      if (
        (key == 'skip' || key == 'skip_activity' || key == 'isGuestPlayable') &&
        !value
      ) {
        value = false;
      }
      this.isDirty = true;
      this.updateActivityData({
        key,
        value,
      });
    },
    onChangeGameData(key, value, index) {
      this.isDirty = true;
      const game_data = [...this.activity.game_data];
      const hasVideoData = this.requiredVideoData.indexOf(value) > -1;
      if (key === 'game_type' && hasVideoData) {
        // Reset video_data to default value if game_type that has videoData changes
        game_data[index].video_data = [
          { video_uri: '', isLoop: false, nextIndex: -1 },
        ];
      }

      if (
        key === 'game_type' &&
        this.hasEndingEffect.indexOf(value) == -1 &&
        game_data[index].ending_effect
      ) {
        game_data[index].ending_effect = false;
      }
      if (key == 'ending_effect' && !value) {
        value = false;
      }

      if (key == 'icon_uri') {
        game_data[index].icon_uri = value;
      }

      game_data[index][key] = value;
      game_data[index].sub_id_list = [''];
      if (key == 'game_type') {
        game_data[index].game_id = '';
      }
      this.updateActivityData({
        key: 'game_data',
        value: game_data,
      });
      if (key == 'game_id') {
        // Reset state of activity when user change category
        game_data[index].video_data = [
          { video_uri: '', isLoop: false, nextIndex: -1 },
        ];
        this.subTitleList[index] = this.getSubData(
          game_data[index].game_type,
          value,
        );
      }
    },
    onChangeCreateActivityGameData(key, value) {
      const game_data = [...this.lpStore.createActivity.game_data];
      game_data[key] = value;
      this.updateCreateActivityData({
        key: 'game_data',
        value: game_data,
      });
    },
    validateAndCleanMinichatVideoURI() {
      if (this.minichatVideoURIsState.length !== 0) {
        this.clearMinichatVideoURIs();
      }
    },
    async validateAndFetchAllGameType() {
      const gameTypeStoreIsEmpty = Object.keys(this.gameTypeStore).length === 0;
      if (gameTypeStoreIsEmpty) {
        await this.fetchAllGameType();
      }
    },
    watchMinichatVideoState() {
      this.minichatVideoURIsStateWatcher = this.$watch(
        'minichatVideoURIsState',
        () => {
          if (
            Array.isArray(this.minichatVideoURIsState) &&
            this.minichatVideoURIsState.length
          ) {
            return this.minichatVideoURIsState.forEach(
              async (videoLink, videoIndex) => {
                await this.onAutoGenerateMinichatVideoURIs(
                  videoLink,
                  videoIndex,
                  this.gamedataIndex,
                );
              },
            );
          } else {
            return;
          }
        },
      );
    },
    async onSelectActivity(index, id) {
      this.validateAndCleanMinichatVideoURI();
      this.clearUpdateActivityData();
      if (this.isDirty) {
        swal
          .fire({
            titleText: 'Unsaved modify. Are you sure you want to leave? ',
            showCancelButton: true,
            type: 'question',
          })
          .then(result => {
            if (result.value) {
              this.selectedGameDataIndex = -1;
              this.selectedActivityIndex = index;
              this.selectActivityId = id;
              this.activity = {};
              this.isDirty = false;
              this.newSubId = false;
              this.activity = {
                ...this.lpStore.selectedLearningpath.lessons[
                  this.selectedLessonIndex
                ].activities[index],
              };
              const game_data = [...this.activity.game_data];
              Object.keys(game_data).forEach(index => {
                this.subTitleList[index] = this.getSubData(
                  game_data[index].game_type,
                  game_data[index].game_id,
                );
              });
              this.game_data = game_data;
              this.lpStore.updateActivity = this.activity;
              this.isLockAdmin(this.activity.isLock);
              this.updateActivityData({
                key: 'id',
                value: id,
              });
            }
          });
      } else {
        this.selectedGameDataIndex = -1;
        this.selectedActivityIndex = index;
        this.selectActivityId = id;
        this.activity = {};
        this.isDirty = false;
        this.newSubId = false;
        this.activity = {
          ...this.lpStore.selectedLearningpath.lessons[this.selectedLessonIndex]
            .activities[index],
        };
        const game_data = [...this.activity.game_data];
        Object.keys(game_data).forEach(index => {
          this.subTitleList[index] = this.getSubData(
            game_data[index].game_type,
            game_data[index].game_id,
          );
        });
        this.game_data = game_data;
        this.lpStore.updateActivity = this.activity;
        this.isLockAdmin(this.activity.isLock);
        this.updateActivityData({
          key: 'id',
          value: id,
        });
      }
    },
    getSubData(game_type, game_id) {
      if (this.requiredSubIdList.indexOf(game_type) < 0) {
        return;
      }

      const games = this.gameTypeStore[game_type];
      const index = games.findIndex(game => game.category_id === game_id);
      if (index == -1) {
        return;
      }

      if (game_type == 'ESL') {
        const subTitleList = this.gameTypeStore[game_type][index].books;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'CN') {
        const subTitleList = this.gameTypeStore[game_type][index].nodes;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'DD') {
        const subTitleList = this.gameTypeStore[game_type][index].sets;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'CHR') {
        const subTitleList = this.gameTypeStore[game_type][index].quests;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MC' || game_type == 'MM' || game_type == 'SW') {
        const subTitleList = this.gameTypeStore[game_type][index].decks;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MPU') {
        const subTitleList = this.gameTypeStore[game_type][index].activities;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MEDIA') {
        const subTitleList = this.gameTypeStore[game_type][index].videos;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'TTS') {
        const subTitleList = this.gameTypeStore[game_type][index].object_sets;
        return subTitleList.map(data => {
          if (data) {
            return {
              text: data.name,
              value: data._id,
            };
          }
        });
      } else if (game_type == 'TR' || game_type == 'TB') {
        const subTitleList = this.gameTypeStore[game_type][index].blueprints;
        return subTitleList.map(data => {
          return {
            text: data.text,
            value: data._id,
          };
        });
      } else if (game_type == 'WWA') {
        const subTitleList = this.gameTypeStore[game_type][index].questions;
        return subTitleList.map(data => {
          return {
            text: data.question,
            value: data._id,
          };
        });
      } else if (game_type == 'LB') {
        const subTitleList = this.gameTypeStore[game_type][index].label_sets;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'MNC') {
        const subTitleList = this.gameTypeStore[game_type][index].minichats;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'LM') {
        const subTitleList = this.gameTypeStore[game_type][index].groups;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'FG') {
        const subTitleList = this.gameTypeStore[game_type][index].flyingGames;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'TS') {
        const subTitleList = this.gameTypeStore[game_type][index].tapstory;
        return subTitleList.map(data => {
          return {
            text: data.name,
            value: data._id,
          };
        });
      } else if (game_type == 'SP') {
        const subTitleList = this.gameTypeStore[game_type][index].slidePuzzles;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'WAM') {
        const subTitleList = this.gameTypeStore[game_type][index].whackAmoles;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      }
      // else if (game_type == 'MG') {
      //   const subTitleList = this.gameTypeStore[game_type][index].mazeGames;
      //   return subTitleList.map(data => {
      //     return {
      //       text: data.title,
      //       value: data._id,
      //     };
      //   });
      // }
      else if (game_type == 'DC') {
        const subTitleList = this.gameTypeStore[game_type][index].drag_combo;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'SNAP') {
        const subTitleList = this.gameTypeStore[game_type][index].snaps;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'RUNNINGGAME') {
        const subTitleList = this.gameTypeStore[game_type][index].running_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'DRIVINGGAME') {
        const subTitleList = this.gameTypeStore[game_type][index].driving_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'SEQUENCEGAME') {
        const subTitleList = this.gameTypeStore[game_type][index]
          .sequence_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'QUIZ') {
        const subTitleList = this.gameTypeStore[game_type][index].quiz_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'DD2') {
        const subTitleList = this.gameTypeStore[game_type][index]
          .drag_and_drop_2_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'LABYRINTH') {
        const subTitleList = this.gameTypeStore[game_type][index]
          .labyrinth_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'SHOOTING_ARROW') {
        const subTitleList = this.gameTypeStore[game_type][index]
          .shooting_arrow_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      } else if (game_type == 'COOKING_GAME') {
        const subTitleList = this.gameTypeStore[game_type][index].cooking_games;
        return subTitleList.map(data => {
          return {
            text: data.title,
            value: data._id,
          };
        });
      }
    },
    onSelectLesson(index, id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText: 'Unsaved modify. Are you sure you want to leave? ',
            showCancelButton: true,
            type: 'question',
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateLessonData();
              this.updateLessonData({
                key: 'id',
                value: id,
              });
              this.isDirty = false;
              this.selectImageUri = '';
              this.selectLessonId = id;
              this.selectedLessonIndex = index;
              this.selectedActivityIndex = -1;
              this.lesson = {
                ...this.lpStore.selectedLearningpath.lessons[
                  this.selectedLessonIndex
                ],
              };
            }
          });
      } else {
        this.clearUpdateLessonData();
        this.updateLessonData({
          key: 'id',
          value: id,
        });
        this.isDirty = false;
        this.selectImageUri = '';
        this.selectLessonId = id;
        this.selectedLessonIndex = index;
        this.selectedActivityIndex = -1;
        this.lesson = {
          ...this.lpStore.selectedLearningpath.lessons[
            this.selectedLessonIndex
          ],
        };
      }
    },
    onChangeCreateActivityData(key, value) {
      this.createActivityData({
        key,
        value,
      });
    },
    onChangeConfig(json) {
      this.isDirty = true;
      if (this.isAddActivity) {
        this.createActivityData({
          key: 'config',
          value: JSON.parse(json),
        });
      } else {
        this.updateActivityData({
          key: 'config',
          value: this.activity.config,
        });
      }
    },
    onCloseCreateActivity() {
      this.isAddActivity = false;
      this.isDirty = false;
    },
    onClickSaveCreateActivity() {
      const lessonId = this.lpStore.selectedLearningpath.lessons[
        this.selectedLessonIndex
      ]._id;
      this.createActivity(lessonId);
      this.selectGameType = '';
      this.selectGameId = '';
      this.onCloseCreateActivity();
    },
    onClickCreateActivity() {
      const lessonId = this.lpStore.selectedLearningpath.lessons[
        this.selectedLessonIndex
      ]._id;
      this.createActivity(lessonId);
    },
    onUpdateActivity() {
      this.isDirty = false;
      this.selectedActivityIndex = -1;
      this.updateActivity();
    },
    onClickDeleteLesson() {
      this.deleteLesson(
        this.lpStore.selectedLearningpath.lessons[this.selectedLessonIndex]._id,
      );
      this.selectedActivityIndex = -1;
      this.selectedLessonIndex = -1;
      this.selectGameId = '';
      this.selectGameType = '';
    },
    onClickDeleteUnit() {
      this.deleteUnit(this.lpStore.selectedLearningpath._id);
    },
    onClickUpdateLesson() {
      this.isDirty = false;
      this.selectedLessonIndex = -1;
      this.updateLesson();
      // this.lesson = { ...this.lpStore.updateLesson };
      // this.updateLessonData({
      //   key: 'id',
      //   value: this.selectLessonId,
      // });
    },
    onClickOpenCreateSubtitleVideoLocalization(vdoData) {
      const video_uri = vdoData.video_uri;
      let path = '/subtitle_video_localization';
      let routeData = this.$router.resolve({
        path: path,
        query: { video_uri: video_uri },
      });
      window.open(routeData.href, '_blank');
    },
    onClickOpenGameWindow(game_type, category_id, sub_id) {
      let path = '';
      switch (game_type) {
        case 'ESL':
          path = '/book/detail';
          break;
        case 'CN':
          path = '/connectingnode/detail';
          break;
        case 'CHR':
          path = '/galaxychat/detail';
          break;
        case 'DD':
          path = '/draganddrop/detail';
          break;
        case 'DD2':
          path = '/drag_and_drop_2/detail';
          break;
        case 'MC':
          path = '/matchcard/detail';
          break;
        case 'MEDIA':
          path = '/media/detail';
          break;
        case 'MPU':
          path = '/micpopup/detail';
          break;
        case 'TTS':
          path = '/taptosay/detail';
          break;
        case 'TR':
          path = '/trace/detail';
          break;
        case 'TB':
          path = '/tracebezier/detail';
          break;
        case 'WWA':
          path = '/wordwalk/detail';
          break;
        case 'MM':
          path = '/mixandmatch/detail';
          break;
        case 'SW':
          path = '/swipe/detail';
          break;
        case 'LB':
          path = '/labeling/detail';
          break;
        case 'MNC':
          path = '/minichat/list';
          break;
        case 'LM':
          path = '/learningmedia/detail';
          break;
        case 'FG':
          path = '/flyinggame/detail';
          break;
        case 'TS':
          path = '/tapstory/detail';
          break;
        case 'SP':
          path = '/slide_puzzle/detail';
          break;
        case 'WAM':
          path = '/whack_a_mole/detail';
          break;
        // case 'MG':
        //   path = '/maze_game/detail';
        //   break;
        case 'DC':
          path = '/drag_combo/detail';
          break;
        case 'SNAP':
          path = '/snap/detail';
          break;
        case 'RUNNINGGAME':
          path = '/running_game/detail';
          break;
        case 'DRIVINGGAME':
          path = '/driving_game/detail';
          break;
        case 'SEQUENCEGAME':
          path = '/sequence_game/detail';
          break;
        case 'QUIZ':
          path = '/quiz_game/detail';
          break;
        case 'LABYRINTH':
          path = '/labyrinth/detail';
          break;
        case 'SHOOTING_ARROW':
          path = '/shooting_arrow/detail';
          break;
        case 'COOKING_GAME':
          path = '/cooking_game/detail';
          break;
      }

      let routeData = this.$router.resolve({
        path: path,
        query: { category_id: category_id, sub_id: sub_id },
      });
      window.open(routeData.href, '_blank');
    },
    homeData(type) {
      this.isOpenHomeData = true;
      this.typeHomeData = type;
    },
    removeTag(item) {
      this.lpStore.selectedLearningpath.tags.splice(
        this.lpStore.selectedLearningpath.tags.indexOf(item),
        1,
      );
      this.lpStore.selectedLearningpath.tags = [
        ...this.lpStore.selectedLearningpath.tags,
      ];
    },
    openFromHomePage() {
      const lessonIndex = this.$route.query.lessonIndex,
        activityIndex = this.$route.query.activityIndex;
      if (lessonIndex && activityIndex) {
        const lessonId = this.lpStore.selectedLearningpath.lessons[lessonIndex]
          ._id;
        const activitiesId = this.lpStore.selectedLearningpath.lessons[
          lessonIndex
        ].activities[activityIndex]._id;
        this.onSelectLesson(lessonIndex, lessonId);
        this.onSelectActivity(activityIndex, activitiesId);
      }
    },
    templateAssignData() {
      this.isOpenLiveClassTemplate = true;
    },
  },
  beforeDestroy() {
    this.minichatVideoURIsStateWatcher();
  },
  async created() {
    await this.validateAndFetchAllGameType();
    await this.fetchSingleLP(this.$route.query.id);
    this.updateUnitData({
      key: 'id',
      value: this.$route.query.id,
    });
    this.watchMinichatVideoState();
    if (this.userData.role_id !== 99 && this.userData.role_id !== 100) {
      // delete GAME_TYPE == MG if not develop role dev
      constants.GAME_TYPE.splice(constants.GAME_TYPE.length - 1, 1);
    }
  },
  watch: {
    tags(val) {
      if ('tags' in this.lpStore.selectedLearningpath) {
        if (val.length > 10) {
          this.$nextTick(() => this.lpStore.selectedLearningpath.tags.pop());
        }
      }
    },
    'lpStore.selectedLearningpath'() {
      this.openFromHomePage();
    },
  },
};
</script>
