export default {
  CHARACTER: [
    {
      text: 'Nana',
      value: 0,
    },
    {
      text: 'Maxi',
      value: 1,
    },
    {
      text: 'Nana Airplane',
      value: 2,
    },
    {
      text: 'Maxi Airplane',
      value: 3,
    },
  ],
  ITEM_TYPE: [
    {
      text: 'Item',
      value: 0,
    },
    {
      text: 'Obstacle',
      value: 1,
    },
  ],
};
