import http from '../../utils/httpClient';
import moment from 'moment';
import swal from 'sweetalert2';
const state = {
  startNodeList: [],
  minichatData: {},
  minichats: [],
  minichatCategory: [],
  selectedCategoryId: '',
  selectedCategoryMinichat: '',
  selectedMinichatIndex: -1,
  minichat: {},
  nodeList: [],
  nodeData: {},
  gotoNodeData: {},
  selectMiniChat: {},
  updateNodeData: {},
  addMiniChatData: {
    name: '',
    main_character: 0,
    background_uri: '',
    position: null,
    size: null,
    node_list: [],
    skip_legacy_process: true,
  },
  addNodeData: {
    node_name: '',
    type: '',
    start_animation: 0,
    end_animation: 0,
    delay: '',
    word: [],
    goto_node: [],
    image_uri: [],
    audio_uri: '',
    main_character: 0,
    background_uri: '',
    front_img_uri: [],
    back_img_uri: [],
  },
  editImageData: {
    image_uri: [],
  },
  addImage: [],
  imageData: [],
  isUpdating: false,
  readMindMapData: [],
  updateMindMapData: {},
  createMindMapData: {},
  updateMiniChatData: {},
  spriteSheetData: {
    column: 1,
    row: 1,
    frame_ms: 0,
    loop_count: -1,
  },
};
const getters = {};
const actions = {
  resetMinichatDataState({ commit }) {
    commit('RESET_MINICHAT_DATA_STATE');
  },
  updateMindMapData({ commit, dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'info',
          message: 'Updating Mind Map',
        },
        {
          root: true,
        },
      );
      http
        .PUT(
          '/api/minichat/mindmap/update',
          { ...updateData },
          { isAuthorization: true },
        )
        .then(() => {
          commit('storeMindMapUpdateData', updateData);
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Update Complete',
            },
            {
              root: true,
            },
          );
        })
        .catch(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'error',
              message: 'Update Error',
            },
            {
              root: true,
            },
          );
        });
    }
  },

  updateEditImage({ commit }, { key, value, index }) {
    commit('storeEditImageData', {
      key,
      value,
      index,
    });
  },
  updateCreateImage({ commit }, { key, value, index }) {
    commit('storeCreateImageData', {
      key,
      value,
      index,
    });
  },
  updateCreateNode({ commit }, { key, value, index }) {
    commit('storeCreateNodeData', {
      key,
      value,
      index,
    });
  },
  updateCreateMiniChat({ commit }, { key, value }) {
    commit('storeCreateMiniChatData', {
      key,
      value,
    });
  },
  addMiniChat({ dispatch }, addMiniChatData) {
    dispatch(
      'appConfig/toggleAlert',
      {
        type: 'info',
        message: 'Creating Mini Chat',
      },
      {
        root: true,
      },
    );
    http
      .POST('/api/minichat', addMiniChatData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create Mini Chat Complete',
          },
          {
            root: true,
          },
        );
        // dispatch('fetchMiniChat', {});
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: error.response.data.message,
          },
          {
            root: true,
          },
        );
      });
  },
  updateMiniChat({ dispatch }, updateMiniChatData) {
    if (Object.keys(updateMiniChatData).length > 1) {
      dispatch(
        'appConfig/toggleAlert',
        {
          type: 'info',
          message: 'Creating minichat',
        },
        {
          root: true,
        },
      );
      http
        .PUT('/api/minichat', updateMiniChatData, {
          isAuthorization: true,
        })
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Update Mini Chat Complete',
            },
            {
              root: true,
            },
          );
          dispatch('fetchMinichatInCategory', {
            id: state.minichatData.category_id,
            sub_id: '',
          });
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'error',
              message: 'Update Error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearMinichatDataState({ commit }) {
    commit('clearMinichatDataState');
  },
  clearSelectedMiniChatState({ commit }) {
    commit('clearSelectedMiniChatState');
  },
  clearSelectedCategoryId({ commit }) {
    commit('clearSelectedCategoryId');
  },
  clearselectedCategoryMinichat({ commit }) {
    commit('clearselectedCategoryMinichat');
  },
  clearMiniChatStateCreation({ commit }) {
    commit('clearMiniChatStateCreation');
  },
  clearNodeStateCreation({ commit }) {
    commit('clearNodeStateCreation');
  },
  clearImageStateCreation({ commit }) {
    commit('clearImageStateCreation');
  },
  clearNodeDataState({ commit }) {
    commit('clearNodeDataState');
  },
  clearMindMapState({ commit }) {
    commit('clearMindMapState');
  },
  getMindMap({ commit, dispatch }, id) {
    http
      .POST(
        `/api/minichat/mindmap/request`,
        { minichat_id: id },
        { isAuthorization: true },
      )
      .then(({ data }) => {
        commit('storeMindMapRequestData', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot get mind map at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  upsertMindMap({ commit, dispatch }, id) {
    http
      .POST(
        `/api/minichat/mindmap/upsert`,
        { minichat_id: id },
        { isAuthorization: true },
      )
      .then(({ data }) => {
        commit('storeMindMapUpsertData', data);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot upsert mind map at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchMiniChat({ commit, dispatch }, { page = 1, limit = 0 }) {
    http
      .GET(`/api/minichat?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(minichat => ({
          ...minichat,
          created_at: moment(minichat.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(minichat.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('storeMinichat', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  selectCategory({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  selectCategoryMinichat({ commit }, id) {
    commit('storeSelectCategory', id);
  },
  fetchStartNode({ commit, dispatch }, minichatId) {
    http
      .GET(`/api/minichat/node/getNodeList?minichat_id=${minichatId}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const ANSWER_NODE = 1;
        let startNode = [];
        let filteredData = [...data.filter(node => node.type !== ANSWER_NODE)];
        filteredData.map(node => {
          startNode.push(node.node_name);
        });
        commit('storeStartNode', startNode);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch start node at this time. Please try again later.',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchNodeList({ commit, dispatch }, minichatId) {
    http
      .GET(`/api/minichat/node/getNodeList?minichat_id=${minichatId}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(nodes => ({
          ...nodes,
          created_at: moment(nodes.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(nodes.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('storeNodeList', formatData);
        commit('storeGotoNodeData', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchNodes({ commit, dispatch }, nodeId) {
    http
      .GET(`/api/minichat/node/getNode?_id=${nodeId}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = {
          ...data,
          created_at: moment(data.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(data.updated_at).format('YYYY MMM DD h:mm A'),
        };
        commit('storeNodeData', formatData);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleNodeById({ commit, dispatch }, id) {
    http
      .GET(`/api/minichat/node?_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(node => ({
          ...node,
          created_at: moment(node.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(node.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('storeMinichat', formatData);
        commit('storeSelectNode', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchSingleMinichat({ commit, dispatch }, id) {
    http
      .GET(`/api/minichat?_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(minichat => ({
          ...minichat,
          created_at: moment(minichat.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(minichat.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('storeMinichat', formatData);
        commit('storeSelectNode', id);
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  updateMiniChatDataCreation({ commit }, { key, value, index }) {
    commit('storeMiniChatDataCreation', {
      key,
      value,
      index,
    });
  },
  updateNodeData({ commit }, { key, value }) {
    commit('storeUpdateNodeData', {
      key,
      value,
    });
  },
  updateMiniChatData({ commit }, { key, value }) {
    commit('storeUpdateMiniChatData', {
      key,
      value,
    });
  },
  updateNode({ dispatch }, updateData) {
    if (Object.keys(updateData).length > 1) {
      http
        .PUT(
          '/api/minichat/node',
          {
            ...updateData,
          },
          {
            isAuthorization: true,
          },
        )
        .then(() => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Update Node Success',
              type: 'success',
            },
            {
              root: true,
            },
          );
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Failed to update node. Please try again',
              type: 'error',
            },
            {
              root: true,
            },
          );
        });
    }
  },
  clearUpdateNodeState({ commit }) {
    commit('clearUpdateNodeState');
  },
  clearUpdateMiniChatState({ commit }) {
    commit('clearUpdateMiniChatState');
  },
  deleteMiniChat({ dispatch }, id) {
    swal
      .fire({
        titleText: 'Confirm Delete Mini Chat',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/minichat?_id=${id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              // TODO: promise this.fetchNodeList(id);
            })
            .catch(err => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message:
                    'Cannot delete at this moment, please try again later !',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  deleteNode({ dispatch }, { node_id, minichat_id }) {
    swal
      .fire({
        titleText: 'Confirm Delete Node',
        showCancelButton: true,
        type: 'warning',
      })
      .then(result => {
        if (result.value) {
          http
            .DELETE(`/api/minichat/node?_id=${node_id}`, {
              isAuthorization: true,
            })
            .then(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Delete Successfully',
                  type: 'success',
                },
                { root: true },
              );
              dispatch('fetchNodeList', minichat_id);
            })
            .catch(() => {
              dispatch(
                'appConfig/toggleAlert',
                {
                  message: 'Failed delete!',
                  type: 'error',
                },
                { root: true },
              );
            });
        }
      });
  },
  addNode({ dispatch }, nodeData) {
    http
      .POST('/api/minichat/node', nodeData, {
        isAuthorization: true,
      })
      .then(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Node successfully created',
            type: 'success',
          },
          {
            root: true,
          },
        );
        dispatch('fetchNodeList', nodeData.minichat_id);
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: error.response.data.message,
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchMinichatCategory({ commit, dispatch }) {
    http
      .GET(`/api/minichat/getCategory`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(minichatCategory => ({
          ...minichatCategory,
          created_at: moment(minichatCategory.created_at).format(
            'YYYY MMM DD h:mm A',
          ),
          updated_at: moment(minichatCategory.updated_at).format(
            'YYYY MMM DD h:mm A',
          ),
        }));
        commit('storeMinichatCategory', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch category for minichat at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchMinichatInCategory({ commit, dispatch }, { id, sub_id }) {
    http
      .GET(`/api/minichat/getMinichats?category_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(minichats => ({
          ...minichats,
          created_at: moment(minichats.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(minichats.updated_at).format('YYYY MMM DD h:mm A'),
        }));
        commit('storeMinichats', formatData);
        commit('storeSelectedMinichat', { id, sub_id });
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch minichat in this category at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
  fetchMinichatData({ commit, dispatch }, id) {
    http
      .GET(`/api/minichat/getMinichatData?_id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = {
          ...data,
          created_at: moment(data.created_at).format('YYYY MMM DD h:mm A'),
          updated_at: moment(data.updated_at).format('YYYY MMM DD h:mm A'),
        };
        commit('storeMinichatData', formatData);
      })
      .catch(() => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch minichat data at this time. Please try again',
            type: 'error',
          },
          {
            root: true,
          },
        );
      });
  },
};
const mutations = {
  RESET_MINICHAT_DATA_STATE(state) {
    state.minichatData = {};
  },
  storeMinichatCategory(state, value) {
    if (value) {
      state.minichatCategory = value;
    } else {
      delete state.minichatCategory;
    }
  },
  storeMinichats(state, value) {
    if (value) {
      state.minichats = value;
    } else {
      delete state.minichats;
    }
  },
  storeNodeData(state, value) {
    if (value) {
      if (value.type === 6) {
        if (value.image_uri.length) {
          if (!('is_sprite_sheet' in value)) {
            value.is_sprite_sheet = [];
            value.image_uri.forEach(() => {
              value.is_sprite_sheet.push(false);
            });
          }
          if (!('sprite_sheet_data' in value)) {
            value.sprite_sheet_data = [];
            value.image_uri.forEach(() => {
              value.sprite_sheet_data.push(
                JSON.parse(JSON.stringify(state.spriteSheetData)),
              );
            });
          }
        } else {
          value.is_sprite_sheet = [];
          value.sprite_sheet_data = [];
          value.is_sprite_sheet.push(false);
          value.sprite_sheet_data.push(
            JSON.parse(JSON.stringify(state.spriteSheetData)),
          );
        }
      } else if (value.type === 8) {
        if ('item' in value) {
          if (value.item.length) {
            value.item.forEach(e => {
              if (!('is_sprite_sheet' in e)) {
                e.is_sprite_sheet = false;
              }
              if (!('sprite_sheet_data' in e)) {
                e.sprite_sheet_data = JSON.parse(
                  JSON.stringify(state.spriteSheetData),
                );
              }
            });
          }
        } else {
          value.item = [];
          value.item.push({
            name: '',
            image_uri: '',
            sound_uri: '',
            detect_animation: 0,
            receive_animation: 0,
            is_sprite_sheet: false,
            sprite_sheet_data: JSON.parse(
              JSON.stringify(state.spriteSheetData),
            ),
          });
        }
      } else if (value.type === 9) {
        if ('item' in value) {
          if (value.item.length) {
            value.item.forEach(e => {
              if (!('is_sprite_sheet' in e)) {
                e.is_sprite_sheet = false;
              }
              if (!('sprite_sheet_data' in e)) {
                e.sprite_sheet_data = JSON.parse(
                  JSON.stringify(state.spriteSheetData),
                );
              }
            });
          }
        } else {
          value.item = [];
          value.item.push({
            name: '',
            image_uri: '',
            sound_uri: '',
            is_sprite_sheet: false,
            sprite_sheet_data: JSON.parse(
              JSON.stringify(state.spriteSheetData),
            ),
          });
        }
      }
      state.nodeData = value;
    } else {
      delete state.nodeData;
    }
  },
  storeMinichatData(state, value) {
    if (value) {
      state.minichatData = value;
    } else {
      delete state.minichatData;
    }
  },
  storeSelectCategory(state, value) {
    if (value) {
      state.selectedCategoryId = value;
    } else {
      delete state.selectedCategoryId;
    }
  },
  storeSelectCategoryMinichat(state, value) {
    if (value) {
      state.selectedCategoryMinichat = value;
    } else {
      delete state.selectedCategoryMinichat;
    }
  },
  storeSelectedMinichat(state, { id, sub_id }) {
    if (sub_id) {
      const minichatIndex = state.minichats.findIndex(
        minichat => minichat.minichat_id === sub_id,
      );
      state.selectedMinichatIndex = minichatIndex;
    }
  },
  storeMindMapRequestData(state, mindmap) {
    mindmap.forEach(mindmap => {
      state.readMindMapData = mindmap;
    });
  },
  storeMindMapUpsertData(state, mindmap) {
    state.createMindMapData = mindmap;
  },
  storeMindMapUpdateData(state, mindmap) {
    state.updateMindMapData = mindmap;
  },
  storeCreateMiniChatData(state, { key, value }) {
    state.addMiniChatData = {
      ...state.addMiniChatData,
      [key]: value,
    };
  },
  storeCreateNodeData(state, { key, value, index }) {
    state.addNodeData[index] = {
      ...state.addNodeData[index],
      [key]: value,
    };
  },
  storeEditImageData(state, { key, value, index }) {
    state.editImageData[index] = {
      ...state.editImageData[index],
      [key]: value,
    };
  },
  storeCreateImageData(state, { key, value, index }) {
    state.imageData[index] = {
      ...state.imageData[index],
      [key]: value,
    };
  },

  storeUpdateMiniChatData(state, { key, value }) {
    state.updateMiniChatData = {
      ...state.updateMiniChatData,
      [key]: value,
    };
  },
  clearMinichatDataState(state) {
    state.minichatData = {};
  },
  clearMiniChatStateCreation(state) {
    state.addMiniChatData = {};
  },
  clearNodeStateCreation(state) {
    state.addNodeData = {
      node_name: '',
      type: '',
      start_animation: 0,
      end_animation: 0,
      delay: '',
      word: [],
      goto_node: [],
      image_uri: [],
      audio_uri: '',
      main_character: 0,
      background_uri: '',
      front_img_uri: [],
      back_img_uri: [],
    };
  },
  clearImageStateCreation(state) {
    state.imageData = [];
  },
  clearNodeListState(state) {
    state.nodeList = [];
  },
  clearNodeDataState(state) {
    state.nodeData = {};
  },
  clearMindMapState(state) {
    state.readMindMapData = [];
  },
  clearUpdateNodeState(state) {
    state.updateNodeData = {};
  },
  clearUpdateMiniChatState(state) {
    state.updateMiniChatData = {};
  },
  clearSelectedMiniChatState(state) {
    state.selectMiniChat = {};
  },
  clearSelectedCategoryId(state) {
    state.selectedCategoryId = '';
  },
  toggleStoreUpdate(state) {
    state.isUpdating = !state.isUpdating;
  },
  storeMinichat(state, minichatList) {
    state.minichat = minichatList;
  },
  storeStartNode(state, startNodeList) {
    state.startNodeList = startNodeList;
  },
  storeNodeList(state, nodeList) {
    state.nodeList = nodeList;
  },
  storeGotoNodeData(state, nameList) {
    state.gotoNodeData = nameList;
  },
  storeSelectNode(state, value) {
    if (value) {
      const index = state.nodes.findIndex(nodes => nodes._id === value);
      state.selectMiniChat = state.nodes[index];
    } else {
      delete state.selectMiniChat;
    }
  },
  storeUpdateNodeData(state, { key, value }) {
    state.updateNodeData = {
      ...state.updateNodeData,
      [key]: value,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
