<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Localization Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex>
            <v-autocomplete
              label="Select Language"
              v-model="selectedLanguage"
              :items="languageList"
              chips
              hide-details
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-flex>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div v-if="userLevel === 99">
            <v-btn color="success" @click="onClickAddLanguage()">
              Create
            </v-btn>
          </div>
          <v-btn color="primary" @click="uploadToS3()"> Upload </v-btn>
          <v-btn color="orange" @click="publishToProduction()">
            Publish-Production
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-title>
            <v-text-field
              v-if="selectedLanguage === 'Arabic'"
              dir="rtl"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-text-field
              v-if="selectedLanguage !== 'Arabic'"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <p
              v-if="selectedLanguage !== 'English'"
              color="teal"
              @click="fetchAllRef()"
            >
              Fetch All Reference
            </p>
          </v-card-title>
          <v-data-table
            :headers="header"
            :items="languageStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.created_at }}</td>
              <td class="text-xs-center">{{ props.item.updated_at }}</td>
              <td class="text-xs-center">{{ props.item.ID }}</td>
              <td style="text-align: -webkit-center">
                <v-img
                  class="image_thumbnail"
                  :src="props.item.image_uri"
                  @click="fullscreenImage(props.item.image_uri)"
                />
              </td>
              <td class="text-xs-center">
                {{ props.item.English }}
              </td>
              <td class="text-xs-center">
                {{ props.item[selectedLanguage] }}
              </td>
              <td class="justify-center layout px-0">
                <v-icon
                  small
                  class="mr-2"
                  @click="onClickEditLanguage(props.item)"
                  >edit</v-icon
                >
                <v-icon
                  v-if="userLevel === 99"
                  small
                  @click="onClickDeleteLanguage(props.item._id, props.item.ID)"
                  >delete</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-flex>
    <!-- Edit Modal -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      title="Edit Language"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                readonly
                label="ID"
                :value="language.ID"
                v-model="language.ID"
              ></v-text-field>
              <div v-if="selectedLanguage === 'Arabic'">
                <v-textarea
                  dir="rtl"
                  :label="selectedLanguage"
                  :value="language.editor"
                  v-model="language.editor"
                  @change="v => onChangeEditLanguage(selectedLanguage, v)"
                ></v-textarea>
              </div>
              <div v-else>
                <v-textarea
                  :label="selectedLanguage"
                  :value="language.editor"
                  v-model="language.editor"
                  @change="v => onChangeEditLanguage(selectedLanguage, v)"
                ></v-textarea>
              </div>
              <v-flex v-if="userLevel === 99">
                <img
                  v-if="language.image_uri"
                  :src="language.image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Context Image Reference URI"
                  :value="language.image_uri"
                  v-model="language.image_uri"
                  @change="v => onChangeEditLanguage('image_uri', v)"
                ></v-text-field>
                <v-btn v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <ResourceSelect
            v-if="isEditImage"
            resourceType="image"
            :isShow="isEditImage"
            :close="closeEditImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
    <!-- Add Modal -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="onCloseCreate"
      :isLastStep="valid && validKeyStore && validateDuplicateKey.isChecking"
      title="Create Language"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  required
                  :rules="requiredRule"
                  :error-messages="validateDuplicateKey.answer"
                  label="ID"
                  :value="language.ID"
                  v-model="language.ID"
                  @change="v => onChangeAddLanguage('ID', v)"
                ></v-text-field>
                <v-textarea
                  :rules="requiredRule"
                  :error-messages="errorMessages"
                  label="English"
                  :value="language.English"
                  v-model="language.English"
                  @change="v => onChangeAddLanguage('English', v)"
                ></v-textarea>
                <v-flex v-if="userLevel === 99">
                  <img
                    v-if="language.image_uri"
                    :src="language.image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Context Image Reference URI"
                    :value="language.image_uri"
                    v-model="language.image_uri"
                    @change="v => onChangeAddLanguage('image_uri', v)"
                  ></v-text-field>
                  <v-btn v-on:click="isAddImage = true">
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </v-flex>
              </v-flex>
            </v-layout>
            <ResourceSelect
              v-if="isAddImage"
              resourceType="image"
              :isShow="isAddImage"
              :close="closeAddImage"
              :save="saveImage"
              :value="selectImageUri"
              :onSelect="onSelectImage"
            />
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar dark>
          <v-icon white @click="dialog = false">close</v-icon>
          | Press [esc] key to exit full screen
        </v-toolbar>
        <v-img :src="img" />
      </v-card>
    </v-dialog>
  </Container>
</template>
<script>
import { saveAs } from 'file-saver';
import { mapState, mapActions } from 'vuex';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import Container from '../../components/Container';
import { LANGUAGES } from '../../constants/localization/languageList';
import _ from 'lodash';
import swal from 'sweetalert2';
export default {
  name: 'Localization',
  components: {
    ResourceSelect,
    Container,
    Modal,
  },
  computed: {
    ...mapState({
      languageStore: state => state.localization.language,
      updatedLanguageStore: state => state.localization.updateLanguage,
      updatedLanguagePrevStore: state => state.localization.updateLanguagePrev,
      languageJSONtoCSVStore: state => state.localization.languageJSONtoCSV,
      createdLanguageStore: state => state.localization.createLanguage,
      userLevel: state => state.userInfo.userData.role_id,
      validKeyStore: state => state.localization.isValidKey,
      uploadResultStore: state => state.localization.uploadResult,
    }),
    form() {
      return {
        ID: this.language.ID,
      };
    },
  },
  data() {
    return {
      isDirty: false,
      validateDuplicateKey: {
        answer: 'Please enter the key.',
        isChecking: null,
      },
      img: '',
      dialog: false,
      requiredRule: [v => !!v || 'Required, cannot be empty.'],
      errorMessages: '',
      valid: false,
      isAddImage: false,
      isEditImage: false,
      selectImageUri: '',
      language: {},
      search: '',
      isCreate: false,
      isEdit: false,
      editedIndex: -1,
      clearable: true,
      pagination: {
        rowsPerPage: 25,
      },
      selectedLanguage: '',
      languageList: LANGUAGES,
      header: [
        {
          text: 'Created At',
          value: 'created_at',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Updated At',
          value: 'updated_at',
          align: 'center',
          sortable: true,
        },
        {
          text: 'ID',
          value: 'ID',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Context Image Reference',
          value: 'image_uri',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Reference Language',
          value: 'English',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Current Language',
          value: '',
          align: 'center',
          sortable: false,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions('localization', [
      'fetchLanguage',
      'uploadLanguageDataToS3',
      'addLanguage',
      'editLanguage',
      'deleteLanguage',
      'stateCreateLanguage',
      'stateEditLanguage',
      'statePreviousData',
      'clearCreateState',
      'clearUpdateState',
      'clearUpdatePrevState',
      'publishToProduction',
      'checkDuplicateKey',
      'fetchAllReference',
    ]),
    fetchAllRef() {
      swal
        .fire({
          titleText: `Fetch all reference`,
          text: `This operation will replace all empty of ${this.selectedLanguage} with English.`,
          confirmButtonColor: 'primary',
          confirmButtonText: 'Replace with English',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
          type: 'warning',
        })
        .then(async response => {
          if (response.value) {
            await this.fetchAllReference(this.selectedLanguage);
          }
        });
    },
    async alertUpload(languageToUpload, languageToSwitch) {
      swal
        .fire({
          titleText: `Changes Detected, Please confirm upload`,
          text: 'You should upload this language file before switch to other languages.',
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          cancelButtonText: 'Later',
          showCancelButton: true,
          type: 'warning',
        })
        .then(async response => {
          if (response.value) {
            await this.uploadLanguageDataToS3(languageToUpload);
            await this.fetchLanguage(languageToSwitch);
          } else {
            await this.fetchLanguage(languageToSwitch);
          }
        });
    },
    searchLocalize(word) {
      this.search = word;
      this.$router.push({});
    },
    validateKey() {
      let key = this.language.ID;
      this.checkDuplicateKey(key);
    },
    validateKeyFlag() {
      if (!this.validKeyStore) {
        this.validateDuplicateKey.answer = 'Duplicate ID is detected.';
      } else {
        this.validateDuplicateKey.answer = '';
        this.validateDuplicateKey.isChecking = true;
      }
    },
    fullscreenImage(image) {
      if (image) {
        this.dialog = true;
        this.img = image;
      }
    },
    async uploadToS3() {
      const resultUploadToS3 = await this.uploadLanguageDataToS3(
        this.selectedLanguage,
      );
    },
    saveFileLocally() {
      let blob = new Blob([this.languageJSONtoCSVStore], {
        type: 'text/csv',
      });
      const filename = `${this.selectedLanguage}.csv`;
      saveAs(blob, filename);
    },
    onClickAddLanguage() {
      this.language = {};
      this.isCreate = true;
    },
    onChangeAddLanguage(key, value) {
      this.stateCreateLanguage({ key, value });
    },
    onSaveCreate() {
      this.addLanguage({
        languageData: this.createdLanguageStore,
        selectedLanguage: this.selectedLanguage,
      });
      this.isCreate = false;
    },
    onCloseCreate() {
      this.isCreate = false;
      this.clearCreateState();
    },
    onClickEditLanguage(item) {
      this.statePreviousData({ key: '_id', value: item._id });
      if (item[this.selectedLanguage]) {
        this.statePreviousData({
          key: this.selectedLanguage,
          value: item[this.selectedLanguage],
        });
      } else {
        this.statePreviousData({
          key: this.selectedLanguage,
          value: '',
        });
      }
      this.stateEditLanguage({ key: '_id', value: item._id });
      this.isEdit = true;
      this.editedIndex = this.languageStore.indexOf(item);
      this.language = Object.assign({}, item);
      this.language.editor = this.language[this.selectedLanguage];
    },
    onChangeEditLanguage(key, value) {
      this.stateEditLanguage({ key, value });
    },
    onSaveEdit() {
      const updateData = {
        from: this.updatedLanguagePrevStore,
        to: this.updatedLanguageStore,
      };
      this.editLanguage({
        updateData: updateData,
        selectedLanguage: this.selectedLanguage,
      });
      this.isDirty = true;
      this.onCloseEdit();
    },
    onCloseEdit() {
      this.clearUpdatePrevState();
      this.clearUpdateState();
      this.isEdit = false;
    },
    onClickDeleteLanguage(id, ID) {
      this.deleteLanguage({
        id: id,
        ID: ID,
        selectedLanguage: this.selectedLanguage,
      });
    },
    closeAddImage() {
      this.selectImageUri = '';
      this.isAddImage = !this.isAddImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveImage() {
      const uri = this.selectImageUri;
      if (this.isAddImage) {
        this.stateCreateLanguage({
          key: 'image_uri',
          value: uri,
        });
        this.isAddImage = !this.isAddImage;
      } else if (this.isEditImage) {
        this.stateEditLanguage({
          key: 'image_uri',
          value: uri,
        });
        this.isEditImage = !this.isEditImage;
      }
      this.language.image_uri = uri;
      this.selectImageUri = '';
    },
  },
  created() {
    this.debouncedValidateKey = _.debounce(this.validateKey, 500);
    this.debouncedErrorMessage = _.debounce(this.validateKeyFlag, 900);
  },
  mounted() {
    this.selectedLanguage = 'English';
    this.fetchLanguage(this.selectedLanguage);
    if (this.$route.query.viewLocalize) {
      const word = this.$route.query.viewLocalize;
      this.searchLocalize(word);
    }
  },
  watch: {
    selectedLanguage(newValue, oldValue) {
      const languageToUpload = oldValue;
      const languageToSwitch = newValue;
      this.header[5].text = this.selectedLanguage;
      this.header[5].value = this.selectedLanguage;
      if (this.isDirty) {
        this.alertUpload(languageToUpload, languageToSwitch);
        this.isDirty = false;
      } else {
        this.fetchLanguage(languageToSwitch);
      }
    },
    uploadResultStore(data) {
      if (data.status === 200) {
        this.isDirty = false;
      }
    },
    'form.ID'() {
      this.validateDuplicateKey.answer = 'Checking key now...';
      this.validateDuplicateKey.isChecking = false;
      this.debouncedValidateKey();
      this.debouncedErrorMessage();
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 50px;
  width: 50px;
  margin-top: 0px;
}
.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
</style>
