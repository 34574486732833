<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn icon color="white" light v-on:click="$router.push('/library')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            (libraryStore.selectLibrary &&
              libraryStore.selectLibrary.category) ||
            'Loading...'
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="libraryStore.selectLibrary">
          <v-layout row grid-list-md v-if="!libraryStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Group</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddGroup">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(group, index) in libraryStore.selectLibrary.groups"
                    :key="group._id"
                    v-on:click="onSelectGroup(group._id)"
                    :class="
                      index === selectedGroupIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ group.name }}</v-list-tile-title>
                      <!-- <v-list-tile-sub-title
                        v-if="group.words.length > 1"
                      >{{ group.words.length }} words</v-list-tile-sub-title>
                      <v-list-tile-sub-title v-else>{{ group.words.length }} word</v-list-tile-sub-title>-->
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedGroupIndex != -1">
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark>
                    <v-toolbar-title>Group Detail</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green"
                      :disabled="!valid"
                      v-on:click="onClickUpdateGroup"
                    >
                      <v-icon>save</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-layout column>
                      <v-flex>
                        <v-checkbox
                          label="Available for Free User"
                          v-model="group.isGuestPlayable"
                          :value="group.isGuestPlayable"
                          v-on:change="
                            e => onChangeGroupData('isGuestPlayable', e)
                          "
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Group Name"
                          :value="group.name"
                          :rules="[rules.required]"
                          v-on:change="e => onChangeGroupData('name', e)"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      color="red"
                      dark
                      v-on:click="onClickDeleteGroup(group._id)"
                    >
                      <v-icon>delete</v-icon>Delete this group
                    </v-btn>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="words.length > 0 && selectedGroupIndex != -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Word</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddWord">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(word, index) in libraryStore.selectLibrary.groups[
                      selectedGroupIndex
                    ].words"
                    :key="word._id"
                    v-on:click="onSelectWord(word._id)"
                    :class="index === selectedWordIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ index + 1 }}. {{ word.word }}</v-list-tile-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="Object.keys(word).length > 1 && selectedGroupIndex != -1"
              xs4
              mr-2
            >
              <v-card dark>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark>
                    <v-toolbar-title>Word Detail</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green"
                      :disabled="!valid"
                      v-on:click="onClickSaveWord"
                    >
                      <v-icon>save</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-layout column>
                      <v-flex>
                        <v-text-field
                          disabled
                          ref="word"
                          label="Word"
                          v-model="word.word"
                          :rules="[rules.required, rules.wordEN]"
                          v-on:change="e => onChangeWordData('word', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          ref="wordEN"
                          label="EN"
                          v-model="word.en"
                          :rules="[rules.wordEN]"
                          v-on:change="e => onChangeWordData('en', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          ref="wordTH"
                          label="TH"
                          v-model="word.th"
                          v-on:change="e => onChangeWordData('th', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="CH"
                          v-model="word.ch"
                          v-on:change="e => onChangeWordData('ch', e)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex>
                        <img
                          v-if="word.img_uri"
                          :src="word.img_uri"
                          class="content_img"
                          :style="previewBackgroundColor"
                        />
                        <v-text-field
                          label="Image"
                          :value="word.img_uri"
                          v-on:change="e => onChangeWordData('img_uri', e)"
                        />
                        <v-btn
                          color="black"
                          dark
                          v-on:click="isEditImage = true"
                        >
                          <v-icon>image</v-icon>Edit Image
                        </v-btn>
                        <!-- <v-checkbox
                          v-model="word.isHasAnimation"
                          label="Animation"
                          v-on:change="
                            e => onChangeWordData('isHasAnimation', e)
                          "
                        ></v-checkbox> -->
                      </v-flex>
                      <!-- <v-layout v-if="word.isHasAnimation">
                        <v-flex>
                          <v-text-field
                            type="number"
                            label="Image per columns (minimun 1)"
                            :value="word.columns"
                            v-model.number="word.columns"
                            :rules="[rules.numberRule]"
                            v-on:change="
                              onChangeWordData('columns', word.columns)
                            "
                          ></v-text-field>
                          <v-text-field
                            type="number"
                            label="Image per rows (minimun 1)"
                            :value="word.rows"
                            v-model.number="word.rows"
                            :rules="[rules.numberRule]"
                            v-on:change="onChangeWordData('rows', word.rows)"
                          ></v-text-field>
                          <v-text-field
                            type="number"
                            label="Loop count (minimun -1)"
                            :value="word.loop_count"
                            v-model.number="word.loop_count"
                            :rules="[rules.numberRuleLoop]"
                            v-on:change="
                              onChangeWordData('loop_count', word.loop_count)
                            "
                          ></v-text-field>
                          <v-text-field
                            type="number"
                            label="frame ms"
                            :value="word.frame_ms"
                            v-model.number="word.frame_ms"
                            v-on:change="
                              onChangeWordData('frame_ms', word.frame_ms)
                            "
                          ></v-text-field>
                          <v-container grid-list-xs text-xs-center>
                            <img
                              v-if="word.ani_uri"
                              :src="word.ani_uri ? word.ani_uri : ''"
                              class="content_img"
                              :style="previewBackgroundColor"
                            />
                          </v-container>
                          <v-text-field
                            label="Animation Image"
                            :value="word.ani_uri"
                            v-model="word.ani_uri"
                            v-on:change="e => onChangeWordData('ani_uri', e)"
                          />
                          <span class="headline" dark>
                            <v-btn
                              color="black"
                              dark
                              v-on:click="
                                isEditImage = true;
                                isEditAnimation = true;
                              "
                            >
                              <v-icon>image</v-icon>Edit Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout> -->

                      <v-flex>
                        <v-autocomplete
                          label="Background Color"
                          v-model="word.bg_color"
                          :items="colorStore"
                          item-text="color_name"
                          item-value="hex"
                          v-on:change="e => onChangeWordData('bg_color', e)"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Sound"
                          :value="word.sound_uri"
                          v-on:change="e => onChangeWordData('sound_uri', e)"
                        />
                        <v-btn
                          color="black"
                          dark
                          v-on:click="isEditSound = true"
                        >
                          <v-icon>audiotrack</v-icon>Edit Sound
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      color="red"
                      :disabled="lastWordInGroup"
                      dark
                      v-on:click="onClickDeleteWord(group._id, word._id)"
                    >
                      <v-icon>delete</v-icon>Delete this word
                    </v-btn>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <Modal
      :v-if="isAddGroup"
      :isOpen="isAddGroup"
      :close="toggleAddGroup"
      :save="onClickAddGroup"
      :isLastStep="
        isGroupBtnSave && isWordBtnSave && valid && validWordStore && isChecking
      "
      title="Add Group"
    >
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-checkbox
                label="Available for Free User"
                v-model="group.isGuestPlayable"
                :value="group.isGuestPlayable"
                v-on:change="e => onChangeCreateGroup('isGuestPlayable', e)"
              >
              </v-checkbox>
              <v-text-field
                label="Group Name"
                :value="group.name"
                v-model="group.name"
                :rules="[rules.required]"
                @input="e => onChangeCreateGroup('name', e)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-toolbar dark>
            <v-toolbar-title>Word</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-text-field
                ref="word"
                label="Word"
                :value="word.word"
                v-model="word.word"
                :rules="[rules.required, rules.word, rules.wordEN]"
                :error-messages="answer"
                :append-icon="
                  !!form.word && validWordStore && isChecking ? 'done' : 'error'
                "
                @input="e => onChangeCreateWord('word', e)"
              ></v-text-field>
              <v-btn small color="green" v-on:click="toggleDictionary">
                <v-icon>arrow_right_alt</v-icon>Add Existing Word
              </v-btn>
            </v-flex>
            <v-flex>
              <v-text-field
                ref="wordEN"
                label="EN"
                :value="word.en"
                v-model="word.en"
                v-on:change="e => onChangeCreateWord('en', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                ref="wordTH"
                label="TH"
                :value="word.th"
                v-model="word.th"
                v-on:change="e => onChangeCreateWord('th', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="CH"
                :value="word.ch"
                v-model="word.ch"
                v-on:change="e => onChangeCreateWord('ch', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-container grid-list-xs text-xs-center>
                <img
                  v-if="word.img_uri"
                  :src="word.img_uri ? word.img_uri : ''"
                  class="content_img"
                  :style="previewBackgroundColor"
                />
              </v-container>
              <v-text-field
                label="Image"
                :value="word.img_uri"
                v-on:change="e => onChangeCreateWord('img_uri', e)"
              />
              <span class="headline" dark>
                <v-btn color="black" dark v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Edit Image
                </v-btn>
              </span>
            </v-flex>
            <v-flex>
              <v-autocomplete
                label="Background Color"
                v-model="word.bg_color"
                :value="word.bg_color"
                :items="colorStore"
                item-text="color_name"
                item-value="hex"
                v-on:change="e => onChangeCreateWord('bg_color', e)"
              ></v-autocomplete>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Sound"
                :value="word.sound_uri"
                v-model="word.sound_uri"
                v-on:change="e => onChangeCreateWord('sound_uri', e)"
              />
              <v-btn color="black" dark v-on:click="isEditSound = true">
                <v-icon>audiotrack</v-icon>Edit Sound
              </v-btn>
            </v-flex>
          </v-card-text>
        </v-form>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddWord"
      :isOpen="isAddWord"
      :close="onClickCloseAddWord"
      :save="onClickAddWord"
      title="Add Word"
      :isLastStep="isWordBtnSave && valid && validWordStore && isChecking"
    >
      <br />
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-flex>
            <v-text-field
              label="Word"
              v-model="word.word"
              :value="word.word"
              :rules="[rules.required, rules.word, rules.wordEN]"
              :error-messages="answer"
              :append-icon="
                !!form.word && validWordStore && isChecking ? 'done' : 'error'
              "
              @input="e => onChangeCreateWord('word', e)"
            ></v-text-field>
            <v-btn small color="green" v-on:click="toggleDictionary">
              <v-icon>arrow_right_alt</v-icon>Add Existing Word
            </v-btn>
          </v-flex>
          <v-flex>
            <v-text-field
              label="EN"
              v-model="word.en"
              :value="word.en"
              v-on:change="e => onChangeCreateWord('en', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="TH"
              v-model="word.th"
              :value="word.th"
              v-on:change="e => onChangeCreateWord('th', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="CH"
              v-model="word.ch"
              :value="word.ch"
              v-on:change="e => onChangeCreateWord('ch', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-container grid-list-xs text-xs-center>
              <img
                v-if="word.img_uri"
                :src="word.img_uri ? word.img_uri : ''"
                class="content_img"
                :style="previewBackgroundColor"
              />
            </v-container>
            <v-text-field
              label="Image"
              :value="word.img_uri"
              v-model="word.img_uri"
              v-on:change="e => onChangeCreateWord('img_uri', e)"
            />
            <span class="headline" dark>
              <v-btn color="black" dark v-on:click="isEditImage = true">
                <v-icon>image</v-icon>Edit Image
              </v-btn>
            </span>
            <v-checkbox
              v-model="word.isHasAnimation"
              label="Animation"
              v-on:change="
                e => {
                  onChangeCreateWord('isHasAnimation', e);
                  createUpdateDataAnimation('create');
                }
              "
            ></v-checkbox>
            <v-layout v-if="word.isHasAnimation">
              <v-flex>
                <v-text-field
                  type="number"
                  label="Image per columns (minimun 1)"
                  :value="1"
                  :rules="[rules.numberRule]"
                  v-on:change="e => onChangeCreateWord('columns', parseInt(e))"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="Image per rows (minimun 1)"
                  :value="1"
                  :rules="[rules.numberRule]"
                  v-on:change="e => onChangeCreateWord('rows', parseInt(e))"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="Loop count (minimun -1)"
                  :value="-1"
                  :rules="[rules.numberRuleLoop]"
                  v-on:change="
                    e => onChangeCreateWord('loop_count', parseInt(e))
                  "
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="frame ms"
                  :value="200"
                  v-on:change="e => onChangeCreateWord('frame_ms', parseInt(e))"
                ></v-text-field>
                <v-container grid-list-xs text-xs-center>
                  <img
                    v-if="word.ani_uri"
                    :src="word.ani_uri ? word.ani_uri : ''"
                    class="content_img"
                    :style="previewBackgroundColor"
                  />
                </v-container>
                <v-text-field
                  label="Animation Image"
                  :value="word.ani_uri"
                  v-model="word.ani_uri"
                  v-on:change="e => onChangeCreateWord('ani_uri', e)"
                />
                <span class="headline" dark>
                  <v-btn
                    color="black"
                    dark
                    v-on:click="
                      isEditImage = true;
                      isAddAnimation = true;
                    "
                  >
                    <v-icon>image</v-icon>Edit Image
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-autocomplete
              label="Background Color"
              v-model="word.bg_color"
              :value="word.bg_color"
              :items="colorStore"
              item-text="color_name"
              item-value="hex"
              v-on:change="e => onChangeCreateWord('bg_color', e)"
            ></v-autocomplete>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Sound"
              :value="word.sound_uri"
              v-model="word.sound_uri"
              v-on:change="e => onChangeCreateWord('sound_uri', e)"
            />
            <v-btn color="black" dark v-on:click="isEditSound = true">
              <v-icon>audiotrack</v-icon>Edit Sound
            </v-btn>
          </v-flex>
        </v-form>
      </v-card-text>
    </Modal>
    <Dictionary
      :v-if="isAddDictionary"
      :isOpen="isAddDictionary"
      :close="isAddWord ? onClickCloseAddWord : onClickCloseAddGroup"
      :onSelect="isAddWord ? onClickAddExistingWord : onClickAddExistingGroup"
      title="Select Existing Word"
    >
      <v-container>
        <v-btn color="green" v-on:click="toggleDictionary">
          <v-icon>arrow_right_alt</v-icon>Add New Word
        </v-btn>
      </v-container>
    </Dictionary>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  background-color: var(--bg-color);
}
</style>

<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Dictionary from '../../components/Dictionary';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import _ from 'lodash';

export default {
  name: 'Library',
  components: {
    Container,
    ResourceSelect,
    Dictionary,
    Modal,
  },
  computed: {
    ...mapState({
      libraryStore: state => state.library,
      colorStore: state => state.library.colorData,
      validWordStore: state => state.library.isValidWord,
    }),
    form() {
      return {
        word: this.word.word,
        wordEN: this.word.en,
        wordTH: this.word.th,
      };
    },
    previewBackgroundColor() {
      return {
        '--bg-color': this.bgColor || '',
      };
    },
  },
  data: function () {
    return {
      answer: 'Please enter the word.',
      words: [],
      word: {},
      group: {},
      selectedGroupIndex: -1,
      selectedWordIndex: -1,
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddWord: false,
      isAddDictionary: false,
      isAddGroup: false,
      isUpdateGroup: false,
      constants,
      selectGroupId: '',
      valid: false,
      lastWordInGroup: false,
      bgColor: '',
      isChecking: false,
      isGroupBtnSave: false,
      isWordBtnSave: false,
      isAddAnimation: false,
      isEditAnimation: false,
      rules: {
        required: value => !!value || 'Required',
        word: value => {
          const pattern = /[\w]/g;
          return pattern.test(value) || 'Invalid word';
        },
        wordEN: value => {
          const pattern = /^[^-\sก-๛๐-๙0-9._#-][a-zA-Z0-9._#-\s-]*$/gm;
          return pattern.test(value) || 'Invalid word';
        },
        wordTH: value => {
          const pattern = /^[^-\sa-zA-Z0-9._#-][ก-๛๐-๙0-9._#-\s-]*$/gm;
          return pattern.test(value) || 'Invalid word';
        },
        numberRule: value => {
          if (!isNaN(parseFloat(value)) && value >= 1) return true;
          return 'The number must be greater than 1 or 1';
        },
        numberRuleLoop: value => {
          if (!isNaN(parseFloat(value)) && value >= -1) return true;
          return 'The number must be greater than -1 or -1';
        },
        // numberRuleFrame: value => {
        //   if (!isNaN(parseFloat(value)) && value >= 400) return true;
        //   return 'The number must be greater than 400 or 400';
        // },
      },
    };
  },
  watch: {
    'form.word'() {
      this.answer = 'Checking word now...';
      this.isChecking = false;
      this.debouncedValidateWord();
      this.debouncedErrorMessage();
    },
  },
  methods: {
    ...mapActions('library', [
      'fetchLibrary',
      'fetchSingleLibrary',
      'updateWordData',
      'updateWord',
      'clearUpdateData',
      'deleteGroup',
      'deleteWord',
      'updateCreateGroupData',
      'updateCreateWordData',
      'updateCreateSingleWordData',
      'updateGroupData',
      'updateGroup',
      'addGroup',
      'addWordByGroupId',
      'addExistingWordByGroupId',
      'selectCategory',
      'selectLibrary',
      'fetchColorData',
      'validateDuplicateWord',
    ]),
    maskToLowerCase(value) {
      if (!value) return '';
      value = value.toString();
      return value.toLocaleLowerCase();
    },
    validateWord() {
      this.validateDuplicateWord(this.word.word);
    },
    validateWordFlag() {
      if (!this.validWordStore) {
        this.answer = 'Duplicate word is detected.';
      } else {
        this.answer = '';
        this.isChecking = true;
      }
    },
    onClickLastword() {
      this.lastWordInGroup = !this.lastWordInGroup;
    },
    validateLastWordLeft() {
      let groupStore =
        this.libraryStore.selectLibrary.groups[this.selectedGroupIndex].words;
      if (groupStore.length === 1) {
        this.lastWordInGroup = true;
      } else {
        this.lastWordInGroup = false;
      }
    },
    onSelectGroup(id) {
      const groups = this.libraryStore.selectLibrary.groups;
      const index = groups.findIndex(group => group._id === id);
      this.selectedGroupIndex = index;
      this.selectedWordIndex = -1;
      this.words = [];
      this.word = {};
      this.words = [...groups[index].words];
      this.group = {
        ...groups[index],
      };
      this.updateGroupData({
        key: '_id',
        value: id,
      });
      this.selectGroupId = id;
      this.validateLastWordLeft();
    },
    onSelectWord(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this word yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateData();
              this.isDirty = false;
              const words = [
                ...this.libraryStore.selectLibrary.groups[
                  this.selectedGroupIndex
                ].words,
              ];
              const index = words.findIndex(word => word._id === id);
              this.selectedWordIndex = index;
              this.word = words[index];
              this.selectImageUri = '';
              this.checkAddDataAnimation();
              this.updateWordData({
                key: '_id',
                value: id,
              });
            }
          });
      } else {
        this.clearUpdateData();
        const words = [
          ...this.libraryStore.selectLibrary.groups[this.selectedGroupIndex]
            .words,
        ];
        const index = words.findIndex(word => word._id === id);
        this.selectedWordIndex = index;
        this.word = words[index];
        this.bgColor = this.word.bg_color;
        this.selectImageUri = '';
        this.checkAddDataAnimation();
        this.updateWordData({
          key: '_id',
          value: id,
        });
      }
    },
    checkAddDataAnimation() {
      const keyAnimantion = [
        { key: 'isHasAnimation', default: false },
        { key: 'columns', default: 1 },
        { key: 'rows', default: 1 },
        { key: 'loop_count', default: -1 },
        { key: 'frame_ms', default: 200 },
        // { key: 'ani_uri', default: '' },
      ];
      const checkKey = (key, value) => {
        if (!Object.keys(this.word).includes(key)) {
          this.word = { ...this.word, [key]: value };
        }
      };
      keyAnimantion.forEach(e => {
        checkKey(e.key, e.default);
      });
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeBgColor(key, value) {
      if (key === 'bg_color') {
        this.bgColor = value;
      }
    },
    onChangeWordData(key, value) {
      if (key === 'isHasAnimation') {
        this.createUpdateDataAnimation('update');
      }
      // this.group = { ...this.libraryStore.addGroupData };
      // this.word = { ...this.libraryStore.addWordData };
      this.onChangeBgColor(key, value);
      this.isDirty = true;
      this.word[key] = value;
      this.updateWordData({
        key,
        value,
      });
    },
    createUpdateDataAnimation(status) {
      const dataKey = [
        { key: 'columns', default: 1 },
        { key: 'rows', default: 1 },
        { key: 'loop_count', default: -1 },
        { key: 'frame_ms', default: 200 },
        // { key: 'ani_uri', default: '' },
      ];
      const saveDataAnimation = (key, value) => {
        if (status === 'create') {
          this.updateCreateWordData({
            key,
            value,
          });
        } else {
          //update
          this.word[key] = value;
          this.updateWordData({
            key,
            value,
          });
        }
      };
      dataKey.forEach(e => {
        if (status === 'create') {
          saveDataAnimation(e.key, e.default);
        } else {
          // update
          saveDataAnimation(e.key, this.word[e.key]);
        }
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if ((this.isAddWord && this.isAddGroup) || this.isAddGroup) {
        this.updateCreateWordData({
          key: 'img_uri',
          value: uri,
        });
        this.word.img_uri = uri;
      } else if (this.isAddAnimation) {
        this.updateCreateWordData({
          key: 'ani_uri',
          value: uri,
        });
        this.isAddAnimation = false;
        this.word.ani_uri = uri;
      } else if (this.isAddWord) {
        this.updateCreateWordData({
          key: 'img_uri',
          value: uri,
        });
        this.word.img_uri = uri;
      } else if (this.isEditAnimation) {
        this.isDirty = true;
        this.updateWordData({
          key: 'ani_uri',
          value: uri,
        });
        this.word.ani_uri = uri;
      } else {
        this.isDirty = true;
        this.updateWordData({
          key: 'img_uri',
          value: uri,
        });
        this.word.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if ((this.isAddWord && this.isAddGroup) || this.isAddGroup) {
        this.updateCreateWordData({
          key: 'sound_uri',
          value: uri,
        });
        this.word.sound_uri = uri;
      } else {
        this.isDirty = true;
        this.updateWordData({
          key: 'sound_uri',
          value: uri,
        });
        this.word.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onClickSaveWord() {
      const updateWordData = {
        ...this.libraryStore.updateWordData,
      };
      this.updateWord(updateWordData);
      this.isDirty = false;
      Object.keys(updateWordData).forEach(key => {
        this.word[key] = updateWordData[key];
      });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onChangeCreateWord(key, value) {
      if (key === 'word' && value === '') {
        this.isWordBtnSave = false;
      } else {
        this.isWordBtnSave = true;
        this.onChangeBgColor(key, value);
        this.updateCreateWordData({
          key,
          value,
        });
      }
    },
    toggleAddGroup() {
      this.selectedGroupIndex = -1;
      this.selectedWordIndex = -1;
      // init group data
      this.updateCreateGroupData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.group = {};
      this.word = {};
      // this.group = { ...this.libraryStore.addGroupData };
      // init word data
      // this.word = { ...this.libraryStore.addWordData };
      this.isAddGroup = !this.isAddGroup;
      this.isGroupBtnSave = false;
      this.isWordBtnSave = false;
      if (!this.isAddGroup && this.libraryStore.selectedCategoryId) {
        this.$router.push('/library');
      }
    },
    onClickAddGroup() {
      const id = this.$route.query.id;
      const createGroupData = {
        ...this.libraryStore.addGroupData,
      };
      const createWordData = { ...this.libraryStore.addWordData };
      if (createGroupData.name === '') {
        swal
          .fire({
            titleText: 'group name require',
            type: 'warning',
            showCancelButton: false,
          })
          .then(() => {});
      } else if (createWordData.word === '') {
        swal
          .fire({
            titleText: 'word require',
            type: 'warning',
            showCancelButton: false,
          })
          .then(() => {});
      } else {
        this.addGroup({
          category_id: id,
          group: createGroupData,
          word: createWordData,
        });
        this.isAddGroup = false;
      }
    },
    onClickDeleteGroup(id) {
      this.deleteGroup(id);
      this.isDirty = false;
      this.selectedGroupIndex = -1;
      this.word = {};
      this.words = [];
      this.selectedWordIndex = -1;
    },
    toggleAddWord() {
      this.word = { ...this.libraryStore.cardData };
      this.isWordBtnSave = false;
      this.isAddWord = !this.isAddWord;
    },
    onClickCloseAddWord() {
      this.isAddWord = false;
      this.isAddDictionary = false;
    },
    onClickCloseAddGroup() {
      this.isAddGroup = false;
      this.isAddDictionary = false;
    },
    onClickAddWord() {
      if (this.valid) {
        const id = this.$route.query.id;
        const createWordData = {
          ...this.libraryStore.addWordData,
        };
        if (createWordData.word === '') {
          swal
            .fire({
              titleText: 'word require',
              type: 'warning',
              showCancelButton: false,
            })
            .then(() => {});
        } else {
          this.addWordByGroupId({
            category_id: id,
            group_id: this.selectGroupId,
            word: createWordData,
          });
          this.isAddWord = false;
          this.selectedGroupIndex = -1;
          this.selectedWordIndex = -1;
        }
      }
    },
    toggleDictionary() {
      // this.isAddWord = !this.isAddWord;
      this.isAddDictionary = !this.isAddDictionary;
    },
    onClickAddExistingWord(word_id) {
      const id = this.$route.query.id;
      const wordList = [
        ...this.libraryStore.selectLibrary.groups[this.selectedGroupIndex]
          .words,
      ];
      const wordIdList = wordList.map(word => word._id);
      const isExist = wordIdList.indexOf(word_id) !== -1;
      if (isExist) {
        swal
          .fire({
            titleText: 'Duplicate Word',
            type: 'info',
            showCancelButton: false,
          })
          .then(() => {});
      } else {
        this.addExistingWordByGroupId({
          category_id: id,
          group_id: this.selectGroupId,
          words: [...wordIdList, word_id],
        });
        this.isAddDictionary = false;
        this.isAddWord = false;
        this.selectedGroupIndex = -1;
        this.selectedWordIndex = -1;
      }
    },
    onClickAddExistingGroup(word_id) {
      const id = this.$route.query.id;
      const createGroupData = {
        ...this.libraryStore.addGroupData,
      };
      if (createGroupData.name === '') {
        swal
          .fire({
            titleText: 'group name require',
            type: 'warning',
            showCancelButton: false,
          })
          .then(() => {});
      } else {
        this.addGroup({
          category_id: id,
          group: createGroupData,
          word: { _id: word_id },
        });
        this.isAddGroup = false;
        this.isAddDictionary = false;
        this.selectedGroupIndex = -1;
        this.selectedWordIndex = -1;
      }
    },
    onClickDeleteWord(groupId, wordId) {
      this.deleteWord({ groupId, wordId });
      this.isDirty = false;
      this.selectedWordIndex = -1;
      this.selectedGroupIndex = -1;
      this.word = {};
    },
    onChangeGroupData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.onChangeBgColor(key, value);
      this.updateGroupData({
        key,
        value,
      });
    },
    onClickUpdateGroup() {
      const updateGroupData = {
        ...this.libraryStore.updateGroupData,
      };
      this.updateGroup(updateGroupData);
      this.selectedGroupIndex = -1;
      this.selectedWordIndex = -1;
      this.word = {};
      this.words = [];
    },
    onChangeCreateGroup(key, value) {
      if (value === '') {
        this.isGroupBtnSave = false;
      } else {
        this.isGroupBtnSave = true;
        this.onChangeBgColor(key, value);
        this.updateCreateGroupData({
          key,
          value,
        });
      }
    },
  },
  created: function () {
    this.debouncedValidateWord = _.debounce(this.validateWord, 500);
    this.debouncedErrorMessage = _.debounce(this.validateWordFlag, 900);
  },
  mounted() {
    this.fetchColorData();
    if (this.libraryStore.selectedCategoryId) {
      this.toggleAddGroup();
    } else if (Object.keys(this.libraryStore.selectLibrary).length < 1) {
      this.fetchSingleLibrary(this.$route.query.id);
    }
  },
};
</script>
