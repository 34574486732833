<template>
  <div>
    <Modal
      v-if="isOpen"
      :isOpen="isOpen"
      :save="save"
      :close="close"
      :isLastStep="valid"
      :title="isCreate ? 'Create ShootingArrow' : 'Update ShootingArrow'"
    >
      <v-card>
        <v-container>
          <v-form
            ref="formUpdate"
            v-model="valid"
            lazy-validation
            @submit.prevent="save()"
          >
            <div v-if="isCreate">
              <v-layout grid-list-xs column text-xs-center v-if="isCreate">
                <v-autocomplete
                  chips
                  required
                  label="Select Category"
                  :items="categoryListStore"
                  item-text="category"
                  item-value="_id"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="data.category_id"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('category_id', e)"
                ></v-autocomplete>
                <v-text-field
                  required
                  label="Title"
                  :value="data.title"
                  v-model="data.title"
                  :rules="[rules.required]"
                  @change="e => onchangeUpdate('title', e)"
                ></v-text-field>
              </v-layout>
            </div>

            <div v-else>
              <v-layout grid-list-xs column text-xs-center>
                <v-flex>
                  <v-text-field
                    label="Title"
                    :value="data.title"
                    v-model="data.title"
                    :rules="[rules.required]"
                    @change="e => onchangeUpdate('title', e)"
                  ></v-text-field>

                  <img
                    v-if="data.background_uri"
                    :src="data.background_uri"
                    :alt="data.background_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Background URI (URL or Select file)"
                    v-model.trim="data.background_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('background_uri', e)"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="setResource('image', 'isBackgroundImage')"
                  >
                    <v-icon>images</v-icon>Select Background URI
                  </v-btn>

                  <v-layout grid-list-xs column text-xs-center>
                    <v-flex>
                      <v-text-field
                        label="Background Music URI (URL or Select file)"
                        v-model.trim="data.bgm_uri"
                        :rules="[rules.validURI]"
                        @change="e => onchangeUpdate('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn dark @click="setResource('sound', 'isBgmSound')">
                        <v-icon>audiotrack</v-icon>Select Background Music
                      </v-btn>
                    </v-flex>
                  </v-layout>

                  <v-text-field
                    label="TTS Message"
                    v-model.trim="data.tts_message"
                    @change="e => onchangeUpdate('tts_message', e)"
                  ></v-text-field>
                  <v-select
                    :items="globalConstants.VOICE_NAME"
                    label="Voice Name"
                    :value="data.voice_name"
                    v-model="data.voice_name"
                    @change="e => onchangeUpdate('voice_name', e)"
                  ></v-select>

                  <v-text-field
                    label="Intro Music URI (URL or Select file)"
                    v-model.trim="data.music_intro_uri"
                    :rules="[rules.validURI]"
                    @change="e => onchangeUpdate('music_intro_uri', e)"
                  ></v-text-field>
                  <v-btn dark @click="setResource('sound', 'isIntroSound')">
                    <v-icon>audiotrack</v-icon>Select Intro Music
                  </v-btn>
                </v-flex>
              </v-layout>

              <ShootingWord
                v-if="isCreate === false"
                @validate="valid = $event"
                :isCreate="isCreate"
              >
              </ShootingWord>

              <v-layout column class="elevation-5" style="padding: 20px">
                <b style="align-self: center;">Obstacle</b>
                <v-flex>
                  <v-flex>
                    <img
                      v-if="data.obstacle.image_uri"
                      :src="data.obstacle.image_uri"
                      :alt="data.obstacle.image_uri"
                      class="content_img"
                    />
                    <v-text-field
                      label="Image URI (URL or Select file)"
                      :value="data.obstacle.image_uri"
                      v-model="data.obstacle.image_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('obstacle.image_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('image', 'isImageUri')"
                      ><v-icon>image</v-icon>Select Image</v-btn
                    >
                  </v-flex>

                  <v-flex>
                    <SpriteSheet
                      :title="'Use Sprite Sheet'"
                      :isToggle="data.obstacle.is_sprite_sheet"
                      :fieldname="'Edit Move Sheet Data'"
                      :object="data.obstacle.sprite_sheet_data"
                      :color="'dark'"
                      @onchangeToggleSprite="onchangeToggleSprite($event)"
                      @saveSpriteSheetData="saveSpriteSheetData($event)"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      label="Sound URI (URL or Select file)"
                      :value="data.obstacle.sound_uri"
                      v-model="data.obstacle.sound_uri"
                      :rules="[rules.validURI]"
                      @change="e => onchangeUpdate('obstacle.sound_uri', e)"
                    ></v-text-field>
                    <v-btn dark @click="setResource('sound', 'isSoundUri')"
                      ><v-icon>audiotrack</v-icon>Select Sound</v-btn
                    >
                  </v-flex>
                </v-flex>
              </v-layout>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';
import ResourceSelect from '../../../../components/ResourceSelect';
import constants from './constants';
import ShootingWord from './ShootingWord.vue';
import globalConstants from '../../../../constants';
import SpriteSheet from '../../../../components/SpriteSheetNew.vue';
import { keyExists } from '../../../../utils/lib/keyExists.js';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Modal, ResourceSelect, ShootingWord, SpriteSheet },
  data() {
    return {
      keyExists,
      globalConstants,
      constants,
      valid: true,
      rules,
      data: {},
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isImage: false,
        },
      },
      resourceType: '',
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      shootingArrowStore: state => state.shooting_arrow,
      selectedShootingArrow: state =>
        state.shooting_arrow.selectedShootingArrow,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  methods: {
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('shooting_arrow', [
      'createShootingArrow',
      'updateShootingArrow',
      'setStateByKey',
      'clearStateByKey',
    ]),
    checkSpriteSheetData(object) {
      const default_obstacle_sprite_sheet_data = {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      };
      let altered_object = Object.assign({}, object);
      const expected_default = {
        is_sprite_sheet: false,
        sprite_sheet_data: JSON.parse(
          JSON.stringify(default_obstacle_sprite_sheet_data),
        ),
      };
      if (
        !this.keyExists(object.obstacle, 'is_sprite_sheet') ||
        object.obstacle.is_sprite_sheet == false
      ) {
        altered_object.obstacle.is_sprite_sheet =
          expected_default.is_sprite_sheet;
        this.onchangeUpdate(
          'obstacle.is_sprite_sheet',
          expected_default.is_sprite_sheet,
        );
      }
      if (
        !this.keyExists(object.obstacle, 'sprite_sheet_data') ||
        object.obstacle.sprite_sheet_data === null
      ) {
        this.onchangeUpdate(
          'obstacle.sprite_sheet_data',
          expected_default.sprite_sheet_data,
        );
        altered_object.obstacle.sprite_sheet_data =
          expected_default.sprite_sheet_data;
      }
      return altered_object;
    },
    onchangeToggleSprite({ value }) {
      this.data.obstacle.is_sprite_sheet = value;
      this.onchangeUpdate('obstacle.is_sprite_sheet', value);
    },
    saveSpriteSheetData({ value }) {
      this.data.obstacle.sprite_sheet_data = value;
      this.onchangeUpdate('obstacle.sprite_sheet_data', value);
    },
    setSpriteSheetData() {
      this.data = { ...this.checkSpriteSheetData(this.data) };
    },
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.isCreate ? this.createShootingArrow() : this.updateShootingArrow();
        this.close();
      }
    },
    close() {
      this.isCreate
        ? this.clearStateByKey('createShootingArrow')
        : this.clearStateByKey('updateShootingArrow');
      this.$emit('close');
    },
    setResource(resource, isType) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isImageUri) {
            this.data.obstacle.image_uri = uri;
            this.onchangeUpdate('obstacle.image_uri', uri);
            this.resource[resourceType].isImageUri = false;
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isSoundUri) {
            this.data.obstacle.sound_uri = uri;
            this.onchangeUpdate('obstacle.sound_uri', uri);
            this.resource[resourceType].isSoundUri = false;
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    onchangeUpdate(key, value) {
      this.setStateByKey({
        statekey: this.isCreate ? 'createShootingArrow' : 'updateShootingArrow',
        key: key,
        value: value,
      });
    },
    async fetchCategories() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
    },
    set_category_id() {
      if (
        this.isCreate &&
        'category_id' in this.shootingArrowStore.shootingArrowList
      ) {
        this.data.category_id = this.shootingArrowStore.shootingArrowList.category_id;
        this.onchangeUpdate(
          'category_id',
          this.shootingArrowStore.shootingArrowList.category_id,
        );
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.isCreate) {
          this.fetchCategories();
          this.set_category_id();
          this.data = JSON.parse(
            JSON.stringify({ ...this.shootingArrowStore.defaultCreate }),
          );
        } else {
          this.data = JSON.parse(
            JSON.stringify({ ...this.selectedShootingArrow }),
          );
          this.setSpriteSheetData();
        }
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
