<template>
  <div>
    <v-text-field
      label="name"
      :value="data[index].name"
      v-model="data[index].name"
      :rules="[rules.required]"
    ></v-text-field>
    <v-checkbox
      v-model="data[index].is_clickable"
      label="Is Clickable"
    ></v-checkbox>
    <v-select
      :items="eventIndexList()"
      item-text="name"
      item-value="index"
      :value="data[index].play_event_index"
      v-model="data[index].play_event_index"
      label="Play Event Index"
      :rules="[rules.required]"
    ></v-select>
    <!-- <v-text-field
      type="number"
      label="Play Event Index"
      :value="data[index].play_event_index"
      v-model.number="data[index].play_event_index"
      :rules="[rules.required]"
    ></v-text-field> -->
    <div v-for="(value, key, index) in data[index]" :key="`${key}-${index}`">
      <div v-if="key === 'start_image' || key === 'correct_image'">
        <div class="key-image">
          <h2 v-if="key === 'start_image'">Start Image</h2>
          <h2 v-if="key === 'correct_image'">Correct Image</h2>
        </div>
        <br />
        <img :src="value.image.image_uri" class="content_img" />
        <v-text-field
          label="Image URI"
          :value="value.image.image_uri"
          v-model="value.image.image_uri"
          :rules="[rules.validURI]"
        ></v-text-field>
        <v-btn
          v-if="key === 'correct_image'"
          v-on:click="
            isEditImage = true;
            isCorrectImage = true;
          "
        >
          <v-icon>image</v-icon>Select Image
        </v-btn>
        <v-btn v-else v-on:click="isEditImage = true">
          <v-icon>image</v-icon>Select Image
        </v-btn>
        <br />
        <h3>Sheet Data</h3>
        <v-layout>
          <v-flex>
            <v-text-field
              type="number"
              label="column"
              :value="value.image.columns"
              v-model.number="value.image.columns"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              type="number"
              label="row"
              :value="value.image.rows"
              v-model.number="value.image.rows"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-text-field
              type="number"
              label="Frame/Millisec"
              :value="value.image.frame_ms"
              v-model.number="value.image.frame_ms"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              type="number"
              label="Loop (default -1)"
              :value="value.image.loop"
              v-model.number="value.image.loop"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- <v-layout>
          <v-flex>
            <v-text-field
              type="number"
              label="Face Ratio"
              :value="value.image.face_ratio"
              v-model.number="value.image.face_ratio"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              type="number"
              label="Face Point Index"
              :value="value.image.face_point_index"
              v-model.number="value.image.face_point_index"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="FilterType"
              item-text="name"
              item-value="value"
              :value="value.image.type"
              v-model="value.image.type"
              label="Type"
            ></v-select>
          </v-flex>
        </v-layout> -->
        <h3>Anchor</h3>
        <v-layout>
          <v-flex>
            <v-text-field
              type="number"
              label="Min X"
              :value="value.anchor.min.x"
              v-model="value.anchor.min.x"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              type="number"
              label="Min Y"
              :value="value.anchor.min.y"
              v-model="value.anchor.min.y"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-text-field
              type="number"
              label="Max X"
              :value="value.anchor.max.x"
              v-model="value.anchor.max.x"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              type="number"
              label="Max Y"
              :value="value.anchor.max.y"
              v-model="value.anchor.max.y"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
  </div>
</template>

<script>
import ResourceSelect from '../../../components/ResourceSelect.vue';
import constant from '../constant';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    ResourceSelect,
  },
  data() {
    return {
      isEditImage: false,
      selectImageUri: '',
      isCorrectImage: false,
      rules: constant.rule,
      FilterType: constant.FilterType,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      shortStoryStore: state => state.short_story,
    }),
  },
  methods: {
    eventIndexList() {
      const events = [...this.shortStoryStore.selectedShortStory.events];
      if (events.length) {
        const eventTypePlay = events.filter((e, index) => {
          if (e.type === 1) {
            e.index = index;
            return e;
          }
        });
        return eventTypePlay;
      } else return [];
    },
    onclickEditImage() {
      this.isEditImage = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isCorrectImage:
          this.data[this.index].correct_image.image.image_uri = uri;
          this.isCorrectImage = false;
          break;
        default:
          this.data[this.index].start_image.image.image_uri = uri;
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
  },
};
</script>

<style scoped>
.key-image {
  background-color: #333333;
  text-align: center;
}
</style>
