<template>
  <Container>
    <v-flex>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Customer Service Chat Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="fetchChatList()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-toolbar>

        <v-layout row>
          <v-flex xs12 sm6 offset-sm0>
            <v-card>
              <v-toolbar color="#5834c1" dark>
                <v-toolbar-title>Inbox</v-toolbar-title>
                <v-spacer></v-spacer>

                <!-- <v-btn icon>
                  <v-icon>search</v-icon>
                </v-btn> -->
              </v-toolbar>

              <v-list three-line id="customerChatList">
                <template v-for="(item, index) in items">
                  <v-subheader v-if="item.header" :key="item.header">
                    {{ item.header }}
                  </v-subheader>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>

                  <v-list-tile
                    v-else
                    :key="index"
                    avatar
                    @click="displayChatbox(item)"
                    :class="
                      index === selectedChatIndex ? 'activeBar' : 'inactiveBar'
                    "
                  >
                    <v-list-tile-avatar>
                      <img :src="item.avatar || defaultProfilePicture" />
                    </v-list-tile-avatar>

                    <v-list-tile-content>
                      <v-list-tile-title v-html="item.name"></v-list-tile-title>
                      <v-list-tile-sub-title
                        v-html="item.title"
                        :class="
                          index === selectedChatIndex
                            ? 'activeBarSubtitle'
                            : 'inactiveBar'
                        "
                      >
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-spacer></v-spacer>
                    <v-flex>
                      <v-list-tile-action>
                        Status : {{ item.status_display_name }}
                        <v-list-tile-action>
                          {{ item.send_date }}
                        </v-list-tile-action>
                        <v-btn
                          v-if="item.status !== CONSTANT.STATUS.CLOSED"
                          icon
                          ripple
                          @click="openPopupSwalStatus(item)"
                        >
                          <v-icon color="grey lighten-1">check_circle</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-flex>
                  </v-list-tile>
                </template>
              </v-list>
            </v-card>
          </v-flex>

          <v-flex id="chatbox">
            <CustomerServiceChat
              :isOepn="isOpenChatbox"
              @closeChatbox="closeChatbox"
              :participants="participantsStore"
              :myself="myselfStore"
              :messages="messagesStore"
              :chatTitle="chatTitle"
              :customerId="customerId"
            ></CustomerServiceChat>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </Container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import userProfileImage from '../../../src/assets/user.png';
import Container from '../../components/Container';
import CustomerServiceChat from './CustomerServiceChat';
import swal from 'sweetalert2';
import CONSTANT from './constant';
export default {
  name: 'CustomerServiceChatList',
  components: { Container, CustomerServiceChat },
  methods: {
    ...mapActions('customer_service_chat', [
      'fetchChatList',
      'fetchChatDataByCustomerId',
      'updateStatusByUserId',
    ]),
    removeActiveState() {
      const elems = document.querySelector('.activeBar');
      if (elems !== null) {
        elems.classList.remove('activeBar');
      }
    },
    openPopupSwalStatus(item) {
      this.isopenPopupSwalStatus = true;
      const userId = item.sender_id;
      swal
        .fire({
          titleText: 'Update Status ?',
          text:
            'This operation will update to close status. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Change to Close',
          showCancelButton: true,
          type: 'warning',
        })
        .then(async result => {
          if (result.value) {
            const updateObject = {
              customer_id: userId,
              status: CONSTANT.STATUS.CLOSED,
            };
            this.updateStatusByUserId(updateObject);
            setTimeout(() => {
              this.removeActiveState();
            }, 1000);
          }
        });
    },
    autoFetchChatList() {
      const fifteenMinutes = 900000; // millisecond
      this.fetchChatListInterval = setInterval(() => {
        this.fetchChatList();
      }, fifteenMinutes);
    },
    openChat() {
      this.isOpenChatbox = true;
    },
    closeChatbox() {
      this.isOpenChatbox = false;
      this.chatDataWatcher();
    },
    sampleChat() {
      this.$router.push('/customer_service_chat/detail');
    },
    updatePendingStatus(item) {
      const customer_id = item.sender_id;
      const status = item.status;
      if (status === CONSTANT.STATUS.NEW || status === '') {
        const updateStatusObject = {
          customer_id: customer_id,
          status: CONSTANT.STATUS.PENDING,
        };
        this.updateStatusByUserId(updateStatusObject);
        setTimeout(() => {
          this.removeActiveState();
        }, 1000);
      }
    },
    displayChatbox(item) {
      this.selectedChatIndex = this.items.indexOf(item);
      this.openChat();
      this.fetchChatDataByCustomerId(item.sender_id);
      this.updatePendingStatus(item);
      this.chatDataWatcher = this.$watch(
        'customerServiceChatDataStore',
        value => {
          if (value && value.messages && value.messages.length > 0) {
            this.customerId = item.sender_id;
            this.chatTitle = value.participants[0].name;
            this.myselfStore = value.myself;
            this.participantsStore = value.participants;
            this.messagesStore = value.messages;
          } else {
            this.customerId = item.sender_id;
            this.chatTitle = '';
            this.myselfStore = {};
            this.participantsStore = [];
            this.messagesStore = [];
          }
        },
      );
    },
  },
  computed: {
    ...mapState({
      customerServiceChatListStore: state =>
        state.customer_service_chat.CHAT_LIST,
      customerServiceChatDataStore: state =>
        state.customer_service_chat.CHAT_DATA,
    }),
  },
  data() {
    return {
      CONSTANT,
      isopenPopupSwalStatus: false,
      fetchChatListInterval: null,
      selectedChatIndex: -1,
      participantsStore: [],
      messagesStore: [],
      myselfStore: {},
      customerId: '',
      chatTitle: '',
      chatDataWatcher: null,
      chatListWatcher: null,
      defaultProfilePicture: userProfileImage,
      participants_store_temp: [
        {
          name: 'Nana_P',
          id: 1,
          profilePicture:
            'https://s3-ap-southeast-1.amazonaws.com/rgk-images/asset%2046@2x.png',
        },
      ],
      myself_store_temp: {
        name: 'Me from list',
        id: 3,
        profilePicture:
          'https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_run.png',
      },
      messages_store_temp: [
        {
          content: 'Hello cloud you please help me on ...',
          myself: false,
          participantId: 1,
          timestamp: {
            year: 2019,
            month: 3,
            day: 5,
            hour: 20,
            minute: 10,
            second: 3,
            millisecond: 123,
          },
          type: 'text',
        },
        {
          content: 'Sure please let me know your issue.',
          myself: true,
          participantId: 3,
          timestamp: {
            year: 2019,
            month: 4,
            day: 5,
            hour: 19,
            minute: 10,
            second: 3,
            millisecond: 123,
          },
          type: 'text',
        },
        {
          content: 'other received messages',
          myself: false,
          participantId: 2,
          timestamp: {
            year: 2019,
            month: 5,
            day: 5,
            hour: 10,
            minute: 10,
            second: 3,
            millisecond: 123,
          },
          type: 'text',
        },
      ],
      isOpenChatbox: false,
      items: [],
    };
  },
  created() {
    this.fetchChatList();
    this.chatListWatcher = this.$watch(
      'customerServiceChatListStore',
      value => {
        this.items = value;
      },
    );
    this.autoFetchChatList();
  },
  beforeDestroy() {
    this.chatListWatcher();
    clearInterval(this.fetchChatListInterval);
  },
};
</script>

<style scoped>
body {
  overflow: hidden;
}

.activeBar {
  color: whitesmoke;
  background-color: #292929;
}

.activeBarSubtitle {
  color: black;
}

#customerChatList {
  overflow: auto;
  height: 696px;
  flex: auto;
}

#chatbox {
  flex: 1 1;
}
</style>
