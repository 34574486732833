import moment from 'moment';
import http from '../../utils/httpClient';
import userProfileImage from '../../assets/user.png';
const state = {
  CHAT_LIST: [],
  CHAT_DATA: [],
};
const getters = {};
const actions = {
  fetchChatList({ commit }) {
    http
      .GET(`/api/chat_system/customer_service_chat/list`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(chatList => ({
          ...chatList,
          send_date: moment(chatList.send_date).format('MMMM D YYYY h:mm A'),
        }));
        commit('SET_CHAT_LIST', formatData);
      })
      .catch(error => {
        throw error;
      });
  },

  fetchChatDataByCustomerId({ commit }, customer_id) {
    http
      .GET(
        `/api/chat_system/customer_service_chat/?customer_id=${customer_id}`,
        {
          isAuthorization: true,
        },
      )
      .then(({ data }) => {
        data.myself.profilePicture = userProfileImage;
        data.participants.map(element => {
          element.profilePicture = userProfileImage;
        });
        commit('SET_CHAT_DATA', data);
      })
      .catch(error => {
        throw error;
      });
  },
  sendMessageToCustomer({ dispatch }, { customer_id, message }) {
    http
      .PUT(
        `/api/chat_system/private/admin`,
        { client_id: customer_id, message: message },
        {
          isAuthorization: true,
        },
      )
      .then(({}) => {
        dispatch('fetchChatDataByCustomerId', customer_id);
      })
      .catch(error => {
        throw error;
      });
  },
  updateStatusByUserId({ dispatch }, { customer_id, status }) {
    http
      .PUT(
        `/api/chat_system/customer_service_chat/status`,
        { user_id: customer_id, status: status },
        {
          isAuthorization: true,
        },
      )
      .then(({}) => {
        // dispatch(
        //   'appConfig/toggleAlert',
        //   {
        //     type: 'success',
        //     message: 'Update Status to Close Successfully',
        //   },
        //   {
        //     root: true,
        //   },
        // );
        dispatch('fetchChatDataByCustomerId', customer_id);
        dispatch('fetchChatList');
      })
      .catch(error => {
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'error',
            message: 'Update Status Failed, Please try again later.',
          },
          {
            root: true,
          },
        );
        throw error;
      });
  },
};
const mutations = {
  SET_CHAT_LIST(state, payload) {
    if (payload) {
      state.CHAT_LIST = payload;
    } else {
      delete state.CHAT_LIST;
    }
  },
  SET_CHAT_DATA(state, payload) {
    if (payload) {
      state.CHAT_DATA = payload;
    } else {
      delete state.CHAT_DATA;
    }
  },
};
export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
