const env = process.env.NODE_ENV || 'test';

const config = {
  test: {
    server_address: 'http://localhost:2019',
    prod_address: 'https://prod-academy.galaxyportal.net',
  },
  development: {
    server_address: 'https://dev-academy.galaxyportal.net',
    prod_address: 'https://prod-academy.galaxyportal.net',
  },
  production: {
    server_address: 'https://prod-academy.galaxyportal.net',
    prod_address: 'https://prod-academy.galaxyportal.net',
  },
  chinese: {
    server_address: 'http://chinese-dev.galaxyportal.net',
    prod_address: 'http://chinese.galaxyportal.net',
  },
};

export default config[env];
