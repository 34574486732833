<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/liveclass')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Your Offer Cards Preparing</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-if="!isLoadOfferCards"
            :items="liveClassOfferList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>{{ props.index + 1 }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.offer_id }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.child_name }}</v-layout>
              </td>
              <td>
                <v-layout
                  justify-center
                  v-if="props.item.live_class_offer_data"
                  >{{
                    moment(props.item.live_class_offer_data.create_time).format(
                      'MMMM D YYYY h:mm a',
                    )
                  }}</v-layout
                >
              </td>
              <td>
                <v-layout
                  justify-center
                  v-if="props.item.live_class_offer_data"
                >
                  <v-chip
                    :color="
                      props.item.live_class_offer_data.status ===
                      constants.STATUS.READY
                        ? 'success'
                        : 'primary'
                    "
                    text-color="white"
                    >{{
                      formatDisplaystatus(
                        props.item.live_class_offer_data.status,
                      )
                    }}</v-chip
                  >
                </v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-btn
                    color="primary"
                    v-on:click="showModalEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                    Edit
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="false"
      @close="isShowModal = false"
    ></ModalCreateUpdate>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import { ModalCreateUpdate } from './components/';
import rules from '../../../utils/ruleValidate';
import swal from 'sweetalert2';
import constants from './constants';
import moment from 'moment';

export default {
  name: 'LiveclassOfferCardsList',
  components: {
    Container,
    ModalCreateUpdate,
  },
  data() {
    return {
      constants,
      search: '',
      headers: [
        {
          text: 'No.',
          value: 'no.',
          align: 'center',
          sortable: false,
        },
        {
          text: 'offer_id',
          value: 'offer_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Child name',
          value: 'child_name',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Create time',
          value: 'create_time',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
      ],
      // isCreate: false,
      isShowModal: false,
      rules,
      moment,
    };
  },
  async mounted() {
    await this.fetchOfferByOwnerUserId();
  },
  computed: {
    ...mapState({
      liveClassOfferList: state => state.liveclass.liveClassOfferList,
      liveClassTemplatesList: state => state.liveclass.liveClassTemplatesList,
      selectedOfferCard: state => state.liveclass.selectedOfferCard,
      isLoadOfferCards: state => state.liveclass.isLoadOfferCards,
      updateLiveClassData: state => state.liveclass.updateLiveClassData,
    }),
  },
  methods: {
    ...mapActions('liveclass', [
      'fetchOfferByOwnerUserId',
      'fetchLiveClassTempateList',
      'updateOfferCard',
      'setStateByKey',
    ]),
    onClickReady(id) {
      const updateData = this.updateLiveClassData;
      if (Object.keys(updateData).length > 0) {
        if (updateData._id === id) this.updateOfferCard();
      }
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure.',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
          }
        });
    },
    formatDisplaystatus(value) {
      if (value >= 0) {
        switch (value) {
          case constants.STATUS.WAITING:
            return 'WAITING';
          case constants.STATUS.CANCLE:
            return 'CANCLE';
          case constants.STATUS.PREPARING:
            return 'PREPARING';
          case constants.STATUS.READY:
            return 'READY';
          default:
            return null;
        }
      } else {
        return null;
      }
    },
    showModalEdit(liveclass_id) {
      const [selectedData] = this.liveClassOfferList.filter(
        ({ _id }) => _id == liveclass_id,
      );
      if (Object.keys(selectedData).length > 0) {
        this.isShowModal = true;
        this.setStateByKey({
          statekey: 'selectedOfferCard',
          value: selectedData,
        });
        this.setStateByKey({
          statekey: 'updateLiveClassData',
          key: '_id',
          value: liveclass_id,
        });
      }
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
