<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>Subtitle Localization Manager</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex>
            <v-autocomplete
              label="Select Language"
              v-model="selectedLanguage"
              :items="languageList"
              chips
              hide-details
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-flex>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div v-if="userLevel === 99">
            <v-btn color="success" @click="onClickAddLanguage()">
              Create
            </v-btn>
          </div>
        </v-toolbar>
        <v-card>
          <v-card-title>
            <v-text-field
              v-if="selectedLanguage === 'Arabic'"
              dir="rtl"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-text-field
              v-if="selectedLanguage !== 'Arabic'"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="header"
            :items="languageStore"
            :search="search"
            :pagination.sync="pagination"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.created_at }}</td>
              <td class="text-xs-center">{{ props.item.updated_at }}</td>
              <!-- <td class="text-xs-center">{{ props.item.game_id }}</td>
              <td class="text-xs-center">{{ props.item._id }}</td> -->
              <td style="text-align: -webkit-center">
                <v-img
                  class="image_thumbnail"
                  :src="props.item.image_uri"
                  @click="fullscreenImage(props.item.image_uri)"
                />
              </td>
              <td class="text-xs-center">{{ props.item.game_type }}</td>
              <td class="text-xs-center">
                {{ props.item.English }}
              </td>
              <td class="text-xs-center">
                {{ props.item[selectedLanguage] }}
              </td>
              <td class="justify-center layout px-0">
                <v-icon
                  v-if="selectedLanguage !== 'English'"
                  small
                  class="mr-2"
                  @click="onClickEditLanguage(props.item)"
                  >edit</v-icon
                >
                <v-icon
                  v-if="userLevel === 99"
                  small
                  @click="
                    onClickDeleteLanguage(props.item._id, props.item.English)
                  "
                  >delete</v-icon
                >
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-flex>
    <!-- Edit Modal -->
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onSaveEdit"
      :close="onCloseEdit"
      title="Edit Language"
    >
      <v-card>
        <v-container>
          <v-layout grid-list-xs column text-xs-center>
            <v-flex>
              <v-text-field
                readonly
                label="game_id"
                :value="language.game_id"
                v-model="language.game_id"
              ></v-text-field>
              <div v-if="selectedLanguage === 'Arabic'">
                <v-textarea
                  dir="rtl"
                  :label="selectedLanguage"
                  :value="language.editor"
                  v-model="language.editor"
                  @change="v => onChangeEditLanguage(selectedLanguage, v)"
                ></v-textarea>
              </div>
              <div v-else-if="selectedLanguage === 'English'">
                <v-textarea
                  disabled
                  readonly
                  :label="selectedLanguage"
                  :value="language.editor"
                  v-model="language.editor"
                  @change="v => onChangeEditLanguage(selectedLanguage, v)"
                ></v-textarea>
              </div>
              <div v-else>
                <v-textarea
                  :label="selectedLanguage"
                  :value="language.editor"
                  v-model="language.editor"
                  @change="v => onChangeEditLanguage(selectedLanguage, v)"
                ></v-textarea>
              </div>
              <v-flex v-if="userLevel === 99">
                <img
                  v-if="language.image_uri"
                  :src="language.image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Context Image Reference URI"
                  :value="language.image_uri"
                  v-model="language.image_uri"
                  @change="v => onChangeEditLanguage('image_uri', v)"
                ></v-text-field>
                <v-btn v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <ResourceSelect
            v-if="isEditImage"
            resourceType="image"
            :isShow="isEditImage"
            :close="closeEditImage"
            :save="saveImage"
            :value="selectImageUri"
            :onSelect="onSelectImage"
          />
        </v-container>
      </v-card>
    </Modal>
    <!-- Add Modal -->
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onSaveCreate"
      :close="onCloseCreate"
      :isLastStep="valid && validKeyStore && validateDuplicateKey.isChecking"
      title="Create Language"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-container>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  required
                  :rules="requiredRule"
                  :error-messages="validateDuplicateKey.answer"
                  label="game_id"
                  :value="language.game_id"
                  v-model="language.game_id"
                  @change="v => onChangeAddLanguage('game_id', v)"
                ></v-text-field>
                <v-textarea
                  :rules="requiredRule"
                  :error-messages="errorMessages"
                  label="English"
                  :value="language.English"
                  v-model="language.English"
                  @change="v => onChangeAddLanguage('English', v)"
                ></v-textarea>
                <v-flex v-if="userLevel === 99">
                  <img
                    v-if="language.image_uri"
                    :src="language.image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Context Image Reference URI"
                    :value="language.image_uri"
                    v-model="language.image_uri"
                    @change="v => onChangeAddLanguage('image_uri', v)"
                  ></v-text-field>
                  <v-btn v-on:click="isAddImage = true">
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </v-flex>
              </v-flex>
            </v-layout>
            <ResourceSelect
              v-if="isAddImage"
              resourceType="image"
              :isShow="isAddImage"
              :close="closeAddImage"
              :save="saveImage"
              :value="selectImageUri"
              :onSelect="onSelectImage"
            />
          </v-container>
        </v-card>
      </v-form>
    </Modal>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="fade-transition"
    >
      <v-card>
        <v-toolbar dark>
          <v-icon white @click="dialog = false">close</v-icon>
          | Press [esc] key to exit full screen
        </v-toolbar>
        <v-img :src="img" />
      </v-card>
    </v-dialog>
  </Container>
</template>
<script>
import { saveAs } from 'file-saver';
import { mapState, mapActions } from 'vuex';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import Container from '../../components/Container';
import { LANGUAGES } from '../../constants/localization/languageList';
import _ from 'lodash';
export default {
  name: 'SubtitleLocalization',
  components: {
    ResourceSelect,
    Container,
    Modal,
  },
  computed: {
    ...mapState({
      languageStore: state => state.subtitle_localization.language,
      updatedLanguageStore: state => state.subtitle_localization.updateLanguage,
      updatedLanguagePrevStore: state =>
        state.subtitle_localization.updateLanguagePrev,
      languageJSONtoCSVStore: state =>
        state.subtitle_localization.languageJSONtoCSV,
      createdLanguageStore: state => state.subtitle_localization.createLanguage,
      isSafeDeleteState: state => state.subtitle_localization.isSafeDelete,
      userLevel: state => state.userInfo.userData.role_id,
      validKeyStore: state => state.subtitle_localization.isValidKey,
      subtitleDataStore: state =>
        state.subtitle.SUBTITLE_DATA_FROM_SUBTITLE_LOCALIZATION,
    }),
    form() {
      return {
        game_id: this.language.game_id,
      };
    },
  },
  data() {
    return {
      item: {},
      validateDuplicateKey: {
        answer: 'Please enter the key.',
        isChecking: null,
      },
      img: '',
      dialog: false,
      requiredRule: [v => !!v || 'Required, cannot be empty.'],
      errorMessages: '',
      valid: false,
      isAddImage: false,
      isEditImage: false,
      selectImageUri: '',
      language: {},
      search: '',
      isCreate: false,
      isEdit: false,
      editedIndex: -1,
      clearable: true,
      pagination: {
        rowsPerPage: 25,
      },
      selectedLanguage: '',
      languageList: LANGUAGES,
      header: [
        {
          text: 'Updated At',
          value: 'updated_at',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Created At',
          value: 'created_at',
          align: 'center',
          sortable: true,
        },
        // {
        //   text: 'Game ID',
        //   value: 'game_id',
        //   align: 'center',
        //   sortable: true,
        // },
        // {
        //   text: 'Primary Key',
        //   value: '_id',
        //   align: 'center',
        //   sortable: true,
        // },
        {
          text: 'Context Image Reference',
          value: 'image_uri',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Game Type',
          value: 'game_type',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Reference Language',
          value: 'English',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Current Language',
          value: '',
          align: 'center',
          sortable: false,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions('subtitle', ['getSubtitleByID', 'updateSingleFieldSubtitle']),
    ...mapActions('subtitle_localization', [
      'fetchLanguage',
      'uploadLanguageDataToS3',
      'addLanguage',
      'editLanguage',
      'deleteLanguage',
      'stateCreateLanguage',
      'stateEditLanguage',
      'statePreviousData',
      'clearCreateState',
      'clearUpdateState',
      'clearUpdatePrevState',
      'publishToProduction',
      'checkDuplicateKey',
      'isAbleToDelete',
    ]),
    validateKey() {
      let key = this.language.game_id;
      this.checkDuplicateKey(key);
    },
    validateKeyFlag() {
      if (!this.validKeyStore) {
        this.validateDuplicateKey.answer = 'Duplicate game_id is detected.';
      } else {
        this.validateDuplicateKey.answer = '';
        this.validateDuplicateKey.isChecking = true;
      }
    },
    fullscreenImage(image) {
      if (image) {
        this.dialog = true;
        this.img = image;
      }
    },
    // async uploadToS3() {
    //   await this.uploadLanguageDataToS3(this.selectedLanguage);
    // },
    saveFileLocally() {
      let blob = new Blob([this.languageJSONtoCSVStore], {
        type: 'text/csv',
      });
      const filename = `${this.selectedLanguage}.csv`;
      saveAs(blob, filename);
    },
    onClickAddLanguage() {
      this.language = {};
      this.isCreate = true;
    },
    onChangeAddLanguage(key, value) {
      this.stateCreateLanguage({ key, value });
    },
    onSaveCreate() {
      this.addLanguage({
        languageData: this.createdLanguageStore,
        selectedLanguage: this.selectedLanguage,
      });
      this.isCreate = false;
    },
    onCloseCreate() {
      this.isCreate = false;
      this.clearCreateState();
    },
    async onClickEditLanguage(item) {
      this.item = item;
      this.statePreviousData({ key: '_id', value: item._id });
      if (item[this.selectedLanguage]) {
        this.statePreviousData({
          key: this.selectedLanguage,
          value: item[this.selectedLanguage],
        });
      } else {
        this.statePreviousData({
          key: this.selectedLanguage,
          value: '',
        });
      }
      this.stateEditLanguage({ key: '_id', value: item._id });
      this.isEdit = true;
      this.editedIndex = this.languageStore.indexOf(item);
      this.language = Object.assign({}, item);
      this.language.editor = this.language[this.selectedLanguage];
      await this.getSubtitleByID(this.item.subtitle_id);
    },
    async updateSubtitleData() {
      // this will update English in Subtitle Collection
      if (this.selectedLanguage === 'English') {
        if (this.subtitleDataStore && this.subtitleDataStore.English) {
          if (this.subtitleDataStore.English.length === 1) {
            const updateSubtitleObject = {
              _id: this.item.subtitle_id,
              English: [this.language.editor],
            };
            await this.updateSingleFieldSubtitle({
              subtitle: updateSubtitleObject,
            });
          } else if (this.subtitleDataStore.English.length > 1) {
            const englishBeforeUpdate = this.item.English;
            const position =
              this.subtitleDataStore.English.indexOf(englishBeforeUpdate);
            this.subtitleDataStore.English[position] = this.language.editor;
            const updateSubtitleObject = {
              _id: this.item.subtitle_id,
              English: [...this.subtitleDataStore.English],
            };
            await this.updateSingleFieldSubtitle({
              subtitle: updateSubtitleObject,
            });
          }
        }
      }
    },
    onChangeEditLanguage(key, value) {
      this.stateEditLanguage({ key, value });
    },
    onSaveEdit() {
      const updateData = {
        from: this.updatedLanguagePrevStore,
        to: this.updatedLanguageStore,
      };
      this.editLanguage({
        updateData: updateData,
        selectedLanguage: this.selectedLanguage,
      });
      // this.updateSubtitleData();
      this.onCloseEdit();
    },
    onCloseEdit() {
      this.clearUpdatePrevState();
      this.clearUpdateState();
      this.isEdit = false;
    },
    async onClickDeleteLanguage(id, English) {
      this.isAbleToDelete({
        id: id,
        English: English,
        selectedLanguage: this.selectedLanguage,
      });
    },
    closeAddImage() {
      this.selectImageUri = '';
      this.isAddImage = !this.isAddImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    saveImage() {
      const uri = this.selectImageUri;
      if (this.isAddImage) {
        this.stateCreateLanguage({
          key: 'image_uri',
          value: uri,
        });
        this.isAddImage = !this.isAddImage;
      } else if (this.isEditImage) {
        this.stateEditLanguage({
          key: 'image_uri',
          value: uri,
        });
        this.isEditImage = !this.isEditImage;
      }
      this.language.image_uri = uri;
      this.selectImageUri = '';
    },
  },
  created() {
    this.debouncedValidateKey = _.debounce(this.validateKey, 500);
    this.debouncedErrorMessage = _.debounce(this.validateKeyFlag, 900);
  },
  mounted() {
    this.selectedLanguage = 'English';
    this.fetchLanguage(this.selectedLanguage);
  },
  watch: {
    selectedLanguage() {
      this.header[5].text = this.selectedLanguage;
      this.header[5].value = this.selectedLanguage;
      this.fetchLanguage(this.selectedLanguage);
    },
    'form.game_id'() {
      this.validateDuplicateKey.answer = 'Checking key now...';
      this.validateDuplicateKey.isChecking = false;
      this.debouncedValidateKey();
      this.debouncedErrorMessage();
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 50px;
  width: 50px;
  margin-top: 0px;
}
.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
</style>
