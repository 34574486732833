<template>
  <div>
    <v-flex>
      <img :src="player.idle_image_uri" class="content_img" />
      <v-text-field
        label="Idle Image URI"
        v-model="player.idle_image_uri"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickIdleImage"
        ><v-icon>image</v-icon>Select Idle Image URI</v-btn
      >
      <SpriteSheet
        :title="'is idle sprite sheet'"
        :isToggle="player.is_idle_sprite_sheet"
        :fieldname="'Edit Idle Sheet Data'"
        :object="player.idle_sprite_sheet_data"
        @onchangeToggleSprite="
          $emit(
            'onchangeToggleSprite',
            'is_idle_sprite_sheet',
            'idle_sprite_sheet_data',
            $event,
          )
        "
        @saveSpriteSheetData="
          $emit('saveSpriteSheetData', 'idle_sprite_sheet_data', $event)
        "
      />
      <img :src="player.move_image_uri" class="content_img" />
      <v-text-field
        label="Move Image URI"
        v-model="player.move_image_uri"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickMoveImage"
        ><v-icon>image</v-icon>Select Move Image URI</v-btn
      >
      <SpriteSheet
        :title="'is move sprite sheet'"
        :isToggle="player.is_move_sprite_sheet"
        :fieldname="'Edit Move Sheet Data'"
        :object="player.move_sprite_sheet_data"
        @onchangeToggleSprite="
          $emit(
            'onchangeToggleSprite',
            'is_move_sprite_sheet',
            'move_sprite_sheet_data',
            $event,
          )
        "
        @saveSpriteSheetData="
          $emit('saveSpriteSheetData', 'move_sprite_sheet_data', $event)
        "
      />
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
  </div>
</template>

<script>
import SpriteSheet from '../../../components/SpriteSheetNew.vue';
import ResourceSelect from '../../../components/ResourceSelect.vue';

export default {
  name: 'Player',
  components: {
    SpriteSheet,
    ResourceSelect,
  },
  data() {
    return {
      isSelectImage: false,
      isSelectSound: false,
      isSelectIdleImage: false,
      isSelectMoveImage: false,
      selectedSoundUri: '',
      selectedImageUri: '',
    };
  },
  props: {
    player: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    onclickIdleImage() {
      this.isSelectImage = true;
      this.isSelectIdleImage = true;
    },
    onclickMoveImage() {
      this.isSelectImage = true;
      this.isSelectMoveImage = true;
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      switch (true) {
        case this.isSelectIdleImage:
          this.player.idle_image_uri = uri;
          this.isSelectIdleImage = false;
          break;
        case this.isSelectMoveImage:
          this.player.move_image_uri = uri;
          this.isSelectMoveImage = false;
          break;
        default:
          break;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
  },
};
</script>
<style scoped>
.content_img {
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
.btn-center {
  margin: 0 auto;
  display: flex;
}
</style>
