<template>
  <div>
    <v-layout>
      <v-flex>
        <v-text-field
          label="System Phonic"
          :value="speakingWord"
          v-model="speakingWord"
          disabled
        />
        <v-btn
          color="black"
          dark
          @click="$emit('getSpeakingWord')"
          :disabled="validated == '' || null"
        >
          System Phonic
        </v-btn>
      </v-flex>
      <v-flex>
        <v-text-field
          label="System Phonic (AZURE)"
          :value="speakingWordAzure"
          v-model="speakingWordAzure"
          disabled
        />
        <v-btn
          color="black"
          dark
          @click="$emit('getSpeakingWordAzure')"
          :disabled="validated == '' || null"
        >
          System Phonic (AZURE)
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    speakingWord: {
      type: String,
      require: true,
      // default: true,
    },
    speakingWordAzure: {
      type: String,
      // require: true,
      // default: true,
    },
    validated: {
      type: String,
      // default: false,
    },
  },
};
</script>
