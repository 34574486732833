<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn icon color="white" light v-on:click="$router.push('/snap')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ snapList.category }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            light
            v-on:click="
              onClickIsModalSnapManager();
              isCreate = true;
            "
          >
            <v-icon>add</v-icon>Add Snap
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="snapList.snaps">
          <v-layout row grid-list-md>
            <v-flex xs12 mr-2>
              <v-card>
                <v-text-field
                  class="pt-4 pl-4 pr-4"
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-data-table
                  :items="snapList.snaps"
                  :headers="headersSnap"
                  :search="search"
                  :rows-per-page-items="[20, 50, 100]"
                  :pagination.sync="pagination"
                  @update:pagination="onclickPagination"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>
                        <v-layout justify-center>{{ props.item._id }}</v-layout>
                      </td>
                      <td>
                        <v-layout justify-center>{{
                          props.item.title
                        }}</v-layout>
                      </td>
                      <td>
                        <v-layout justify-center>{{
                          props.item.snap.length
                        }}</v-layout>
                      </td>
                      <td>
                        <v-layout justify-center>{{
                          props.item.updated_at
                        }}</v-layout>
                      </td>
                      <td>
                        <v-layout justify-end>
                          <v-btn
                            icon
                            color="black"
                            @click="onClickAddHomePage(props.item._id)"
                          >
                            <v-icon>home</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="black"
                            v-on:click="onClickEdit(props.item._id)"
                          >
                            <v-icon color="white">edit</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="red"
                            v-on:click="onClickDelete(props.item._id)"
                          >
                            <v-icon color="white">delete</v-icon>
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <SnapManager
      :isOpen="isModalSnapManager"
      :closeModal="closeModalSnapManager"
      :isCreate="isCreate"
      @saveModal="isCreate ? onSaveCreate($event) : onSaveEdit($event)"
    ></SnapManager>
    <HomeDataAssign
      :isOpen="isAddHomepage"
      :gameData="selectedSnap"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.SnapGame"
      @close="isAddHomepage = false"
    ></HomeDataAssign>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import SnapManager from './components/SnapManager.vue';
import swal from 'sweetalert2';
import HomeDataAssign from '../../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../../HomePage/constant';

export default {
  name: 'Category',
  components: {
    Container,
    SnapManager,
    HomeDataAssign,
  },
  computed: {
    ...mapState({
      snapStore: state => state.snap,
      snapList: state => state.snap.snapList,
      selectedSnap: state => state.snap.selectedSnap,
      selectedCategory: state => state.snap.selectedCategory,
    }),
  },
  data: function () {
    return {
      isCreate: false,
      isModalSnapManager: false,
      headersSnap: [
        { text: '_id', value: '_id', sortable: false, align: 'center' },
        { text: 'title', value: 'title', sortable: false, align: 'center' },
        {
          text: 'snap game count',
          value: 'snap game count',
          sortable: false,
          align: 'center',
        },
        {
          text: 'updated_at',
          value: 'updated_at',
          sortable: false,
          align: 'center',
        },
      ],
      search: '',
      pagination: {
        descending: false,
        sortBy: null,
        rowsPerPage: 20,
        page: 1,
        totalItems: 0,
      },
      constantHomePage,
      isAddHomepage: false,
    };
  },
  methods: {
    ...mapActions('snap', [
      'fetchSnapList',
      'setStateByKey',
      'createSnap',
      'updateSnap',
      'deleteSnap',
    ]),
    onclickPagination() {
      // this.selectedCategoryIndex = -1;
      // this.selectedIndex = -1;
    },
    setSelectedSnapGame(id) {
      const [selectedSnap] = this.snapList.snaps.filter(({ _id }) => _id == id);
      this.setStateByKey({
        statekey: 'selectedSnap',
        value: selectedSnap,
      });
    },
    onClickAddHomePage(id) {
      this.setSelectedSnapGame(id);
      this.isAddHomepage = true;
    },
    onClickEdit(id) {
      this.setSelectedSnapGame(id);
      this.isCreate = false;
      this.onClickIsModalSnapManager();
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteSnap(id);
          }
        });
      // this.pagination.page = 1;
    },
    onSaveEdit(updateData) {
      // this.pagination.page = 1;
      const updateCategory = { ...updateData };
      this.updateSnap(updateCategory);
      this.closeModalSnapManager();
    },
    onSaveCreate(createData) {
      const createSnapData = { ...createData };
      this.createSnap(createSnapData);
      this.closeModalSnapManager();
    },
    onClickIsModalSnapManager() {
      // this.pagination.page = 1;
      this.isModalSnapManager = true;
    },
    closeModalSnapManager() {
      this.isModalSnapManager = false;
      this.isCreate = false;
    },
  },
  async mounted() {
    const { id, game_list_id, category_id, sub_id } = this.$route.query;
    if (id) {
      await this.fetchSnapList({ id });
      this.search = id;
      this.onClickEdit(id);
    } else if (game_list_id) {
      await this.fetchSnapList({
        game_list_id,
      });
    } else if (category_id && sub_id) {
      await this.fetchSnapList({
        category_id,
      });
      this.search = sub_id;
      this.onClickEdit(sub_id);
    }
  },
};
</script>
<style>
.image_thumbnail {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin-top: 15px;
}
.image_edit {
  height: 200px;
}
.row-pointer {
  cursor: pointer;
}
</style>
