<template>
  <v-flex xs7 mr-2>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Home Page List</v-toolbar-title>
        <v-spacer></v-spacer>
        <ModalFormatCell></ModalFormatCell>
      </v-toolbar>
    </v-card>
    <v-card-text class="pa-1">
      <table class="table table-striped homedata">
        <thead class="thead-dark">
          <tr>
            <th>Index</th>
            <th>Title</th>
            <th>Game</th>
            <th>Image</th>
            <th>Format</th>
            <th>Status</th>
          </tr>
        </thead>
        <draggable
          v-model="pageGameStore.selectedHomePageData.cell_datas"
          tag="tbody"
          @end="editIndexHomeList"
          :disabled="isLock"
        >
          <tr
            v-for="(element, index) in pageGameStore.selectedHomePageData
              .cell_datas"
            :key="element.name"
            @click="onClickSelect(element._id, index)"
            :class="
              index === pageGameStore.selectedHomePageIndex
                ? 'active'
                : 'inactive'
            "
          >
            <td>{{ index }}</td>
            <td>{{ element.title }}</td>
            <td>{{ HCellType[element.type].name }}</td>
            <td>
              <img
                v-if="element.img_uri"
                class="image_thumbnail1"
                lazy="element.img_uri"
                :src="element.img_uri"
              />
              <img v-else class="content_img" />
            </td>
            <td>{{ HCellFormat[element.format].name }}</td>
            <td>
              <div v-if="element.unlinked" style="color: red">Deleted</div>
            </td>
          </tr>
        </draggable>
      </table>
    </v-card-text>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '../constant';
import { ModalFormatCell } from '../components';
import draggable from 'vuedraggable';

export default {
  components: { ModalFormatCell, draggable },
  data() {
    return {
      HCellType: constant.HCellType,
      HCellFormat: constant.HCellFormat,
      headers: [
        { text: 'Title', value: 'name', sortable: false },
        { text: 'Game', value: '', sortable: false },
        { text: 'Image', value: '', sortable: false },
        { text: 'Format', value: '', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      pageGameStore: state => state.homepage,
      isLock: state => state.homepage.adminIsLock,
    }),
  },
  methods: {
    ...mapActions('homepage', [
      'setStateByKey',
      'clearStateByKey',
      'updatePageGroupData',
    ]),
    editIndexHomeList(event) {
      if (event.oldDraggableIndex !== event.newDraggableIndex) {
        const cell_list = this.pageGameStore.selectedHomePageData.cell_datas.map(
          home => home._id,
        );
        this.setStateByKey({
          statekey: 'updatePageGroup',
          key: 'cell_datas',
          value: cell_list,
        });
        this.clearStateByKey('selectedHomePageIndex');
        this.updatePageGroupData();
      }
    },
    onClickSelect(id, index) {
      const data = this.pageGameStore.selectedHomePageData.cell_datas[index];
      this.clearStateByKey('updatePageDetail');
      this.setStateByKey({
        statekey: 'updatePageDetail',
        key: 'id',
        value: id,
      });
      this.setStateByKey({
        statekey: 'selectedHomePageIndex',
        key: null,
        value: index,
      });
      this.setStateByKey({
        statekey: 'selectedHomePageDetail',
        key: null,
        value: data,
      });
      this.setStateByKey({
        statekey: 'infoGame',
        key: null,
        value: this.HCellType[data.type].name,
      });
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 350);
    },
  },
  created() {
    this.clearStateByKey('homePageList');
  },
};
</script>

<style scope>
.active {
  background-color: #212121;
}
.image_thumbnail1 {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}

.homedata {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.homedata td,
.homedata th {
  border-bottom: 1px solid #7a7a7a;
  padding: 4px;
}

.homedata tr:hover {
  background-color: #666666;
  cursor: pointer;
}

.homedata th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}
</style>
