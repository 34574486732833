/* eslint-disable prettier/prettier */
import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  shootingArrowList: [],
  selectedShootingArrow: {},
  createShootingArrow: {},
  updateShootingArrow: {},
  isLoadShootingArrow: true,
  defaultCreate: {
    category_id: '',
    title : '',
    background_uri : '',
    bgm_uri : '',
    tts_message : '',
    voice_name : 'enUSGuyNeural',
    music_intro_uri : '',
    shooting_word : [ 
        {
            word : 'hi',
            image_uri : '',
            is_sprite_sheet : false,
            sprite_sheet_data : null,
            sound_uri : '',
            collect_count : 1,
            hit_point : 2
        }, 
    ],
    obstacle : {
        image_uri : '',
        is_sprite_sheet : false,
        sprite_sheet_data : null,
        sound_uri : ''
    }
  },
};
const getters = {
  defaultCreate(state) {
    return state.defaultCreate;
  },
};
const actions = {
  fetchShootingArrowCategory({ commit, dispatch }, { page = 1, limit = 0 }) {
    const query = {
      params: { page, limit },
    };
    return http
      .GET(`/api/shooting_arrow/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'shootingArrowList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch shooting arrow in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadShootingArrow',
          key: null,
          value: false,
        });
      });
  },
  fetchShootingArrowList(
    { commit, dispatch },
    { id, game_list_id, category_id },
  ) {
    const url = id ? `/api/shooting_arrow` : `/api/shooting_arrow/category`;
    const query = {
      params: game_list_id
        ? { id: game_list_id }
        : category_id
        ? { category_id }
        : { id, isWeb: true },
    };
    return http
      .GET(url, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => {
          const shooting_arrow = e.shooting_arrow_games.map(data => {
            return {
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            };
          });
          e.shooting_arrow_games = shooting_arrow;
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'shootingArrowList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch shooting arrow in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadShootingArrow',
          key: null,
          value: false,
        });
      });
  },
  createShootingArrow({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createShootingArrow;
    http
      .POST(`/api/shooting_arrow`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.shootingArrowList._id) {
          dispatch('fetchShootingArrowList', {
            game_list_id: state.shootingArrowList._id,
          });
        } else {
          router.go({
            path: '/shooting_arrow',
          });
          dispatch('fetchShootingArrowCategory', {
            page: 1,
            limit: 0,
          });
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create shooting arrow successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create shooting arrow in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteShootingArrow({ commit, dispatch }, id) {
    http
      .DELETE(`/api/shooting_arrow?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateShootingArrow');
        dispatch('fetchShootingArrowList', {
          game_list_id: state.shootingArrowList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete shooting arrow successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete runnung game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateShootingArrow({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateShootingArrow;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/shooting_arrow`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchShootingArrowList', {
          game_list_id: state.shootingArrowList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update shooting arrow successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message || 'Cannot update shooting arrow in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
