<template>
  <v-navigation-drawer
    v-if="userInfo.isLogin"
    v-model="appConfig.drawer"
    absolute
    temporary
    style="calc(100% - 48px); height: 100vh;"
  >
    <!-- Customer Service Menu -->
    <v-list v-if="userInfo.userData.role_id === 51" class="pt-0">
      <h3 style="text-align: center; padding: 10px">Customer Service</h3>
      <v-list-tile
        v-for="menu in customerServiceMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Customer Service Menu -->

    <!-- Translator Menu -->
    <v-list v-if="userInfo.userData.role_id === 50" class="pt-0">
      <h3 style="text-align: center; padding: 10px">Translator</h3>
      <v-list-tile
        v-for="menu in translatorMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Translator Menu -->

    <!-- Developer Menu -->
    <v-list
      v-if="
        userInfo.userData.role_id === 99 || userInfo.userData.role_id === 100
      "
      class="pt-0"
    >
      <h3
        style="text-align: center; padding: 10px"
        v-if="userInfo.userData.role_id === 99"
      >
        Developer
      </h3>
      <h3
        style="text-align: center; padding: 10px"
        v-if="userInfo.userData.role_id === 100"
      >
        Admin
      </h3>
      <v-list-tile
        v-for="menu in developerMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Developer Menu -->

    <!-- Media Menu -->
    <v-list v-if="userInfo.userData.role_id === 98" class="pt-0">
      <h3 style="text-align: center; padding: 10px">Media</h3>
      <v-list-tile
        v-for="menu in mediaMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Media Menu -->

    <!-- Marketing Menu -->
    <v-list v-if="userInfo.userData.role_id === 97" class="pt-0">
      <h3 style="text-align: center; padding: 10px">Marketing</h3>
      <v-list-tile
        v-for="menu in marketingMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Marketing Menu -->

    <!-- Content Creator Menu -->
    <v-list v-if="userInfo.userData.role_id === 96" class="pt-0">
      <h3 style="text-align: center; padding: 10px">Content Creator</h3>
      <v-list-tile
        v-for="menu in contentCreatorMenu"
        :key="menu.title"
        @click="showSideBar(menu.path)"
      >
        <v-list-tile-action>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ menu.title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider></v-divider>
    </v-list>
    <!-- End Content Creator Menu -->
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { developerMenu } from '../utils/sidebarMenu/developer';
import { contentCreatorMenu } from '../utils/sidebarMenu/contentCreator';
import { marketingMenu } from '../utils/sidebarMenu/marketing';
import { translatorMenu } from '../utils/sidebarMenu/translator';
import { mediaMenu } from '../utils/sidebarMenu/media';
import { customerServiceMenu } from '../utils/sidebarMenu/customerService';

export default {
  name: 'Sidebar',
  data() {
    return {
      developerMenu: developerMenu,
      contentCreatorMenu: contentCreatorMenu,
      marketingMenu: marketingMenu,
      translatorMenu: translatorMenu,
      customerServiceMenu: customerServiceMenu,
      mediaMenu: mediaMenu,
    };
  },
  methods: {
    ...mapActions('appConfig', ['toggleDrawer']),
    showSideBar(menu) {
      this.$router.push(menu);
      this.toggleDrawer();
    },
  },
  computed: {
    ...mapState({
      appConfig: state => state.appConfig,
      userInfo: state => state.userInfo,
    }),
    // developerMenuFilterByUsername() {
    //   const gameWantHide = ['Maze Game'];
    //   const usernameWantShow = ['hinzahawa', 'ekie'];
    //   const username = this.userInfo.userData.username;
    //   return this.developerMenu.filter(({ title }) => {
    //     if (!usernameWantShow.includes(username))
    //       return !gameWantHide.includes(title);
    //     else return title;
    //   });
    // },
  },
};
</script>
