<template>
  <div>
    <v-flex>
      <v-text-field
        required
        :rules="nameRules"
        :error-messages="errorMessages"
        v-model="node.node_name"
        label="Node Name"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-text-field label="Video URI" v-model="node.video_uri"></v-text-field>
      <v-btn
        @click="
          isEditVideo = true;
          isVideoUri = true;
        "
      >
        <v-icon>audiotrack</v-icon>Select Video
      </v-btn>
    </v-flex>
    <v-flex>
      <v-checkbox
        label="Wait Video End"
        v-model="node.wait_video_end"
      ></v-checkbox>
    </v-flex>
    <!-- <v-flex>
      <v-select
        :items="constant.VIDEO_POSITION"
        label="Video Position"
        v-model="node.video_position"
      ></v-select>
    </v-flex> -->
    <v-flex>
      <v-select
        :items="constant.AFTER_VIDEO_ACTION"
        label="After Video Action"
        v-model="node.after_video_action"
      ></v-select>
    </v-flex>
    <v-flex>
      <v-checkbox
        label="Close Video In The End"
        v-model="node.close_video_in_the_end"
      ></v-checkbox>
    </v-flex>

    <v-flex>
      <v-text-field
        type="number"
        label="Delay"
        v-model="node.delay"
      ></v-text-field>
    </v-flex>
    <v-flex>
      <v-autocomplete
        label="Goto Node"
        v-model="node.goto_node"
        :items="gotoNodeStore"
        item-text="display"
        item-value="node_name"
        chips
        multiple
        hide-details
        hide-no-data
        hide-selected
      ></v-autocomplete>
    </v-flex>

    <v-flex>
      <img
        v-if="node.background_uri"
        :src="node.background_uri"
        :alt="node.node"
        class="content_img"
      />
      <v-text-field
        label="Background URI"
        v-model="node.background_uri"
      ></v-text-field>
      <v-btn
        @click="
          isSelectImage = true;
          isBackgroundUri = true;
        "
      >
        <v-icon>image</v-icon>Select Background
      </v-btn>
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeEditImage"
      :save="saveSelectImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditVideo"
      resourceType="video"
      :isShow="isEditVideo"
      :close="closeEditVideo"
      :save="saveEditVideo"
      :value="selectVideoUri"
      :onSelect="onSelectVideo"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ResourceSelect from '../../../../../components/ResourceSelect';
import constant from '../../../constant';
export default {
  name: 'CreateVideoNode',
  components: {
    ResourceSelect,
  },
  computed: {
    ...mapState({
      gotoNodeStore: state => state.minichat.gotoNodeData,
      skipLegacyProcessStatus: state =>
        state.minichat.minichatData.skip_legacy_process,
    }),
  },
  props: {
    node: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      isEditVideo: false,
      selectVideoUri: '',
      constant,
      nameRules: [v => !!v || 'Name is required'],
      typeRules: [v => !!(v > -1) || 'Type is required'],
      errorMessages: '',
      selectImageUri: '',
      isBackgroundUri: false,
      isSelectImage: false,
      width: '60%',
    };
  },
  methods: {
    saveEditVideo() {
      const uri = this.selectVideoUri;
      if (this.isVideoUri) {
        this.node.video_uri = uri;
      }
      this.selectVideoUri = '';
      this.isVideoUri = false;
      this.isEditVideo = false;
    },
    closeEditVideo() {
      this.selectVideoUri = '';
      this.isEditVideo = false;
    },
    onSelectVideo(uri) {
      this.selectVideoUri = uri;
    },

    saveSelectImage() {
      const uri = this.selectImageUri;
      if (this.isBackgroundUri) {
        this.node.background_uri = uri;
      }
      this.selectImageUri = '';
      this.isBackgroundUri = false;
      this.isSelectImage = false;
    },
    closeEditImage() {
      this.isSelectImage = false;
      this.selectImageUri = '';
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
  },
};
</script>

<style scoped>
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
</style>
