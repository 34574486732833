<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/flyinggame')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              (selectedGameListStore && selectedGameListStore.category) ||
              'Loading...'
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedGameListStore">
          <v-layout row grid-list-md v-if="!updatingState">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Game</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(game, index) in gameDataStore"
                    :key="index"
                    @click="onClickSelectGame(game)"
                    :class="index === selectedGameIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ game.title }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs5 mr-2 v-if="selectedGameIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Game Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" @click="onClickUpdateGame">
                    <v-icon>save</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <div>
                      <v-tabs
                        v-model="active"
                        color="black"
                        dark
                        slider-color="yellow"
                      >
                        <v-tab v-for="n in 5" :key="n" ripple>
                          {{ menuLabel(n - 1) }}
                        </v-tab>
                        <v-tab-item v-for="n in 5" :key="n">
                          <v-flex v-if="n === 1">
                            <v-text-field
                              label="Group Title"
                              v-model="game.title"
                            ></v-text-field>
                            <v-flex>
                              <v-text-field
                                label="Introduction Word(tts message)"
                                v-model="game.tts_message"
                              ></v-text-field>
                              <v-select
                                :items="constants.VOICE_NAME"
                                label="Voice Name"
                                :value="game.voice_name"
                                v-model="game.voice_name"
                              ></v-select>
                            </v-flex>
                            <v-flex>
                              <v-btn
                                v-if="game.introduction_voice_uri"
                                class="white--text"
                                color="subtitle_sound"
                                @click="openSubtitleSound()"
                                >Subtitle Sound
                              </v-btn>
                              <v-text-field
                                label="Introduction Voice (URL or Select file)"
                                v-model="game.introduction_voice_uri"
                              ></v-text-field>
                              <v-btn
                                @click="
                                  isSelectSound = true;
                                  isSelectIntroVoice = true;
                                "
                                ><v-icon>audiotrack</v-icon>Select Introduction
                                Voice</v-btn
                              >
                            </v-flex>
                            <v-text-field
                              label="Background Music URI (URL or Select file)"
                              v-model="game.bgm_uri"
                            ></v-text-field>
                            <v-btn
                              @click="
                                isSelectSound = true;
                                isSelectBackgroundMusic = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select Background
                              Music</v-btn
                            >
                            <v-flex>
                              <img
                                v-if="game.main_background_uri"
                                :src="game.main_background_uri"
                                class="content_img"
                              />
                              <v-text-field
                                label="Main Background URI (URL or Select file)"
                                v-model="game.main_background_uri"
                              ></v-text-field>
                              <v-btn
                                @click="
                                  isSelectImage = true;
                                  isSelectMainBackground = true;
                                "
                              >
                                <v-icon>image</v-icon>Select Main Background
                              </v-btn>
                              <v-btn dark @click="openSubBackgroundModal()">
                                <v-icon>image</v-icon>Select Sub Background
                              </v-btn>
                            </v-flex>
                          </v-flex>
                          <v-flex v-if="n === 2">
                            <v-autocomplete
                              chips
                              required
                              label="Select Character"
                              :items="constant.CHARACTER"
                              item-text="text"
                              item-value="value"
                              hide-details
                              hide-no-data
                              hide-selected
                              v-model.number="game.player_data.character"
                            ></v-autocomplete>
                            <v-text-field
                              label="Hit Audio (URL or Select file)"
                              v-model="game.player_data.hit_audio_uri"
                            ></v-text-field>
                            <v-btn
                              @click="
                                isSelectSound = true;
                                isSelectHitAudioPlayer = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select Hit Audio
                              Player</v-btn
                            >
                            <v-divider></v-divider>
                            <v-checkbox
                              label="Hide Particle"
                              v-model="game.player_data.is_hide_particle"
                            ></v-checkbox>
                            <v-text-field
                              label="Paricle URI (URL or Select file)"
                              v-model="game.player_data.particle_uri"
                            ></v-text-field>
                            <v-btn
                              @click="
                                isSelectImage = true;
                                isSelectParticleUri = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select Particle
                              Image</v-btn
                            >
                          </v-flex>
                          <v-flex v-if="n === 3">
                            <div class="modal-item">
                              <v-btn
                                block
                                large
                                color="primary"
                                @click="openModalItem()"
                              >
                                <v-icon>category</v-icon>
                                Item Manager
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex v-if="n === 4">
                            <div class="point-data">
                              <v-btn
                                block
                                large
                                class="white--text"
                                color="pointdata"
                                @click="openPointData"
                                >Point Data</v-btn
                              >
                            </div>
                          </v-flex>
                        </v-tab-item>
                      </v-tabs>
                      <div class="text-xs-center mt-3">
                        <v-btn @click="next">next tab</v-btn>
                      </div>
                    </div>
                  </v-layout>
                </v-card-text>
                <v-btn
                  block
                  color="red"
                  dark
                  @click="onClickDeleteGame(game._id)"
                >
                  <v-icon>delete</v-icon>Delete game
                </v-btn>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="selectedGameIndex !== -1 ? game.learned_words : null"
      :incidentalWords="selectedGameIndex !== -1 ? game.incidental_words : null"
      :rewards="selectedGameIndex !== -1 ? game.reward : null"
      :sentences="selectedGameIndex !== -1 ? game.sentence : null"
      :phonics="selectedGameIndex !== -1 ? game.phonic : null"
    >
    </PointData>
    <Modal
      v-if="isOpenItemModal"
      :isOpen="isOpenItemModal"
      :save="onSaveItem"
      :close="closeModalItem"
      :isLastStep="true"
      :width="width"
      title="Item Manager"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.item_manager_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.item_manager_list">
          <v-stepper-header>
            <template v-for="n in step.item_manager_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.item_manager_list > n"
                :step="n"
                editable
              >
                Item {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.item_manager_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.item_manager_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.item_manager_list,
                      name_list.item_manager_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <v-text-field
                  label="Name"
                  v-model="game.item[step - 1].name"
                ></v-text-field>
                <v-autocomplete
                  chips
                  required
                  label="Type"
                  :items="constant.ITEM_TYPE"
                  item-text="text"
                  item-value="value"
                  hide-details
                  hide-no-data
                  hide-selected
                  v-model="game.item[step - 1].type"
                ></v-autocomplete>
                <v-text-field
                  v-if="game.item[step - 1].type !== 1"
                  label="Amount"
                  type="number"
                  v-model.number="game.item[step - 1].amount"
                ></v-text-field>
                <v-flex>
                  <v-text-field
                    label="Introduction Word(tts message)"
                    v-model="game.item[step - 1].tts_message"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Voice Name"
                    :value="game.item[step - 1].voice_name"
                    v-model="game.item[step - 1].voice_name"
                  ></v-select>
                </v-flex>
                <v-flex>
                  <v-text-field
                    label="Hit Audio (URL or Select file)"
                    v-model="game.item[step - 1].hit_audio_uri"
                  ></v-text-field>
                  <v-btn
                    @click="
                      isSelectSound = true;
                      isSelectItemHitAudio = true;
                    "
                  >
                    <v-icon>audiotrack</v-icon>Select Hit Audio Item</v-btn
                  >
                </v-flex>
                <img
                  v-if="game.item[step - 1].image_uri"
                  :src="game.item[step - 1].image_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Item Image URI (URL or Select file)"
                  v-model="game.item[step - 1].image_uri"
                ></v-text-field>
                <v-btn
                  dark
                  @click="
                    isSelectItemImage = true;
                    isSelectImage = true;
                  "
                >
                  <v-icon>image</v-icon>Select Item Image
                </v-btn>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.item_manager_list)"
              >
                Next
              </v-btn>
              <v-btn flat @click="backStep(step, name_list.item_manager_list)">
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
    <Modal
      v-if="isOpenModalSubBackground"
      :isOpen="isOpenModalSubBackground"
      :save="onSaveSubBackground"
      :close="closeModalSubBackground"
      :isLastStep="true"
      title="Sub Background"
    >
      <v-flex xs12 sm12 md12>
        <v-btn
          color="success"
          small
          fab
          @click="increaseStep(name_list.sub_background_list)"
          ><v-icon>add</v-icon>
        </v-btn>
        <v-stepper v-model="element.sub_background_list">
          <v-stepper-header>
            <template v-for="n in step.sub_background_list">
              <v-stepper-step
                :key="`${n}-step`"
                :complete="element.sub_background_list > n"
                :step="n"
                editable
              >
                Sub Background {{ n }}
              </v-stepper-step>

              <v-divider
                v-if="n !== step.sub_background_list"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in step.sub_background_list"
              :key="`${step}-content`"
              :step="step"
            >
              <v-flex>
                <v-btn
                  color="error"
                  small
                  absolute
                  fab
                  right
                  @click="
                    reduceStep(
                      element.sub_background_list,
                      name_list.sub_background_list,
                    )
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <img
                  v-if="game.sub_background_list[step - 1].sub_background_uri"
                  :src="game.sub_background_list[step - 1].sub_background_uri"
                  class="content_img"
                />
                <v-text-field
                  label="Sub Background URI (URL or Select file)"
                  v-model="
                    game.sub_background_list[step - 1].sub_background_uri
                  "
                ></v-text-field>
                <v-btn
                  dark
                  @click="
                    isSelectSubBackground = true;
                    isSelectImage = true;
                  "
                >
                  <v-icon>image</v-icon>Select Sub Background
                </v-btn>
                <v-text-field
                  label="Sub Background Speed"
                  type="number"
                  v-model="
                    game.sub_background_list[step - 1].sub_background_speed
                  "
                ></v-text-field>
              </v-flex>
              <v-btn
                color="primary"
                @click="nextStep(step, name_list.sub_background_list)"
              >
                Next
              </v-btn>
              <v-btn
                flat
                @click="backStep(step, name_list.sub_background_list)"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </Modal>
  </Container>
</template>

<script>
import constant from './constant';
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import constants from '../../constants';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'FlyingGame',
  components: {
    Container,
    ResourceSelect,
    Modal,
    PointData,
  },
  computed: {
    ...mapState({
      gameDataStore: state => state.flyinggame.GAME_DATA,
      selectedGameListStore: state => state.flyinggame.SELECTED_GAME_LIST,
      updatingState: state => state.flyinggame.isUpdating,
    }),
  },
  watch: {
    'step.item_manager_list'(val) {
      this.validateWidth(val);
      if (this.element.item_manager_list > val) {
        this.element.item_manager_list = val;
      }
    },
  },
  data() {
    return {
      constants,
      isSelectParticleUri: false,
      isSelectIntroVoice: false,
      isOpenPointData: false,
      width: '60%',
      name_list: {
        sub_background_list: 'sub_background_list',
        item_manager_list: 'item_manager_list',
      },
      element: {
        sub_background_list: 1,
        item_manager_list: 1,
      },
      step: {
        sub_background_list: 1,
        item_manager_list: 1,
      },
      isSelectSubBackground: false,
      isOpenModalSubBackground: false,

      isOpenItemModal: false,
      isSelectItemHitAudio: false,
      isSelectItemImage: false,

      constant,
      active: null,
      selectedSoundUri: '',
      isSelectMainBackground: false,
      isSelectSound: false,
      isSelectBackgroundMusic: false,
      isSelectImage: false,
      selectedImageUri: '',
      selectedGameIndex: -1,
      game: {},
    };
  },
  methods: {
    ...mapActions('flyinggame', [
      'getGameList',
      'getGameData',
      'getGameDataByObjectID',
      'getCategoryByGameListID',
      'updateGame',
      'deleteGame',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.game.introduction_voice_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.game.learned_words = learned_words;
      }
      if (incidental_words) {
        this.game.incidental_words = incidental_words;
      }
      if (reward) {
        this.game.reward = reward;
      }
      if (sentence) {
        this.game.sentence = sentence;
      }
      if (phonic) {
        this.game.phonic = phonic;
      }
      this.onClickUpdateGame();
      this.isOpenPointData = false;
    },
    async createFlyingGameItem() {
      await this.fetchCategory({
        page: 1,
        limit: 0,
      });
      this.isCreate = true;
    },
    validateWidth(val) {
      if (val > 6) {
        this.width = '80%';
      } else if (val <= 6) {
        this.width = '60%';
      }
    },
    onClickDeleteGame(id) {
      this.deleteGame({ gameID: id });
    },
    openSubBackgroundModal() {
      this.isOpenModalSubBackground = true;
      this.calculateSubBackgroundStep();
    },
    calculateSubBackgroundStep() {
      if (this.game.sub_background_list.length === 0) {
        this.step.sub_background_list = 1;
      } else {
        this.step.sub_background_list = this.game.sub_background_list.length;
      }
    },
    addDefaultObjectSubBackground() {
      const formatObject = {
        sub_background_uri: '',
      };
      this.game.sub_background_list.push(formatObject);
    },
    addDefaultObjectItem() {
      const formatObject = {
        name: '',
        type: '',
        amount: 1,
        image: '',
        hit_audio_uri: '',
      };
      this.game.item.push(formatObject);
    },
    increaseStep(list) {
      const max = list === this.name_list.sub_background_list ? 3 : 10;
      if (this.step[list] < max) {
        if (list === this.name_list.sub_background_list) {
          this.addDefaultObjectSubBackground();
        } else if (list === this.name_list.item_manager_list) {
          this.addDefaultObjectItem();
        }
        this.step[list] += 1;
      }
    },
    reduceStep(step, list) {
      let index = step - 1;
      if (this.step[list] > 1) {
        if (list === this.name_list.item_manager_list) {
          this.step[list] -= 1;
          this.game.item.splice(index, 1);
        } else {
          this.step[list] -= 1;
          this.game[list].splice(index, 1);
        }
      }
    },
    nextStep(currentStep, list) {
      if (currentStep === this.step[list]) {
        this.element[list] = 1;
      } else {
        this.element[list] = currentStep + 1;
      }
    },
    backStep(currentStep, list) {
      if (currentStep > 1) {
        this.element[list] -= 1;
      }
    },
    onSaveSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    closeModalSubBackground() {
      this.isOpenModalSubBackground = false;
    },
    onSaveItem() {
      this.isOpenItemModal = false;
    },
    closeModalItem() {
      this.isOpenItemModal = false;
    },
    openModalItem() {
      this.isOpenItemModal = true;
      this.calculateItemStep();
    },
    calculateItemStep() {
      if (this.game.item.length === 0) {
        this.step.item_manager_list = 1;
      } else {
        this.step.item_manager_list = this.game.item.length;
      }
    },
    menuLabel(index) {
      const label = ['Game', 'Player', 'Item', 'Point Data'];
      return label[index];
    },
    next() {
      const active = parseInt(this.active);
      this.active = active < 2 ? active + 1 : 0;
    },
    closeSelectSound() {
      (this.isSelectSound = false), (this.selectedSoundUri = '');
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      if (this.isSelectBackgroundMusic) {
        this.game.bgm_uri = uri;
        this.isSelectBackgroundMusic = false;
      } else if (this.isSelectItemHitAudio) {
        let index = this.element.item_manager_list - 1;
        this.game.item[index].hit_audio_uri = uri;
        this.isSelectItemHitAudio = false;
      } else if (this.isSelectHitAudioPlayer) {
        this.game.player_data.hit_audio_uri = uri;
        this.isSelectHitAudioPlayer = false;
      } else if (this.isSelectIntroVoice) {
        this.game.introduction_voice_uri = uri;
        this.isSelectIntroVoice = false;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      if (this.isSelectMainBackground) {
        this.game.main_background_uri = uri;
      } else if (this.isSelectItemImage) {
        let index = this.element.item_manager_list - 1;
        this.game.item[index].image_uri = uri;
      } else if (this.isSelectSubBackground) {
        let index = this.element.sub_background_list - 1;
        this.game.sub_background_list[index].sub_background_uri = uri;
      } else if (this.isSelectParticleUri) {
        this.game.player_data.particle_uri = uri;
      }
      this.isSelectParticleUri = false;
      this.isSelectSubBackground = false;
      this.isSelectItemImage = false;
      this.isSelectMainBackground = false;
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    async onClickSelectGame(data) {
      const gameStore = this.gameDataStore;
      this.selectedGameIndex = gameStore.findIndex(
        game => game._id === data._id,
      );
      this.game = gameStore[this.selectedGameIndex];
    },
    onClickUpdateGame() {
      this.updateGame(this.game);
    },
  },
  async mounted() {
    if (Object.keys(this.selectedGameListStore).length === 0) {
      if (this.$route.query && this.$route.query.sub_id) {
        await this.getGameDataByObjectID(this.$route.query.sub_id);
      } else {
        await this.getGameData(this.$route.query.id);
      }
      setTimeout(() => {
        this.getCategoryByGameListID(this.gameDataStore[0].game_list_id);
      }, 1000);
    }
  },
};
</script>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.modal-item {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.point-data {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}

.subtitle-btn {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
}
</style>
