/* eslint-disable prettier/prettier */
import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  labyrinthList: [],
  selectedLabyrinth: {},
  createLabyrinth: {},
  updateLabyrinth: {},
  isLoadLabyrinth: true,
  defaultCreate: {
    category_id: '',
    title: '',
    character_info : {
      head_data : {
          head_uri : '',
          is_sprite_sheet : false,
          labyrinth_sprite_sheet_data : {
            column: 1, 
            row: 1, 
            frame_ms: 200, 
            loop_count: -1,
          }
      },
      body_uri : '',
      neck_uri : '',
  },
    game_round: [
      {
        items_info: {
          word: '',
          image_uri: '',
          voice_name: 'enUSAnaNeural',
          tts_message: ''
        },
        start_point: 0,
        end_point: 0
      },
    ],
    size_area: 10,
    background_uri: '',
    ground_uri: '',
    wall_uri: '',
    collect_sfx: '',
    bgm_uri: '',
    bgm_volume: '1',
    music_intro_uri: '',
    tts_message: '',
    voice_name: 'enUSAnaNeural'
  },
};
const getters = {
  defaultCreate(state) {
    return state.defaultCreate;
  },
};
const actions = {
  fetchLabyrinthCategory({ commit, dispatch }, { page = 1, limit = 0 }) {
    const query = {
      params: { page, limit },
    };
    return http
      .GET(`/api/labyrinth/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'labyrinthList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch labyrinth in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadLabyrinth',
          key: null,
          value: false,
        });
      });
  },
  fetchLabyrinthList(
    { commit, dispatch },
    { id, game_list_id, category_id },
  ) {
    const url = id ? `/api/labyrinth` : `/api/labyrinth/category`;
    const query = {
      params: game_list_id
        ? { id: game_list_id }
        : category_id
        ? { category_id }
        : { id, isWeb: true },
    };
    return http
      .GET(url, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => {
          const labyrinth = e.labyrinth_games.map(data => {
            return {
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            };
          });
          e.labyrinth_games = labyrinth;
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'labyrinthList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch labyrinth in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadLabyrinth',
          key: null,
          value: false,
        });
      });
  },
  createLabyrinth({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createLabyrinth;
    http
      .POST(`/api/labyrinth`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.labyrinthList._id) {
          dispatch('fetchLabyrinthList', {
            game_list_id: state.labyrinthList._id,
          });
        } else {
          router.go({
            path: '/labyrinth',
          });
          dispatch('fetchLabyrinthCategory', {
            page: 1,
            limit: 0,
          });
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create labyrinth successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create labyrinth in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteLabyrinth({ commit, dispatch }, id) {
    http
      .DELETE(`/api/labyrinth?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateLabyrinth');
        dispatch('fetchLabyrinthList', {
          game_list_id: state.labyrinthList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete labyrinth successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete runnung game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateLabyrinth({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateLabyrinth;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/labyrinth`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchLabyrinthList', {
          game_list_id: state.labyrinthList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update labyrinth successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message || 'Cannot update labyrinth in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
