<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-toolbar-title>Dictionary - Phonic</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="isCreate = true">
            <v-icon>add</v-icon>New Word
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout grid-list-xs row text-xs-center justify-center>
            <v-flex xs10>
              <v-radio-group v-model="searchSwitcher" row>
                <v-radio
                  label="Word"
                  value="word"
                  @change="searchByWord"
                ></v-radio>
                <v-radio
                  label="Tag"
                  value="tag"
                  @change="clearWord()"
                ></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="searchSwitcher === 'word'"
                outline
                v-model="search"
                label="Search by word"
                append-icon="search"
              ></v-text-field>
              <v-layout pb-3 v-else>
                <v-combobox
                  v-model="searchtag.model"
                  :items="searchtag.items"
                  :search-input.sync="searchtag.search"
                  hide-selected
                  hint="Maximum of 15 tags"
                  label="Search by tag (Enter to search)"
                  multiple
                  persistent-hint
                  small-chips
                  v-on:keyup.enter="searchByTag"
                >
                </v-combobox>
                <v-btn icon color="black" v-on:click="searchByTag">
                  <v-icon color="white">search</v-icon>
                </v-btn>
              </v-layout>
              <!-- <v-text-field
                  outline
                  v-model="search"
                  label="Filter by word"
                  append-icon="search"
                ></v-text-field> -->
            </v-flex>
          </v-layout>
          <v-data-table
            v-if="!dictionaryStore.isLoadDictionary"
            :items="dictionaryStore.dictionaryList"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>{{ props.item.word }}</td>
              <td>
                <img
                  v-if="props.item.img_uri"
                  lazy="props.item.img_uri"
                  class="image_thumbnail"
                  :src="props.item.img_uri"
                />
                <img v-else class="content_img" />
                <!-- <v-chip v-else color="grey" text-color="white">
                  Image Not found
                </v-chip> -->
              </td>
              <td>
                <v-chip
                  v-if="formatDisplayTag(props.item.tags)"
                  color="primary"
                  text-color="white"
                  >{{ formatDisplayTag(props.item.tags) }}</v-chip
                >
                <v-chip
                  v-if="!formatDisplayTag(props.item.tags)"
                  color="grey"
                  text-color="white"
                  >tag not found</v-chip
                >
              </td>
              <td>
                {{ props.item.updated_at }}
              </td>
              <td>
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditResource"
      :resourceType="resourceType"
      :isShow="isEditResource"
      :close="closeEditResource"
      :save="saveEditResource"
      :value="uriResource"
      :onSelect="onSelectResource"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="isEdit ? dictionaryStore.selectedWord.learned_words : null"
      :incidentalWords="
        isEdit ? dictionaryStore.selectedWord.incidental_words : null
      "
      :rewards="isEdit ? dictionaryStore.selectedWord.reward : null"
      :sentences="isEdit ? dictionaryStore.selectedWord.sentence : null"
      :phonics="isEdit ? dictionaryStore.selectedWord.phonic : null"
    >
    </PointData>
    <Modal
      v-if="isCreate"
      :isOpen="isCreate"
      :save="onClickSaveWord"
      :close="closeCreate"
      :isLastStep="valid"
      title="Create Word"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-text-field
                  label="Word*"
                  v-model="dictionaryStore.createWord.word"
                  :rules="[rules.required]"
                  @change="e => onChangeCreateWord('word', e)"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  :items="constants.VOICE_NAME"
                  label="Voice Name"
                  :value="dictionaryStore.createWord.voice_name"
                  v-model="dictionaryStore.createWord.voice_name"
                  @change="e => onChangeCreateWord('voice_name', e)"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-combobox
                  v-model="tag.model"
                  hide-selected
                  hint="Maximum of 15 tags"
                  label="Add some tags*"
                  :rules="[rules.validtag]"
                  multiple
                  persistent-hint
                  small-chips
                  @change="e => onChangeCreateWord('tags', e)"
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Start SFX (URL or Select file)"
                  :value="dictionaryStore.createWord.start_word_sfx_uri"
                  v-model="dictionaryStore.createWord.start_word_sfx_uri"
                  :rules="[rules.validURI]"
                  @change="e => onChangeCreateWord('start_word_sfx_uri', e)"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isStartSoundSFX')"
                >
                  <v-icon>audiotrack</v-icon> Edit Start SFX
                </v-btn>
                <br />
                <v-text-field
                  label="Sound* (URL or Select file)"
                  :value="dictionaryStore.createWord.soundUri"
                  v-model="dictionaryStore.createWord.soundUri"
                  :rules="[rules.required, rules.validURI]"
                  @change="e => onChangeCreateWord('soundUri', e)"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isSound')"
                >
                  <v-icon>audiotrack</v-icon> Edit Sound
                </v-btn>
                <v-text-field
                  label="End SFX (URL or Select file)"
                  :value="dictionaryStore.createWord.sound_sfx_uri"
                  v-model="dictionaryStore.createWord.sound_sfx_uri"
                  @change="e => onChangeCreateWord('sound_sfx_uri', e)"
                  :rules="[rules.validURI]"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isEndSoundSFX')"
                >
                  <v-icon>audiotrack</v-icon> Edit End SFX
                </v-btn>
              </v-flex>
              <SystemPhonic
                :speakingWord="dictionaryStore.createWord.system_phone"
                :speakingWordAzure="
                  dictionaryStore.createWord.system_phone_azure
                "
                :validated="dictionaryStore.createWord.word"
                @getSpeakingWord="
                  getSpeakingWord(dictionaryStore.createWord.word, 'create')
                "
                @getSpeakingWordAzure="
                  getSpeakingWordAzure(
                    dictionaryStore.createWord.word,
                    'create',
                  )
                "
              ></SystemPhonic>
              <v-flex>
                <v-text-field
                  label="Image URI* (URL or Select file)"
                  v-model="dictionaryStore.createWord.img_uri"
                  :rules="[rules.required, rules.validURI]"
                  @change="e => onChangeCreateWord('img_uri', e)"
                ></v-text-field>
                <v-btn
                  color="black"
                  dark
                  @click="setResource('image', 'isImage')"
                  ><v-icon>images</v-icon>Select Image URI</v-btn
                >
              </v-flex>
              <v-flex
                ><v-text-field
                  type="number"
                  label="Mic Duration"
                  v-model="dictionaryStore.createWord.mic_duration"
                  :rules="[rules.isNumber]"
                  @change="e => onChangeCreateWord('mic_duration', e)"
                ></v-text-field
              ></v-flex>
              <v-flex>
                <SpriteSheet
                  :title="'Use Sprite Sheet'"
                  :isToggle="dictionaryStore.createWord.is_sprite_sheet"
                  :fieldname="'Edit Sheet Data'"
                  :object="dictionaryStore.createWord.sprite_sheet_data"
                  @onchangeToggleSprite="
                    onchangeToggleSprite(
                      'is_sprite_sheet',
                      'createWord',
                      $event,
                    )
                  "
                  @saveSpriteSheetData="
                    saveSpriteSheetData(
                      'sprite_sheet_data',
                      'createWord',
                      $event,
                    )
                  "
                />
              </v-flex>
              <v-flex
                v-for="(ps, index) in dictionaryStore.createWord.phonicSound"
                :key="index"
                class="elevation-2"
              >
                <container>
                  <v-layout column>
                    <v-card-actions>
                      <label>
                        <b>🎯 Sound {{ index + 1 }}:</b>
                      </label>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="dictionaryStore.createWord.phonicSound.length > 1"
                        icon
                        color="red"
                        dark
                        @click="onDeleteCreatePhonicSound(index)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-flex>
                      <v-text-field
                        label="Index"
                        v-model="ps.index"
                        v-on:change="
                          e =>
                            onChangeCreatePhonicSound(
                              'index',
                              parseInt(e),
                              index,
                            )
                        "
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Karaoke phonic text (display for combine)*"
                        :value="ps.text"
                        v-model="ps.text"
                        :rules="[rules.required]"
                        v-on:change="
                          e => onChangeCreatePhonicSound('text', e, index)
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Sound* (URL or Select file)"
                        :value="ps.soundUri"
                        v-model="ps.soundUri"
                        :rules="
                          notRequired[index]
                            ? [rules.validURI]
                            : [rules.required, rules.validURI]
                        "
                        v-on:change="
                          e => onChangeCreatePhonicSound('soundUri', e, index)
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="
                          setResource('sound', 'isSoundPhonic', index)
                        "
                      >
                        <v-icon>audiotrack</v-icon> Edit Sound
                      </v-btn>
                    </v-flex>
                    <br />
                    <v-layout row wrap>
                      <v-flex>
                        <v-text-field
                          label="Phone*"
                          :value="ps.phone"
                          v-model="ps.phone"
                          :rules="[rules.required]"
                          v-on:change="
                            e => onChangeCreatePhonicSound('phone', e, index)
                          "
                        />
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Match character string*"
                          :value="ps.match_character_string"
                          v-model.trim="ps.match_character_string"
                          :rules="[rules.required]"
                          v-on:change="
                            e =>
                              onChangeCreatePhonicSound(
                                'match_character_string',
                                e,
                                index,
                              )
                          "
                        />
                      </v-flex>
                    </v-layout>
                    <v-flex>
                      <v-text-field
                        label="Suggest Video URI (.mp4)"
                        :value="ps.suggest_video_uri"
                        v-model="ps.suggest_video_uri"
                        :rules="[rules.validVideo]"
                        v-on:change="
                          e =>
                            onChangeCreatePhonicSound(
                              'suggest_video_uri',
                              e,
                              index,
                            )
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="setResource('video', 'isVideo', index)"
                      >
                        <v-icon>video_library</v-icon> Edit Suggest Video
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Suggest Sound URI"
                        :value="ps.suggest_sound_uri"
                        v-model="ps.suggest_sound_uri"
                        v-on:change="
                          e =>
                            onChangeCreatePhonicSound(
                              'suggest_sound_uri',
                              e,
                              index,
                            )
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="
                          setResource('sound', 'isSuggestSound', index)
                        "
                      >
                        <v-icon>audiotrack</v-icon> Edit Suggest Sound
                      </v-btn>
                    </v-flex>
                    <!-- <v-flex>
                      <v-text-field
                        label="Video Description"
                        :value="ps.video_description"
                        v-model="ps.video_description"
                        v-on:change="
                          e =>
                            onChangeCreatePhonicSound(
                              'video_description',
                              e,
                              index,
                            )
                        "
                      />
                    </v-flex> -->
                    <v-flex>
                      <v-select
                        label="Repeat"
                        v-model="ps.repeat"
                        v-on:change="
                          e => onChangeCreatePhonicSound('repeat', e, index)
                        "
                        :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        label="Play with"
                        v-model="ps.playWith"
                        v-on:change="
                          e => onChangeCreatePhonicSound('playWith', e, index)
                        "
                        :items="[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Skip"
                        v-model="ps.skip"
                        :value="ps.skip"
                        v-on:change="
                          e => onChangeCreatePhonicSound('skip', e, index)
                        "
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </container>
              </v-flex>
              <v-flex>
                <v-btn
                  color="green"
                  v-on:click="
                    addCreatePhonicSound();
                    addnotRequired();
                  "
                >
                  <v-icon>add</v-icon>Add
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
    <Modal
      v-if="isEdit"
      :isOpen="isEdit"
      :save="onClickUpdateWord"
      :close="closeEdit"
      :isLastStep="valid"
      title="Edit Word"
    >
      <v-card>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout grid-list-xs column text-xs-center>
              <v-flex>
                <v-btn
                  class="white--text"
                  color="pointdata"
                  @click="openPointData"
                  >Point Data
                </v-btn>
              </v-flex>
              <v-flex>
                <v-checkbox
                  label="Available for Free User"
                  v-model="dictionaryStore.selectedWord.isGuestPlayable"
                  :value="dictionaryStore.selectedWord.isGuestPlayable"
                  v-on:change="e => onChangeUpdateWord('isGuestPlayable', e)"
                >
                </v-checkbox>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Word*"
                  v-model="dictionaryStore.selectedWord.word"
                  v-on:change="e => onChangeUpdateWord('word', e)"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-select
                  :items="constants.VOICE_NAME"
                  label="Voice Name"
                  :value="dictionaryStore.selectedWord.voice_name"
                  v-model="dictionaryStore.selectedWord.voice_name"
                  v-on:change="e => onChangeUpdateWord('voice_name', e)"
                ></v-select>
              </v-flex>
              <v-flex>
                <v-combobox
                  v-model="dictionaryStore.selectedWord.tags"
                  :value="dictionaryStore.selectedWord.tags"
                  hide-selected
                  hint="Maximum of 15 tags"
                  label="Add some tags*"
                  :rules="[rules.validtag]"
                  multiple
                  persistent-hint
                  small-chips
                  @change="e => onChangeUpdateWord('tags', e)"
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Start SFX (URL or Select file)"
                  :value="dictionaryStore.selectedWord.start_word_sfx_uri"
                  v-model="dictionaryStore.selectedWord.start_word_sfx_uri"
                  @change="e => onChangeUpdateWord('start_word_sfx_uri', e)"
                  :rules="[rules.validURI]"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isStartSoundSFX')"
                >
                  <v-icon>audiotrack</v-icon> Edit End SFX
                </v-btn>
                <br />
                <v-text-field
                  label="Sound (URL or Select file)"
                  :value="dictionaryStore.selectedWord.soundUri"
                  v-model="dictionaryStore.selectedWord.soundUri"
                  :rules="[rules.validURI]"
                  v-on:change="e => onChangeUpdateWord('soundUri', e)"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isSound')"
                >
                  <v-icon>audiotrack</v-icon> Edit Sound
                </v-btn>
                <v-text-field
                  label="End SFX (URL or Select file)"
                  :value="dictionaryStore.selectedWord.sound_sfx_uri"
                  v-model="dictionaryStore.selectedWord.sound_sfx_uri"
                  @change="e => onChangeUpdateWord('sound_sfx_uri', e)"
                  :rules="[rules.validURI]"
                />
                <v-btn
                  color="black"
                  dark
                  v-on:click="setResource('sound', 'isEndSoundSFX')"
                >
                  <v-icon>audiotrack</v-icon> Edit End SFX
                </v-btn>
              </v-flex>
              <SystemPhonic
                :speakingWord="dictionaryStore.selectedWord.system_phone"
                :speakingWordAzure="
                  dictionaryStore.selectedWord.system_phone_azure
                "
                @getSpeakingWord="
                  getSpeakingWord(dictionaryStore.selectedWord.word)
                "
                @getSpeakingWordAzure="
                  getSpeakingWordAzure(dictionaryStore.selectedWord.word)
                "
              ></SystemPhonic>
              <v-flex>
                <v-text-field
                  label="Image URI*"
                  v-model="dictionaryStore.selectedWord.img_uri"
                  v-on:change="e => onChangeUpdateWord('img_uri', e)"
                  :rules="[rules.required, rules.validURI]"
                ></v-text-field>
                <v-btn
                  color="black"
                  dark
                  @click="setResource('image', 'isImage')"
                  ><v-icon>images</v-icon>Select Image URI</v-btn
                >
              </v-flex>
              <v-flex
                ><v-text-field
                  type="number"
                  label="Mic Duration"
                  v-model="dictionaryStore.selectedWord.mic_duration"
                  :rules="[rules.isNumber]"
                  @change="e => onChangeUpdateWord('mic_duration', e)"
                ></v-text-field
              ></v-flex>
              <v-flex>
                <SpriteSheet
                  :title="'Use Sprite Sheet'"
                  :isToggle="dictionaryStore.selectedWord.is_sprite_sheet"
                  :fieldname="'Edit Sheet Data'"
                  :object="dictionaryStore.selectedWord.sprite_sheet_data"
                  @onchangeToggleSprite="
                    onchangeToggleSprite('is_sprite_sheet', 'editWord', $event)
                  "
                  @saveSpriteSheetData="
                    saveSpriteSheetData('sprite_sheet_data', 'editWord', $event)
                  "
                />
              </v-flex>
              <v-flex
                v-for="(ps, index) in dictionaryStore.selectedWord.phonicSound"
                :key="index"
                class="elevation-2"
              >
                <container>
                  <v-layout column>
                    <v-card-actions>
                      <label>
                        <b>🎯 Sound {{ index + 1 }}:</b>
                      </label>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="
                          dictionaryStore.selectedWord.phonicSound.length > 1
                        "
                        icon
                        color="red"
                        dark
                        @click="onDeleteUpdatePhonicSound(index)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-flex>
                      <v-text-field
                        label="Index"
                        v-model="ps.index"
                        v-on:change="
                          e =>
                            onChangeUpdatePhonicSound(
                              'index',
                              parseInt(e),
                              index,
                            )
                        "
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Karaoke phonic text (display for combine)*"
                        :value="ps.text"
                        v-model="ps.text"
                        :rules="[rules.required]"
                        v-on:change="
                          e => onChangeUpdatePhonicSound('text', e, index)
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Sound* (URL or Select file)"
                        :value="ps.soundUri"
                        v-model="ps.soundUri"
                        :rules="
                          notRequired[index]
                            ? [rules.validURI]
                            : [rules.required, rules.validURI]
                        "
                        v-on:change="
                          e => onChangeUpdatePhonicSound('soundUri', e, index)
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="
                          setResource('sound', 'isSoundPhonic', index)
                        "
                      >
                        <v-icon>audiotrack</v-icon> Edit Sound
                      </v-btn>
                    </v-flex>
                    <br />
                    <v-layout row wrap>
                      <v-flex>
                        <v-text-field
                          label="Phone*"
                          :value="ps.phone"
                          v-model="ps.phone"
                          :rules="[rules.required]"
                          v-on:change="
                            e => onChangeUpdatePhonicSound('phone', e, index)
                          "
                        />
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          label="Match character string*"
                          :value="ps.match_character_string"
                          v-model="ps.match_character_string"
                          :rules="[rules.required]"
                          v-on:change="
                            e =>
                              onChangeUpdatePhonicSound(
                                'match_character_string',
                                e,
                                index,
                              )
                          "
                        />
                      </v-flex>
                    </v-layout>
                    <v-flex>
                      <v-text-field
                        label="Suggest Video URI (.mp4)"
                        :value="ps.suggest_video_uri"
                        v-model="ps.suggest_video_uri"
                        :rules="[rules.validVideo]"
                        v-on:change="
                          e =>
                            onChangeUpdatePhonicSound(
                              'suggest_video_uri',
                              e,
                              index,
                            )
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="setResource('video', 'isVideo', index)"
                      >
                        <v-icon>video_library</v-icon> Edit Suggest Video
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Suggest Sound URI"
                        :value="ps.suggest_sound_uri"
                        v-model="ps.suggest_sound_uri"
                        v-on:change="
                          e =>
                            onChangeUpdatePhonicSound(
                              'suggest_sound_uri',
                              e,
                              index,
                            )
                        "
                      />
                      <v-btn
                        color="black"
                        dark
                        v-on:click="
                          setResource('sound', 'isSuggestSound', index)
                        "
                      >
                        <v-icon>audiotrack</v-icon> Edit Suggest Sound
                      </v-btn>
                    </v-flex>
                    <!-- <v-flex>
                      <v-text-field
                        label="Video Description"
                        :value="ps.video_description"
                        v-model="ps.video_description"
                        v-on:change="
                          e =>
                            onChangeUpdatePhonicSound(
                              'video_description',
                              e,
                              index,
                            )
                        "
                      />
                    </v-flex> -->
                    <v-flex>
                      <v-select
                        label="Repeat"
                        placeholder="Repeat"
                        v-model="ps.repeat"
                        v-on:change="
                          e => onChangeUpdatePhonicSound('repeat', e, index)
                        "
                        :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-select
                        label="Play with"
                        v-model="ps.playWith"
                        v-on:change="
                          e => onChangeUpdatePhonicSound('playWith', e, index)
                        "
                        :items="[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      ></v-select>
                      <!-- ></v-text-field> -->
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Skip"
                        v-model="ps.skip"
                        :value="ps.skip"
                        v-on:change="
                          e => onChangeUpdatePhonicSound('skip', e, index)
                        "
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </container>
              </v-flex>
              <v-flex>
                <v-btn color="green" v-on:click="addUpdatePhonicSound()">
                  <v-icon>add</v-icon>Add
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card>
    </Modal>
  </Container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import swal from 'sweetalert2';
import Container from '../components/Container';
import Modal from '../components/Modal';
import ResourceSelect from '../components/ResourceSelect';
import PointData from '../components/PointData';
import constants from '../constants';
import SystemPhonic from '../components/SystemPhonic.vue';
import SpriteSheet from '../components/SpriteSheetNew.vue';
import rules from '../utils/ruleValidate';

export default {
  name: 'Dictionary',
  components: {
    Container,
    Modal,
    ResourceSelect,
    PointData,
    SystemPhonic,
    SpriteSheet,
  },
  data: function () {
    return {
      constants,
      search: '',
      headers: [
        { text: 'Word', value: 'word', sortable: true },
        { text: 'Image', value: 'img', sortable: false },
        { text: 'Tag', value: 'tag', sortable: true },
        { text: 'Updated Date', value: 'updated_at', sortable: true },
        { text: '', value: '', sortable: false },
      ],
      isCreate: false,
      isEdit: false,
      phonicIndex: 0,
      isOpenPointData: false,
      notRequired: [false],
      searchSwitcher: 'word',
      searchtag: {
        items: [],
        model: [],
        // search: '',
      },
      tag: {
        // items: [],
        model: [],
        // search: '',
      },
      valid: true,
      rules,
      resourceType: '',
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isSound: false,
          isEndSoundSFX: false,
          isStartSoundSFX: false,
          isSoundPhonic: false,
          isSuggestSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isImage: false,
        },
        video: {
          isEdit: false,
          selectUri: '',
          isVideo: false,
        },
      },
    };
  },
  methods: {
    ...mapActions('appConfig', ['toggleAlert']),
    ...mapActions('dicphonic', [
      'fetchDictionary',
      'addNewWord',
      'updateWord',
      'deleteWordById',
      'editUpdateWord',
      'editUpdatePhonicSound',
      'editCreatePhonicSound',
      'addUpdatePhonicSound',
      'addCreatePhonicSound',
      'editCreateWord',
      'selectedWord',
      'clearSelectData',
      'clearCreateWord',
      'fetchSpeakingWord',
      'fetchSpeakingWordAzure',
      'searchTags',
      'clearDictionaryList',
    ]),

    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.editUpdateWord({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.editUpdateWord({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.editUpdateWord({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.editUpdateWord({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.editUpdateWord({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateWord();
      this.isOpenPointData = false;
    },
    onClickEdit(id) {
      this.clearSelectData();
      this.isEdit = true;
      this.selectedWord(id);
      this.notRequired = [];
      this.dictionaryStore.selectedWord.phonicSound.forEach(e => {
        this.notRequired.push(e.skip);
      });
      this.checkDataSpriteSheet();
    },
    checkDataSpriteSheet() {
      if (!('is_sprite_sheet' in this.dictionaryStore.selectedWord)) {
        this.dictionaryStore.selectedWord = {
          ...this.dictionaryStore.selectedWord,
        };
        this.dictionaryStore.selectedWord.is_sprite_sheet = false;
      }
      if (!('sprite_sheet_data' in this.dictionaryStore.selectedWord)) {
        this.dictionaryStore.selectedWord = {
          ...this.dictionaryStore.selectedWord,
        };
        this.dictionaryStore.selectedWord.sprite_sheet_data = {
          column: 1,
          row: 1,
          frame_ms: 200,
          loop_count: -1,
        };
      }
    },
    onClickDelete(id) {
      this.deleteWordById({ id });
    },
    closeCreate() {
      this.isCreate = false;
      this.tag.model = [];
      this.clearCreateWord();
      this.notRequired = [false];
    },
    onClickSaveWord() {
      if (this.$refs.form.validate()) {
        const createWordData = { ...this.dictionaryStore.createWord };
        this.addNewWord(createWordData);
        this.tag.model = [];
        this.clearCreateWord();
        this.isCreate = false;
        this.notRequired = [false];
      }
    },
    onChangeCreateWord(key, value) {
      this.editCreateWord({ key, value });
    },
    onChangeCreatePhonicSound(key, value, index) {
      if (key == 'skip') {
        value = value ? true : false;
        this.notRequired = [...this.notRequired];
        this.notRequired[index] === true
          ? (this.notRequired[index] = false)
          : (this.notRequired[index] = true);
      }
      this.editCreatePhonicSound({ key, value, index });
    },
    closeEdit() {
      this.isEdit = false;
      this.notRequired = [false];
    },
    onClickUpdateWord() {
      if (this.$refs.form.validate()) {
        const updateWordData = { ...this.dictionaryStore.selectedWord };
        if (this.searchtag.model.length) {
          this.updateWord({ data: updateWordData, tag: this.searchtag.model });
        } else {
          this.updateWord({ data: updateWordData });
        }
        this.isEdit = false;
        this.notRequired = [false];
      }
    },
    onChangeUpdateWord(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.editUpdateWord({ key, value });
    },
    onChangeUpdatePhonicSound(key, value, index) {
      if (key == 'skip') {
        value = value ? true : false;
        this.notRequired = [...this.notRequired];
        this.notRequired[index] === true
          ? (this.notRequired[index] = false)
          : (this.notRequired[index] = true);
      }
      this.editUpdatePhonicSound({ key, value, index });
    },
    onDeleteUpdatePhonicSound(index) {
      const phonicData = this.dictionaryStore.selectedWord.phonicSound;
      phonicData.splice(index, 1);
      this.editUpdateWord({
        key: 'phonicSound',
        value: phonicData,
      });
      this.dictionaryStore.selectedWord.phonicSound = phonicData;
      this.notRequired.splice(index, 1);
    },
    onDeleteCreatePhonicSound(index) {
      const phonicData = this.dictionaryStore.createWord.phonicSound;
      phonicData.splice(index, 1);
      this.editCreateWord({
        key: 'phonicSound',
        value: phonicData,
      });
      this.dictionaryStore.createWord.phonicSound = phonicData;
      this.notRequired.splice(index, 1);
    },
    getSpeakingWord(data, type) {
      //api SpeakingWord
      this.fetchSpeakingWord({
        category: data,
        key: 'system_phone',
        type: type,
      });
    },
    getSpeakingWordAzure(data, type) {
      //api SpeakingWordAzure
      this.fetchSpeakingWordAzure({
        word: data,
        key: 'system_phone_azure',
        type: type,
      });
    },
    formatDisplayTag(value) {
      if (value) {
        return value.toString().split(',').join(' | ');
      } else {
        return null;
      }
    },
    searchByTag() {
      if (Array.isArray(this.searchtag.model) && this.searchtag.model.length) {
        this.searchTags({
          tags: this.searchtag.model,
        });
      }
    },
    searchByWord() {
      this.searchtag.model = [];
      this.fetchDictionary({ page: 1, limit: 0 });
    },
    addnotRequired() {
      this.notRequired.push(false);
    },
    onchangeToggleSprite(key, type, { value, index }) {
      if (type === 'createWord') {
        this.dictionaryStore.createWord = {
          ...this.dictionaryStore.createWord,
        };
        this.dictionaryStore.createWord[key] = value;
      } else {
        this.dictionaryStore.selectedWord = {
          ...this.dictionaryStore.selectedWord,
        };
        this.dictionaryStore.selectedWord[key] = value;
      }
    },
    saveSpriteSheetData(key, type, { index, value }) {
      if (type === 'createWord') {
        this.dictionaryStore.createWord = {
          ...this.dictionaryStore.createWord,
        };
        this.dictionaryStore.createWord[key] = value;
      } else {
        this.dictionaryStore.selectedWord = {
          ...this.dictionaryStore.selectedWord,
        };
        this.dictionaryStore.selectedWord[key] = value;
      }
    },
    clearWord() {
      this.clearDictionaryList();
    },
    setResource(resource, isType, indexPhonic) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
      this.phonicIndex = indexPhonic > 0 ? indexPhonic : 0;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isImage) {
            if (this.isCreate) {
              this.editCreateWord({
                key: 'img_uri',
                value: uri,
              });
            } else {
              this.editUpdateWord({
                key: 'img_uri',
                value: uri,
              });
            }
          }
          break;
        case 'sound':
          if (this.resource[resourceType].isSound) {
            if (this.isCreate) {
              this.editUpdateWord({
                key: 'soundUri',
                value: uri,
              });
              this.dictionaryStore.createWord.soundUri = uri;
            } else {
              this.onChangeUpdateWord({
                key: 'soundUri',
                value: uri,
              });
              this.dictionaryStore.selectedWord.soundUri = uri;
            }
          } else if (this.resource[resourceType].isStartSoundSFX) {
            if (this.isCreate) {
              this.editUpdateWord({
                key: 'start_word_sfx_uri',
                value: uri,
              });
              this.dictionaryStore.createWord.start_word_sfx_uri = uri;
            } else {
              this.onChangeUpdateWord({
                key: 'start_word_sfx_uri',
                value: uri,
              });
              this.dictionaryStore.selectedWord.start_word_sfx_uri = uri;
            }
          } else if (this.resource[resourceType].isEndSoundSFX) {
            if (this.isCreate) {
              this.editUpdateWord({
                key: 'sound_sfx_uri',
                value: uri,
              });
              this.dictionaryStore.createWord.sound_sfx_uri = uri;
            } else {
              this.onChangeUpdateWord({
                key: 'sound_sfx_uri',
                value: uri,
              });
              this.dictionaryStore.selectedWord.sound_sfx_uri = uri;
            }
          } else if (this.resource[resourceType].isSoundPhonic) {
            if (this.isCreate) {
              this.editCreatePhonicSound({
                key: 'soundUri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.createWord.phonicSound[
                this.phonicIndex
              ].soundUri = uri;
            } else {
              this.editUpdatePhonicSound({
                key: 'soundUri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.selectedWord.phonicSound[
                this.phonicIndex
              ].soundUri = uri;
            }
          } else if (this.resource[resourceType].isSuggestSound) {
            if (this.isCreate) {
              this.editCreatePhonicSound({
                key: 'suggest_sound_uri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.createWord.phonicSound[
                this.phonicIndex
              ].suggest_sound_uri = uri;
            } else {
              this.editUpdatePhonicSound({
                key: 'suggest_sound_uri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.selectedWord.phonicSound[
                this.phonicIndex
              ].suggest_sound_uri = uri;
            }
          }
          break;
        case 'video':
          if (this.resource[resourceType].isVideo) {
            if (this.isCreate) {
              this.editCreatePhonicSound({
                key: 'suggest_video_uri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.createWord.phonicSound[
                this.phonicIndex
              ].suggest_video_uri = uri;
            } else {
              this.editUpdatePhonicSound({
                key: 'suggest_video_uri',
                value: uri,
                index: this.phonicIndex,
              });
              this.dictionaryStore.selectedWord.phonicSound[
                this.phonicIndex
              ].suggest_video_uri = uri;
            }
          }
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
  },
  mounted: function () {
    this.fetchDictionary({ page: 1, limit: 0 });
  },
  watch: {
    'tag.model'(val) {
      if (val.length > 15) {
        this.$nextTick(() => this.tag.model.pop());
      }
    },
    'searchtag.model'(val) {
      if (val.length > 15) {
        this.$nextTick(() => this.searchtag.model.pop());
      }
    },
    searchSwitcher(val) {
      // this.clearResourceState();
      if (val === 'tag') {
        this.search = '';
      }
      if (val === 'word') {
        this.searchtag.model = [];
      }
    },
  },
  computed: {
    ...mapState({
      dictionaryStore: state => state.dicphonic,
      userInfoStore: state => state.userInfo,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
