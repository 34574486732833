<template>
  <Container>
    <v-flex justify-start>
      <v-card class="elevation-12">
        <v-toolbar dark tabs>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/shooting_arrow')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ shootingArrowList.category }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="showModalCreate()">
            <v-icon>add</v-icon>New ShootingArrow Game
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-if="!isLoadShootingArrow"
            :items="shootingArrowList.shooting_arrow_games"
            :headers="headers"
            :search="search"
            :rows-per-page-items="[20, 50, 100]"
          >
            <template v-slot:items="props">
              <td>
                <v-layout justify-center>{{ props.index + 1 }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>{{ props.item.title }}</v-layout>
              </td>
              <td>
                <v-layout justify-center>
                  <v-chip color="primary" text-color="white">
                    {{ props.item.display_shooting_word_list }}
                  </v-chip>
                </v-layout>
              </td>
              <td>
                <v-layout justify-center> {{ props.item.updated_at }}</v-layout>
              </td>
              <td>
                <v-layout justify-end>
                  <v-btn
                    icon
                    color="black"
                    @click="onClickAddHomePage(props.item._id)"
                  >
                    <v-icon>home</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="black"
                    v-on:click="onClickEdit(props.item._id)"
                  >
                    <v-icon color="white">edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    v-on:click="onClickDelete(props.item._id)"
                  >
                    <v-icon color="white">delete</v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-progress-linear v-else :indeterminate="true"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-flex>
    <ModalCreateUpdate
      :isOpen="isShowModal"
      :isCreate="isCreate"
      @close="isShowModal = false"
    ></ModalCreateUpdate>
    <HomeDataAssign
      :isOpen="isAddHomepage"
      :gameData="selectedShootingArrow"
      :type="constantHomePage.GameType.PgGame"
      :typePgGame="constantHomePage.typePgGame.ShootingArrow"
      @close="isAddHomepage = false"
    ></HomeDataAssign>
  </Container>
</template>

<!-- eslint-disable prettier/prettier -->
<script>
import { mapActions, mapState } from 'vuex';
import Container from '../../../components/Container';
import { ModalCreateUpdate } from './components';
import rules from '../../../utils/ruleValidate';
import swal from 'sweetalert2';
import constants from './components/constants';
import HomeDataAssign from '../../HomePage/components/HomeDataAssign.vue';
import constantHomePage from '../../HomePage/constant';

export default {
  name: 'ShootingArrow',
  components: {
    Container,
    ModalCreateUpdate,
    HomeDataAssign,
  },
  data() {
    return {
      constants,
      constantHomePage,
      search: '',
      headers: [
        {
          text: 'No.',
          value: 'no.',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Title',
          value: 'title',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Shooting Word',
          value: 'round',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
          align: 'center',
          sortable: false,
        },
      ],
      isCreate: false,
      isShowModal: false,
      rules,
      isAddHomepage: false,
    };
  },
  beforeUpdate() {
    this.formatDisplayShootingWord();
  },
  async mounted() {
    const { id, game_list_id, category_id, sub_id } = this.$route.query;
    if (id) {
      await this.fetchShootingArrowList({ id });
      this.onClickEdit(id);
    } else if (game_list_id) {
      await this.fetchShootingArrowList({
        game_list_id,
      });
    } else if (category_id && sub_id) {
      await this.fetchShootingArrowList({
        category_id,
      });
      this.onClickEdit(sub_id);
    }
  },
  computed: {
    ...mapState({
      shootingArrowGameStore: state => state.shooting_arrow,
      shootingArrowList: state => state.shooting_arrow.shootingArrowList,
      selectedShootingArrow: state =>
        state.shooting_arrow.selectedShootingArrow,
      isLoadShootingArrow: state => state.shooting_arrow.isLoadShootingArrow,
      defaultCreate: state => state.shooting_arrow.defaultCreate,
    }),
  },
  methods: {
    ...mapActions('shooting_arrow', [
      'fetchShootingArrowList',
      'deleteShootingArrow',
      'setStateByKey',
    ]),
    formatDisplayShootingWord() {
      this.shootingArrowList.shooting_arrow_games.forEach((element, index) => {
        let shootingWords =
          this.shootingArrowList.shooting_arrow_games[index].shooting_word;
        let list = [];
        shootingWords.forEach(element => {
          list.push(element.word);
        });
        this.shootingArrowList.shooting_arrow_games[
          index
        ].display_shooting_word_list = list.toString();
      });
    },
    setSelectedShootingArrow(id) {
      const [selectedData] = this.shootingArrowList.shooting_arrow_games.filter(
        ({ _id }) => _id == id,
      );
      if (selectedData) {
        this.setStateByKey({
          statekey: 'selectedShootingArrow',
          value: selectedData,
        });
      }
    },
    onClickAddHomePage(id) {
      this.setSelectedShootingArrow(id);
      this.isAddHomepage = true;
    },
    onClickEdit(id) {
      this.setSelectedShootingArrow(id);
      this.setStateByKey({
        statekey: 'updateShootingArrow',
        key: 'id',
        value: id,
      });
      this.isShowModal = true;
      this.isCreate = false;
    },
    onClickDelete(id) {
      swal
        .fire({
          titleText: 'Are you sure to delete?',
          type: 'warning',
          showCancelButton: true,
        })
        .then(result => {
          if (result.value) {
            this.deleteShootingArrow(id);
          }
        });
    },
    showModalCreate() {
      this.isShowModal = true;
      this.isCreate = true;
      this.setStateByKey({
        statekey: 'createShootingArrow',
        value: this.defaultCreate,
      });
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 90px;
  width: 90px;
  margin-top: 15px;
}
.content_img {
  height: 90px;
  width: 90px;
  border-radius: 8px;
}
</style>
