<template>
  <div>
    <Modal
      :v-if="isEditMutiImage"
      :isOpen="isEditMutiImage"
      :isLastStep="valid"
      :close="close"
      :save="save"
      title="Background Images"
    >
      <v-btn icon color="green" v-on:click="addData()">
        <v-icon>add</v-icon>
      </v-btn>
      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              <!-- item {{ n }} -->
            </v-stepper-step>

            <v-divider v-if="n !== steps" :key="n"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content v-for="n in steps" :key="`${n}-content`" :step="n">
            <v-form>
              <v-card v-if="currentStep > 0">
                <v-toolbar dark>
                  <v-toolbar-title>Image {{ currentStep }}</v-toolbar-title>
                  <v-btn
                    v-if="steps > 1"
                    color="error"
                    small
                    absolute
                    fab
                    right
                    @click="onClickRemoveByIndex(currentStep - 1)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container v-if="data.length">
                  <v-layout>
                    <v-flex>
                      <v-form ref="formItem" v-model="valid" lazy-validation>
                        <div>
                          <img
                            v-if="data[currentStep - 1]"
                            :src="data[currentStep - 1]"
                            :alt="data[currentStep - 1]"
                            class="content_img"
                          />
                          <v-text-field
                            label="Background Image URI (URL or Select file)"
                            :value="data[currentStep - 1]"
                            v-model="data[currentStep - 1]"
                            :rules="[rules.validURI]"
                            @change="onchangeItem()"
                          ></v-text-field>
                          <v-btn dark @click="setResource('image')"
                            ><v-icon>image</v-icon>Select Background
                            Image</v-btn
                          >
                        </div>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="currentStep > 1"
                  color="red"
                  dark
                  @click="onClickBackStep()"
                  >Previous</v-btn
                >
                <v-btn
                  v-if="currentStep < steps"
                  color="primary"
                  dark
                  @click="onClickNextStep()"
                  >Next</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <ResourceSelect
        v-if="isEditResource"
        :resourceType="resourceType"
        :isShow="isEditResource"
        :close="closeEditResource"
        :save="saveEditResource"
        :value="uriResource"
        :onSelect="onSelectResource"
      />
    </Modal>
    <v-btn dark @click="openModal()"
      ><v-icon>image</v-icon>Edit Background Images</v-btn
    >
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import Modal from '../../../components/Modal';

export default {
  name: 'itemInfo',
  components: { ResourceSelect, Modal },
  computed: {
    ...mapState({
      labelingSets: state => state.labeling.selectLabeling.label_sets,
    }),
    labelSet() {
      const index = this.labelSetIndex;
      if (index >= 0) {
        if (this.labelingSets[index].background_multi_uri) {
          return this.labelingSets[index].background_multi_uri;
        } else return [];
      } else return [];
    },
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  data() {
    return {
      rules,
      currentStep: 1,
      steps: 1,
      data: [],
      valid: true,
      isEditMutiImage: false,
      resource: {
        image: {
          isEdit: false,
          selectUri: '',
        },
      },
      resourceType: '',
      limitData: 5,
    };
  },
  props: {
    labelSetIndex: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    ...mapActions('labeling', ['updateLabelSetData', 'updateLabelSet']),
    addData() {
      if (this.data.length < this.limitData) {
        this.data.push('');
        this.currentStep = this.data.length;
        this.steps = this.data.length;
      }
    },
    onClickRemoveByIndex(index) {
      swal
        .fire({
          titleText: `Are your sure item ${index + 1}?`,
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            this.onchangeItem();
            this.steps = this.data.length;
            if (this.currentStep === 1) {
              this.currentStep = 1;
            } else this.currentStep -= 1;
          }
        });
    },
    onClickNextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    onchangeItem() {
      if (this.$refs.formItem[0].validate()) {
        this.updateLabelSetData({
          key: 'background_multi_uri',
          value: this.data,
        });
      }
    },
    setResource(resource) {
      //resource = image||sound||video
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      const index = this.currentStep - 1;
      switch (resourceType) {
        case 'image':
          this.data[index] = uri;
          break;
        default:
          break;
      }
      this.onchangeItem();
      this.closeEditResource();
    },
    closeEditResource() {
      this.resource[this.resourceType].isEdit = false;
      this.resource[this.resourceType].selectUri = '';
      this.resourceType = '';
    },
    close() {
      this.isEditMutiImage = false;
    },
    save() {
      this.isEditMutiImage = false;
    },
    openModal() {
      this.isEditMutiImage = true;
      if (this.labelSet.length) {
        this.data = this.labelSet;
        this.steps = this.labelSet.length;
      } else {
        this.data = [''];
        this.steps = 1;
      }
    },
  },
  mounted() {
    // if (this.isCreate) {
    //   this.data = JSON.parse(
    //     JSON.stringify(this.drivingGameStore.defaultCreate.items_info),
    //   );
    // } else {
    //   this.data = JSON.parse(JSON.stringify(this.items_info));
    //   this.steps = this.data.length;
    // }
  },
};
</script>
