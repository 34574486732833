<template>
  <v-flex mr-2 xs3>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>Game Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="onClickUpdateGameDetail()" color="green">
          <v-icon>save</v-icon>Save
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-xs text-xs-center>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-layout column>
              <v-flex>
                <Clipboard
                  :objectToCopy="dragComboStore.selectedDragCombo"
                  :havePaste="true"
                  @pasteFromClipboard="pasteFromClipboard($event)"
                >
                </Clipboard>
                <v-text-field
                  label="Title"
                  :value="dragComboStore.selectedDragCombo.title"
                  @change="e => onchangeGameDetail('title', e)"
                  :rules="[rules.required]"
                ></v-text-field>
                <img
                  class="image_thumbnail"
                  :src="dragComboStore.selectedDragCombo.background_uri"
                />
                <v-text-field
                  label="Background Image URI (URL or Select file)"
                  :value="dragComboStore.selectedDragCombo.background_uri"
                  v-model="dragComboStore.selectedDragCombo.background_uri"
                  @change="e => onchangeGameDetail('background_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickEditImage()"
                  ><v-icon>image</v-icon>Select Background Image URI</v-btn
                >
                <v-text-field
                  :value="dragComboStore.selectedDragCombo.bgm_uri"
                  label="Background Music URI (URL or Select file)"
                  v-on:change="e => onchangeGameDetail('bgm_uri', e)"
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound(true)"
                  ><v-icon>audiotrack</v-icon>Select Background Music</v-btn
                >
                <v-flex>
                  <v-text-field
                    label="Introduction Word (tts message)"
                    :value="dragComboStore.selectedDragCombo.tts_message"
                    v-on:change="e => onchangeGameDetail('tts_message', e)"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Voice Name"
                    :value="dragComboStore.selectedDragCombo.voice_name"
                    v-model="dragComboStore.selectedDragCombo.voice_name"
                    v-on:change="e => onchangeGameDetail('voice_name', e)"
                  ></v-select>
                </v-flex>
                <v-text-field
                  :value="
                    dragComboStore.selectedDragCombo.introduction_voice_uri
                  "
                  label="Introduction Voice URI (URL or Select file)"
                  v-on:change="
                    e => onchangeGameDetail('introduction_voice_uri', e)
                  "
                  :rules="[rules.validURI]"
                ></v-text-field>
                <v-btn class="btn-center" @click="onclickMusicSound()"
                  ><v-icon>audiotrack</v-icon>Select Introduction Voice</v-btn
                >
                <v-text-field
                  label="_id"
                  :value="dragComboStore.selectedDragCombo._id"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-btn
                  v-if="getSetLength < 3"
                  color="green"
                  small
                  @click="createSets()"
                >
                  <v-icon>add</v-icon>Create Set
                </v-btn>
                <v-data-table
                  :headers="[{ text: 'Sets', value: 'set', sortable: false }]"
                  :items="dragComboStore.selectedDragCombo.sets"
                  class="elevation-1 row-pointer"
                  hide-actions
                >
                  <template v-slot:items="props">
                    <tr
                      :class="
                        props.index === dragComboStore.selectedSetIndex
                          ? 'active'
                          : 'inactive'
                      "
                      @click="seleteSets(props.item._id, props.index)"
                    >
                      <td class="text-xs-left">
                        {{ props.item.name }}
                      </td>
                      <td>
                        <v-btn
                          color="error"
                          @click="deleteSets(props.item._id)"
                          small
                          fab
                          left
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
              <br />
              <v-flex>
                <v-btn
                  color="red"
                  dark
                  @click="
                    onClickDeleteGame(dragComboStore.selectedDragCombo._id)
                  "
                >
                  <v-icon>delete</v-icon>Delete this game
                </v-btn>
                <p>
                  Last Updated:
                  {{ dragComboStore.selectedDragCombo.updated_at }}
                </p>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
    <ModalCreate
      :isOpen="isCreate"
      :title="title"
      @close="closeCreate()"
    ></ModalCreate>
  </v-flex>
</template>

<script>
import swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import ResourceSelect from '../../../components/ResourceSelect.vue';
import rules from '../../../utils/ruleValidate';
import ModalCreate from './ModalCreate.vue';
import constants from '../../../constants';
import Clipboard from '../../../components/Clipboard.vue';

export default {
  components: {
    ResourceSelect,
    ModalCreate,
    Clipboard,
  },
  data() {
    return {
      constants,
      isEditImage: false,
      selectImageUri: '',
      isSelectBgImage: false,
      isSelectSound: false,
      selectedSoundUri: '',
      isSelectBgMusic: false,
      isSelectIntroMusic: false,
      valid: true,
      rules,
      isCreate: false,
      title: '',
    };
  },
  computed: {
    ...mapState({
      dragComboStore: state => state.dragcombo,
    }),
    getSetLength() {
      if (this.dragComboStore.selectedDragCombo.sets) {
        return this.dragComboStore.selectedDragCombo.sets.length;
      } else return -1;
    },
  },
  methods: {
    ...mapActions('dragcombo', [
      'selectedDragComboById',
      'setStateByKey',
      'updateDragComboDetail',
      'deleteDragCombo',
      'deleteSetDragCombo',
      'clearStateByKey',
    ]),
    onclickEditImage() {
      this.isEditImage = true;
      this.isSelectBgImage = true;
    },
    onSelectImageResource(uri) {
      this.selectImageUri = uri;
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      switch (true) {
        case this.isSelectBgImage:
          this.setStateByKey({
            statekey: 'selectedDragCombo',
            key: 'background_uri',
            value: uri,
          });
          this.setStateByKey({
            statekey: 'updateDetail',
            key: 'background_uri',
            value: uri,
          });
          this.isSelectBgImage = false;
          // this.setDirty('game');
          break;
        default:
          break;
      }
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    closeEditImage() {
      this.isEditImage = false;
      this.selectImageUri = '';
    },
    onclickMusicSound(type) {
      this.isSelectSound = true;
      type ? (this.isSelectBgMusic = true) : (this.isSelectIntroMusic = true);
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectBgMusic:
          this.setStateByKey({
            statekey: 'selectedDragCombo',
            key: 'bgm_uri',
            value: uri,
          });
          this.setStateByKey({
            statekey: 'updateDetail',
            key: 'bgm_uri',
            value: uri,
          });
          this.isSelectBgMusic = false;
          break;
        // this.setDirty('game');
        case this.isSelectIntroMusic:
          this.setStateByKey({
            statekey: 'selectedDragCombo',
            key: 'introduction_voice_uri',
            value: uri,
          });
          this.setStateByKey({
            statekey: 'updateDetail',
            key: 'introduction_voice_uri',
            value: uri,
          });
          this.isSelectIntroMusic = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    onClickUpdateGameDetail() {
      if (this.$refs.form.validate()) {
        // swal
        //   .fire({
        //     titleText: 'Are you sure.',
        //     type: 'warning',
        //     showCancelButton: true,
        //   })
        //   .then(result => {
        //     if (result.value) {
        this.updateDragComboDetail();
        // this.clearDirty('game');
        // }
        // });
      }
    },
    onchangeGameDetail(key, value) {
      // this.setDirty('game');
      this.setStateByKey({
        statekey: 'updateDetail',
        key: key,
        value: value,
      });
    },
    onClickDeleteGame(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteDragCombo(id);
          }
        });
    },
    seleteSets(id, index) {
      this.clearStateByKey('selectedNodeIndex');
      this.clearStateByKey('selectedChoiceIndex');
      this.clearStateByKey('updateSet');
      this.clearStateByKey('updateChoice');
      this.clearStateByKey('updateNode');
      this.setStateByKey({
        statekey: 'selectedSetIndex',
        value: index,
      });
      this.setStateByKey({
        statekey: 'updateSet',
        key: 'id',
        value: id,
      });
    },
    deleteSets(id) {
      swal
        .fire({
          titleText: 'Are you sure delete.',
          confirmButtonColor: 'red',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteSetDragCombo(id);
          }
        });
    },
    createSets() {
      this.isCreate = true;
      this.title = 'Create Sets';
    },
    closeCreate() {
      this.isCreate = false;
      this.title = '';
    },
    pasteFromClipboard(jsonData) {
      const isDragComboJson = json => {
        if ('sets' in json && 'title' in json) {
          this.$store.dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Paste from clipboard',
              type: 'success',
            },
            { root: true },
          );
          return true;
        } else {
          this.$store.dispatch(
            'appConfig/toggleAlert',
            {
              message:
                'Cannot paste from clipboard, Dragcombo data format invalid.',
              type: 'error',
            },
            { root: true },
          );
          return false;
        }
      };
      if (isDragComboJson(jsonData)) {
        let originalData = JSON.parse(
          JSON.stringify(this.dragComboStore.selectedDragCombo),
        );
        let newPasteData = JSON.parse(JSON.stringify(jsonData));
        // delete newPasteData.created_at;
        // delete newPasteData.updated_at;
        newPasteData._id = originalData._id;
        newPasteData.category_id = this.$route.query.category_id;
        const sets = newPasteData.sets
          .map((set, indexSet) => {
            // delete set.created_at;
            // delete set.updated_at;
            set.dragcombo_id = this.$route.query.id
              ? this.$route.query.id
              : originalData._id;
            if (originalData.sets[indexSet]) {
              set._id = originalData.sets[indexSet]._id;
              const nodes = set.nodes
                .map((node, indexNode) => {
                  // delete node.created_at;
                  // delete node.updated_at;
                  delete node._id;
                  delete node.set_id;
                  if (originalData.sets[indexSet].nodes[indexNode]) {
                    const newId =
                      originalData.sets[indexSet].nodes[indexNode]._id;
                    node._id = newId;
                    node.set_id = originalData.sets[indexSet]._id;
                  }
                  return node;
                })
                .filter(nodes => nodes !== undefined);
              return { ...set, nodes: nodes };
            } else {
              delete set._id;
              const nodes = set.nodes.map(node => {
                delete node._id;
                delete node.set_id;
                // delete node.created_at;
                // delete node.updated_at;
                return node;
              });
              return { ...set, nodes: nodes };
            }
          })
          .filter(set => set !== undefined);
        newPasteData = { ...newPasteData, sets };
        this.setStateByKey({
          statekey: 'selectedDragCombo',
          value: newPasteData,
        });
        delete newPasteData.created_at;
        delete newPasteData.updated_at;
        let filterSets = newPasteData.sets.map(set => {
          delete set.created_at;
          delete set.updated_at;
          const nodes = set.nodes.map(node => {
            delete node.created_at;
            delete node.updated_at;
            return node;
          });
          return { ...set, nodes };
        });
        filterSets = { ...newPasteData, sets: filterSets };
        this.setStateByKey({
          statekey: 'updateDetail',
          value: filterSets,
        });
      }
    },
  },
};
</script>

<style scoped>
.image_thumbnail {
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
.row-pointer:hover {
  cursor: pointer;
}
</style>
