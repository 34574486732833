<template>
  <div>
    <Modal
      :isOpen="isOpen"
      :close="closeModal"
      :save="saveModal"
      :isLastStep="valid"
      :title="isCreate ? 'Create Snap Manager' : 'Update Snap Manager'"
    >
      <div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-flex xs12 sm12 md12 style="text-align: right">
            <v-autocomplete
              v-if="isCreate"
              chips
              required
              label="Select Category"
              :items="categoryListStore"
              item-text="category"
              item-value="_id"
              hide-details
              hide-no-data
              hide-selected
              v-model="snaps.category_id"
              :rules="[rules.required]"
            ></v-autocomplete>
            <v-text-field
              class="ma-2"
              label="Title"
              v-model.trim="snaps.title"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Intro Music URI (URL or Select file)"
              v-model.trim="snaps.music_intro_uri"
              :rules="[rules.validURI]"
            ></v-text-field>
            <v-btn dark @click="setResource('sound', 'isIntroSound')">
              <v-icon>audiotrack</v-icon>Select Intro Music
            </v-btn>
            <v-text-field
              label="TTS Message"
              v-model.trim="snaps.tts_message"
            ></v-text-field>
            <v-select
              :items="constants.VOICE_NAME"
              label="Voice Name"
              :value="snaps.voice_name"
              v-model="snaps.voice_name"
            ></v-select>
          </v-flex>
          <v-stepper v-model="element.snap">
            <v-stepper-header>
              <template v-for="n in steps.snap">
                <v-stepper-step
                  :key="`${n}-step`"
                  :complete="element.snap > n"
                  :step="n"
                  editable
                  @click="onChangeSnap(n - 1)"
                >
                  Snap Game {{ n }}
                </v-stepper-step>

                <v-divider v-if="n !== steps.snap" :key="n"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                v-for="snapStep in steps.snap"
                :key="`${snapStep}-content`"
                :step="snapStep"
              >
                <v-btn
                  v-if="steps.snap < imgMax"
                  color="success"
                  small
                  fab
                  @click="addData('snap', snapStep - 1)"
                  ><v-icon>add</v-icon>
                </v-btn>
                <v-btn
                  v-if="steps.snap > 1"
                  color="error"
                  small
                  fab
                  @click="removeData('snap', snapStep - 1)"
                  ><v-icon>delete</v-icon>
                </v-btn>
                <v-card v-if="snaps.snap" class="ma-2 pa-4" height="920px">
                  <v-text-field
                    label="Title"
                    v-model.trim="snaps.snap[snapStep - 1].title"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-img
                    v-if="snaps.snap[snapStep - 1].correct_img_url"
                    :src="snaps.snap[snapStep - 1].correct_img_url"
                    class="content_img"
                  />
                  <v-text-field
                    label="Correct Image URI (URL or Select file)"
                    v-model.trim="snaps.snap[snapStep - 1].correct_img_url"
                    :rules="[rules.validURI]"
                  ></v-text-field>
                  <v-btn
                    dark
                    @click="
                      setResource('image', 'isCorrectImage', snapStep - 1)
                    "
                  >
                    <v-icon>images</v-icon>Select Correct Image
                  </v-btn>
                  <v-text-field
                    label="TTS Message"
                    v-model.trim="snaps.snap[snapStep - 1].tts_message"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-select
                    :items="constants.VOICE_NAME"
                    label="Voice Name"
                    :value="snaps.snap[snapStep - 1].voice_name"
                    v-model="snaps.snap[snapStep - 1].voice_name"
                    :rules="[rules.required]"
                  ></v-select>

                  <v-toolbar dark tabs>
                    <v-toolbar-title>Incorrect Image</v-toolbar-title>
                    <!-- <v-spacer></v-spacer> -->
                  </v-toolbar>

                  <!-- incorrect -->
                  <v-stepper v-model="element.incorrect">
                    <v-stepper-header>
                      <template v-for="n in steps.incorrect">
                        <v-stepper-step
                          :key="`${n}-step`"
                          :complete="element.incorrect > n"
                          :step="n"
                          editable
                        >
                        </v-stepper-step>

                        <v-divider
                          v-if="n !== steps.incorrect"
                          :key="n"
                        ></v-divider>
                      </template>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content
                        v-for="correctStep in steps.incorrect"
                        :key="`${correctStep}-content`"
                        :step="correctStep"
                      >
                        <v-btn
                          v-if="steps.incorrect < imgMax"
                          color="success"
                          small
                          fab
                          @click="addData('incorrect_img_url', snapStep - 1)"
                          ><v-icon>add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="steps.incorrect > 3"
                          color="error"
                          small
                          fab
                          @click="
                            removeData(
                              'incorrect_img_url',
                              snapStep - 1,
                              correctStep - 1,
                            )
                          "
                          ><v-icon>delete</v-icon>
                        </v-btn>
                        <v-card class="ma-2" height="220px">
                          <v-img
                            v-if="
                              snaps.snap[snapStep - 1].incorrect_img_url[
                                correctStep - 1
                              ]
                            "
                            :src="
                              snaps.snap[snapStep - 1].incorrect_img_url[
                                correctStep - 1
                              ]
                            "
                            class="content_img ma-2"
                          />
                          <v-text-field
                            class="ml-2 mr-2"
                            label="Incorrect Image URI (URL or Select file)"
                            v-model.trim="
                              snaps.snap[snapStep - 1].incorrect_img_url[
                                correctStep - 1
                              ]
                            "
                            :rules="[rules.required, rules.validURI]"
                          ></v-text-field>
                          <v-btn
                            dark
                            @click="
                              setResource(
                                'image',
                                null,
                                snapStep - 1,
                                correctStep - 1,
                              )
                            "
                          >
                            <v-icon>images</v-icon>Select Incorrect Image
                          </v-btn>
                        </v-card>

                        <!-- <v-btn
                        color="primary"
                        @click="nextStep(correctStep, 'incorrect')"
                      >
                        Next
                      </v-btn>

                      <v-btn
                        flat
                        @click="reduceStep(correctStep - 1, 'incorrect')"
                        >Back</v-btn
                      > -->
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-card>
                <v-btn
                  color="primary"
                  v-if="steps.snap > 1"
                  @click="nextStep(snapStep, 'snap')"
                >
                  Next
                </v-btn>

                <v-btn flat v-if="snapStep > 1" @click="reduceStep('snap')"
                  >Back</v-btn
                >
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <br />
          <v-text-field
            label="Background Music URI (URL or Select file)"
            v-model.trim="snaps.bgm_uri"
            :rules="[rules.validURI]"
          ></v-text-field>
          <v-btn dark @click="setResource('sound', 'isBgmSound')">
            <v-icon>audiotrack</v-icon>Select Background Music
          </v-btn>
          <v-text-field
            label="Volume Music (0-1)"
            v-model.trim="snaps.bgm_volume"
            type="number"
            :rules="[rules.required, rules.numberFloat]"
          ></v-text-field>
          <ResourceSelect
            v-if="isEditResource"
            :resourceType="resourceType"
            :isShow="isEditResource"
            :close="closeEditResource"
            :save="saveEditResource"
            :value="uriResource"
            :onSelect="onSelectResource"
          />
        </v-form>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import ResourceSelect from '../../../../components/ResourceSelect.vue';
import constants from '../../../../constants';
import { mapActions, mapState } from 'vuex';
import rules from '../../../../utils/ruleValidate';

export default {
  name: 'SnapManager',
  components: {
    ResourceSelect,
    Modal,
    // SpriteSheet,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      categoryListStore: state => state.category.category,
      snapStore: state => state.snap,
    }),
    isEditResource() {
      if (this.resourceType) return this.resource[this.resourceType].isEdit;
      else return false;
    },
    uriResource() {
      if (this.resourceType) return this.resource[this.resourceType].selectUri;
      else return false;
    },
  },
  data() {
    return {
      constants,
      isCorrectImage: false,
      element: {
        // correct: 1,
        incorrect: 1,
        snap: 1,
      },
      steps: {
        // correct: 1,
        incorrect: 1,
        snap: 1,
      },
      imgMax: 10,
      snaps: {},
      index: { snap: 0, incorrect: 0 },
      valid: true,
      rules,
      resourceType: '',
      resource: {
        sound: {
          isEdit: false,
          selectUri: '',
          isBgmSound: false,
          isIntroSound: false,
        },
        image: {
          isEdit: false,
          selectUri: '',
          isCorrectImage: false,
        },
      },
    };
  },
  methods: {
    onChangeSnap(indexSnap, resetIncorrect = true) {
      this.steps.incorrect =
        this.snaps.snap[indexSnap].incorrect_img_url.length;
      if (resetIncorrect) this.element.incorrect = 1;
    },
    saveModal() {
      if (this.$refs.form.validate()) {
        this.$emit('saveModal', this.snaps);
      }
    },
    intialStepper() {
      if (this.snaps.snap.length > 0) {
        this.steps.snap = this.snaps.snap.length;
        if (this.snaps.snap[0].incorrect_img_url.length > 0) {
          this.steps.incorrect = this.snaps.snap[0].incorrect_img_url.length;
        } else this.steps.incorrect = 1;
      } else this.steps.snap = 1;
    },
    setResource(resource, isType, indexSnap, indexIncorrect) {
      //resource = image||sound||video
      //isType = isCorrectImage
      if (isType) this.resource[resource][isType] = true;
      this.resourceType = resource;
      this.resource[resource].isEdit = true;
      this.index.snap = indexSnap != undefined ? indexSnap : 0;
      this.index.incorrect = indexIncorrect != undefined ? indexIncorrect : 0;
    },
    onSelectResource(uri) {
      const resourceType = this.resourceType;
      this.resource[resourceType].selectUri = uri;
    },
    saveEditResource() {
      const resourceType = this.resourceType;
      const uri = this.resource[resourceType].selectUri;
      switch (resourceType) {
        case 'image':
          if (this.resource[resourceType].isCorrectImage)
            this.snaps.snap[this.index.snap].correct_img_url = uri;
          else
            this.snaps.snap[this.index.snap].incorrect_img_url[
              this.index.incorrect
            ] = uri;
          break;
        case 'sound':
          if (this.resource[resourceType].isBgmSound) this.snaps.bgm_uri = uri;
          else this.snaps.music_intro_uri = uri;
          break;
        default:
          break;
      }
      this.closeEditResource();
    },
    closeEditResource() {
      for (const [resourceType, valueResourceType] of Object.entries(
        this.resource,
      )) {
        for (const [key, value] of Object.entries(valueResourceType)) {
          switch (typeof value) {
            case 'string':
              this.resource[resourceType][key] = '';
              break;
            case 'boolean':
              this.resource[resourceType][key] = false;
              break;
            default:
              break;
          }
        }
      }
      this.resourceType = '';
    },
    nextStep(currentStep, key) {
      if (currentStep === this.steps[key]) this.element[key] = 1;
      else this.element[key] = currentStep + 1;
    },
    reduceStep(key) {
      this.element[key]--;
    },
    removeData(key, indexSnap, indexIncorrect) {
      if (key === 'snap') {
        if (this.snaps[key].length > 1) {
          this.snaps[key].splice(indexSnap, 1);
          if (this.element.snap === 1) {
            this.steps.snap--;
            this.element.snap = 1;
            this.onChangeSnap(0);
          } else {
            this.steps.snap--;
            this.element.snap--;
            this.onChangeSnap(indexSnap - 1);
          }
        }
      } else {
        if (this.snaps.snap[indexSnap].incorrect_img_url.length > 1) {
          this.snaps.snap[indexSnap].incorrect_img_url.splice(
            indexIncorrect,
            1,
          );
          if (this.element.incorrect === 1) {
            this.steps.incorrect--;
            this.element.incorrect = 1;
          } else {
            this.steps.incorrect--;
            this.element.incorrect--;
          }
        }
      }
    },
    addData(key, indexSnap) {
      if (key === 'snap') {
        if (this.snaps.snap.length < this.imgMax) {
          const object = JSON.parse(
            JSON.stringify(this.snapStore.defaultCreateSnap.snap[0]),
          );
          this.snaps.snap.push(object);
          this.steps.snap = this.snaps.snap.length;
          this.onChangeSnap(indexSnap + 1);
          this.element.snap++;
        }
      } else {
        if (this.snaps.snap[indexSnap].incorrect_img_url.length < this.imgMax) {
          this.snaps.snap[indexSnap].incorrect_img_url.push('');
          this.onChangeSnap(indexSnap, false);
          this.element.incorrect =
            this.snaps.snap[indexSnap].incorrect_img_url.length;
        }
      }
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.element.snap = 1;
        this.element.incorrect = 1;
        this.snaps = this.isCreate
          ? JSON.parse(JSON.stringify(this.snapStore.defaultCreateSnap))
          : JSON.parse(JSON.stringify(this.snapStore.selectedSnap));
        if (this.isCreate && 'category_id' in this.snapStore.snapList) {
          this.snaps.category_id = this.snapStore.snapList.category_id;
        }
        this.intialStepper();
      }
    },
  },
};
</script>

<style></style>
