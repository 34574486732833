<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/draganddrop')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title
            >Drag And Drop :
            {{
              (draganddropStore.selectDragAndDrop &&
                draganddropStore.selectDragAndDrop.category) ||
              'Loading...'
            }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text v-if="draganddropStore.selectDragAndDrop">
          <v-layout row grid-list-md v-if="!draganddropStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Set</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddSet">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(set, index) in draganddropStore.selectDragAndDrop
                      .sets"
                    :key="set._id"
                    v-on:click="onSelectSet(set._id)"
                    :class="index === selectedSetIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ set.name }}</v-list-tile-title>
                      <v-list-tile-sub-title
                        >Container: {{ set.containers.length }}, Choice:
                        {{ set.choices.length }}</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedSetIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Set Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateSet">
                    <v-icon>save</v-icon>Save Set
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column grid-list-xs text-xs-center>
                    <v-flex>
                      <CopyIdForm
                        :game_id="set._id"
                        :category_id="this.getCategoryId"
                      ></CopyIdForm>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data</v-btn
                      >
                      <Clipboard :objectToCopy="set"> </Clipboard>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="set.isGuestPlayable"
                        :value="set.isGuestPlayable"
                        v-on:change="e => onChangeSetData('isGuestPlayable', e)"
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Set Name"
                        :value="set.name"
                        v-on:change="e => onChangeSetData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="TTS Message"
                        :value="set.tts_message"
                        v-on:change="e => onChangeSetData('tts_message', e)"
                      ></v-text-field>
                      <v-select
                        :items="constants.VOICE_NAME"
                        label="Voice Name"
                        :value="set.voice_name"
                        v-model="set.voice_name"
                        v-on:change="e => onChangeSetData('voice_name', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex v-if="set.type || !set.type">
                      <v-select
                        :items="constants.CARD_TYPE"
                        label="Object Type"
                        :value="set.type"
                        v-model="set.type"
                        v-on:change="e => onChangeSetData('type', e)"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        v-if="
                          set.sound_uri &&
                          set.type &&
                          (set.type == 'sound' || set.type == 'image_sound')
                        "
                        class="white--text"
                        color="subtitle_sound"
                        @click="openSubtitleSound()"
                        >Subtitle Sound
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="
                        set.type &&
                        (set.type == 'sound' || set.type == 'image_sound')
                      "
                    >
                      <v-text-field
                        label="Sound for question (URL or Select file)"
                        :value="set.sound_uri"
                        v-on:change="e => onChangeSetData('sound_uri', e)"
                      />
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          sound_key = 'set';
                        "
                      >
                        <v-icon>audiotrack</v-icon>Edit Sound
                      </v-btn>
                    </v-flex>
                    <v-flex
                      v-if="
                        set.type &&
                        (set.type == 'image' || set.type == 'image_sound')
                      "
                    >
                      <img
                        v-if="set.img_uri"
                        :src="set.img_uri"
                        :alt="set.set"
                        class="content_img"
                      />
                      <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                          <v-text-field
                            label="Background Image (URL or Select file)"
                            :value="set.img_uri"
                            v-on:change="e => onChangeSetData('img_uri', e)"
                          />
                          <span class="headline" dark>
                            <v-btn
                              v-on:click="
                                isEditImage = true;
                                img_key = 'set';
                              "
                            >
                              <v-icon>image</v-icon>Edit Image
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        :value="set.bgm_uri"
                        v-model="set.bgm_uri"
                        v-on:change="e => onChangeSetData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isEditBGM = true;
                        "
                        >Select Sound</v-btn
                      >
                    </v-flex>
                    <v-list>
                      <v-list-tile
                        v-on:click="onSelectType('Container')"
                        :class="type === 'Container' ? 'active' : 'inactive'"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title
                            >Container ({{
                              set.containers.length
                            }})</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile
                        v-on:click="onSelectType('Choice')"
                        :class="type === 'Choice' ? 'active' : 'inactive'"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title
                            >Choice ({{
                              set.choices.length
                            }})</v-list-tile-title
                          >
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-layout>
                  <v-layout column class="elevation-2" style="padding: 20px">
                    <label>
                      <u>⌗ Rec.Event</u>
                    </label>
                    <td
                      v-for="(event, index) in rectEvent"
                      :key="index"
                      class="elevation-2"
                      style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <label>Event {{ index + 1 }}:</label>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start X"
                            :value="event.startX"
                            v-on:change="
                              e => onChangeEventData(index, 'startX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End X"
                            :value="event.endX"
                            v-on:change="
                              e => onChangeEventData(index, 'endX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start Y"
                            :value="event.startY"
                            v-on:change="
                              e => onChangeEventData(index, 'startY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End Y"
                            :value="event.endY"
                            v-on:change="
                              e => onChangeEventData(index, 'endY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-flex>
                        <v-text-field
                          label="Next Index"
                          :value="event.nextIndex"
                          v-on:change="
                            e =>
                              onChangeEventData(index, 'nextIndex', parseInt(e))
                          "
                        ></v-text-field>
                      </v-flex>
                    </td>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="rectEvent && rectEvent.length > 0"
                        icon
                        color="red"
                        @click="onRemoveEventData"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn v-else icon color="grey">
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn icon color="green" @click="onAddEventData">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteSet(set._id)"
                  >
                    <v-icon>delete</v-icon>Delete this set
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="selectedSetIndex != -1 && choices.length > 0"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>{{ type }}s</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddChoice">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list v-if="type == 'Container'">
                  <v-list-tile
                    v-for="(choice, index) in set.containers"
                    :key="choice._id"
                    v-on:click="onSelectChoice(choice._id, index)"
                    :class="
                      index === selectedChoiceIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ index + 1 }}. {{ choice.text }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Key: {{ choice.match_key }}</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-list v-if="type == 'Choice'">
                  <v-list-tile
                    v-for="(choice, index) in set.choices"
                    :key="choice._id"
                    v-on:click="onSelectChoice(choice._id, index)"
                    :class="
                      index === selectedChoiceIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ index + 1 }}. {{ choice.text }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Key: {{ choice.match_key }}</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                    <v-list-tile-avatar v-if="choice.is_correct == true">
                      <v-icon color="green">check_circle</v-icon>
                    </v-list-tile-avatar>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="
                selectedSetIndex != -1 &&
                selectedChoiceIndex != -1 &&
                Object.keys(choice).length > 1
              "
              xs4
              mr-2
            >
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Choice Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveChoice">
                    <v-icon>save</v-icon>Save Choice
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex>
                        <v-text-field
                          label="Text"
                          :value="choice.text"
                          v-on:change="e => onChangeChoiceData('text', e)"
                        />
                      </v-flex>
                      <v-flex>
                        <v-select
                          :items="matchKey"
                          label="Match Key"
                          :value="choice.match_key"
                          v-model="choice.match_key"
                          v-on:change="
                            e => onChangeChoiceData('match_key', parseInt(e))
                          "
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="type == 'Choice'">
                        <v-select
                          :items="constants.CARD_TYPE"
                          label="Choice Type"
                          :value="choice.type"
                          v-model="choice.type"
                          v-on:change="e => onChangeChoiceData('type', e)"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        v-if="
                          type == 'Choice' &&
                          choice.type &&
                          (choice.type == 'sound' ||
                            choice.type == 'image_sound')
                        "
                      >
                        <v-text-field
                          label="Sound for question (URL or Select file)"
                          :value="choice.sound_uri"
                          v-on:change="e => onChangeChoiceData('sound_uri', e)"
                        />
                        <v-btn color="black" v-on:click="isEditSound = true">
                          <v-icon>audiotrack</v-icon>Edit Sound
                        </v-btn>
                        <v-flex>
                          <v-text-field
                            label="Correct Sound (URL or Select file)"
                            :value="choice.correct_sfx_uri"
                            v-model="choice.correct_sfx_uri"
                            @change="
                              e =>
                                updateDirectLinkSound(
                                  'correct_sfx_uri',
                                  e,
                                  'update',
                                )
                            "
                          />
                          <span class="headline" dark>
                            <v-btn
                              dark
                              v-on:click="
                                isEditSound = true;
                                isCorrectSFX = true;
                              "
                            >
                              <v-icon>audiotrack</v-icon>Select SFX Sound
                            </v-btn>
                          </span>
                        </v-flex>
                      </v-flex>
                      <v-flex
                        v-if="
                          type == 'Choice' &&
                          choice.type &&
                          (choice.type == 'image' ||
                            choice.type == 'image_sound')
                        "
                      >
                        <img
                          v-if="choice.img_uri"
                          :src="choice.img_uri"
                          :alt="choice.choice"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Background Image (URL or Select file)"
                                :value="choice.img_uri"
                                v-on:change="
                                  e => onChangeChoiceData('img_uri', e)
                                "
                              />
                              <span class="headline" dark>
                                <v-btn
                                  color="black"
                                  v-on:click="isEditImage = true"
                                >
                                  <v-icon>image</v-icon>Edit Image
                                </v-btn>
                              </span>
                              <SpriteSheet
                                :title="'Use Sprite Sheet'"
                                :isToggle="choice.is_sprite_sheet"
                                :fieldname="'Edit Move Sheet Data'"
                                :object="choice.sprite_sheet_data"
                                :color="'dark'"
                                @onchangeToggleSprite="
                                  onchangeToggleSprite(
                                    'is_sprite_sheet',
                                    'sprite_sheet_data',
                                    $event,
                                  )
                                "
                                @saveSpriteSheetData="
                                  saveSpriteSheetData(
                                    'sprite_sheet_data',
                                    $event,
                                  )
                                "
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                        <hr />
                        <img
                          v-if="choice.drag_img_uri"
                          :src="choice.drag_img_uri"
                          :alt="choice.choice"
                          class="content_img"
                        />
                        <v-container>
                          <v-layout fill-height>
                            <v-flex xs12 align-end flexbox>
                              <v-text-field
                                label="Drag Image (URL or Select file)"
                                :value="choice.drag_img_uri"
                                v-on:change="
                                  e => onChangeChoiceData('drag_img_uri', e)
                                "
                              />
                              <span class="headline" dark>
                                <v-btn
                                  color="black"
                                  v-on:click="
                                    (isEditImage = true), (isDragImages = true)
                                  "
                                >
                                  <v-icon>image</v-icon>Edit Image
                                </v-btn>
                              </span>
                              <SpriteSheet
                                :title="'Use Drag Sprite Sheet'"
                                :isToggle="choice.is_drag_sprite_sheet"
                                :fieldname="'Edit Move Sheet Data'"
                                :object="choice.drag_sprite_sheet_data"
                                :color="'dark'"
                                @onchangeToggleSprite="
                                  onchangeToggleSprite(
                                    'is_drag_sprite_sheet',
                                    'drag_sprite_sheet_data',
                                    $event,
                                  )
                                "
                                @saveSpriteSheetData="
                                  saveSpriteSheetData(
                                    'drag_sprite_sheet_data',
                                    $event,
                                  )
                                "
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                      <v-flex v-if="type == 'Container'">
                        <v-layout align-content-space-between>
                          <v-flex>
                            <v-text-field
                              label="Start X"
                              :value="choice.startX"
                              v-on:change="e => onChangeChoiceData('startX', e)"
                            ></v-text-field>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="End X"
                              :value="choice.endX"
                              v-on:change="e => onChangeChoiceData('endX', e)"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout align-content-space-between>
                          <v-flex>
                            <v-text-field
                              label="Start Y"
                              :value="choice.startY"
                              v-on:change="e => onChangeChoiceData('startY', e)"
                            ></v-text-field>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="End Y"
                              :value="choice.endY"
                              v-on:change="e => onChangeChoiceData('endY', e)"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-flex>
                          <v-text-field
                            label="Next Index"
                            :value="choice.nextIndex"
                            v-on:change="
                              e => onChangeChoiceData('nextIndex', parseInt(e))
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-flex>
                      <v-flex v-if="type == 'Container'">
                        <v-btn @click="openContentManager()"
                          >Container Content Manager</v-btn
                        >
                        <ContentManager
                          :isOpen="isOpenContentManager"
                          :closeModal="closeContentManager"
                          :saveModal="saveContentManager"
                          :images="images"
                          :sounds="sounds"
                          :choice="choice"
                          @onchangeToggleSprite="onchangeToggleSprite"
                          @saveSpriteSheetData="saveSpriteSheetData"
                        ></ContentManager>
                        <!-- <h3>Container Images:</h3> -->

                        <!-- <v-flex v-for="(image, index) in images" :key="index">
                          <v-btn
                            v-on:click="
                              isEditImage = true;
                              isEditImageIndex = index;
                            "
                            >Select Image {{ index + 1 }}</v-btn
                          >
                          <br />
                          <img
                            v-if="image"
                            :src="image"
                            :alt="image"
                            class="content_img"
                          />
                          <v-text-field
                            :value="image"
                            v-model="images[index]"
                            @change="e => updateDirectLinkImage('update')"
                          >
                            <template v-slot:label
                              >Image {{ index + 1 }}</template
                            >
                          </v-text-field>
                        </v-flex> -->

                        <!-- <v-container>
                          <v-card-actions>
                            <v-btn color="red" @click="onDeleteImage">
                              <v-icon>remove</v-icon>Remove Image
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="green" @click="onAddImage">
                              <v-icon>add</v-icon>Add Image
                            </v-btn>
                          </v-card-actions>
                        </v-container> -->
                      </v-flex>
                    </v-layout>
                    <v-btn
                      v-if="choices.length > 1"
                      color="red"
                      dark
                      v-on:click="onClickDeleteChoice()"
                    >
                      <v-icon>delete</v-icon>Delete this choice
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="selectedSetIndex !== -1 ? set.learned_words : null"
      :incidentalWords="selectedSetIndex !== -1 ? set.incidental_words : null"
      :rewards="selectedSetIndex !== -1 ? set.reward : null"
      :sentences="selectedSetIndex !== -1 ? set.sentence : null"
      :phonics="selectedSetIndex !== -1 ? set.phonic : null"
    >
    </PointData>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <Modal
      :v-if="isAddSet"
      :isOpen="isAddSet"
      :isLastStep="isLastStep"
      :close="toggleAddSet"
      :save="onClickAddSet"
      title="Add Set"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <v-flex>
            <v-text-field
              label="Set Name"
              :value="set.name"
              v-model="set.name"
              v-on:change="e => onChangeCreateSet('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Question"
              :value="set.question"
              v-model="set.question"
              v-on:change="e => onChangeCreateSet('question', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Object Type"
              :value="set.type"
              v-model="set.type"
              v-on:change="e => onChangeCreateSet('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              set.type && (set.type == 'sound' || set.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound for question (URL or Select file)"
              :value="set.sound_uri"
              disabled
            />
            <span class="headline" dark>
              <v-btn dark v-on:click="isEditSound = true">
                <v-icon>audiotrack</v-icon>Select Sound
              </v-btn>
            </span>
          </v-flex>
          <v-flex
            v-if="
              set.type && (set.type == 'image' || set.type == 'image_sound')
            "
          >
            <img
              v-if="set.img_uri"
              :src="set.img_uri"
              :alt="set.set"
              class="content_img"
            />
            <v-layout fill-height>
              <v-flex xs12 align-end flexbox>
                <v-text-field
                  label="Background Image (URL or Select file)"
                  :value="set.img_uri"
                  disabled
                />
                <span class="headline" dark>
                  <v-btn
                    dark
                    v-on:click="
                      isEditImage = true;
                      img_key = 'set';
                    "
                  >
                    <v-icon>image</v-icon>Select Image
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="set.bgm_uri"
              v-model="set.bgm_uri"
              v-on:change="e => onChangeCreateSet('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              v-on:click="
                isEditSound = true;
                isEditBGM = true;
              "
              >Select Sound</v-btn
            >
          </v-flex>
        </v-layout>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title v-if="currentStep == 0">Container</v-toolbar-title>
            <v-toolbar-title v-if="currentStep == 1">Choice</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-text-field
                label="Text"
                :value="choice.text"
                v-model="choice.text"
                v-on:change="e => onChangeCreateChoice('text', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Match Key"
                :value="choice.match_key"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex v-if="currentStep == 1">
              <v-select
                :items="constants.CARD_TYPE"
                label="Choice Type"
                :value="choice.type"
                v-model="choice.type"
                v-on:change="e => onChangeCreateChoice('type', e)"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="
                currentStep == 1 &&
                choice.type &&
                (choice.type == 'sound' || choice.type == 'image_sound')
              "
            >
              <v-text-field
                label="Sound for question (URL or Select file)"
                :value="choice.sound_uri"
                v-model="choice.sound_uri"
                disabled
              />
              <span class="headline" dark>
                <v-btn dark v-on:click="isEditSound = true">
                  <v-icon>audiotrack</v-icon>Select Sound
                </v-btn>
              </span>
            </v-flex>
            <v-flex
              v-if="
                currentStep == 1 &&
                choice.type &&
                (choice.type == 'image' || choice.type == 'image_sound')
              "
            >
              <img
                v-if="choice.img_uri"
                :src="choice.img_uri"
                :alt="choice.choice"
                class="content_img"
              />
              <v-layout fill-height>
                <v-flex xs12 align-end flexbox>
                  <v-text-field
                    label="Background Image (URL or Select file)"
                    :value="choice.img_uri"
                    disabled
                  />
                  <span class="headline" dark>
                    <v-btn dark v-on:click="isEditImage = true">
                      <v-icon>image</v-icon>Select Image
                    </v-btn>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="currentStep == 0">
              <v-flex v-for="(image, index) in images" :key="index">
                <v-btn
                  dark
                  v-on:click="
                    isEditImage = true;
                    isEditImageIndex = index;
                  "
                  >Select Image {{ index + 1 }}</v-btn
                >
                <br />
                <img
                  v-if="image"
                  :src="image"
                  :alt="image"
                  class="content_img"
                />
                <v-text-field :value="image" disabled>
                  <template v-slot:label>Image {{ index + 1 }}</template>
                </v-text-field>
              </v-flex>
              <v-container>
                <v-card-actions>
                  <v-btn
                    dark
                    v-if="images.length > 1"
                    color="red"
                    @click="onDeleteImage"
                  >
                    <v-icon>remove</v-icon>Remove Image
                  </v-btn>
                  <v-btn dark v-else color="grey">
                    <v-icon>remove</v-icon>Remove Image
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn dark color="green" @click="onAddImage">
                    <v-icon>add</v-icon>Add Image
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            v-on:click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep + 1 < choices.length"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Next</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddChoice"
      :isOpen="isAddChoice"
      :close="toggleAddChoice"
      :save="onClickAddChoice"
      :title="'Add ' + type"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Text"
              :value="choice.text"
              v-model="choice.text"
              v-on:change="e => onChangeCreateSingleChoice('text', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              label="Match Key"
              :value="choice.match_key"
              v-model="choice.match_key"
              v-on:change="
                e => onChangeCreateSingleChoice('match_key', parseInt(e))
              "
            ></v-select>
          </v-flex>
          <v-flex v-if="type == 'Choice'">
            <v-select
              :items="constants.CARD_TYPE"
              label="Choice Type"
              :value="choice.type"
              v-model="choice.type"
              v-on:change="e => onChangeCreateSingleChoice('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              type == 'Choice' &&
              choice.type &&
              (choice.type == 'sound' || choice.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound for question (URL or Select file)"
              :value="choice.sound_uri"
              disabled
            />
            <v-btn dark v-on:click="isEditSound = true">
              <v-icon>audiotrack</v-icon>Edit Sound
            </v-btn>
            <v-flex>
              <v-text-field
                label="Correct Sound (URL or Select file)"
                :value="choice.correct_sfx_uri"
                v-model="choice.correct_sfx_uri"
                @change="
                  e => updateDirectLinkSound('correct_sfx_uri', e, 'create')
                "
              />
              <span class="headline" dark>
                <v-btn
                  dark
                  v-on:click="
                    isEditSound = true;
                    isCorrectSFX = true;
                  "
                >
                  <v-icon>audiotrack</v-icon>Select SFX Sound
                </v-btn>
              </span>
            </v-flex>
          </v-flex>
          <v-flex
            v-if="
              type == 'Choice' &&
              choice.type &&
              (choice.type == 'image' || choice.type == 'image_sound')
            "
          >
            <img
              v-if="choice.img_uri"
              :src="choice.img_uri"
              :alt="choice.choice"
              class="content_img"
            />
            <v-container>
              <v-layout fill-height>
                <v-flex xs12 align-end flexbox>
                  <v-text-field
                    label="Background Image (URL or Select file)"
                    :value="choice.img_uri"
                    disabled
                  />
                  <span class="headline" dark>
                    <v-btn dark v-on:click="isEditImage = true">
                      <v-icon>image</v-icon>Edit Image
                    </v-btn>
                  </span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex v-if="type == 'Container'">
            <h3>Container Images:</h3>
            <v-flex v-for="(image, index) in images" :key="index">
              <v-btn
                dark
                v-on:click="
                  isEditImage = true;
                  isEditImageIndex = index;
                "
                >Select Image {{ index + 1 }}</v-btn
              >
              <br />
              <img v-if="image" :src="image" :alt="image" class="content_img" />
              <v-text-field
                :value="image"
                v-model="images[index]"
                @change="e => updateDirectLinkImage('create')"
              >
                <template v-slot:label>Image {{ index + 1 }}</template>
              </v-text-field>
            </v-flex>
            <v-container>
              <v-card-actions>
                <v-btn
                  dark
                  v-if="images.length > 1"
                  color="red"
                  @click="onDeleteImage"
                >
                  <v-icon>remove</v-icon>Remove Image
                </v-btn>
                <v-btn dark v-else color="grey">
                  <v-icon>remove</v-icon>Remove Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn dark color="green" @click="onAddImage">
                  <v-icon>add</v-icon>Add Image
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */
.content_img {
  height: 150px;
  width: 150px;
  border-radius: 5px;
}
.choice {
  height: 150px;
  width: 150px;
  align-content: center;
  justify-content: center;
}
</style>

<script>
import PointData from '../../components/PointData';
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import Clipboard from '../../components/Clipboard.vue';
import ContentManager from '../../components/ContentManager.vue';
import SpriteSheet from '../../components/SpriteSheetNew.vue';
import CopyIdForm from '../../components/CopyIdForm.vue';

export default {
  name: 'DragAndDrop',
  components: {
    ContentManager,
    Clipboard,
    Container,
    ResourceSelect,
    Modal,
    PointData,
    SpriteSheet,
    CopyIdForm,
  },
  computed: {
    ...mapState({
      draganddropStore: state => state.draganddrop,
      selectedSetIndexStore: state => state.draganddrop.selectedSetIndex,
    }),
    getCategoryId() {
      return this.draganddropStore.selectDragAndDrop.category_id;
    },
  },
  data() {
    return {
      sounds: [],
      isOpenContentManager: false,
      matchKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      isOpenPointData: false,
      isCorrectSFX: null,
      currentStep: -1,
      isLastStep: false,
      lastStep: 1,
      maxChoice: 2,
      type: '',
      sets: [],
      set: {},
      choices: [],
      choice: {},
      rectEvent: [],
      selectedSetIndex: -1,
      selectedChoiceIndex: -1,
      images: [''],
      img_key: '',
      sound_key: '',
      isEditImageIndex: -1,
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      selectSoundUri: '',
      isEditBGM: false,
      text: '',
      isDirty: false,
      isAddChoice: false,
      isAddSet: false,
      isUpdateSet: false,
      isAddChoiceOfSet: false,
      constants,
      selectSetId: '',
      selectChoiceId: '',
      isDragImages: false,
    };
  },
  methods: {
    ...mapActions('draganddrop', [
      'fetchDragAndDrop',
      'fetchSingleDragAndDrop',
      'updateChoiceData',
      'updateChoice',
      'clearUpdateData',
      'deleteSet',
      'deleteChoice',
      'updateCreateSetData',
      'updateCreateChoiceData',
      'updateCreateSingleChoiceData',
      'updateSetData',
      'updateSet',
      'addSet',
      'addChoiceBySetId',
      'selectCategory',
      'selectDragAndDrop',
      'clearCreateData',
    ]),
    openContentManager() {
      this.isOpenContentManager = true;
    },
    closeContentManager() {
      this.isOpenContentManager = false;
    },
    saveContentManager() {
      const keyUpdate = [
        'images',
        'sounds',
        'is_container_sprite_sheet_data',
        'container_sprite_sheet_data',
        'tts_message',
        'is_tts_message',
        'voice_name',
      ];
      keyUpdate.forEach(key => {
        switch (key) {
          case 'images':
            this.onChangeChoiceData(key, this.images);
            break;
          case 'sounds':
            this.onChangeChoiceData(key, this.sounds);
            break;
          default:
            this.onChangeChoiceData(key, this.choice[key]);
            break;
        }
      });
      // const currentImageArray = this.images;
      // const currentSoundArray = this.sounds;
      // this.onChangeChoiceData('sounds', currentSoundArray);
      // this.onChangeChoiceData('images', currentImageArray);
      this.isOpenContentManager = false;
    },
    updateDirectLinkSound(key, value, state) {
      if (state === 'update') {
        this.updateChoiceData({
          key,
          value,
        });
      } else if (state === 'create') {
        this.updateCreateSingleChoiceData({
          key,
          value,
        });
      }
    },
    updateDirectLinkImage(state) {
      if (state === 'update') {
        this.updateChoiceData({
          key: 'images',
          value: this.images,
        });
      } else if (state === 'create') {
        this.updateCreateSingleChoiceData({
          state: 'images',
          value: this.images,
        });
      }
    },
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.set.sound_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      if (learned_words) {
        this.updateSetData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateSetData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateSetData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateSetData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateSetData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateSet();
      this.isOpenPointData = false;
    },
    onClickNextStep() {
      if (this.currentStep === 0) {
        this.isAddChoiceOfSet = true;
      }
      this.currentStep++;
      if (this.currentStep == this.lastStep) {
        this.isLastStep = true;
      }
      this.onActiveCreateChoice();
    },
    onClickBackStep() {
      this.currentStep--;
      this.onActiveCreateChoice();
    },
    async onSelectSet(id) {
      this.clearUpdateData('updateSetData');
      const sets = this.draganddropStore.selectDragAndDrop.sets;
      const index = sets.findIndex(set => set._id === id);
      this.selectedSetIndex = index;
      this.selectedChoiceIndex = -1;
      this.choices = [];
      this.choice = {};
      this.type = '';
      this.set = {
        ...sets[index],
      };
      this.rectEvent = this.set.rectEvent;
      this.updateSetData({
        key: '_id',
        value: id,
      });
      this.selectSetId = id;
    },
    onSelectType(type) {
      this.clearUpdateData('updateChoiceData');
      this.selectedChoiceIndex = -1;
      this.type = type;
      if (type == 'Container') {
        this.choices = [...this.set.containers];
      } else {
        this.choices = [...this.set.choices];
      }
    },
    onSelectChoice(id, indexChoice) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not save this choice yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.clearUpdateData('updateChoiceData');
              this.isDirty = false;
              const choices =
                this.type == 'Container'
                  ? [...this.set.containers]
                  : [...this.set.choices];
              const index = id
                ? choices.findIndex(choice => choice._id === id)
                : indexChoice;
              this.selectedChoiceIndex = index;
              this.choice = { ...choices[index] };
              this.images =
                this.type == 'Container' && this.choice.images
                  ? [...this.choice.images]
                  : [''];
              this.sounds =
                this.type == 'Container' && this.choice.sounds
                  ? [...this.choice.sounds]
                  : [''];
              this.selectImageUri = '';
              this.updateChoiceData({
                key: '_id',
                value: id,
              });
              this.choice = { ...this.checkSpriteSheetData(this.choice) };
              this.checkTTSMessage();
              this.selectChoiceId = id;
            }
          });
      } else {
        this.clearUpdateData('updateChoiceData');
        this.isDirty = false;
        const choices =
          this.type == 'Container'
            ? [...this.set.containers]
            : [...this.set.choices];
        const index = id
          ? choices.findIndex(choice => choice._id === id)
          : indexChoice;
        this.selectedChoiceIndex = index;
        this.choice = { ...choices[index] };
        this.images =
          this.type == 'Container' && this.choice.images
            ? [...this.choice.images]
            : [''];
        this.sounds =
          this.type == 'Container' && this.choice.sounds
            ? [...this.choice.sounds]
            : [''];
        this.selectImageUri = '';
        this.updateChoiceData({
          key: '_id',
          value: id,
        });
        this.choice = { ...this.checkSpriteSheetData(this.choice) };
        this.checkTTSMessage();
        this.selectChoiceId = id;
      }
    },
    checkTTSMessage() {
      const keyData = ['tts_message', 'is_tts_message', 'voice_name'];
      keyData.forEach(key => {
        if (!(key in this.choice)) {
          if (this.choice.images) {
            switch (key) {
              case 'is_tts_message':
                let bool =
                  this.type === 'Container'
                    ? this.choice.images.map(() => false)
                    : false;
                this.choice = { ...this.choice, [key]: bool };
                break;
              case 'tts_message':
              case 'voice_name':
                let str =
                  this.type === 'Container'
                    ? this.choice.images.map(() => '')
                    : '';
                this.choice = { ...this.choice, [key]: str };
              default:
                break;
            }
          } else {
            switch (key) {
              case 'is_tts_message':
                this.choice =
                  this.type === 'Container'
                    ? { ...this.choice, [key]: [false] }
                    : { ...this.choice, [key]: false };
                break;
              case 'tts_message':
              case 'voice_name':
                this.choice =
                  this.type === 'Container'
                    ? { ...this.choice, [key]: [''] }
                    : { ...this.choice, [key]: '' };
              default:
                break;
            }
          }
        }
      });
    },
    checkSpriteSheetData(object) {
      const sprite_sheet_data = {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      };
      if (!('is_sprite_sheet' in object))
        object = { ...object, is_sprite_sheet: false };
      if (!('sprite_sheet_data' in object))
        object = {
          ...object,
          sprite_sheet_data: JSON.parse(JSON.stringify(sprite_sheet_data)),
        };
      if (!('is_drag_sprite_sheet' in object))
        object = { ...object, is_drag_sprite_sheet: false };
      if (!('drag_sprite_sheet_data' in object))
        object = {
          ...object,
          drag_sprite_sheet_data: JSON.parse(JSON.stringify(sprite_sheet_data)),
        };
      if (!('is_container_sprite_sheet_data' in object)) {
        if ('images' in object) {
          if (object.images.length) {
            let toggle = [];
            object.images.forEach(() => toggle.push(false));
            object = {
              ...object,
              is_container_sprite_sheet_data: toggle,
            };
          } else {
            object = {
              ...object,
              is_container_sprite_sheet_data: [false],
            };
          }
        } else {
          object = {
            ...object,
            is_container_sprite_sheet_data: [false],
          };
        }
      }

      if (!('container_sprite_sheet_data' in object))
        if ('images' in object) {
          if (object.images.length) {
            let data = [];
            object.images.forEach(() =>
              data.push(JSON.parse(JSON.stringify(sprite_sheet_data))),
            );
            object = {
              ...object,
              container_sprite_sheet_data: data,
            };
          } else
            object = {
              ...object,
              container_sprite_sheet_data: [
                JSON.parse(JSON.stringify(sprite_sheet_data)),
              ],
            };
        } else
          object = {
            ...object,
            container_sprite_sheet_data: [
              JSON.parse(JSON.stringify(sprite_sheet_data)),
            ],
          };
      return object;
    },
    onAddImage() {
      this.images = [...this.images, ''];
    },
    onDeleteImage() {
      this.images.pop();
      this.updateChoiceData({
        key: 'images',
        value: this.images,
      });
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeChoiceData(key, value) {
      this.isDirty = true;
      this.choice[key] = value;
      this.updateChoiceData({
        key,
        value,
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddSet && this.img_key == 'set') {
        this.updateCreateSetData({
          key: 'img_uri',
          value: uri,
        });
        this.set.img_uri = uri;
      } else if (this.isAddSet && this.currentStep == 0) {
        this.images[this.isEditImageIndex] = uri;
        this.updateCreateChoiceData({
          key: 'images',
          value: this.images,
          index: this.currentStep,
        });
        this.choice.img_uri = uri;
      } else if (this.isAddSet && this.currentStep == 1) {
        this.updateCreateChoiceData({
          key: 'img_uri',
          value: uri,
          index: this.currentStep,
        });
        this.choice.img_uri = uri;
      } else if (this.isAddChoice && this.type == 'Choice') {
        this.updateCreateSingleChoiceData({
          key: 'img_uri',
          value: uri,
        });
        this.choice.img_uri = uri;
      } else if (this.isAddChoice && this.type == 'Container') {
        this.images[this.isEditImageIndex] = uri;
        this.updateCreateSingleChoiceData({
          key: 'images',
          value: this.images,
        });
        this.choice.img_uri = uri;
      } else if (this.type == 'Container') {
        this.images[this.isEditImageIndex] = uri;
        this.updateChoiceData({
          key: 'images',
          value: this.images,
        });
        this.choice.img_uri = uri;
      } else if (this.img_key == 'set') {
        this.updateSetData({
          key: 'img_uri',
          value: uri,
        });
        this.set.img_uri = uri;
      } else if (this.type == 'Choice' && this.isDragImages) {
        this.updateChoiceData({
          key: 'drag_img_uri',
          value: uri,
        });
        this.choice.drag_img_uri = uri;
        this.isDragImages = false;
      } else {
        this.isDirty = true;
        this.updateChoiceData({
          key: 'img_uri',
          value: uri,
        });
        this.choice.img_uri = uri;
      }
      this.selectImageUri = '';
      this.img_key = '';
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.img_key = '';
      this.isEditImage = !this.isEditImage;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddSet && !this.isAddChoiceOfSet && this.isEditBGM) {
        this.updateCreateSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.set.bgm_uri = uri;
      } else if (this.isAddSet && !this.isAddChoiceOfSet) {
        this.updateCreateSetData({
          key: 'sound_uri',
          value: uri,
        });
        this.set.sound_uri = uri;
      } else if (this.isEditBGM) {
        this.updateSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.set.bgm_uri = uri;
      } else if (this.sound_key === 'set') {
        this.updateSetData({
          key: 'sound_uri',
          value: uri,
        });
        this.set.sound_uri = uri;
      } else if (this.isAddSet && this.currentStep == 1) {
        this.updateCreateChoiceData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.choice.sound_uri = uri;
      } else if (this.isAddChoiceOfSet) {
        this.updateCreateChoiceData({
          key: 'sound_uri',
          value: uri,
        });
        this.choice.sound_uri = uri;
      } else if (this.isAddChoice && !this.isCorrectSFX) {
        this.updateCreateSingleChoiceData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.choice.sound_uri = uri;
      } else if (
        this.isAddChoice &&
        this.isCorrectSFX &&
        this.type == 'Container'
      ) {
        this.choice.correct_sfx_uri = uri;
        this.updateCreateSingleChoiceData({
          key: 'correct_sfx_uri',
          value: uri,
        });
      } else {
        this.isDirty = true;
        if (this.isCorrectSFX) {
          this.updateChoiceData({
            key: 'correct_sfx_uri',
            value: uri,
          });
          this.choice.correct_sfx_uri = uri;
          this.isCorrectSFX = !this.isCorrectSFX;
        } else {
          this.updateChoiceData({
            key: 'sound_uri',
            value: uri,
          });
          this.choice.sound_uri = uri;
        }
      }
      this.sound_key = null;
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    onClickSaveChoice() {
      let setData = this.draganddropStore.updateSetData;
      if ('choices' in setData && 'containers' in setData) {
        const asArray = [{ ...setData }];
        [setData] = asArray.map(e => {
          return {
            choices: e.choices,
            containers: e.containers,
            set_id: e._id,
          };
        });
        this.updateChoice(setData);
      } else {
        const updateChoiceData = {
          ...this.draganddropStore.updateChoiceData,
        };
        this.updateChoice(updateChoiceData);
        this.isDirty = false;
        Object.keys(updateChoiceData).forEach(key => {
          this.choice[key] = updateChoiceData[key];
        });
      }
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateChoice() {
      const choices = [...this.draganddropStore.addChoiceData];
      if (
        this.currentStep == choices.length &&
        this.currentStep < this.maxChoice
      ) {
        const new_choice = { ...this.draganddropStore.choiceData };
        this.choice = { ...new_choice };
        this.choices = [...choices, new_choice];
        this.updateCreateChoiceData({
          key: 'text',
          value: '',
          index: this.currentStep,
        });
      } else {
        this.choice = { ...choices[this.currentStep] };
        this.choices = [...choices];
      }
    },
    resetValueOfSet(key, value) {
      if (key === 'type' && (value === 'sound' || value === 'image')) {
        if (value === 'sound') {
          this.set.img_uri = null;
        } else {
          this.set.sound_uri = null;
        }
      }
    },
    resetValueOfChoice(key, value) {
      if (key === 'type' && (value === 'sound' || value === 'image')) {
        if (value === 'sound') {
          this.choice.img_uri = null;
        } else {
          this.choice.sound_uri = null;
        }
      }
    },
    onChangeCreateChoice(key, value) {
      this.resetValueOfChoice(key, value);
      this.updateCreateChoiceData({
        key,
        value,
        index: this.currentStep,
      });
    },
    onChangeCreateSingleChoice(key, value) {
      this.resetValueOfChoice(key, value);
      this.updateCreateSingleChoiceData({
        key,
        value,
      });
    },
    toggleAddSet() {
      // init set data
      this.updateCreateSetData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.set = { ...this.draganddropStore.addSetData };
      // init choice data
      this.choices = [...this.draganddropStore.addChoiceData];
      this.isAddSet = !this.isAddSet;
      if (!this.isAddSet && this.draganddropStore.selectedCategoryId) {
        this.$router.push('/draganddrops');
      }
      // clear create data
      this.clearCreateData();
      this.images = [''];
      this.currentStep = -1;
    },
    onClickAddSet() {
      const id = this.$route.query.id;
      const createSetData = {
        ...this.draganddropStore.addSetData,
      };
      const createChoiceData = [...this.draganddropStore.addChoiceData];
      this.addSet({
        category_id: id,
        set: createSetData,
        choices: createChoiceData,
      });
      this.isAddSet = false;
      // clear create data
      this.clearCreateData();
      this.images = [''];
      this.currentStep = -1;
    },
    onClickDeleteSet(id) {
      this.deleteSet(id);
      this.isDirty = false;
      this.selectedSetIndex = -1;
      this.choice = {};
      this.choices = [];
      this.selectedChoiceIndex = -1;
    },
    toggleAddChoice() {
      this.images = [''];
      this.isEditImageIndex = -1;
      this.choice = { ...this.draganddropStore.choiceData };
      this.selectedChoiceIndex = -1;
      this.isAddChoice = !this.isAddChoice;
    },
    onClickAddChoice() {
      const id = this.$route.query.id;
      const createChoiceData = {
        ...this.draganddropStore.addSingleChoiceData,
      };
      this.addChoiceBySetId({
        category_id: id,
        set_id: this.selectSetId,
        type: this.type,
        choice: createChoiceData,
      });
      this.isAddChoice = false;
    },
    onClickDeleteChoice() {
      swal
        .fire({
          titleText: 'Confirm Delete this choice',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteChoice(this.selectChoiceId);
            this.isDirty = false;
            this.selectedChoiceIndex = -1;
            // this.type = '';
            // this.choices = [];
          }
        });
    },
    onChangeSetData(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.resetValueOfSet(key, value);
      this.updateSetData({
        key,
        value,
      });
    },
    onClickUpdateSet() {
      const updateSetData = {
        ...this.draganddropStore.updateSetData,
      };
      this.updateSet(updateSetData);
      Object.keys(updateSetData).forEach(key => {
        this.set[key] = updateSetData[key];
      });
    },
    onChangeCreateSet(key, value) {
      if (key == 'isGuestPlayable') {
        value = value ? true : false;
      }
      this.resetValueOfSet(key, value);
      this.updateCreateSetData({
        key,
        value,
      });
    },
    onChangeEventData(index, key, value) {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent[index][key] = value;
      this.rectEvent = rectEvent;
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onAddEventData() {
      const rectEvent = this.rectEvent ? [...this.rectEvent] : [];
      this.rectEvent = [...rectEvent, { ...this.draganddropStore.eventData }];
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onRemoveEventData() {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent.pop();
      this.rectEvent = rectEvent;
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onchangeToggleSprite(key, key_data, { value, index }) {
      if (index === undefined) {
        this.updateChoiceData({
          key: key,
          value: value,
        });
        this.choice[key] = value;
        this.updateChoiceData({
          key: key_data,
          value: this.choice[key_data],
        });
      } else {
        this.choice[key] = [...this.choice[key]];
        this.choice[key][index] = value;
        this.updateChoiceData({
          key: key,
          value: this.choice[key],
        });
        this.updateChoiceData({
          key: key_data,
          value: this.choice[key_data],
        });
      }
      this.isDirty = true;
    },
    saveSpriteSheetData(key, { index, value }) {
      if (index === undefined) {
        this.updateChoiceData({
          key: key,
          value: value,
        });
        this.choice[key] = value;
      } else {
        this.choice[key] = [...this.choice[key]];
        this.choice[key][index] = value;
        this.updateChoiceData({
          key: key,
          value: this.choice[key],
        });
      }
      this.isDirty = true;
    },
  },
  watch: {
    selectedSetIndexStore() {
      if (this.$route.query.category_id) {
        this.onSelectSet(this.$route.query.sub_id);
      }
    },
    'draganddropStore.selectDragAndDrop'(val) {
      if (this.selectedSetIndex > -1) {
        this.set = val.sets[this.selectedSetIndex];
      }
    },
  },
  async mounted() {
    const id = this.$route.query.id || this.$route.query.category_id,
      sub_id = this.$route.query.sub_id;
    if (this.draganddropStore.selectedCategoryId) {
      this.toggleAddSet();
    } else if (id) {
      await this.fetchSingleDragAndDrop({
        id,
        sub_id,
      });
    }
  },
};
</script>

<style scoped>
hr {
  display: block;
  height: 20px;
  border: 0;
  border-top: 2px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
</style>
