import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import userInfo from './modules/userInfo';
import appConfig from './modules/app';
import resource from './modules/resource';
import users from './modules/users';
import books from './modules/books';
import level from './modules/level';
import learningpath from './modules/learningpath';
import game from './modules/game';
import wordwalks from './modules/wordwalks';
import category from './modules/category';
import matchcard from './modules/matchcard';
import connectingnode from './modules/connectingnode';
import taptosay from './modules/taptosay';
import draganddrop from './modules/draganddrop';
import media from './modules/media';
import mixandmatch from './modules/mixandmatch';
import swipe from './modules/swipe';
import labeling from './modules/labeling';
import galaxychat from './modules/galaxychat';
import code from './modules/code';
import tracing from './modules/tracing';
import tracingbezier from './modules/tracingbezier';
import micpopup from './modules/micpopup';
import dashboard from './modules/dashboard';
import library from './modules/library';
import minichat from './modules/minichat';
import dictionary from './modules/dictionary';
import movedata from './modules/movedata';
import storygame from './modules/storygame';
import storyvideo from './modules/storyvideo';
import dicphonic from './modules/dicphonic';
import news from './modules/news';
import shop from './modules/shop';
import quest from './modules/quest';
import localization from './modules/localization';
import subtitle_localization from './modules/subtitle_localization';
import pointdata from './modules/pointdata';
import learningmedia from './modules/learningmedia';
import flyinggame from './modules/flyinggame';
import subtitle from './modules/subtitle';
import tapstory from './modules/tapstory';
import subtitle_video_localization from './modules/subtitle_video_localization';
import subtitle_sound_localization from './modules/subtitle_sound_localization';
import content_navigation from './modules/content_navigation';
import paymentstore from './modules/paymentstore';
import postbox from './modules/postbox';
import customer_service_chat from './modules/customer_service_chat';
import slide_puzzle from './modules/slide_puzzle';
import whack_a_mole from './modules/whack_a_mole';
import maze_game from './modules/mazegame';
import short_story from './modules/short_story';
import homepage from './modules/homepage';
import dragcombo from './modules/dragcombo';
import snap from './modules/snap';
import running_game from './modules/running_game';
import driving_game from './modules/driving_game';
import sequence_game from './modules/sequence_game';
import speech_lab_tag from './modules/speech_lab_tag';
import quiz_game from './modules/quiz_game';
import liveclass from './modules/liveclass';
import drag_and_drop_2 from './modules/drag_and_drop_2';
import labyrinth from './modules/labyrinth';
import shooting_arrow from './modules/shooting_arrow';
import cooking_game from './modules/cooking_game';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    userInfo,
    appConfig,
    resource,
    users,
    books,
    level,
    learningpath,
    game,
    wordwalks,
    category,
    matchcard,
    mixandmatch,
    connectingnode,
    swipe,
    labeling,
    taptosay,
    draganddrop,
    media,
    galaxychat,
    code,
    tracing,
    tracingbezier,
    micpopup,
    dashboard,
    library,
    minichat,
    dictionary,
    dicphonic,
    movedata,
    storygame,
    storyvideo,
    news,
    shop,
    quest,
    localization,
    subtitle_localization,
    pointdata,
    learningmedia,
    flyinggame,
    subtitle,
    tapstory,
    subtitle_video_localization,
    subtitle_sound_localization,
    content_navigation,
    paymentstore,
    postbox,
    customer_service_chat,
    slide_puzzle,
    whack_a_mole,
    maze_game,
    short_story,
    homepage,
    dragcombo,
    snap,
    running_game,
    driving_game,
    sequence_game,
    speech_lab_tag,
    quiz_game,
    liveclass,
    drag_and_drop_2,
    labyrinth,
    shooting_arrow,
    cooking_game,
  },
  plugins: debug ? [createLogger()] : [],
});
