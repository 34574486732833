export default {
  GAME_TYPE_ABBREV_ENUMS: {
    BOOK: 'ESL',
    CONNECTING_NODE: 'CN',
    DRAG_AND_DROP: 'DD',
    // GALAXY_CHAT: 'CHR',
    MATCH_CARD: 'MC',
    MEDIA: 'MEDIA',
    MIC_POP_UP: 'MPU',
    TAP_TO_SAY: 'TTS',
    TRACING_GAME: 'TR',
    TRACING_BEZIER: 'TB',
    WORD_WALK: 'WWA',
    MIX_AND_MATCH: 'MM',
    SWIPE: 'SW',
    LABELING: 'LB',
    MINICHAT: 'MNC',
    LEARNING_MEDIA: 'LM',
    FLYING_GAME: 'FG',
    TAPSTORY: 'TS',
    SLIDE_PUZZLE: 'SP',
    WHACK_A_MOLE: 'WAM',
    // MAZE_GAME: 'MG',
    DRAG_COMBO: 'DC',
    SNAP: 'SNAP',
    RUNNINGGAME: 'RUNNINGGAME',
    DRIVINGGAME: 'DRIVINGGAME',
    SEQUENCEGAME: 'SEQUENCEGAME',
    QUIZ: 'QUIZ',
    DRAG_AND_DROP_2: 'DD2',
    LABYRINTH: 'LABYRINTH',
    SHOOTING_ARROW: 'SHOOTING_ARROW',
    COOKING_GAME: 'COOKING_GAME',
  },
  GAME_TYPE: [
    {
      text: '',
      value: '',
    },
    {
      text: 'BOOK',
      value: 'ESL',
    },
    {
      text: 'CONNECTING_NODE',
      value: 'CN',
    },
    {
      text: 'DRAG_AND_DROP',
      value: 'DD',
    },
    // {
    //   text: 'GALAXY_CHAT',
    //   value: 'CHR',
    // },
    {
      text: 'MATCH_CARD',
      value: 'MC',
    },
    {
      text: 'MEDIA',
      value: 'MEDIA',
    },
    {
      text: 'MIC_POP_UP',
      value: 'MPU',
    },
    {
      text: 'TAP_TO_SAY',
      value: 'TTS',
    },
    {
      text: 'TRACING_GAME',
      value: 'TR',
    },
    {
      text: 'TRACING_BEZIER',
      value: 'TB',
    },
    {
      text: 'WORD_WALK',
      value: 'WWA',
    },
    {
      text: 'MIX_AND_MATCH',
      value: 'MM',
    },
    {
      text: 'SWIPE',
      value: 'SW',
    },
    {
      text: 'LABELING',
      value: 'LB',
    },
    {
      text: 'MINICHAT',
      value: 'MNC',
    },
    {
      text: 'LEARNING_MEDIA',
      value: 'LM',
    },
    {
      text: 'FLYING_GAME',
      value: 'FG',
    },
    {
      text: 'TAPSTORY',
      value: 'TS',
    },
    {
      text: 'SLIDE_PUZZLE',
      value: 'SP',
    },
    {
      text: 'WHACK_A_MOLE',
      value: 'WAM',
    },
    // {
    //   text: 'MAZE_GAME',
    //   value: 'MG',
    // },
    {
      text: 'DRAG_COMBO',
      value: 'DC',
    },
    {
      text: 'SNAP',
      value: 'SNAP',
    },
    {
      text: 'RUNNINGGAME',
      value: 'RUNNINGGAME',
    },
    {
      text: 'DRIVINGGAME',
      value: 'DRIVINGGAME',
    },
    {
      text: 'SEQUENCEGAME',
      value: 'SEQUENCEGAME',
    },
    {
      text: 'QUIZ',
      value: 'QUIZ',
    },
    {
      text: 'DRAG_AND_DROP_2',
      value: 'DD2',
    },
    {
      text: 'LABYRINTH',
      value: 'LABYRINTH',
    },
    {
      text: 'SHOOTING_ARROW',
      value: 'SHOOTING_ARROW',
    },
    {
      text: 'COOKING_GAME',
      value: 'COOKING_GAME',
    },
  ],
  QUESTION_TYPE: [
    {
      text: 'Text Only',
      value: 0,
    },
    {
      text: 'Text and Speech',
      value: 1,
    },
    {
      text: 'Speech Only',
      value: 2,
    },
    {
      text: 'Image Only',
      value: 3,
    },
    {
      text: 'Image and Speech',
      value: 4,
    },
    {
      text: 'Image and Text',
      value: 5,
    },
    {
      text: 'Image, Text and Speech',
      value: 6,
    },
  ],
  ANSWER_TYPE: [
    {
      text: 'Correct Answer(s)',
      value: 0,
    },
    {
      text: 'Order',
      value: 1,
    },
    {
      text: 'Matching',
      value: 2,
    },
  ],
  CARD_TYPE: [
    {
      text: 'Image Only',
      value: 'image',
    },
    {
      text: 'Sound Only',
      value: 'sound',
    },
    {
      text: 'Image and Sound',
      value: 'image_sound',
    },
  ],
  CARD_TYPE_NODE_SOUND_ONLY: [
    {
      text: 'Sound Only',
      value: true,
    },
  ],
  CARD_TYPE_CORRECT_IMAGE: [
    {
      text: 'Correct Image',
      value: true,
    },
  ],
  COLORCODE: [
    {
      text: 'Black',
      value: '#000000',
    },
    {
      text: 'Maroon',
      value: '#800000',
    },
    {
      text: 'Green',
      value: '#008000',
    },
    {
      text: 'Olive',
      value: '#808000',
    },
    {
      text: 'Navy',
      value: '#000080',
    },
    {
      text: 'Purple',
      value: '#800080',
    },
    {
      text: 'Teal',
      value: '#008080',
    },
    {
      text: 'Silver',
      value: '#c0c0c0',
    },
    {
      text: 'Grey',
      value: '#808080',
    },
    {
      text: 'Red',
      value: '#ff0000',
    },
    {
      text: 'Lime',
      value: '#00ff00',
    },
    {
      text: 'Yellow',
      value: '#ffff00',
    },
    {
      text: 'Blue',
      value: '#0000ff',
    },
    {
      text: 'Fuchsia',
      value: '#ff00ff',
    },
    {
      text: 'Aqua',
      value: '#00ffff',
    },
    {
      text: 'White',
      value: '#ffffff',
    },
    {
      text: 'Grey0',
      value: '#000000',
    },
    {
      text: 'NavyBlue',
      value: '#00005f',
    },
    {
      text: 'DarkBlue',
      value: '#000087',
    },
    {
      text: 'Blue3',
      value: '#0000af',
    },
    {
      text: 'Blue3',
      value: '#0000d7',
    },
    {
      text: 'Blue1',
      value: '#0000ff',
    },
    {
      text: 'DarkGreen',
      value: '#005f00',
    },
    {
      text: 'DeepSkyBlue4',
      value: '#005f5f',
    },
    {
      text: 'DeepSkyBlue4',
      value: '#005f87',
    },
    {
      text: 'DeepSkyBlue4',
      value: '#005faf',
    },
    {
      text: 'DodgerBlue3',
      value: '#005fd7',
    },
    {
      text: 'DodgerBlue2',
      value: '#005fff',
    },
    {
      text: 'Green4',
      value: '#008700',
    },
    {
      text: 'SpringGreen4',
      value: '#00875f',
    },
    {
      text: 'Turquoise4',
      value: '#008787',
    },
    {
      text: 'DeepSkyBlue3',
      value: '#0087af',
    },
    {
      text: 'DeepSkyBlue3',
      value: '#0087d7',
    },
    {
      text: 'DodgerBlue1',
      value: '#0087ff',
    },
    {
      text: 'Green3',
      value: '#00af00',
    },
    {
      text: 'SpringGreen3',
      value: '#00af5f',
    },
    {
      text: 'DarkCyan',
      value: '#00af87',
    },
    {
      text: 'LightSeaGreen',
      value: '#00afaf',
    },
    {
      text: 'DeepSkyBlue2',
      value: '#00afd7',
    },
    {
      text: 'DeepSkyBlue1',
      value: '#00afff',
    },
    {
      text: 'Green3',
      value: '#00d700',
    },
    {
      text: 'SpringGreen3',
      value: '#00d75f',
    },
    {
      text: 'SpringGreen2',
      value: '#00d787',
    },
    {
      text: 'Cyan3',
      value: '#00d7af',
    },
    {
      text: 'DarkTurquoise',
      value: '#00d7d7',
    },
    {
      text: 'Turquoise2',
      value: '#00d7ff',
    },
    {
      text: 'Green1',
      value: '#00ff00',
    },
    {
      text: 'SpringGreen2',
      value: '#00ff5f',
    },
    {
      text: 'SpringGreen1',
      value: '#00ff87',
    },
    {
      text: 'MediumSpringGreen',
      value: '#00ffaf',
    },
    {
      text: 'Cyan2',
      value: '#00ffd7',
    },
    {
      text: 'Cyan1',
      value: '#00ffff',
    },
    {
      text: 'DarkRed',
      value: '#5f0000',
    },
    {
      text: 'DeepPink4',
      value: '#5f005f',
    },
    {
      text: 'Purple4',
      value: '#5f0087',
    },
    {
      text: 'Purple4',
      value: '#5f00af',
    },
    {
      text: 'Purple3',
      value: '#5f00d7',
    },
    {
      text: 'BlueViolet',
      value: '#5f00ff',
    },
    {
      text: 'Orange4',
      value: '#5f5f00',
    },
    {
      text: 'Grey37',
      value: '#5f5f5f',
    },
    {
      text: 'MediumPurple4',
      value: '#5f5f87',
    },
    {
      text: 'SlateBlue3',
      value: '#5f5faf',
    },
    {
      text: 'SlateBlue3',
      value: '#5f5fd7',
    },
    {
      text: 'RoyalBlue1',
      value: '#5f5fff',
    },
    {
      text: 'Chartreuse4',
      value: '#5f8700',
    },
    {
      text: 'DarkSeaGreen4',
      value: '#5f875f',
    },
    {
      text: 'PaleTurquoise4',
      value: '#5f8787',
    },
    {
      text: 'SteelBlue',
      value: '#5f87af',
    },
    {
      text: 'SteelBlue3',
      value: '#5f87d7',
    },
    {
      text: 'CornflowerBlue',
      value: '#5f87ff',
    },
    {
      text: 'Chartreuse3',
      value: '#5faf00',
    },
    {
      text: 'DarkSeaGreen4',
      value: '#5faf5f',
    },
    {
      text: 'CadetBlue',
      value: '#5faf87',
    },
    {
      text: 'CadetBlue',
      value: '#5fafaf',
    },
    {
      text: 'SkyBlue3',
      value: '#5fafd7',
    },
    {
      text: 'SteelBlue1',
      value: '#5fafff',
    },
    {
      text: 'Chartreuse3',
      value: '#5fd700',
    },
    {
      text: 'PaleGreen3',
      value: '#5fd75f',
    },
    {
      text: 'SeaGreen3',
      value: '#5fd787',
    },
    {
      text: 'Aquamarine3',
      value: '#5fd7af',
    },
    {
      text: 'MediumTurquoise',
      value: '#5fd7d7',
    },
    {
      text: 'SteelBlue1',
      value: '#5fd7ff',
    },
    {
      text: 'Chartreuse2',
      value: '#5fff00',
    },
    {
      text: 'SeaGreen2',
      value: '#5fff5f',
    },
    {
      text: 'SeaGreen1',
      value: '#5fff87',
    },
    {
      text: 'SeaGreen1',
      value: '#5fffaf',
    },
    {
      text: 'Aquamarine1',
      value: '#5fffd7',
    },
    {
      text: 'DarkSlateGray2',
      value: '#5fffff',
    },
    {
      text: 'DarkRed',
      value: '#870000',
    },
    {
      text: 'DeepPink4',
      value: '#87005f',
    },
    {
      text: 'DarkMagenta',
      value: '#870087',
    },
    {
      text: 'DarkMagenta',
      value: '#8700af',
    },
    {
      text: 'DarkViolet',
      value: '#8700d7',
    },
    {
      text: 'Purple',
      value: '#8700ff',
    },
    {
      text: 'Orange4',
      value: '#875f00',
    },
    {
      text: 'LightPink4',
      value: '#875f5f',
    },
    {
      text: 'Plum4',
      value: '#875f87',
    },
    {
      text: 'MediumPurple3',
      value: '#875faf',
    },
    {
      text: 'MediumPurple3',
      value: '#875fd7',
    },
    {
      text: 'SlateBlue1',
      value: '#875fff',
    },
  ],
  COLORS: [
    {
      text: 'None',
      value: 'none',
    },
    {
      text: 'Red',
      value: 'red',
    },
    {
      text: 'Green',
      value: 'green',
    },
    {
      text: 'Blue',
      value: 'blue',
    },
    {
      text: 'Yellow',
      value: 'yellow',
    },
    {
      text: 'Orange',
      value: 'orange',
    },
    {
      text: 'Pink',
      value: 'pink',
    },
    {
      text: 'Purple',
      value: 'purple',
    },
    {
      text: 'White',
      value: 'white',
    },
    {
      text: 'Black',
      value: 'black',
    },
    {
      text: 'Multi-Clolor',
      value: 'multicolor',
    },
  ],
  CODE_TYPE: [
    {
      text: 'Discount 1 Month Subscription',
      value: 'subscription.monthly.discountprice',
    },
    {
      text: 'Discount 3 Months Subscription',
      value: 'subscription.three.months.discountprice',
    },
    {
      text: 'Discount 6 Months Subscription',
      value: 'subscription.six.months.discountprice',
    },
    {
      text: 'Discount 12 Months Subscription',
      value: 'subscription.annual.discountprice',
    },
    {
      text: 'Free Trial',
      value: 'subscription.free.trial',
    },
  ],
  POPUP_ANIMATION: [
    {
      text: 'TALK',
      value: 1,
    },
    {
      text: 'CLAP',
      value: 3,
    },
  ],
  POPUP_TYPE: [
    {
      text: 'Wait TTS',
      value: 1,
    },
    {
      text: 'One Time',
      value: 0,
    },
  ],
  PRONOUN: [
    {
      text: 'He',
      value: 0,
    },
    {
      text: 'She',
      value: 1,
    },
    {
      text: 'It',
      value: 2,
    },
    {
      text: 'I',
      value: 3,
    },
    {
      text: 'You',
      value: 4,
    },
    {
      text: 'We',
      value: 5,
    },
    {
      text: 'They',
      value: 6,
    },
  ],
  VOICE_NAME: [
    'enUSGuyNeural',
    'enUSAIGenerate1Neural',
    'enUSAIGenerate2Neura',
    'enUSAmberNeural',
    'enUSAnaNeural',
    'enUSAriaNeural',
    'enUSAshleyNeural',
    'enUSBrandonNeural',
    'enUSChristopherNeural',
    'enUSCoraNeural',
    'enUSDavisNeural',
    'enUSElizabethNeural',
    'enUSEricNeural',
    'enUSJacobNeural',
    'enUSJaneNeural',
    'enUSJasonNeural',
    'enUSJennyMultilingualNeural',
    'enUSJennyNeural',
    'enUSMichelleNeural',
    'enUSMonicaNeural',
    'enUSNancyNeural',
    'enUSRogerNeural',
    'enUSSaraNeural',
    'enUSSteffanNeural',
    'enUSTonyNeural',
  ],
  THEME_NAME: ['random', 'race_track', 'sand', 'water', 'racing_track'],
};
