import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  snapList: [],
  selectedSnap: {},
  defaultCreateSnap: {
    category_id: '',
    title: '',
    snap: [
      {
        title: '',
        correct_img_url: '',
        incorrect_img_url: ['', '', ''],
        tts_message: '',
        voice_name: 'enUSAnaNeural',
      },
    ],
    bgm_uri: '',
    bgm_volume: '0.5',
    music_intro_uri: '',
    tts_message: '',
    voice_name: 'enUSAnaNeural',
  },
};
const getters = {};
const actions = {
  async fetchSnapCategory({ commit, dispatch }, { page = 1, limit = 0 }) {
    return http
      .GET(`/api/snap/category?page=${page}&limit=${limit}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'snapList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch snap in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async fetchSnapList({ commit, dispatch }, { id, game_list_id, category_id }) {
    const url = id ? `/api/snap` : `/api/snap/category`;
    const query = {
      params: game_list_id
        ? { id: game_list_id }
        : category_id
        ? { category_id }
        : { id, isWeb: true },
    };
    return http
      .GET(url, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => {
          const snap = e.snaps.map(data => {
            return {
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            };
          });
          e.snaps = snap;
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'snapList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot fetch snap in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async createSnap({ commit, dispatch }, createData) {
    http
      .POST(`/api/snap`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.snapList._id) {
          dispatch('fetchSnapList', { game_list_id: state.snapList._id });
        } else {
          router.go({
            path: '/snap',
          });
          dispatch('fetchSnapCategory', {
            page: 1,
            limit: 0,
          });
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create snap successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot create snap in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async deleteSnap({ commit, dispatch }, id) {
    http
      .DELETE(`/api/snap?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchSnapList', {
          game_list_id: state.snapList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete Snap Data successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: 'Cannot delete snap in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  async updateSnap({ commit, dispatch }, updateData) {
    if (updateData._id && Object.keys(updateData).length > 0) {
      delete updateData.created_at;
      delete updateData.updated_at;
      delete updateData.game_list_id;
      http
        .PUT(`/api/snap`, updateData, {
          isAuthorization: true,
        })
        .then(({ data }) => {
          dispatch('fetchSnapList', {
            game_list_id: state.selectedSnap.game_list_id,
          });
          dispatch(
            'appConfig/toggleAlert',
            {
              type: 'success',
              message: 'Update snap successfully',
            },
            {
              root: true,
            },
          );
        })
        .catch(err => {
          dispatch(
            'appConfig/toggleAlert',
            {
              message: 'Cannot update snap in this time. please try again.',
              type: 'error',
            },
            {
              root: true,
            },
          );
          throw new Error(err);
        });
    } else {
      dispatch(
        'appConfig/toggleAlert',
        {
          message: 'Cannot update snap in this time. update data invalid form.',
          type: 'error',
        },
        {
          root: true,
        },
      );
    }
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
