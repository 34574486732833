<template>
  <div>
    <v-flex>
      <img :src="finish.start_image_uri" class="content_img" />
      <v-text-field
        label="Start Image URI"
        v-model="finish.start_image_uri"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickStartImage"
        ><v-icon>image</v-icon>Select Start Image URI</v-btn
      >
      <SpriteSheet
        :title="'is start sprite sheet'"
        :isToggle="finish.is_start_sprite_sheet"
        :fieldname="'Edit Start Sheet Data'"
        :object="finish.start_sprite_sheet_data"
        @onchangeToggleSprite="
          $emit(
            'onchangeToggleSprite',
            'is_start_sprite_sheet',
            'start_sprite_sheet_data',
            $event,
          )
        "
        @saveSpriteSheetData="
          $emit('saveSpriteSheetData', 'start_sprite_sheet_data', $event)
        "
      />
      <img :src="finish.finish_image_uri" class="content_img" />
      <v-text-field
        label="Finish Image URI"
        v-model="finish.finish_image_uri"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickFinishImage"
        ><v-icon>audiotrack</v-icon>Select Finish Image URI</v-btn
      >
      <SpriteSheet
        :title="'is finish sprite sheet'"
        :isToggle="finish.is_finish_sprite_sheet"
        :fieldname="'Edit Finish Sheet Data'"
        :object="finish.finish_sprite_sheet_data"
        @onchangeToggleSprite="
          $emit(
            'onchangeToggleSprite',
            'is_finish_sprite_sheet',
            'finish_sprite_sheet_data',
            $event,
          )
        "
        @saveSpriteSheetData="
          $emit('saveSpriteSheetData', 'finish_sprite_sheet_data', $event)
        "
      />
      <v-text-field
        label="Finish Sound URI"
        v-model="finish.finish_sound_uri"
      ></v-text-field>
      <v-btn class="btn-center" @click="onclickFinishSound"
        ><v-icon>audiotrack</v-icon>Select Finish Sound URI</v-btn
      >
    </v-flex>
    <ResourceSelect
      v-if="isSelectImage"
      resourceType="image"
      :isShow="isSelectImage"
      :close="closeSelectImage"
      :save="saveSelectImage"
      :value="selectedImageUri"
      :onSelect="onSelectImageResource"
    />
    <ResourceSelect
      v-if="isSelectSound"
      resourceType="sound"
      :isShow="isSelectSound"
      :close="closeSelectSound"
      :save="saveSelectSound"
      :value="selectedSoundUri"
      :onSelect="onSelectSoundResource"
    />
  </div>
</template>

<script>
import SpriteSheet from '../../../components/SpriteSheetNew.vue';
import ResourceSelect from '../../../components/ResourceSelect.vue';

export default {
  name: 'Finish',
  components: {
    SpriteSheet,
    ResourceSelect,
  },
  data() {
    return {
      isSelectSound: false,
      isSelectImage: false,
      selectedSoundUri: '',
      selectedImageUri: '',
      isSelectFinishVoice: false,
      isSelectStartImage: false,
      isSelectFinishmage: false,
    };
  },
  props: {
    finish: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    onclickStartImage() {
      this.isSelectImage = true;
      this.isSelectStartImage = true;
    },
    onclickFinishImage() {
      this.isSelectImage = true;
      this.isSelectFinishmage = true;
    },
    onSelectImageResource(uri) {
      this.selectedImageUri = uri;
    },
    closeSelectImage() {
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    saveSelectImage() {
      const uri = this.selectedImageUri;
      switch (true) {
        case this.isSelectStartImage:
          this.finish.start_image_uri = uri;
          this.isSelectStartImage = false;
          break;
        case this.isSelectFinishmage:
          this.finish.finish_image_uri = uri;
          this.isSelectFinishmage = false;
          break;
        default:
          break;
      }
      this.isSelectImage = false;
      this.selectedImageUri = '';
    },
    onclickFinishSound() {
      this.isSelectSound = true;
      this.isSelectFinishVoice = true;
    },
    onSelectSoundResource(uri) {
      this.selectedSoundUri = uri;
    },
    closeSelectSound() {
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
    saveSelectSound() {
      const uri = this.selectedSoundUri;
      switch (true) {
        case this.isSelectFinishVoice:
          this.finish.finish_sound_uri = uri;
          this.isSelectFinishVoice = false;
          break;
        default:
          break;
      }
      this.isSelectSound = false;
      this.selectedSoundUri = '';
    },
  },
};
</script>
<style scoped>
.content_img {
  border-radius: 15px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}
.btn-center {
  margin: 0 auto;
  display: flex;
}
</style>
