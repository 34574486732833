<template>
  <Container>
    <v-flex justify-start>
      <v-card>
        <v-toolbar dark>
          <v-btn
            icon
            color="white"
            light
            v-on:click="$router.push('/taptosay')"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            (taptosayStore.selectTapToSay &&
              taptosayStore.selectTapToSay.category) ||
            'Loading...'
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="taptosayStore.selectTapToSay">
          <v-layout row grid-list-md v-if="!taptosayStore.isUpdating">
            <v-flex xs3 mr-2>
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Select Set</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon color="green" v-on:click="toggleAddSet">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(set, index) in taptosayFilteredStore"
                    :key="set._id"
                    v-on:click="onSelectSet(set._id)"
                    :class="index === selectedSetIndex ? 'active' : 'inactive'"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ set.name }}</v-list-tile-title>
                      <v-list-tile-sub-title v-if="set.objects.length > 1"
                        >{{ set.objects.length }} objects</v-list-tile-sub-title
                      >
                      <v-list-tile-sub-title v-else
                        >{{ set.objects.length }} object</v-list-tile-sub-title
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs3 mr-2 v-if="selectedSetIndex != -1">
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Set Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickUpdateSet">
                    <v-icon>save</v-icon>Save Set
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-layout column>
                    <v-flex>
                      <CopyIdForm
                        :game_id="set._id"
                        :category_id="set.category_id"
                      ></CopyIdForm>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        class="white--text"
                        color="pointdata"
                        @click="openPointData"
                        >Point Data
                      </v-btn>
                      <Clipboard :objectToCopy="set"> </Clipboard>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Available for Free User"
                        v-model="set.isGuestPlayable"
                        :value="set.isGuestPlayable"
                        v-on:change="e => onChangeSetData('isGuestPlayable', e)"
                      >
                      </v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Name"
                        :value="set.name"
                        v-on:change="e => onChangeSetData('name', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Question"
                        :value="set.question"
                        v-on:change="e => onChangeSetData('question', e)"
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-select
                        :items="constants.CARD_TYPE"
                        label="Object Type"
                        :value="set.type"
                        v-model="set.type"
                        v-on:change="e => onChangeSetData('type', e)"
                      ></v-select>
                    </v-flex>
                    <v-btn
                      v-if="set.sound_uri"
                      class="white--text"
                      color="subtitle_sound"
                      @click="openSubtitleSound()"
                      >Subtitle Sound
                    </v-btn>
                    <v-flex
                      v-if="
                        set.type &&
                        (set.type == 'sound' || set.type == 'image_sound')
                      "
                    >
                      <v-text-field
                        label="Sound for question"
                        :value="set.sound_uri"
                        v-on:change="e => onChangeSetData('sound_uri', e)"
                      />
                      <v-btn color v-on:click="isEditSound = true">
                        <v-icon>audiotrack</v-icon>Edit Sound
                      </v-btn>
                    </v-flex>

                    <v-flex
                      xs6
                      v-if="
                        set.type &&
                        (set.type == 'image' ||
                          set.type == 'image_sound' ||
                          set.type == 'image_tts')
                      "
                    >
                      <img
                        :src="set.img_uri"
                        :alt="set.set"
                        class="content_img"
                      />
                      <v-container>
                        <v-layout fill-height>
                          <v-flex xs12 align-end flexbox>
                            <v-text-field
                              label="Background Image"
                              :value="set.img_uri"
                              v-on:change="e => onChangeSetData('img_uri', e)"
                            />
                            <span class="headline" dark>
                              <v-btn
                                color
                                v-on:click="
                                  isEditImage = true;
                                  isUpdateSet = true;
                                  isUpdateObject = false;
                                "
                              >
                                <v-icon>image</v-icon>Edit Image
                              </v-btn>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-flex>

                    <v-flex>
                      <v-checkbox
                        label="Hide Item"
                        v-model="set.is_hide"
                        :value="set.is_hide"
                        v-on:change="e => onChangeSetData('is_hide', e)"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                        label="Show finger"
                        v-model="set.is_show"
                        :value="set.is_show"
                        v-on:change="e => onChangeSetData('is_show', e)"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="Background Music"
                        :value="set.bgm_uri"
                        v-model="set.bgm_uri"
                        v-on:change="e => onChangeSetData('bgm_uri', e)"
                      ></v-text-field>
                      <v-btn
                        v-on:click="
                          isEditSound = true;
                          isEditBGM = true;
                        "
                        >Select Sound</v-btn
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout column class="elevation-2" style="padding: 20px">
                    <label>
                      <u>⌗ Rec.Event</u>
                    </label>
                    <td
                      v-for="(event, index) in rectEvent"
                      :key="index"
                      class="elevation-2"
                      style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <label>Event {{ index + 1 }}:</label>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start X"
                            :value="event.startX"
                            v-on:change="
                              e => onChangeEventData(index, 'startX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End X"
                            :value="event.endX"
                            v-on:change="
                              e => onChangeEventData(index, 'endX', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout align-content-space-between>
                        <v-flex>
                          <v-text-field
                            label="Start Y"
                            :value="event.startY"
                            v-on:change="
                              e => onChangeEventData(index, 'startY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            label="End Y"
                            :value="event.endY"
                            v-on:change="
                              e => onChangeEventData(index, 'endY', e)
                            "
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-flex>
                        <v-text-field
                          label="Next Index"
                          :value="event.nextIndex"
                          v-on:change="
                            e =>
                              onChangeEventData(index, 'nextIndex', parseInt(e))
                          "
                        ></v-text-field>
                      </v-flex>
                    </td>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="rectEvent && rectEvent.length > 0"
                        icon
                        color="red"
                        @click="onRemoveEventData"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn v-else icon color="grey">
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn icon color="green" @click="onAddEventData">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-layout>
                  <v-btn
                    color="red"
                    dark
                    v-on:click="onClickDeleteSet(set._id)"
                  >
                    <v-icon>delete</v-icon>Delete this set
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs2
              mr-2
              v-if="objects.length > 0 && selectedSetIndex != -1"
            >
              <v-card>
                <v-toolbar dark>
                  <v-toolbar-title>Object</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    color="green"
                    v-on:click="toggleAddObject"
                    v-if="objects.length < maximumAddObject"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list>
                  <v-list-tile
                    v-for="(object, index) in taptosayStore.selectTapToSay
                      .object_sets[selectedSetIndex].objects"
                    :key="object._id"
                    v-on:click="onSelectObject(object._id)"
                    :class="
                      index === selectedObjectIndex ? 'active' : 'inactive'
                    "
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ index + 1 }}. {{ object.name }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title>{{
                        object.text
                      }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-avatar v-if="object.is_correct == true">
                      <v-icon color="green">check_circle</v-icon>
                    </v-list-tile-avatar>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
            <v-flex
              v-if="Object.keys(object).length > 1 && selectedSetIndex != -1"
              xs4
              mr-2
            >
              <v-card dark>
                <v-toolbar dark>
                  <v-toolbar-title>Object Detail</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="green" v-on:click="onClickSaveObject">
                    <v-icon>save</v-icon>Save object
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-container grid-list-xs text-xs-center>
                    <v-layout column>
                      <v-flex xs6>
                        <v-layout column>
                          <v-flex>
                            <v-text-field
                              label="Name"
                              :value="object.name"
                              v-on:change="e => onChangeObjectData('name', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              label="Text"
                              :value="object.text"
                              v-on:change="e => onChangeObjectData('text', e)"
                            />
                          </v-flex>
                          <v-flex>
                            <v-checkbox
                              label="Correct"
                              v-model="object.is_correct"
                              :value="object.is_correct"
                              v-on:change="e => onChangeCorrectObject(e)"
                            ></v-checkbox>
                          </v-flex>
                          <v-flex>
                            <v-checkbox
                              label="Multiple Media"
                              v-model="object.is_multiple_media"
                              :value="object.is_multiple_media"
                              v-on:change="e => onChangeMultipleMediaList(e)"
                            ></v-checkbox>
                          </v-flex>
                          <v-flex v-if="object.is_multiple_media">
                            <v-btn @click="onClickEditMultipleMedia">
                              <v-icon>image</v-icon>Select Media
                            </v-btn>
                          </v-flex>
                          <div v-if="!object.is_multiple_media">
                            <v-flex>
                              <v-select
                                :items="constants.CARD_TYPE"
                                label="Object Type"
                                :value="object.type"
                                v-model="object.type"
                                v-on:change="e => onChangeObjectData('type', e)"
                              ></v-select>
                            </v-flex>
                            <v-flex
                              v-if="
                                object.type &&
                                (object.type == 'sound' ||
                                  object.type == 'image_sound')
                              "
                            >
                              <v-text-field
                                label="Sound for question"
                                :value="object.sound_uri"
                                v-on:change="
                                  e => onChangeObjectData('sound_uri', e)
                                "
                              />
                              <v-btn
                                color="black"
                                @click="
                                  isEditSound = true;
                                  isEditSoundForQuestionInObjectDetail = true;
                                "
                              >
                                <v-icon>audiotrack</v-icon>Edit Sound
                              </v-btn>
                            </v-flex>
                            <v-flex
                              xs6
                              v-if="
                                object.type &&
                                (object.type == 'image' ||
                                  object.type == 'image_sound' ||
                                  object.type == 'image_tts')
                              "
                            >
                              <img
                                :src="object.img_uri"
                                :alt="object.object"
                                class="content_img"
                              />
                              <v-container>
                                <v-layout fill-height>
                                  <v-flex xs12 align-end flexbox>
                                    <v-text-field
                                      label="Background Image"
                                      :value="object.img_uri"
                                      v-on:change="
                                        e => onChangeObjectData('img_uri', e)
                                      "
                                    />

                                    <span class="headline" dark>
                                      <v-btn
                                        color="black"
                                        v-on:click="
                                          isEditImage = true;
                                          isUpdateObject = true;
                                          isUpdateSet = false;
                                        "
                                      >
                                        <v-icon>image</v-icon>Edit Image
                                      </v-btn>
                                    </span>
                                    <SpriteSheet
                                      :title="'Use Sprite Sheet'"
                                      :isToggle="object.is_sprite_sheet"
                                      :fieldname="'Edit Move Sheet Data'"
                                      :object="object.sprite_sheet_data"
                                      @onchangeToggleSprite="
                                        onchangeToggleSprite(
                                          'is_sprite_sheet',
                                          'sprite_sheet_data',
                                          $event,
                                        )
                                      "
                                      @saveSpriteSheetData="
                                        saveSpriteSheetData(
                                          'sprite_sheet_data',
                                          $event,
                                        )
                                      "
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                            <v-flex>
                              <img
                                :src="object.correct_img_uri"
                                :alt="object.object"
                                class="content_img"
                              />
                              <v-container>
                                <v-layout fill-height>
                                  <v-flex xs12 align-end flexbox>
                                    <v-text-field
                                      label="Correct Image"
                                      :value="object.correct_img_uri"
                                      v-on:change="
                                        e =>
                                          onChangeObjectData(
                                            'correct_img_uri',
                                            e,
                                          )
                                      "
                                    />
                                    <span class="headline" dark>
                                      <v-btn
                                        color="black"
                                        v-on:click="
                                          isEditImage = true;
                                          isUpdateObject = true;
                                          isCorrectImage = true;
                                          isUpdateSet = false;
                                        "
                                      >
                                        <v-icon>image</v-icon>Edit Correct Image
                                      </v-btn>
                                    </span>
                                    <SpriteSheet
                                      :title="'Use Correct Sprite Sheet'"
                                      :isToggle="object.is_correct_sprite_sheet"
                                      :fieldname="'Edit Move Sheet Data'"
                                      :object="object.correct_sprite_sheet_data"
                                      @onchangeToggleSprite="
                                        onchangeToggleSprite(
                                          'is_correct_sprite_sheet',
                                          'correct_sprite_sheet_data',
                                          $event,
                                        )
                                      "
                                      @saveSpriteSheetData="
                                        saveSpriteSheetData(
                                          'correct_sprite_sheet_data',
                                          $event,
                                        )
                                      "
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-flex>
                          </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-btn
                      v-if="objects.length > 1"
                      color="red"
                      dark
                      v-on:click="onClickDeleteObject()"
                    >
                      <v-icon>delete</v-icon>Delete this object
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :save="saveEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :save="saveEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
    <PointData
      :v-if="isOpenPointData"
      :isOpen="isOpenPointData"
      :close="closePointData"
      :save="savePointData"
      :learnedWords="
        selectedSetIndex != -1
          ? taptosayStore.selectTapToSay.object_sets[selectedSetIndex]
              .learned_words
          : null
      "
      :incidentalWords="
        selectedSetIndex != -1
          ? taptosayStore.selectTapToSay.object_sets[selectedSetIndex]
              .incidental_words
          : null
      "
      :rewards="
        selectedSetIndex != -1
          ? taptosayStore.selectTapToSay.object_sets[selectedSetIndex].reward
          : null
      "
      :sentences="
        selectedSetIndex != -1
          ? taptosayStore.selectTapToSay.object_sets[selectedSetIndex].sentence
          : null
      "
      :phonics="
        selectedSetIndex != -1
          ? taptosayStore.selectTapToSay.object_sets[selectedSetIndex].phonic
          : null
      "
    >
    </PointData>
    <Modal
      :v-if="isAddSet"
      :isOpen="isAddSet"
      :isLastStep="isLastStep"
      :close="toggleAddSet"
      :save="onClickAddSet"
      title="Add Set"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center v-if="currentStep == -1">
          <v-flex>
            <v-text-field
              label="Name"
              :value="set.name"
              v-model="set.name"
              v-on:change="e => onChangeCreateSet('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Question"
              :value="set.question"
              v-model="set.question"
              v-on:change="e => onChangeCreateSet('question', e)"
            ></v-text-field>
          </v-flex>

          <v-flex>
            <v-select
              :items="constants.CARD_TYPE"
              label="Object Type"
              :value="set.type"
              v-model="set.type"
              v-on:change="e => onChangeCreateSet('type', e)"
            ></v-select>
          </v-flex>

          <v-flex
            v-if="
              set.type && (set.type == 'sound' || set.type == 'image_sound')
            "
          >
            <v-text-field
              v-if="set.sound_uri"
              label="Sound for question"
              :value="set.sound_uri"
              v-model="set.sound_uri"
              v-on:change="e => onChangeCreateSet('sound_uri', e)"
              disabled
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>

          <v-flex
            v-if="
              set.type &&
              (set.type == 'image' ||
                set.type == 'image_sound' ||
                set.type == 'image_tts')
            "
          >
            <v-flex>
              <v-flex style="text-align: -webkit-center">
                <v-img
                  v-if="set.img_uri"
                  :src="set.img_uri"
                  width="400"
                  height="300"
                ></v-img>
              </v-flex>
            </v-flex>
            <v-text-field
              label="Background Image"
              :value="set.img_uri"
              v-model="set.img_uri"
              v-on:change="e => onChangeCreateSet('img_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
          </v-flex>

          <v-flex>
            <v-checkbox
              label="Hide Item"
              v-model="set.is_hide"
              :value="set.is_hide"
              v-on:change="e => onChangeCreateSet('is_hide', e)"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-checkbox
              label="Show finger"
              v-model="set.is_show"
              :value="set.is_show"
              v-on:change="e => onChangeCreateSet('is_show', e)"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Background Music"
              :value="set.bgm_uri"
              v-model="set.bgm_uri"
              v-on:change="e => onChangeCreateSet('bgm_uri', e)"
            ></v-text-field>
            <v-btn
              v-on:click="
                isEditSound = true;
                isEditBGM = true;
              "
              >Select Sound</v-btn
            >
          </v-flex>
        </v-layout>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Object {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-flex>
              <v-text-field
                label="Name"
                :value="object.name"
                v-model="object.name"
                v-on:change="e => onChangeCreateObject('name', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                label="Text"
                :value="object.text"
                v-model="object.text"
                v-on:change="e => onChangeCreateObject('text', e)"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-checkbox
                label="Correct"
                v-model="object.is_correct"
                :value="object.is_correct"
                v-on:change="e => onChangeCreateObject('is_correct', e)"
              ></v-checkbox>
            </v-flex>
            <v-flex>
              <v-flex>
                <v-flex style="text-align: -webkit-center">
                  <v-img
                    v-if="object.correct_img_uri"
                    :src="object.correct_img_uri"
                    width="400"
                    height="300"
                  ></v-img>
                </v-flex>
              </v-flex>
              <v-text-field
                label="Correct Image"
                :value="object.correct_img_uri"
                v-model="object.correct_img_uri"
                v-on:change="e => onChangeCreateObject('correct_img_uri', e)"
              ></v-text-field>
              <v-btn
                v-on:click="
                  isEditImage = true;
                  isCorrectImage = true;
                  isAddObject = true;
                "
                >Select Correct Image</v-btn
              >
            </v-flex>
            <v-flex>
              <v-select
                :items="constants.CARD_TYPE"
                label="Object Type"
                :value="object.type"
                v-model="object.type"
                v-on:change="e => onChangeCreateObject('type', e)"
              ></v-select>
            </v-flex>
            <v-flex
              v-if="
                object.type &&
                (object.type == 'sound' || object.type == 'image_sound')
              "
            >
              <v-text-field
                v-if="object.sound_uri"
                label="Sound for question"
                :value="object.sound_uri"
                v-model="object.sound_uri"
                v-on:change="e => onChangeCreateObject('sound_uri', e)"
                disabled
              ></v-text-field>
              <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
            </v-flex>
            <v-flex
              v-if="
                object.type &&
                (object.type == 'image' ||
                  object.type == 'image_sound' ||
                  object.type == 'image_tts')
              "
            >
              <v-flex>
                <v-flex style="text-align: -webkit-center">
                  <v-img
                    v-if="object.img_uri"
                    :src="object.img_uri"
                    width="400"
                    height="300"
                  ></v-img>
                </v-flex>
              </v-flex>
              <v-text-field
                label="Background Image"
                :value="object.img_uri"
                v-model="object.img_uri"
                v-on:change="e => onChangeCreateObject('img_uri', e)"
              ></v-text-field>
              <v-btn
                v-on:click="
                  isEditImage = true;
                  isCorrectImage = false;
                  isAddObject = true;
                "
                >Select Image</v-btn
              >
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn
            v-if="currentStep > -1"
            color="red"
            dark
            v-on:click="onClickBackStep"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep + 1 < objects.length"
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Next</v-btn
          >
          <v-btn
            v-if="
              currentStep + 1 == objects.length && currentStep + 1 < maxObject
            "
            color="green"
            dark
            v-on:click="onClickNextStep"
            >Add More Object</v-btn
          >
        </v-card-actions>
      </v-container>
    </Modal>
    <Modal
      :v-if="isAddObject && !isAddSet"
      :isOpen="isAddObject && !isAddSet"
      :close="toggleAddObject"
      :save="onClickAddObject"
      :isLastStep="!!(object.name && object.text)"
      title="Add Object"
    >
      <v-container>
        <v-layout grid-list-xs column text-xs-center>
          <v-flex>
            <v-text-field
              label="Name"
              :value="object.name"
              v-model="object.name"
              v-on:change="e => onChangeCreateSingleObject('name', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-text-field
              label="Text"
              :value="object.text"
              v-model="object.text"
              v-on:change="e => onChangeCreateSingleObject('text', e)"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-checkbox
              label="Correct"
              v-model="object.is_correct"
              :value="object.is_correct"
              v-on:change="e => onChangeCreateSingleObject('is_correct', e)"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-checkbox
              label="Multiple Media"
              v-model="object.is_multiple_media"
              :value="object.is_multiple_media"
              v-on:change="
                e => onChangeCreateSingleObject('is_multiple_media', e)
              "
            ></v-checkbox>
          </v-flex>
          <v-flex v-if="object.is_multiple_media">
            <v-btn @click="onClickAddMultipleMedia">
              <v-icon>image</v-icon>Select Media
            </v-btn>
          </v-flex>
          <v-flex v-if="!object.is_multiple_media">
            <v-select
              :items="constants.CARD_TYPE"
              label="Object Type"
              :value="object.type"
              v-model="object.type"
              v-on:change="e => onChangeCreateSingleObject('type', e)"
            ></v-select>
          </v-flex>
          <v-flex
            v-if="
              object.type &&
              (object.type == 'sound' || object.type == 'image_sound')
            "
          >
            <v-text-field
              label="Sound for question"
              :value="object.sound_uri"
              v-model="object.sound_uri"
              v-on:change="e => onChangeCreateSingleObject('sound_uri', e)"
            ></v-text-field>
            <v-btn v-on:click="isEditSound = true">Select Sound</v-btn>
          </v-flex>
          <v-flex
            v-if="
              object.type &&
              (object.type == 'image' ||
                object.type == 'image_sound' ||
                object.type == 'image_tts')
            "
          >
            <v-flex>
              <v-flex style="text-align: -webkit-center">
                <v-img
                  v-if="object.img_uri"
                  :src="object.img_uri"
                  width="300"
                  height="300"
                ></v-img>
              </v-flex>
              <v-text-field
                label="Background Image"
                :value="object.img_uri"
                v-model="object.img_uri"
                v-on:change="e => onChangeCreateSingleObject('img_uri', e)"
              ></v-text-field>
              <v-btn v-on:click="isEditImage = true">Select Image</v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
    <!-- Start Modal Adding Multiple Media : Image, Audio, Delay -->
    <Modal
      v-if="isAddMultipleMedia"
      :isOpen="isAddMultipleMedia"
      :close="closeModalAddMultipleMedia"
      :save="saveModalAddMultipleMedia"
      :isLastStep="multiple_media_list.length > 0"
      type="create"
      title="Media Manager : Create"
    >
      <v-form>
        <div v-if="multiple_media_list[currentStep]">
          <v-btn
            block
            color="error"
            dark
            @click="onClickRemoveMediaDataAtCreate(currentStep)"
            >Remove data at index {{ currentStep + 1 }}
          </v-btn>
        </div>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Image {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div v-if="multiple_media_list[currentStep]">
                  <img
                    :src="multiple_media_list[currentStep].image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Image URI"
                    :value="multiple_media_list[currentStep].image_uri"
                    v-model="multiple_media_list[currentStep].image_uri"
                    v-on:change="e => onChangeAddMutiMediaData('image_uri', e)"
                  ></v-text-field>
                </div>
                <v-btn v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Audio {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div v-if="multiple_media_list[currentStep] !== undefined">
                  <v-text-field
                    label="Audio URI"
                    :value="multiple_media_list[currentStep].audio_uri"
                    v-model="multiple_media_list[currentStep].audio_uri"
                    v-on:change="e => onChangeAddMutiMediaData('audio_uri', e)"
                  ></v-text-field>
                </div>
                <div v-if="multiple_media_list[currentStep] === undefined">
                  Please select image first
                </div>
                <v-btn
                  :disabled="!multiple_media_list[currentStep]"
                  v-on:click="
                    isEditSound = true;
                    isUpdateAudio = true;
                  "
                >
                  <v-icon>audiotrack</v-icon>Select Audio
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Delay {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div
                  v-if="
                    multiple_media_list[currentStep] !== undefined &&
                    currentStep > 0
                  "
                >
                  <v-text-field
                    type="number"
                    label="Delay"
                    :value="multiple_media_list[currentStep].delay"
                    v-model="multiple_media_list[currentStep].delay"
                    v-on:change="e => onChangeAddMutiMediaData('delay', e)"
                  ></v-text-field>
                </div>
                <div
                  v-if="
                    multiple_media_list[currentStep] !== undefined &&
                    currentStep === 0
                  "
                >
                  <v-text-field
                    disabled
                    type="number"
                    label="Delay"
                    :value="multiple_media_list[currentStep].delay"
                    v-model="multiple_media_list[currentStep].delay"
                    v-on:change="e => onChangeAddMutiMediaData('delay', e)"
                  ></v-text-field>
                </div>
                <div v-if="multiple_media_list[currentStep] === undefined">
                  Please select image first
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep > 0"
            color="red"
            dark
            @click="onClickBackStep('isMultipleMedia')"
            >Previous</v-btn
          >
          <v-btn
            v-if="
              currentStep + 1 < maxImage &&
              multiple_media_list[currentStep] &&
              multiple_media_list[currentStep].image_uri &&
              multiple_media_list[currentStep].audio_uri &&
              multiple_media_list[currentStep].delay
            "
            :color="
              currentStep == multiple_media_list.length - 1
                ? 'success'
                : 'primary'
            "
            dark
            @click="onClickNextStep('isMultipleMedia')"
          >
            <span v-if="currentStep == multiple_media_list.length - 1"
              >Add</span
            >
            <span v-else>Next</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </Modal>
    <!-- End Modal Adding Multiple Media : Image, Audio, Delay -->
    <!-- Start Modal Editing Multiple Media : Image, Audio, Delay -->
    <Modal
      v-if="isEditMultipleMedia"
      :isOpen="isEditMultipleMedia"
      :close="closeModalEditMultipleMedia"
      :save="saveModalEditMultipleMedia"
      :isLastStep="object.multiple_media_list.length > 0"
      type="create"
      title="Media Manager : Update"
    >
      <v-form>
        <div v-if="object.multiple_media_list[currentStep]">
          <v-btn
            block
            color="error"
            dark
            @click="onClickRemoveMediaDataAtEdit(currentStep)"
            >Remove data at index {{ currentStep + 1 }}
          </v-btn>
        </div>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Image {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div v-if="object.multiple_media_list[currentStep]">
                  <img
                    :src="object.multiple_media_list[currentStep].image_uri"
                    class="content_img"
                  />
                  <v-text-field
                    label="Image URI"
                    :value="object.multiple_media_list[currentStep].image_uri"
                    v-model="object.multiple_media_list[currentStep].image_uri"
                    v-on:change="e => onChangeEditMutiMediaData('image_uri', e)"
                  ></v-text-field>
                </div>
                <v-btn v-on:click="isEditImage = true">
                  <v-icon>image</v-icon>Select Image
                </v-btn>
                <div v-if="object.multiple_media_list[currentStep].image_uri">
                  <SpriteSheet
                    :title="'Use Sprite Sheet'"
                    :isToggle="
                      object.multiple_media_list[currentStep].is_sprite_sheet
                    "
                    :fieldname="'Edit Move Sheet Data'"
                    :object="
                      object.multiple_media_list[currentStep].sprite_sheet_data
                    "
                    :index="currentStep"
                    @onchangeToggleSprite="
                      onchangeToggleSprite('is_sprite_sheet', '', $event)
                    "
                    @saveSpriteSheetData="
                      saveSpriteSheetData('sprite_sheet_data', $event)
                    "
                  />
                </div>
                <div v-else>
                  <v-checkbox label="Use Sprite Sheet" disabled></v-checkbox>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Audio {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div v-if="object.multiple_media_list[currentStep].audio_uri">
                  <v-text-field
                    label="Audio URI"
                    :value="object.multiple_media_list[currentStep].audio_uri"
                    v-model="object.multiple_media_list[currentStep].audio_uri"
                    v-on:change="e => onChangeEditMutiMediaData('audio_uri', e)"
                  ></v-text-field>
                </div>
                <div v-else>Please select image first</div>
                <v-btn
                  :disabled="!object.multiple_media_list[currentStep].image_uri"
                  v-on:click="
                    isEditSound = true;
                    isUpdateAudio = true;
                  "
                >
                  <v-icon>audiotrack</v-icon>Select Audio
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card v-if="currentStep > -1">
          <v-toolbar dark>
            <v-toolbar-title>Delay {{ currentStep + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-layout>
              <v-flex>
                <div
                  v-if="
                    object.multiple_media_list[currentStep].image_uri &&
                    currentStep > 0
                  "
                >
                  <v-text-field
                    type="number"
                    label="Delay"
                    :value="object.multiple_media_list[currentStep].delay"
                    v-model="object.multiple_media_list[currentStep].delay"
                    v-on:change="e => onChangeEditMutiMediaData('delay', e)"
                  ></v-text-field>
                </div>
                <div
                  v-else-if="
                    object.multiple_media_list[currentStep].image_uri &&
                    currentStep === 0
                  "
                >
                  <v-text-field
                    disabled
                    type="number"
                    label="Delay"
                    :value="object.multiple_media_list[currentStep].delay"
                    v-model="object.multiple_media_list[currentStep].delay"
                    v-on:change="e => onChangeEditMutiMediaData('delay', e)"
                  ></v-text-field>
                </div>
                <div v-else>Please select image first</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentStep > 0"
            color="red"
            dark
            @click="onClickBackStep('isMultipleMedia')"
            >Previous</v-btn
          >
          <v-btn
            v-if="
              currentStep == object.multiple_media_list.length - 1 &&
              object.multiple_media_list[currentStep] &&
              object.multiple_media_list[currentStep].image_uri &&
              object.multiple_media_list[currentStep].delay
            "
            color="success"
            dark
            @click="addMultipleMediaList()"
          >
            Add
          </v-btn>
          <v-btn
            v-else-if="
              currentStep + 1 < maxImage &&
              object.multiple_media_list[currentStep] &&
              object.multiple_media_list[currentStep].image_uri &&
              object.multiple_media_list[currentStep].delay
            "
            color="primary"
            dark
            @click="onClickNextStep('isMultipleMedia')"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-form>
    </Modal>
    <!-- End Modal Editing Multiple Media : Image, Audio, Delay -->
  </Container>
</template>
<style scoped>
/* .active {
  background-color: grey;
}
.inactive {
  background-color: white;
} */

.content_img {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
</style>
<script>
import Container from '../../components/Container';
import ResourceSelect from '../../components/ResourceSelect';
import PointData from '../../components/PointData';
import Modal from '../../components/Modal';
import { mapState, mapActions } from 'vuex';
import swal from 'sweetalert2';
import constants from '../../constants';
import Clipboard from '../../components/Clipboard.vue';
import SpriteSheet from '../../components/SpriteSheetNew.vue';
import CopyIdForm from '../../components/CopyIdForm.vue';

export default {
  name: 'TapToSay',
  components: {
    Clipboard,
    Container,
    ResourceSelect,
    Modal,
    PointData,
    SpriteSheet,
    CopyIdForm,
  },
  computed: {
    ...mapState({
      taptosayStore: state => state.taptosay,
      selectedSetIndexStore: state => state.taptosay.selectedSetIndex,
    }),
    taptosayFilteredStore() {
      let filteredStore = [];
      let store = this.taptosayStore.selectTapToSay.object_sets;
      if (store) {
        store.forEach(object_sets => {
          if (object_sets) {
            filteredStore.push(object_sets);
          }
        });
      }
      return filteredStore;
    },
  },
  data() {
    return {
      isEditSoundForQuestionInObjectDetail: false,
      maximumAddObject: 12,
      isEditMultipleMedia: false,
      isMultipleMedia: false,
      multiple_media_list: [],
      isUpdateAudio: false,
      isAddMultipleMedia: false,
      maxImage: 100,
      currentStep: -1,
      isLastStep: false,
      maxObject: 3,
      objects: [],
      object: {},
      set: {},
      rectEvent: [],
      selectedSetIndex: -1,
      selectedObjectIndex: -1,
      isEditImage: false,
      selectImageUri: '',
      isEditSound: false,
      isEditBGM: true,
      selectSoundUri: '',
      text: '',
      isDirty: false,
      isAddObject: false,
      isAddSet: false,
      isUpdateSet: false,
      isUpdateObject: false,
      constants,
      selectSetId: '',
      selectObjectId: '',
      isCorrectImage: false,
      isOpenPointData: false,
    };
  },
  methods: {
    ...mapActions('taptosay', [
      'fetchTapToSay',
      'fetchSingleTapToSay',
      'updateObjectData',
      'updateObject',
      'clearUpdateSetData',
      'clearUpdateObjectData',
      'deleteSet',
      'deleteObject',
      'updateCreateSetData',
      'updateCreateObjectData',
      'updateCreateSingleObjectData',
      'updateSetData',
      'updateSet',
      'addSet',
      'addObjectBySetId',
      'selectCategory',
      'selectTapToSay',
    ]),
    openSubtitleSound() {
      const path = '/subtitle_sound_localization';
      const routeData = this.$router.resolve({
        path: path,
        query: { sound_uri: this.set.sound_uri },
      });
      window.open(routeData.href, '_blank');
    },
    openPointData() {
      this.isOpenPointData = true;
    },
    closePointData() {
      this.isOpenPointData = false;
    },
    savePointData(learned_words, incidental_words, reward, sentence, phonic) {
      this.isDirty = true;
      if (learned_words) {
        this.updateSetData({
          key: 'learned_words',
          value: learned_words,
        });
      }
      if (incidental_words) {
        this.updateSetData({
          key: 'incidental_words',
          value: incidental_words,
        });
      }
      if (reward) {
        this.updateSetData({
          key: 'reward',
          value: reward,
        });
      }
      if (sentence) {
        this.updateSetData({
          key: 'sentence',
          value: sentence,
        });
      }
      if (phonic) {
        this.updateSetData({
          key: 'phonic',
          value: phonic,
        });
      }
      this.onClickUpdateSet();
      this.isOpenPointData = false;
    },
    onClickRemoveMediaDataAtCreate(index) {
      swal
        .fire({
          titleText: `Remove data at index ${index + 1}?`,
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.multiple_media_list.splice(index, 1);
          }
        });
    },
    onClickRemoveMediaDataAtEdit(index) {
      swal
        .fire({
          titleText: `Remove data at index ${index + 1}?`,
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.object.multiple_media_list.splice(index, 1);
            this.currentStep = index - 1;
          }
        });
    },
    onClickEditMultipleMedia() {
      const { image_uri, audio_uri, delay } =
        this.object.multiple_media_list[0];
      if (delay && image_uri && audio_uri) {
        this.currentStep = 0;
        this.isEditMultipleMedia = !this.isEditMultipleMedia;
      } else {
        this.onClickAddMultipleMedia();
      }
    },
    onClickAddMultipleMedia() {
      this.currentStep = 0;
      this.isAddMultipleMedia = !this.isAddMultipleMedia;
      this.multiple_media_list = [];
    },
    validateIncrementCurrentStep() {
      this.currentStep++;
      if (this.currentStep == 0) {
        this.isLastStep = true;
      }
    },
    addMultipleMediaList() {
      this.object.multiple_media_list.push({
        is_sprite_sheet: false,
        sprite_sheet_data: {
          column: 1,
          row: 1,
          frame_ms: 0,
          loop_count: -1,
        },
      });
      this.validateIncrementCurrentStep();
    },
    onClickNextStep(flag) {
      if (flag === 'isMultipleMedia') {
        this.validateIncrementCurrentStep();
      } else {
        if (this.isAddSet && !this.isAddObject) {
          this.isAddObject = true;
        }
        this.validateIncrementCurrentStep();
        this.onActiveCreateObject();
      }
    },
    onClickBackStep(flag) {
      if (flag === 'isMultipleMedia') {
        this.currentStep--;
      } else {
        this.currentStep--;
        this.onActiveCreateObject();
      }
    },
    async onSelectSet(id) {
      this.clearUpdateSetData();
      const object_sets = this.taptosayStore.selectTapToSay.object_sets;
      const index = object_sets.findIndex(set => set._id === id);
      this.selectedSetIndex = index;
      this.selectedObjectIndex = -1;
      this.object = {};
      this.objects = [...object_sets[index].objects];
      this.set = {
        ...object_sets[index],
      };
      this.rectEvent = this.set.rectEvent;
      this.updateSetData({
        key: '_id',
        value: id,
      });
      this.selectSetId = id;
    },
    onSelectObject(id) {
      if (this.isDirty) {
        swal
          .fire({
            titleText:
              'You have not saved this object yet. Are you sure you want to leave?',
            type: 'info',
            showCancelButton: true,
          })
          .then(result => {
            if (result.value) {
              this.multiple_media_list = [];
              this.clearUpdateObjectData();
              this.isDirty = false;
              const objects = [
                ...this.taptosayStore.selectTapToSay.object_sets[
                  this.selectedSetIndex
                ].objects,
              ];
              const index = objects.findIndex(object => object._id === id);
              this.selectedObjectIndex = index;
              this.object = { ...objects[index] };
              this.selectImageUri = '';
              this.updateObjectData({
                key: '_id',
                value: id,
              });
              this.object = { ...this.checkSpriteSheetData(this.object) };
              this.selectObjectId = id;
            }
          });
      } else {
        this.multiple_media_list = [];
        this.clearUpdateObjectData();
        const objects = [
          ...this.taptosayStore.selectTapToSay.object_sets[
            this.selectedSetIndex
          ].objects,
        ];
        const index = objects.findIndex(object => object._id === id);
        this.selectedObjectIndex = index;
        this.object = { ...objects[index] };
        this.selectImageUri = '';
        this.updateObjectData({
          key: '_id',
          value: id,
        });
        this.object = { ...this.checkSpriteSheetData(this.object) };
        this.selectObjectId = id;
      }
    },
    checkSpriteSheetData(object) {
      const sprite_sheet_data = {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      };
      const multiple_media = {
        is_sprite_sheet: false,
        sprite_sheet_data: JSON.parse(JSON.stringify(sprite_sheet_data)),
      };
      if (!('is_sprite_sheet' in object))
        object = { ...object, is_sprite_sheet: false };
      if (!('sprite_sheet_data' in object))
        object = {
          ...object,
          sprite_sheet_data: JSON.parse(JSON.stringify(sprite_sheet_data)),
        };
      if (!('is_correct_sprite_sheet' in object))
        object = { ...object, is_correct_sprite_sheet: false };
      if (!('correct_sprite_sheet_data' in object))
        object = {
          ...object,
          correct_sprite_sheet_data: JSON.parse(
            JSON.stringify(sprite_sheet_data),
          ),
        };
      if (!('multiple_media_list' in object))
        object = {
          ...object,
          multiple_media_list: [JSON.parse(JSON.stringify(multiple_media))],
        };
      if ('multiple_media_list' in object) {
        object.multiple_media_list = object.multiple_media_list.map(e => {
          if (!('is_sprite_sheet' in e)) e = { ...e, is_sprite_sheet: false };
          if (!('sprite_sheet_data' in e))
            e = {
              ...e,
              sprite_sheet_data: JSON.parse(JSON.stringify(sprite_sheet_data)),
            };
          return e;
        });
      }
      return object;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
    },
    onChangeAddMutiMediaData(key, value) {
      let uri = value;
      if (!this.isAddObject && this.isAddMultipleMedia) {
        if (key === 'image_uri') {
          let imageObject = { image_uri: uri };
          this.updateObjectData({
            key: 'multiple_media_list',
            value: imageObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].image_uri = uri;
        } else if (key === 'audio_uri') {
          let audioObject = { audio_uri: uri };
          this.updateObjectData({
            key: 'multiple_media_list',
            value: audioObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].audio_uri = uri;
        } else if (key === 'delay') {
          let delayObject = { delay: value };
          this.updateObjectData({
            key: 'multiple_media_list',
            value: delayObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].delay = value;
        }
      } else {
        if (key === 'image_uri') {
          let imageObject = { image_uri: uri };
          this.updateCreateSingleObjectData({
            key: 'multiple_media_list',
            value: imageObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].image_uri = uri;
        } else if (key === 'audio_uri') {
          let audioObject = { audio_uri: uri };
          this.updateCreateSingleObjectData({
            key: 'multiple_media_list',
            value: audioObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].audio_uri = uri;
        } else if (key === 'delay') {
          let delayObject = { delay: value };
          this.updateCreateSingleObjectData({
            key: 'multiple_media_list',
            value: delayObject,
            index: this.currentStep,
          });
          this.multiple_media_list[this.currentStep].delay = value;
        }
      }
    },
    onChangeEditMutiMediaData(key, value) {
      let uri = value;
      if (key === 'image_uri') {
        let imageObject = { image_uri: uri };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: imageObject,
          index: this.currentStep,
        });
        this.multiple_media_list[this.currentStep].image_uri = uri;
      } else if (key === 'audio_uri') {
        let audioObject = { audio_uri: uri };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: audioObject,
          index: this.currentStep,
        });
        if (!this.multiple_media_list[this.currentStep]) {
          this.multiple_media_list.push(audioObject);
        } else {
          this.multiple_media_list[this.currentStep].audio_uri = uri;
        }
      } else if (key === 'delay') {
        let delayObject = { delay: value };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: delayObject,
          index: this.currentStep,
        });
        if (!this.multiple_media_list[this.currentStep]) {
          this.multiple_media_list.push(delayObject);
        } else {
          this.multiple_media_list[this.currentStep].delay = value;
        }
      }
    },
    onChangeObjectData(key, value) {
      this.isDirty = true;
      this.object[key] = value;
      this.updateObjectData({
        key,
        value,
      });
    },
    onChangeMultipleMediaList(value) {
      this.object.is_multiple_media = value ? value : false;
      this.updateObjectData({
        key: 'is_multiple_media',
        value: this.object.is_multiple_media,
      });
    },
    onChangeCorrectObject(e) {
      this.isDirty = true;
      this.object.is_correct = e ? e : false;
      this.updateObjectData({
        key: 'is_correct',
        value: this.object.is_correct,
      });
    },
    saveEditImage() {
      const uri = this.selectImageUri;
      if (this.isAddSet && !this.isAddObject) {
        this.updateCreateSetData({
          key: 'img_uri',
          value: uri,
        });
        this.set.img_uri = uri;
      } else if (this.isAddObject && this.isAddMultipleMedia) {
        let imageObject = { image_uri: uri, delay: '-1' };
        this.updateCreateSingleObjectData({
          key: 'multiple_media_list',
          value: imageObject,
          index: this.currentStep,
        });
        if (!this.multiple_media_list[this.currentStep]) {
          this.multiple_media_list.push(imageObject);
        } else {
          this.multiple_media_list[this.currentStep].image_uri = uri;
        }
      } else if (this.isEditMultipleMedia) {
        let imageObject = { image_uri: uri, delay: '-1' };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: imageObject,
          index: this.currentStep,
        });
        if (!this.object.multiple_media_list[this.currentStep]) {
          imageObject = {
            ...imageObject,
            is_sprite_sheet: false,
            sprite_sheet_data: {
              column: 1,
              row: 1,
              frame_ms: 0,
              loop_count: -1,
            },
          };
          this.object.multiple_media_list.push(imageObject);
        } else {
          this.object.multiple_media_list[this.currentStep].image_uri = uri;
          if (!('delay' in this.object.multiple_media_list[this.currentStep]))
            this.object.multiple_media_list[this.currentStep].delay = '-1';
        }
      } else if (this.isAddMultipleMedia) {
        let imageObject = { image_uri: uri, delay: '-1' };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: imageObject,
          index: this.currentStep,
        });
        if (!this.multiple_media_list[this.currentStep]) {
          this.multiple_media_list.push(imageObject);
        } else {
          this.multiple_media_list[this.currentStep].image_uri = uri;
        }
      } else if (this.isAddSet && this.isAddObject && !this.isCorrectImage) {
        this.updateCreateObjectData({
          key: 'img_uri',
          value: uri,
          index: this.currentStep,
        });
        this.object.img_uri = uri;
      } else if (this.isAddSet && this.isAddObject && this.isCorrectImage) {
        this.updateCreateObjectData({
          key: 'correct_img_uri',
          value: uri,
          index: this.currentStep,
        });
        this.object.correct_img_uri = uri;
      } else if (this.isAddObject) {
        this.updateCreateSingleObjectData({
          key: 'img_uri',
          value: uri,
        });
        this.object.img_uri = uri;
      } else if (this.isUpdateSet && !this.isUpdateObject) {
        this.updateSetData({
          key: 'img_uri',
          value: uri,
        });
        this.set.img_uri = uri;
      } else if (
        this.isUpdateObject &&
        !this.isUpdateSet &&
        this.isCorrectImage
      ) {
        this.updateObjectData({
          key: 'correct_img_uri',
          value: uri,
          index: this.currentStep,
        });
        this.object.correct_img_uri = uri;
      } else {
        this.isDirty = true;
        this.updateObjectData({
          key: 'img_uri',
          value: uri,
        });
        this.object.img_uri = uri;
      }
      this.selectImageUri = '';
      this.isCorrectImage != this.isCorrectImage;
      this.isEditImage = !this.isEditImage;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    closeModalAddMultipleMedia() {
      this.isAddMultipleMedia = false;
    },
    closeModalEditMultipleMedia() {
      this.isEditMultipleMedia = false;
    },
    saveModalEditMultipleMedia() {
      let multiple_media_list = Object.values(this.object.multiple_media_list);
      multiple_media_list.forEach((object, index) => {
        if (!('image_uri' in object)) multiple_media_list.splice(index, 1);
      });
      this.updateObjectData({
        key: 'multiple_media_list',
        value: [...multiple_media_list],
      });
      this.isEditMultipleMedia = false;
    },
    saveModalAddMultipleMedia() {
      let multiple_media_list = Object.values(this.multiple_media_list);
      if (this.isAddMultipleMedia && !this.isAddObject) {
        this.updateObjectData({
          key: 'multiple_media_list',
          value: [...multiple_media_list],
        });
      } else {
        this.updateCreateSingleObjectData({
          key: 'multiple_media_list',
          value: [...multiple_media_list],
        });
      }
      this.isAddMultipleMedia = false;
    },
    saveEditSound() {
      const uri = this.selectSoundUri;
      if (this.isAddSet && this.isEditBGM) {
        this.updateCreateSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.set.bgm_uri = uri;
      } else if (this.isAddSet && !this.isAddObject) {
        this.updateCreateSetData({
          key: 'sound_uri',
          value: uri,
        });
        this.set.sound_uri = uri;
      } else if (this.isAddObject && this.isAddMultipleMedia) {
        let audioObject = { audio_uri: uri };
        this.updateCreateSingleObjectData({
          key: 'multiple_media_list',
          value: audioObject,
          index: this.currentStep,
        });
        this.multiple_media_list[this.currentStep].audio_uri = uri;
      } else if (this.isEditMultipleMedia) {
        let audioObject = { audio_uri: uri };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: audioObject,
          index: this.currentStep,
        });
        if (!this.object.multiple_media_list[this.currentStep]) {
          this.object.multiple_media_list.push(audioObject);
        } else {
          this.object.multiple_media_list[this.currentStep].audio_uri = uri;
        }
      } else if (this.isAddMultipleMedia) {
        let audioObject = { audio_uri: uri };
        this.updateObjectData({
          key: 'multiple_media_list',
          value: audioObject,
          index: this.currentStep,
        });
        if (!this.multiple_media_list[this.currentStep]) {
          this.multiple_media_list.push(audioObject);
        } else {
          this.multiple_media_list[this.currentStep].audio_uri = uri;
        }
      } else if (this.isAddSet && this.isAddObject) {
        this.updateCreateObjectData({
          key: 'sound_uri',
          value: uri,
          index: this.currentStep,
        });
        this.object.sound_uri = uri;
      } else if (this.isAddObject) {
        this.updateCreateSingleObjectData({
          key: 'sound_uri',
          value: uri,
        });
        this.object.sound_uri = uri;
      } else if (this.isEditSoundForQuestionInObjectDetail) {
        this.updateObjectData({
          key: 'sound_uri',
          value: uri,
        });
        this.object.sound_uri = uri;
        this.isEditSoundForQuestionInObjectDetail = false;
      } else if (!this.isAddSet && !this.isAddObject && this.isEditBGM) {
        this.updateSetData({
          key: 'bgm_uri',
          value: uri,
        });
        this.set.bgm_uri = uri;
      } else if (!this.isAddSet && !this.isAddObject) {
        this.updateSetData({
          key: 'sound_uri',
          value: uri,
        });
        this.set.sound_uri = uri;
      } else {
        this.isDirty = true;
        this.updateObjectData({
          key: 'sound_uri',
          value: uri,
        });
        this.object.sound_uri = uri;
      }
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
      this.isEditBGM = false;
    },
    onClickSaveObject() {
      const updateObjectData = {
        ...this.taptosayStore.updateObjectData,
      };
      this.updateObject(updateObjectData);
      this.isDirty = false;
      Object.keys(updateObjectData).forEach(key => {
        this.object[key] = updateObjectData[key];
      });
    },
    onClickFullscreen(link) {
      window.open(link, '_blank');
    },
    onActiveCreateObject() {
      const objects = [...this.taptosayStore.addObjectData];
      if (
        this.currentStep == objects.length &&
        this.currentStep < this.maxObject
      ) {
        const new_object = { ...this.taptosayStore.objectData };
        this.object = { ...new_object };
        this.objects = [...objects, new_object];
        this.updateCreateObjectData({
          key: 'text',
          value: '',
          index: this.currentStep,
        });
      } else {
        this.object = { ...objects[this.currentStep] };
        this.objects = [...objects];
      }
    },
    onChangeCreateObject(key, value) {
      // Set value to null when user swap object type, to prevent leak sending both data.
      if (key === 'type' && (value === 'sound' || value === 'image')) {
        if (value === 'sound') {
          this.object.img_uri = null;
        } else {
          this.object.sound_uri = null;
        }
      }
      this.updateCreateObjectData({
        key,
        value,
        index: this.currentStep,
      });
    },
    onChangeCreateSingleObject(key, value) {
      value = this.setNullToFalseOnBoolean(key, value);
      if (key === 'is_multiple_media' && !value) {
        swal
          .fire({
            titleText: 'Discard media data ?',
            text: 'The data you have created will be discard.',
            confirmButtonColor: 'red',
            confirmButtonText: 'Discard',
            showCancelButton: true,
            type: 'warning',
          })
          .then(result => {
            if (result.value) {
              this.updateCreateSingleObjectData({
                key: 'multiple_media_list',
                value: null,
              });
              this.multiple_media_list = [];
              value = false;
            } else {
              this.object.is_multiple_media = true;
            }
          });
      } else {
        this.object[key] = value;
        this.updateCreateSingleObjectData({
          key,
          value,
        });
      }
    },
    toggleAddSet() {
      this.isAddObject = false;
      this.selectedSetIndex = -1;
      this.selectedObjectIndex = -1;
      // init set data
      this.updateCreateSetData({
        key: 'category_id',
        value: this.$route.query.id,
      });
      this.set = { ...this.taptosayStore.addSetData };
      // init object data
      this.objects = [...this.taptosayStore.addObjectData];
      this.isAddSet = !this.isAddSet;
      if (!this.isAddSet && this.taptosayStore.selectedCategoryId) {
        this.$router.push('/taptosay');
      }
    },
    onClickAddSet() {
      this.isAddObject = false;
      const id = this.$route.query.id;
      const createSetData = {
        ...this.taptosayStore.addSetData,
      };
      const createObjectData = [...this.taptosayStore.addObjectData];
      this.addSet({
        category_id: id,
        set: createSetData,
        objects: createObjectData,
      });
      this.isAddSet = false;
    },
    onClickDeleteSet(id) {
      this.deleteSet(id);
      this.isDirty = false;
      this.selectedSetIndex = -1;
      this.object = {};
      this.objects = [];
      this.selectedObjectIndex = -1;
    },
    toggleAddObject() {
      this.object = { ...this.taptosayStore.objectData };
      this.isAddObject = !this.isAddObject;
    },
    onClickAddObject() {
      const id = this.$route.query.id;
      const createObjectData = {
        ...this.taptosayStore.addSingleObjectData,
      };
      this.addObjectBySetId({
        category_id: id,
        set_id: this.selectSetId,
        object: createObjectData,
      });
      this.isAddObject = false;
    },
    onClickDeleteObject() {
      swal
        .fire({
          titleText: 'Confirm Delete Object',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.deleteObject(this.selectObjectId);
            this.isDirty = false;
            this.selectedObjectIndex = -1;
            this.objects = [
              ...this.taptosayStore.selectTapToSay.object_sets[
                this.selectedSetIndex
              ].objects,
            ];
            this.object = {};
          }
        });
    },
    onChangeSetData(key, value) {
      value = this.setNullToFalseOnBoolean(key, value);
      this.set[key] = value;
      this.updateSetData({
        key,
        value,
      });
    },
    async onClickUpdateSet() {
      const updateSetData = {
        ...this.taptosayStore.updateSetData,
      };
      await this.updateSet(updateSetData);
      this.updateDisplaySet(updateSetData);
      // this.object = {};
      // this.objects = [];
    },
    updateDisplaySet(set) {
      Object.keys(set).forEach(key => {
        this.set[key] = set[key];
      });
    },
    setNullToFalseOnBoolean(key, value) {
      const listOfBoolean = [
        'is_show',
        'is_hide',
        'isGuestPlayable',
        'is_correct',
      ];
      if (listOfBoolean.includes(key) && !value) {
        return false;
      } else {
        return value;
      }
    },
    onChangeCreateSet(key, value) {
      if (key === 'type' && (value === 'sound' || value === 'image')) {
        if (value === 'sound') {
          this.set.img_uri = null;
        } else {
          this.set.sound_uri = null;
        }
      }
      value = this.setNullToFalseOnBoolean(key, value);
      this.set[key] = value;
      this.updateCreateSetData({
        key,
        value,
      });
    },
    onChangeEventData(index, key, value) {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent[index][key] = value;
      this.rectEvent = rectEvent;
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onAddEventData() {
      const rectEvent = this.rectEvent ? [...this.rectEvent] : [];
      this.rectEvent = [...rectEvent, { ...this.taptosayStore.eventData }];
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onRemoveEventData() {
      this.isDirty = true;
      const rectEvent = [...this.rectEvent];
      rectEvent.pop();
      this.rectEvent = rectEvent;
      this.updateSetData({
        key: 'rectEvent',
        value: rectEvent,
      });
    },
    onchangeToggleSprite(key, key_data, { value, index }) {
      if (index === undefined) {
        this.updateObjectData({
          key: key,
          value: value,
        });
        this.object[key] = value;
        this.updateObjectData({
          key: key_data,
          value: this.object[key_data],
        });
      } else {
        const data = this.object.multiple_media_list[index];
        data[key] = value;
        this.updateObjectData({
          key: 'multiple_media_list',
          value: this.object.multiple_media_list,
        });
      }
      this.isDirty = true;
    },
    saveSpriteSheetData(key, { index, value }) {
      if (index === undefined) {
        this.updateObjectData({
          key: key,
          value: value,
        });
        this.object = { ...this.object };
        this.object[key] = value;
      } else {
        const data = this.object.multiple_media_list[index];
        data[key] = value;
        this.updateObjectData({
          key: 'multiple_media_list',
          value: this.object.multiple_media_list,
        });
      }
      this.isDirty = true;
    },
  },
  watch: {
    selectedSetIndexStore() {
      const id = this.$route.query.id || this.$route.query.category_id;
      if (id) {
        this.onSelectSet(this.$route.query.sub_id);
      }
    },
  },

  mounted() {
    const id = this.$route.query.id || this.$route.query.category_id,
      sub_id = this.$route.query.sub_id;
    if (this.taptosayStore.selectedCategoryId) {
      this.toggleAddSet();
    } else if (id) {
      this.fetchSingleTapToSay({
        id,
        sub_id,
      });
    }
  },
};
</script>
