/* eslint-disable prettier/prettier */
import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  cookingGameList: [],
  selectedCookingGame: {},
  createCookingGame: {},
  updateCookingGame: {},
  isLoadCookingGame: true,
  defaultCreate: {
    category_id: '',
    title: '',
    music_intro_uri: '',
    tts_message: '',
    voice_name: 'enUSGuyNeural',
    bgm_uri: '',
    bgm_volume: '.5',
    bg_uri: '',
    table_uri: '',
    microwave_uri: '',
    item_infos: [
      {
        word: 'default word',
        image_uri: '',
        tts_message: '',
        sound_uri: ''
      },
    ],
    recipe_infos: [
      {
        ingredient_items: ['default item'],
        product_infos: {
          word: 'default word',
          image_uri: '',
          tts_message: '',
          sound_uri: ''
        }
      },
    ],
    decorate_images: [
      {
        image_uri: '',
        object_rect: {
          startX: '',
          startY: '',
          endX: '',
          endY: ''
        }
      }
    ]
  },
};
const getters = {
  defaultCreate(state) {
    return state.defaultCreate;
  },
};
const actions = {
  fetchCookingGameCategory({ commit, dispatch }, { page = 1, limit = 0 }) {
    const query = {
      params: { page, limit },
    };
    return http
      .GET(`/api/cooking_game/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => ({
          ...e,
          created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
          updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
        }));
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'cookingGameList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch cooking game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadCookingGame',
          key: null,
          value: false,
        });
      });
  },
  fetchCookingGameList(
    { commit, dispatch },
    { id, game_list_id, category_id },
  ) {
    const url = id ? `/api/cooking_game` : `/api/cooking_game/category`;
    const query = {
      params: game_list_id
        ? { id: game_list_id }
        : category_id
        ? { category_id }
        : { id, isWeb: true },
    };
    return http
      .GET(url, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const [formatData] = data.map(e => {
          const cooking_game = e.cooking_games.map(data => {
            return {
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            };
          });
          e.cooking_games = cooking_game;
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'cookingGameList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch cooking game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadCookingGame',
          key: null,
          value: false,
        });
      });
  },
  createCookingGame({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createCookingGame;
    http
      .POST(`/api/cooking_game`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        if (state.cookingGameList._id) {
          dispatch('fetchCookingGameList', {
            game_list_id: state.cookingGameList._id,
          });
        } else {
          router.go({
            path: '/cooking_game',
          });
          dispatch('fetchCookingGameCategory', {
            page: 1,
            limit: 0,
          });
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create cooking game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create cooking game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteCookingGame({ commit, dispatch }, id) {
    http
      .DELETE(`/api/cooking_game?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateCookingGame');
        dispatch('fetchCookingGameList', {
          game_list_id: state.cookingGameList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete cooking game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete runnung game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateCookingGame({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateCookingGame;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/cooking_game`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('fetchCookingGameList', {
          game_list_id: state.cookingGameList._id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update cooking game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message: err.response.data.message || 'Cannot update cooking game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
