import moment from 'moment';
import http from '../../utils/httpClient';
import router from '../../router';

const state = {
  sequenceGameList: [],
  selectedSequenceGame: {},
  createSequenceGame: {},
  updateSequenceGame: {},
  isLoadSequenceGame: true,
  defaultCreate: {
    category_id: '',
    title: '',
    items_info: [
      {
        word: '',
        image_uri: '',
        scale: '0',
        voice_name: 'enUSAnaNeural',
        tts_message: '',
      },
    ],
    difficulty: 0,
    theme: 0,
    correct_count: 0,
    bgm_uri: '',
    bgm_volume: '0.5',
    music_intro_uri: '',
    tts_message: '',
    voice_name: 'enUSAnaNeural',
  },
};
const getters = {};
const actions = {
  fetchSequenceGameCategory(
    { commit, dispatch },
    { page = 1, limit = 0, sequence_game_id, game_list_id, category_id },
  ) {
    const query = {
      params: sequence_game_id
        ? { page, limit, sequence_game_id }
        : game_list_id
        ? { page, limit, id: game_list_id }
        : category_id
        ? { page, limit, category_id }
        : { page, limit, isWeb: true },
    };
    return http
      .GET(`/api/sequence_game/category`, {
        option: query,
        isAuthorization: true,
      })
      .then(({ data }) => {
        const formatData = data.map(e => {
          e = {
            ...e,
            created_at: moment(e.created_at).format('MMMM D YYYY h:mm a'),
            updated_at: moment(e.updated_at).format('MMMM D YYYY h:mm a'),
          };
          if (e.sequence_games) {
            e.sequence_games = e.sequence_games.map(data => ({
              ...data,
              created_at: moment(data.created_at).format('MMMM D YYYY h:mm a'),
              updated_at: moment(data.updated_at).format('MMMM D YYYY h:mm a'),
            }));
          }
          return { ...e };
        });
        dispatch('clearAllState');
        dispatch('setStateByKey', {
          statekey: 'sequenceGameList',
          value: formatData,
        });
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot fetch sequence game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      })
      .finally(() => {
        dispatch('setStateByKey', {
          statekey: 'isLoadSequenceGame',
          key: null,
          value: false,
        });
      });
  },
  createSequenceGame({ commit, dispatch }, createData) {
    createData = createData ? createData : state.createSequenceGame;
    http
      .POST(`/api/sequence_game`, createData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const game_list_id = state.sequenceGameList[0]._id;
        if (game_list_id) {
          dispatch('fetchSequenceGameCategory', { game_list_id });
        } else {
          router.go({
            path: '/sequence_game',
          });
          dispatch('fetchSequenceGameCategory');
        }
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Create sequence game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot create sequence game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  deleteSequenceGame({ commit, dispatch }, id) {
    http
      .DELETE(`/api/sequence_game?id=${id}`, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        dispatch('clearStateByKey', 'updateSequenceGame');
        const game_list_id = state.sequenceGameList[0]._id;
        dispatch('fetchSequenceGameCategory', {
          game_list_id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Delete sequence game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot delete runnung game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  updateSequenceGame({ commit, dispatch }, updateData) {
    updateData = updateData ? updateData : state.updateSequenceGame;
    delete updateData.created_at;
    delete updateData.updated_at;
    delete updateData.game_list_id;
    http
      .PUT(`/api/sequence_game`, updateData, {
        isAuthorization: true,
      })
      .then(({ data }) => {
        const game_list_id = state.sequenceGameList[0]._id;
        dispatch('fetchSequenceGameCategory', {
          game_list_id,
        });
        dispatch(
          'appConfig/toggleAlert',
          {
            type: 'success',
            message: 'Update sequence game successfully',
          },
          {
            root: true,
          },
        );
      })
      .catch(err => {
        dispatch(
          'appConfig/toggleAlert',
          {
            message:
              'Cannot update sequence game in this time. please try again.',
            type: 'error',
          },
          {
            root: true,
          },
        );
        throw new Error(err);
      });
  },
  setStateByKey({ commit }, { statekey, key, value }) {
    commit('set_state_by_key', { statekey, key, value });
  },
  clearStateByKey({ commit }, statekey) {
    commit('clear_state_by_key', statekey);
  },
  clearAllState({ commit }) {
    commit('clear_all_state');
  },
};
const mutations = {
  set_state_by_key(state, { statekey, key, value }) {
    if (Array.isArray(state[statekey])) {
      state[statekey] = value;
    } else {
      switch (typeof state[statekey]) {
        case 'object':
          key
            ? (state[statekey] = { ...state[statekey], [key]: value })
            : (state[statekey] = { ...value });
          break;
        default:
          state[statekey] = value;
      }
    }
  },
  clear_state_by_key(state, statekey) {
    state[statekey] = ClearType(state[statekey], true);
  },
  clear_all_state(state) {
    for (const [key, value] of Object.entries(state)) {
      if (!key.includes('default')) {
        if (Array.isArray(value)) {
          state[key] = [];
        } else state[key] = ClearType(value);
      }
    }
  },
};
const ClearType = (value, type) => {
  if (Array.isArray(value)) return [];
  else if (value === null) return null;
  else {
    switch (typeof value) {
      case 'string':
        return '';
      case 'number':
        return -1;
      case 'boolean':
        return false;
      case 'object':
        if ('id' in value && type) return { id: value.id };
        // else if ('index' in value && type) return { index: value.index };
        else return {};
      default:
        return null;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
