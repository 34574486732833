<template>
  <Modal
    v-if="isOpen"
    :isOpen="isOpen"
    :close="close"
    :isLastStep="data.length > 0"
    :save="save"
    type="create"
    :title="title"
  >
    <div>
      <v-btn icon color="green" v-on:click="toggleAddSticker">
        <v-icon>add</v-icon>
      </v-btn>

      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in data.length">
            <v-stepper-step
              :key="`${n}-step`"
              :complete="currentStep > n"
              :step="n"
              editable
            >
              {{ data[n - 1][name] }}
            </v-stepper-step>

            <v-divider v-if="n !== data.length" :key="n"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="n in data.length"
            :key="`${n}-content`"
            :step="n"
          >
            <v-form>
              <v-card v-if="currentStep - 1 > -1">
                <v-toolbar dark>
                  <v-toolbar-title>Image {{ currentStep }}</v-toolbar-title>
                  <v-btn
                    v-if="data.length > 1"
                    color="error"
                    small
                    absolute
                    fab
                    right
                    @click="onClickRemoveStickerByIndex(currentStep - 1)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container v-if="data[currentStep - 1]">
                  <v-layout>
                    <v-flex>
                      <div v-show="data[currentStep - 1][name] != undefined">
                        <v-text-field
                          label="name"
                          v-model="data[currentStep - 1][name]"
                        ></v-text-field>
                      </div>
                      <div
                        v-show="data[currentStep - 1][image_uri] != undefined"
                      >
                        <img
                          :src="data[currentStep - 1][image_uri]"
                          class="content_img"
                        />
                        <v-text-field
                          label="Image URI"
                          :value="data[currentStep - 1][image_uri]"
                          v-model="data[currentStep - 1][image_uri]"
                        ></v-text-field>
                        <v-btn v-on:click="isEditImage = true">
                          <v-icon>image</v-icon>Select Image
                        </v-btn>
                      </div>
                      <SpriteSheet
                        :title="'is sprite sheet'"
                        :isToggle="data[currentStep - 1][is_sprite_sheet]"
                        :fieldname="'Edit Sheet Data'"
                        :object="data[currentStep - 1][sprite_sheet_data]"
                        :index="currentStep - 1"
                        @onchangeToggleSprite="onchangeToggleSprite"
                        @saveSpriteSheetData="saveSpriteSheetData"
                      />
                      <v-text-field
                        label="Collect Sound"
                        v-model="data[currentStep - 1][sound_uri]"
                      ></v-text-field>
                      <v-btn @click="isEditSound = true"
                        ><v-icon>audiotrack</v-icon>Select Sound URI</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="currentStep - 1 > 0"
                  color="red"
                  dark
                  @click="onClickBackStep()"
                  >Previous</v-btn
                >
                <v-btn
                  v-if="currentStep - 1 < data.length - 1"
                  color="primary"
                  dark
                  @click="onClickNextStep()"
                  >Next</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <ResourceSelect
      v-if="isEditImage"
      resourceType="image"
      :isShow="isEditImage"
      :close="closeEditImage"
      :value="selectImageUri"
      :onSelect="onSelectImage"
    />
    <ResourceSelect
      v-if="isEditSound"
      resourceType="sound"
      :isShow="isEditSound"
      :close="closeEditSound"
      :value="selectSoundUri"
      :onSelect="onSelectSound"
    />
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import swal from 'sweetalert2';
import ResourceSelect from './ResourceSelect.vue';
// import { mapActions } from 'vuex';
import SpriteSheet from './SpriteSheetNew.vue';

export default {
  name: 'ModalStickers',
  components: {
    Modal,
    ResourceSelect,
    SpriteSheet,
  },
  data() {
    return {
      currentStep: 1,
      isEditImage: false,
      isEditSound: false,
      selectImageUri: '',
      selectSoundUri: '',
      isSpriteSheet: [],
      data: [],
      backupData: [],
      addDataSticker: [],
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    dataGame: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    // close: {
    //   type: Function,
    //   default: () => {},
    // },
    // save: {
    //   type: Function,
    //   default: () => {},
    // },
    image_uri: {
      type: String,
      default: 'image_uri',
    },
    name: {
      type: String,
      default: 'name',
    },
    is_sprite_sheet: {
      type: String,
      default: 'is_sprite_sheet',
    },
    sprite_sheet_data: {
      type: String,
      default: 'sprite_sheet_data',
    },
    sound_uri: { type: String, default: 'sound_uri' },
  },
  methods: {
    // ...mapActions('books', ['updatePageData']),
    toggleAddSticker() {
      if (this.data.length < 5) {
        let objData = {};
        objData[this.name] = '';
        objData[this.image_uri] = '';
        objData[this.is_sprite_sheet] = false;
        objData[this.sprite_sheet_data] = {
          column: 1,
          row: 1,
          frame_ms: 0,
          loop_count: -1,
        };
        this.data.push(objData);
        this.currentStep = this.data.length;
      }
    },
    saveSpriteSheetData({ value, index }) {
      this.data[this.currentStep - 1][this.sprite_sheet_data] = value;
    },
    onchangeToggleSprite({ value, index }) {
      this.data[this.currentStep - 1][this.is_sprite_sheet] = value;
    },
    onSelectImage(uri) {
      this.selectImageUri = uri;
      this.data[this.currentStep - 1][this.image_uri] = uri;
    },
    closeEditImage() {
      this.selectImageUri = '';
      this.isEditImage = !this.isEditImage;
    },
    onSelectSound(uri) {
      this.selectSoundUri = uri;
      this.data[this.currentStep - 1][this.sound_uri] = uri;
    },
    closeEditSound() {
      this.selectSoundUri = '';
      this.isEditSound = !this.isEditSound;
    },
    onClickRemoveStickerByIndex(index) {
      swal
        .fire({
          titleText: `Remove data at index ${index + 1}?`,
          text: 'This operation cannot be undone. Would you like to proceed?',
          confirmButtonColor: 'red',
          confirmButtonText: 'Remove',
          showCancelButton: true,
          type: 'warning',
        })
        .then(result => {
          if (result.value) {
            this.data.splice(index, 1);
            if (this.currentStep - 1 > 0) {
              this.currentStep -= 1;
            }
          }
        });
    },
    save() {
      this.$emit('save', [...this.data]);
    },
    onClickNextStep() {
      if (this.currentStep - 1 < this.data.length - 1) {
        this.currentStep += 1;
      }
    },
    onClickBackStep() {
      if (this.currentStep - 1 >= 0) {
        this.currentStep -= 1;
      }
    },
    close() {
      this.$emit('close', this.backupData);
    },
  },
  created() {
    this.backupData = JSON.parse(JSON.stringify(this.dataGame));
    if (this.dataGame.length) {
      this.data = [...this.dataGame];
    } else {
      let objData = {};
      objData[this.name] = '';
      objData[this.image_uri] = '';
      objData[this.is_sprite_sheet] = false;
      objData[this.sprite_sheet_data] = {
        column: 1,
        row: 1,
        frame_ms: 0,
        loop_count: -1,
      };
      this.data.push(objData);
    }
  },
};
</script>
